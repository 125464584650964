/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WaterStructLockRecord
 */
export interface WaterStructLockRecord {
    /**
     * 
     * @type {number}
     * @memberof WaterStructLockRecord
     */
    id?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructLockRecord
     */
    usableLength?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructLockRecord
     */
    usableWidth?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructLockRecord
     */
    usableDepth?: number | null;
}

/**
 * Check if a given object implements the WaterStructLockRecord interface.
 */
export function instanceOfWaterStructLockRecord(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WaterStructLockRecordFromJSON(json: any): WaterStructLockRecord {
    return WaterStructLockRecordFromJSONTyped(json, false);
}

export function WaterStructLockRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): WaterStructLockRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'usableLength': !exists(json, 'usableLength') ? undefined : json['usableLength'],
        'usableWidth': !exists(json, 'usableWidth') ? undefined : json['usableWidth'],
        'usableDepth': !exists(json, 'usableDepth') ? undefined : json['usableDepth'],
    };
}

export function WaterStructLockRecordToJSON(value?: WaterStructLockRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'usableLength': value.usableLength,
        'usableWidth': value.usableWidth,
        'usableDepth': value.usableDepth,
    };
}

