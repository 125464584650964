/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CodeListItemBasicRecord } from './CodeListItemBasicRecord';
import {
    CodeListItemBasicRecordFromJSON,
    CodeListItemBasicRecordFromJSONTyped,
    CodeListItemBasicRecordToJSON,
} from './CodeListItemBasicRecord';
import type { EnvironmentRecord } from './EnvironmentRecord';
import {
    EnvironmentRecordFromJSON,
    EnvironmentRecordFromJSONTyped,
    EnvironmentRecordToJSON,
} from './EnvironmentRecord';
import type { WaterStructChannelRecord } from './WaterStructChannelRecord';
import {
    WaterStructChannelRecordFromJSON,
    WaterStructChannelRecordFromJSONTyped,
    WaterStructChannelRecordToJSON,
} from './WaterStructChannelRecord';
import type { WaterStructConduitResponseRecord } from './WaterStructConduitResponseRecord';
import {
    WaterStructConduitResponseRecordFromJSON,
    WaterStructConduitResponseRecordFromJSONTyped,
    WaterStructConduitResponseRecordToJSON,
} from './WaterStructConduitResponseRecord';
import type { WaterStructCovResponseRecord } from './WaterStructCovResponseRecord';
import {
    WaterStructCovResponseRecordFromJSON,
    WaterStructCovResponseRecordFromJSONTyped,
    WaterStructCovResponseRecordToJSON,
} from './WaterStructCovResponseRecord';
import type { WaterStructDamResponseRecord } from './WaterStructDamResponseRecord';
import {
    WaterStructDamResponseRecordFromJSON,
    WaterStructDamResponseRecordFromJSONTyped,
    WaterStructDamResponseRecordToJSON,
} from './WaterStructDamResponseRecord';
import type { WaterStructDuctResponseRecord } from './WaterStructDuctResponseRecord';
import {
    WaterStructDuctResponseRecordFromJSON,
    WaterStructDuctResponseRecordFromJSONTyped,
    WaterStructDuctResponseRecordToJSON,
} from './WaterStructDuctResponseRecord';
import type { WaterStructEmbankResponseRecord } from './WaterStructEmbankResponseRecord';
import {
    WaterStructEmbankResponseRecordFromJSON,
    WaterStructEmbankResponseRecordFromJSONTyped,
    WaterStructEmbankResponseRecordToJSON,
} from './WaterStructEmbankResponseRecord';
import type { WaterStructLockRecord } from './WaterStructLockRecord';
import {
    WaterStructLockRecordFromJSON,
    WaterStructLockRecordFromJSONTyped,
    WaterStructLockRecordToJSON,
} from './WaterStructLockRecord';
import type { WaterStructObjectResponseRecord } from './WaterStructObjectResponseRecord';
import {
    WaterStructObjectResponseRecordFromJSON,
    WaterStructObjectResponseRecordFromJSONTyped,
    WaterStructObjectResponseRecordToJSON,
} from './WaterStructObjectResponseRecord';
import type { WaterStructPortRecord } from './WaterStructPortRecord';
import {
    WaterStructPortRecordFromJSON,
    WaterStructPortRecordFromJSONTyped,
    WaterStructPortRecordToJSON,
} from './WaterStructPortRecord';
import type { WaterStructPowerPlantResponseRecord } from './WaterStructPowerPlantResponseRecord';
import {
    WaterStructPowerPlantResponseRecordFromJSON,
    WaterStructPowerPlantResponseRecordFromJSONTyped,
    WaterStructPowerPlantResponseRecordToJSON,
} from './WaterStructPowerPlantResponseRecord';
import type { WaterStructPushResponseRecord } from './WaterStructPushResponseRecord';
import {
    WaterStructPushResponseRecordFromJSON,
    WaterStructPushResponseRecordFromJSONTyped,
    WaterStructPushResponseRecordToJSON,
} from './WaterStructPushResponseRecord';
import type { WaterStructReservoirResponseRecord } from './WaterStructReservoirResponseRecord';
import {
    WaterStructReservoirResponseRecordFromJSON,
    WaterStructReservoirResponseRecordFromJSONTyped,
    WaterStructReservoirResponseRecordToJSON,
} from './WaterStructReservoirResponseRecord';
import type { WaterStructSewerResponseRecord } from './WaterStructSewerResponseRecord';
import {
    WaterStructSewerResponseRecordFromJSON,
    WaterStructSewerResponseRecordFromJSONTyped,
    WaterStructSewerResponseRecordToJSON,
} from './WaterStructSewerResponseRecord';
import type { WaterStructTankResponseRecord } from './WaterStructTankResponseRecord';
import {
    WaterStructTankResponseRecordFromJSON,
    WaterStructTankResponseRecordFromJSONTyped,
    WaterStructTankResponseRecordToJSON,
} from './WaterStructTankResponseRecord';
import type { WaterStructTempReductRecord } from './WaterStructTempReductRecord';
import {
    WaterStructTempReductRecordFromJSON,
    WaterStructTempReductRecordFromJSONTyped,
    WaterStructTempReductRecordToJSON,
} from './WaterStructTempReductRecord';
import type { WaterStructTreatmentResponseRecord } from './WaterStructTreatmentResponseRecord';
import {
    WaterStructTreatmentResponseRecordFromJSON,
    WaterStructTreatmentResponseRecordFromJSONTyped,
    WaterStructTreatmentResponseRecordToJSON,
} from './WaterStructTreatmentResponseRecord';
import type { WaterStructWatertankResponseRecord } from './WaterStructWatertankResponseRecord';
import {
    WaterStructWatertankResponseRecordFromJSON,
    WaterStructWatertankResponseRecordFromJSONTyped,
    WaterStructWatertankResponseRecordToJSON,
} from './WaterStructWatertankResponseRecord';
import type { WaterStructZdrzRecord } from './WaterStructZdrzRecord';
import {
    WaterStructZdrzRecordFromJSON,
    WaterStructZdrzRecordFromJSONTyped,
    WaterStructZdrzRecordToJSON,
} from './WaterStructZdrzRecord';

/**
 * 
 * @export
 * @interface WaterStructAppendixResponseRecord
 */
export interface WaterStructAppendixResponseRecord {
    /**
     * 
     * @type {number}
     * @memberof WaterStructAppendixResponseRecord
     */
    id?: number | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof WaterStructAppendixResponseRecord
     */
    docType?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {string}
     * @memberof WaterStructAppendixResponseRecord
     */
    dateFrom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterStructAppendixResponseRecord
     */
    dateTo?: string | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof WaterStructAppendixResponseRecord
     */
    status?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {string}
     * @memberof WaterStructAppendixResponseRecord
     */
    structName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterStructAppendixResponseRecord
     */
    hydrOrder?: string | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof WaterStructAppendixResponseRecord
     */
    tbdCat?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {boolean}
     * @memberof WaterStructAppendixResponseRecord
     */
    manipOrder?: boolean | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof WaterStructAppendixResponseRecord
     */
    waterStruct?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {string}
     * @memberof WaterStructAppendixResponseRecord
     */
    note?: string | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof WaterStructAppendixResponseRecord
     */
    structUse?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof WaterStructAppendixResponseRecord
     */
    waterOrigin?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructAppendixResponseRecord
     */
    basinArea?: number | null;
    /**
     * 
     * @type {EnvironmentRecord}
     * @memberof WaterStructAppendixResponseRecord
     */
    stream?: EnvironmentRecord | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructAppendixResponseRecord
     */
    riverKmBegin?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructAppendixResponseRecord
     */
    riverKmEnd?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructAppendixResponseRecord
     */
    posBeginXCoord?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructAppendixResponseRecord
     */
    posBeginYCoord?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructAppendixResponseRecord
     */
    posEndXCoord?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructAppendixResponseRecord
     */
    posEndYCoord?: number | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof WaterStructAppendixResponseRecord
     */
    position?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructAppendixResponseRecord
     */
    streamLength?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructAppendixResponseRecord
     */
    maxFlow?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof WaterStructAppendixResponseRecord
     */
    isCov?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructAppendixResponseRecord
     */
    embankLen?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructAppendixResponseRecord
     */
    embankHght?: number | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof WaterStructAppendixResponseRecord
     */
    irrigType?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructAppendixResponseRecord
     */
    irrigArea?: number | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof WaterStructAppendixResponseRecord
     */
    landCulture?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructAppendixResponseRecord
     */
    conduitLen?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructAppendixResponseRecord
     */
    ductLen?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructAppendixResponseRecord
     */
    accumVolume?: number | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof WaterStructAppendixResponseRecord
     */
    waterDrain?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof WaterStructAppendixResponseRecord
     */
    dischargeType?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructAppendixResponseRecord
     */
    drainArea?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructAppendixResponseRecord
     */
    channelLen?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructAppendixResponseRecord
     */
    sectionLen?: number | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof WaterStructAppendixResponseRecord
     */
    shipDevice?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof WaterStructAppendixResponseRecord
     */
    waterBleeding?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof WaterStructAppendixResponseRecord
     */
    sludgeSed?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof WaterStructAppendixResponseRecord
     */
    sludgeBedType?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {boolean}
     * @memberof WaterStructAppendixResponseRecord
     */
    isTakingOffMonit?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof WaterStructAppendixResponseRecord
     */
    isLeakMonit?: boolean | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof WaterStructAppendixResponseRecord
     */
    objManager?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof WaterStructAppendixResponseRecord
     */
    measDevType?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof WaterStructAppendixResponseRecord
     */
    well?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructAppendixResponseRecord
     */
    wellDepth?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructAppendixResponseRecord
     */
    rapidLen?: number | null;
    /**
     * 
     * @type {Array<CodeListItemBasicRecord>}
     * @memberof WaterStructAppendixResponseRecord
     */
    purifMethods?: Array<CodeListItemBasicRecord> | null;
    /**
     * 
     * @type {Array<CodeListItemBasicRecord>}
     * @memberof WaterStructAppendixResponseRecord
     */
    measParams?: Array<CodeListItemBasicRecord> | null;
    /**
     * 
     * @type {WaterStructEmbankResponseRecord}
     * @memberof WaterStructAppendixResponseRecord
     */
    embank?: WaterStructEmbankResponseRecord | null;
    /**
     * 
     * @type {WaterStructReservoirResponseRecord}
     * @memberof WaterStructAppendixResponseRecord
     */
    reservoir?: WaterStructReservoirResponseRecord | null;
    /**
     * 
     * @type {WaterStructDamResponseRecord}
     * @memberof WaterStructAppendixResponseRecord
     */
    dam?: WaterStructDamResponseRecord | null;
    /**
     * 
     * @type {WaterStructZdrzRecord}
     * @memberof WaterStructAppendixResponseRecord
     */
    zdrz?: WaterStructZdrzRecord | null;
    /**
     * 
     * @type {WaterStructDuctResponseRecord}
     * @memberof WaterStructAppendixResponseRecord
     */
    duct?: WaterStructDuctResponseRecord | null;
    /**
     * 
     * @type {WaterStructObjectResponseRecord}
     * @memberof WaterStructAppendixResponseRecord
     */
    object?: WaterStructObjectResponseRecord | null;
    /**
     * 
     * @type {WaterStructTreatmentResponseRecord}
     * @memberof WaterStructAppendixResponseRecord
     */
    treatment?: WaterStructTreatmentResponseRecord | null;
    /**
     * 
     * @type {WaterStructWatertankResponseRecord}
     * @memberof WaterStructAppendixResponseRecord
     */
    watertank?: WaterStructWatertankResponseRecord | null;
    /**
     * 
     * @type {WaterStructPushResponseRecord}
     * @memberof WaterStructAppendixResponseRecord
     */
    push?: WaterStructPushResponseRecord | null;
    /**
     * 
     * @type {WaterStructSewerResponseRecord}
     * @memberof WaterStructAppendixResponseRecord
     */
    sewer?: WaterStructSewerResponseRecord | null;
    /**
     * 
     * @type {WaterStructTempReductRecord}
     * @memberof WaterStructAppendixResponseRecord
     */
    tempReduct?: WaterStructTempReductRecord | null;
    /**
     * 
     * @type {WaterStructTankResponseRecord}
     * @memberof WaterStructAppendixResponseRecord
     */
    tank?: WaterStructTankResponseRecord | null;
    /**
     * 
     * @type {WaterStructCovResponseRecord}
     * @memberof WaterStructAppendixResponseRecord
     */
    cov?: WaterStructCovResponseRecord | null;
    /**
     * 
     * @type {WaterStructLockRecord}
     * @memberof WaterStructAppendixResponseRecord
     */
    lock?: WaterStructLockRecord | null;
    /**
     * 
     * @type {WaterStructChannelRecord}
     * @memberof WaterStructAppendixResponseRecord
     */
    channel?: WaterStructChannelRecord | null;
    /**
     * 
     * @type {WaterStructPowerPlantResponseRecord}
     * @memberof WaterStructAppendixResponseRecord
     */
    powerPlant?: WaterStructPowerPlantResponseRecord | null;
    /**
     * 
     * @type {WaterStructConduitResponseRecord}
     * @memberof WaterStructAppendixResponseRecord
     */
    conduit?: WaterStructConduitResponseRecord | null;
    /**
     * 
     * @type {WaterStructPortRecord}
     * @memberof WaterStructAppendixResponseRecord
     */
    port?: WaterStructPortRecord | null;
    /**
     * 
     * @type {string}
     * @memberof WaterStructAppendixResponseRecord
     */
    createdTimestamp?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterStructAppendixResponseRecord
     */
    createdBy?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterStructAppendixResponseRecord
     */
    updatedTimestamp?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterStructAppendixResponseRecord
     */
    updatedBy?: string | null;
}

/**
 * Check if a given object implements the WaterStructAppendixResponseRecord interface.
 */
export function instanceOfWaterStructAppendixResponseRecord(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WaterStructAppendixResponseRecordFromJSON(json: any): WaterStructAppendixResponseRecord {
    return WaterStructAppendixResponseRecordFromJSONTyped(json, false);
}

export function WaterStructAppendixResponseRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): WaterStructAppendixResponseRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'docType': !exists(json, 'docType') ? undefined : CodeListItemBasicRecordFromJSON(json['docType']),
        'dateFrom': !exists(json, 'dateFrom') ? undefined : json['dateFrom'],
        'dateTo': !exists(json, 'dateTo') ? undefined : json['dateTo'],
        'status': !exists(json, 'status') ? undefined : CodeListItemBasicRecordFromJSON(json['status']),
        'structName': !exists(json, 'structName') ? undefined : json['structName'],
        'hydrOrder': !exists(json, 'hydrOrder') ? undefined : json['hydrOrder'],
        'tbdCat': !exists(json, 'tbdCat') ? undefined : CodeListItemBasicRecordFromJSON(json['tbdCat']),
        'manipOrder': !exists(json, 'manipOrder') ? undefined : json['manipOrder'],
        'waterStruct': !exists(json, 'waterStruct') ? undefined : CodeListItemBasicRecordFromJSON(json['waterStruct']),
        'note': !exists(json, 'note') ? undefined : json['note'],
        'structUse': !exists(json, 'structUse') ? undefined : CodeListItemBasicRecordFromJSON(json['structUse']),
        'waterOrigin': !exists(json, 'waterOrigin') ? undefined : CodeListItemBasicRecordFromJSON(json['waterOrigin']),
        'basinArea': !exists(json, 'basinArea') ? undefined : json['basinArea'],
        'stream': !exists(json, 'stream') ? undefined : EnvironmentRecordFromJSON(json['stream']),
        'riverKmBegin': !exists(json, 'riverKmBegin') ? undefined : json['riverKmBegin'],
        'riverKmEnd': !exists(json, 'riverKmEnd') ? undefined : json['riverKmEnd'],
        'posBeginXCoord': !exists(json, 'posBeginXCoord') ? undefined : json['posBeginXCoord'],
        'posBeginYCoord': !exists(json, 'posBeginYCoord') ? undefined : json['posBeginYCoord'],
        'posEndXCoord': !exists(json, 'posEndXCoord') ? undefined : json['posEndXCoord'],
        'posEndYCoord': !exists(json, 'posEndYCoord') ? undefined : json['posEndYCoord'],
        'position': !exists(json, 'position') ? undefined : CodeListItemBasicRecordFromJSON(json['position']),
        'streamLength': !exists(json, 'streamLength') ? undefined : json['streamLength'],
        'maxFlow': !exists(json, 'maxFlow') ? undefined : json['maxFlow'],
        'isCov': !exists(json, 'isCov') ? undefined : json['isCov'],
        'embankLen': !exists(json, 'embankLen') ? undefined : json['embankLen'],
        'embankHght': !exists(json, 'embankHght') ? undefined : json['embankHght'],
        'irrigType': !exists(json, 'irrigType') ? undefined : CodeListItemBasicRecordFromJSON(json['irrigType']),
        'irrigArea': !exists(json, 'irrigArea') ? undefined : json['irrigArea'],
        'landCulture': !exists(json, 'landCulture') ? undefined : CodeListItemBasicRecordFromJSON(json['landCulture']),
        'conduitLen': !exists(json, 'conduitLen') ? undefined : json['conduitLen'],
        'ductLen': !exists(json, 'ductLen') ? undefined : json['ductLen'],
        'accumVolume': !exists(json, 'accumVolume') ? undefined : json['accumVolume'],
        'waterDrain': !exists(json, 'waterDrain') ? undefined : CodeListItemBasicRecordFromJSON(json['waterDrain']),
        'dischargeType': !exists(json, 'dischargeType') ? undefined : CodeListItemBasicRecordFromJSON(json['dischargeType']),
        'drainArea': !exists(json, 'drainArea') ? undefined : json['drainArea'],
        'channelLen': !exists(json, 'channelLen') ? undefined : json['channelLen'],
        'sectionLen': !exists(json, 'sectionLen') ? undefined : json['sectionLen'],
        'shipDevice': !exists(json, 'shipDevice') ? undefined : CodeListItemBasicRecordFromJSON(json['shipDevice']),
        'waterBleeding': !exists(json, 'waterBleeding') ? undefined : CodeListItemBasicRecordFromJSON(json['waterBleeding']),
        'sludgeSed': !exists(json, 'sludgeSed') ? undefined : CodeListItemBasicRecordFromJSON(json['sludgeSed']),
        'sludgeBedType': !exists(json, 'sludgeBedType') ? undefined : CodeListItemBasicRecordFromJSON(json['sludgeBedType']),
        'isTakingOffMonit': !exists(json, 'isTakingOffMonit') ? undefined : json['isTakingOffMonit'],
        'isLeakMonit': !exists(json, 'isLeakMonit') ? undefined : json['isLeakMonit'],
        'objManager': !exists(json, 'objManager') ? undefined : CodeListItemBasicRecordFromJSON(json['objManager']),
        'measDevType': !exists(json, 'measDevType') ? undefined : CodeListItemBasicRecordFromJSON(json['measDevType']),
        'well': !exists(json, 'well') ? undefined : CodeListItemBasicRecordFromJSON(json['well']),
        'wellDepth': !exists(json, 'wellDepth') ? undefined : json['wellDepth'],
        'rapidLen': !exists(json, 'rapidLen') ? undefined : json['rapidLen'],
        'purifMethods': !exists(json, 'purifMethods') ? undefined : (json['purifMethods'] === null ? null : (json['purifMethods'] as Array<any>).map(CodeListItemBasicRecordFromJSON)),
        'measParams': !exists(json, 'measParams') ? undefined : (json['measParams'] === null ? null : (json['measParams'] as Array<any>).map(CodeListItemBasicRecordFromJSON)),
        'embank': !exists(json, 'embank') ? undefined : WaterStructEmbankResponseRecordFromJSON(json['embank']),
        'reservoir': !exists(json, 'reservoir') ? undefined : WaterStructReservoirResponseRecordFromJSON(json['reservoir']),
        'dam': !exists(json, 'dam') ? undefined : WaterStructDamResponseRecordFromJSON(json['dam']),
        'zdrz': !exists(json, 'zdrz') ? undefined : WaterStructZdrzRecordFromJSON(json['zdrz']),
        'duct': !exists(json, 'duct') ? undefined : WaterStructDuctResponseRecordFromJSON(json['duct']),
        'object': !exists(json, 'object') ? undefined : WaterStructObjectResponseRecordFromJSON(json['object']),
        'treatment': !exists(json, 'treatment') ? undefined : WaterStructTreatmentResponseRecordFromJSON(json['treatment']),
        'watertank': !exists(json, 'watertank') ? undefined : WaterStructWatertankResponseRecordFromJSON(json['watertank']),
        'push': !exists(json, 'push') ? undefined : WaterStructPushResponseRecordFromJSON(json['push']),
        'sewer': !exists(json, 'sewer') ? undefined : WaterStructSewerResponseRecordFromJSON(json['sewer']),
        'tempReduct': !exists(json, 'tempReduct') ? undefined : WaterStructTempReductRecordFromJSON(json['tempReduct']),
        'tank': !exists(json, 'tank') ? undefined : WaterStructTankResponseRecordFromJSON(json['tank']),
        'cov': !exists(json, 'cov') ? undefined : WaterStructCovResponseRecordFromJSON(json['cov']),
        'lock': !exists(json, 'lock') ? undefined : WaterStructLockRecordFromJSON(json['lock']),
        'channel': !exists(json, 'channel') ? undefined : WaterStructChannelRecordFromJSON(json['channel']),
        'powerPlant': !exists(json, 'powerPlant') ? undefined : WaterStructPowerPlantResponseRecordFromJSON(json['powerPlant']),
        'conduit': !exists(json, 'conduit') ? undefined : WaterStructConduitResponseRecordFromJSON(json['conduit']),
        'port': !exists(json, 'port') ? undefined : WaterStructPortRecordFromJSON(json['port']),
        'createdTimestamp': !exists(json, 'createdTimestamp') ? undefined : json['createdTimestamp'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'updatedTimestamp': !exists(json, 'updatedTimestamp') ? undefined : json['updatedTimestamp'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
    };
}

export function WaterStructAppendixResponseRecordToJSON(value?: WaterStructAppendixResponseRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'docType': CodeListItemBasicRecordToJSON(value.docType),
        'dateFrom': value.dateFrom,
        'dateTo': value.dateTo,
        'status': CodeListItemBasicRecordToJSON(value.status),
        'structName': value.structName,
        'hydrOrder': value.hydrOrder,
        'tbdCat': CodeListItemBasicRecordToJSON(value.tbdCat),
        'manipOrder': value.manipOrder,
        'waterStruct': CodeListItemBasicRecordToJSON(value.waterStruct),
        'note': value.note,
        'structUse': CodeListItemBasicRecordToJSON(value.structUse),
        'waterOrigin': CodeListItemBasicRecordToJSON(value.waterOrigin),
        'basinArea': value.basinArea,
        'stream': EnvironmentRecordToJSON(value.stream),
        'riverKmBegin': value.riverKmBegin,
        'riverKmEnd': value.riverKmEnd,
        'posBeginXCoord': value.posBeginXCoord,
        'posBeginYCoord': value.posBeginYCoord,
        'posEndXCoord': value.posEndXCoord,
        'posEndYCoord': value.posEndYCoord,
        'position': CodeListItemBasicRecordToJSON(value.position),
        'streamLength': value.streamLength,
        'maxFlow': value.maxFlow,
        'isCov': value.isCov,
        'embankLen': value.embankLen,
        'embankHght': value.embankHght,
        'irrigType': CodeListItemBasicRecordToJSON(value.irrigType),
        'irrigArea': value.irrigArea,
        'landCulture': CodeListItemBasicRecordToJSON(value.landCulture),
        'conduitLen': value.conduitLen,
        'ductLen': value.ductLen,
        'accumVolume': value.accumVolume,
        'waterDrain': CodeListItemBasicRecordToJSON(value.waterDrain),
        'dischargeType': CodeListItemBasicRecordToJSON(value.dischargeType),
        'drainArea': value.drainArea,
        'channelLen': value.channelLen,
        'sectionLen': value.sectionLen,
        'shipDevice': CodeListItemBasicRecordToJSON(value.shipDevice),
        'waterBleeding': CodeListItemBasicRecordToJSON(value.waterBleeding),
        'sludgeSed': CodeListItemBasicRecordToJSON(value.sludgeSed),
        'sludgeBedType': CodeListItemBasicRecordToJSON(value.sludgeBedType),
        'isTakingOffMonit': value.isTakingOffMonit,
        'isLeakMonit': value.isLeakMonit,
        'objManager': CodeListItemBasicRecordToJSON(value.objManager),
        'measDevType': CodeListItemBasicRecordToJSON(value.measDevType),
        'well': CodeListItemBasicRecordToJSON(value.well),
        'wellDepth': value.wellDepth,
        'rapidLen': value.rapidLen,
        'purifMethods': value.purifMethods === undefined ? undefined : (value.purifMethods === null ? null : (value.purifMethods as Array<any>).map(CodeListItemBasicRecordToJSON)),
        'measParams': value.measParams === undefined ? undefined : (value.measParams === null ? null : (value.measParams as Array<any>).map(CodeListItemBasicRecordToJSON)),
        'embank': WaterStructEmbankResponseRecordToJSON(value.embank),
        'reservoir': WaterStructReservoirResponseRecordToJSON(value.reservoir),
        'dam': WaterStructDamResponseRecordToJSON(value.dam),
        'zdrz': WaterStructZdrzRecordToJSON(value.zdrz),
        'duct': WaterStructDuctResponseRecordToJSON(value.duct),
        'object': WaterStructObjectResponseRecordToJSON(value.object),
        'treatment': WaterStructTreatmentResponseRecordToJSON(value.treatment),
        'watertank': WaterStructWatertankResponseRecordToJSON(value.watertank),
        'push': WaterStructPushResponseRecordToJSON(value.push),
        'sewer': WaterStructSewerResponseRecordToJSON(value.sewer),
        'tempReduct': WaterStructTempReductRecordToJSON(value.tempReduct),
        'tank': WaterStructTankResponseRecordToJSON(value.tank),
        'cov': WaterStructCovResponseRecordToJSON(value.cov),
        'lock': WaterStructLockRecordToJSON(value.lock),
        'channel': WaterStructChannelRecordToJSON(value.channel),
        'powerPlant': WaterStructPowerPlantResponseRecordToJSON(value.powerPlant),
        'conduit': WaterStructConduitResponseRecordToJSON(value.conduit),
        'port': WaterStructPortRecordToJSON(value.port),
        'createdTimestamp': value.createdTimestamp,
        'createdBy': value.createdBy,
        'updatedTimestamp': value.updatedTimestamp,
        'updatedBy': value.updatedBy,
    };
}

