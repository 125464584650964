/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WaterStructEmbankRequestRecord
 */
export interface WaterStructEmbankRequestRecord {
    /**
     * 
     * @type {number}
     * @memberof WaterStructEmbankRequestRecord
     */
    id?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructEmbankRequestRecord
     */
    typeCode?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructEmbankRequestRecord
     */
    kindCode?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructEmbankRequestRecord
     */
    locCode?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructEmbankRequestRecord
     */
    altit?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructEmbankRequestRecord
     */
    height?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructEmbankRequestRecord
     */
    length?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructEmbankRequestRecord
     */
    cap?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructEmbankRequestRecord
     */
    capoth?: number | null;
}

/**
 * Check if a given object implements the WaterStructEmbankRequestRecord interface.
 */
export function instanceOfWaterStructEmbankRequestRecord(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WaterStructEmbankRequestRecordFromJSON(json: any): WaterStructEmbankRequestRecord {
    return WaterStructEmbankRequestRecordFromJSONTyped(json, false);
}

export function WaterStructEmbankRequestRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): WaterStructEmbankRequestRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'typeCode': !exists(json, 'typeCode') ? undefined : json['typeCode'],
        'kindCode': !exists(json, 'kindCode') ? undefined : json['kindCode'],
        'locCode': !exists(json, 'locCode') ? undefined : json['locCode'],
        'altit': !exists(json, 'altit') ? undefined : json['altit'],
        'height': !exists(json, 'height') ? undefined : json['height'],
        'length': !exists(json, 'length') ? undefined : json['length'],
        'cap': !exists(json, 'cap') ? undefined : json['cap'],
        'capoth': !exists(json, 'capoth') ? undefined : json['capoth'],
    };
}

export function WaterStructEmbankRequestRecordToJSON(value?: WaterStructEmbankRequestRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'typeCode': value.typeCode,
        'kindCode': value.kindCode,
        'locCode': value.locCode,
        'altit': value.altit,
        'height': value.height,
        'length': value.length,
        'cap': value.cap,
        'capoth': value.capoth,
    };
}

