/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WaterStructPowerPlantRequestRecord
 */
export interface WaterStructPowerPlantRequestRecord {
    /**
     * 
     * @type {number}
     * @memberof WaterStructPowerPlantRequestRecord
     */
    id?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructPowerPlantRequestRecord
     */
    maxFall?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructPowerPlantRequestRecord
     */
    minFall?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructPowerPlantRequestRecord
     */
    turbines?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructPowerPlantRequestRecord
     */
    turbineTypeCode?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructPowerPlantRequestRecord
     */
    desFlow?: number | null;
    /**
     * 
     * @type {string}
     * @memberof WaterStructPowerPlantRequestRecord
     */
    perform?: string | null;
}

/**
 * Check if a given object implements the WaterStructPowerPlantRequestRecord interface.
 */
export function instanceOfWaterStructPowerPlantRequestRecord(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WaterStructPowerPlantRequestRecordFromJSON(json: any): WaterStructPowerPlantRequestRecord {
    return WaterStructPowerPlantRequestRecordFromJSONTyped(json, false);
}

export function WaterStructPowerPlantRequestRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): WaterStructPowerPlantRequestRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'maxFall': !exists(json, 'maxFall') ? undefined : json['maxFall'],
        'minFall': !exists(json, 'minFall') ? undefined : json['minFall'],
        'turbines': !exists(json, 'turbines') ? undefined : json['turbines'],
        'turbineTypeCode': !exists(json, 'turbineTypeCode') ? undefined : json['turbineTypeCode'],
        'desFlow': !exists(json, 'desFlow') ? undefined : json['desFlow'],
        'perform': !exists(json, 'perform') ? undefined : json['perform'],
    };
}

export function WaterStructPowerPlantRequestRecordToJSON(value?: WaterStructPowerPlantRequestRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'maxFall': value.maxFall,
        'minFall': value.minFall,
        'turbines': value.turbines,
        'turbineTypeCode': value.turbineTypeCode,
        'desFlow': value.desFlow,
        'perform': value.perform,
    };
}

