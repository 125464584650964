/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CodeListItemBasicRecord } from './CodeListItemBasicRecord';
import {
    CodeListItemBasicRecordFromJSON,
    CodeListItemBasicRecordFromJSONTyped,
    CodeListItemBasicRecordToJSON,
} from './CodeListItemBasicRecord';

/**
 * 
 * @export
 * @interface DangerSubstanceResponseRecord
 */
export interface DangerSubstanceResponseRecord {
    /**
     * 
     * @type {number}
     * @memberof DangerSubstanceResponseRecord
     */
    id?: number | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof DangerSubstanceResponseRecord
     */
    dangerSubstance?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof DangerSubstanceResponseRecord
     */
    concUnit?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {number}
     * @memberof DangerSubstanceResponseRecord
     */
    concMonth?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DangerSubstanceResponseRecord
     */
    concDay?: number | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof DangerSubstanceResponseRecord
     */
    balUnit?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {number}
     * @memberof DangerSubstanceResponseRecord
     */
    balMonth?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DangerSubstanceResponseRecord
     */
    balDay?: number | null;
}

/**
 * Check if a given object implements the DangerSubstanceResponseRecord interface.
 */
export function instanceOfDangerSubstanceResponseRecord(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DangerSubstanceResponseRecordFromJSON(json: any): DangerSubstanceResponseRecord {
    return DangerSubstanceResponseRecordFromJSONTyped(json, false);
}

export function DangerSubstanceResponseRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): DangerSubstanceResponseRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'dangerSubstance': !exists(json, 'dangerSubstance') ? undefined : CodeListItemBasicRecordFromJSON(json['dangerSubstance']),
        'concUnit': !exists(json, 'concUnit') ? undefined : CodeListItemBasicRecordFromJSON(json['concUnit']),
        'concMonth': !exists(json, 'concMonth') ? undefined : json['concMonth'],
        'concDay': !exists(json, 'concDay') ? undefined : json['concDay'],
        'balUnit': !exists(json, 'balUnit') ? undefined : CodeListItemBasicRecordFromJSON(json['balUnit']),
        'balMonth': !exists(json, 'balMonth') ? undefined : json['balMonth'],
        'balDay': !exists(json, 'balDay') ? undefined : json['balDay'],
    };
}

export function DangerSubstanceResponseRecordToJSON(value?: DangerSubstanceResponseRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'dangerSubstance': CodeListItemBasicRecordToJSON(value.dangerSubstance),
        'concUnit': CodeListItemBasicRecordToJSON(value.concUnit),
        'concMonth': value.concMonth,
        'concDay': value.concDay,
        'balUnit': CodeListItemBasicRecordToJSON(value.balUnit),
        'balMonth': value.balMonth,
        'balDay': value.balDay,
    };
}

