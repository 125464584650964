/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TechnicalAttributeRecord
 */
export interface TechnicalAttributeRecord {
    /**
     * 
     * @type {string}
     * @memberof TechnicalAttributeRecord
     */
    creator?: string;
    /**
     * 
     * @type {string}
     * @memberof TechnicalAttributeRecord
     */
    dateCreated?: string;
    /**
     * 
     * @type {string}
     * @memberof TechnicalAttributeRecord
     */
    modifier?: string;
    /**
     * 
     * @type {string}
     * @memberof TechnicalAttributeRecord
     */
    dateModified?: string;
}

/**
 * Check if a given object implements the TechnicalAttributeRecord interface.
 */
export function instanceOfTechnicalAttributeRecord(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TechnicalAttributeRecordFromJSON(json: any): TechnicalAttributeRecord {
    return TechnicalAttributeRecordFromJSONTyped(json, false);
}

export function TechnicalAttributeRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): TechnicalAttributeRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'creator': !exists(json, 'creator') ? undefined : json['creator'],
        'dateCreated': !exists(json, 'dateCreated') ? undefined : json['dateCreated'],
        'modifier': !exists(json, 'modifier') ? undefined : json['modifier'],
        'dateModified': !exists(json, 'dateModified') ? undefined : json['dateModified'],
    };
}

export function TechnicalAttributeRecordToJSON(value?: TechnicalAttributeRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'creator': value.creator,
        'dateCreated': value.dateCreated,
        'modifier': value.modifier,
        'dateModified': value.dateModified,
    };
}

