/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiExceptionRecord,
  PageSubmissionRecord,
  PairMonitoringPointsResponse,
  SetMonitoringPointRecord,
  SubmissionAttachmentRecord,
  SubmissionContentRecord,
  TransformedEform,
} from '../models/index';
import {
    ApiExceptionRecordFromJSON,
    ApiExceptionRecordToJSON,
    PageSubmissionRecordFromJSON,
    PageSubmissionRecordToJSON,
    PairMonitoringPointsResponseFromJSON,
    PairMonitoringPointsResponseToJSON,
    SetMonitoringPointRecordFromJSON,
    SetMonitoringPointRecordToJSON,
    SubmissionAttachmentRecordFromJSON,
    SubmissionAttachmentRecordToJSON,
    SubmissionContentRecordFromJSON,
    SubmissionContentRecordToJSON,
    TransformedEformFromJSON,
    TransformedEformToJSON,
} from '../models/index';

export interface SubmissionsPUBLICAPIApiApproveSubmissionRequest {
    submissionId: number;
}

export interface SubmissionsPUBLICAPIApiAssignSubmissionRequest {
    submissionId: number;
}

export interface SubmissionsPUBLICAPIApiCreateNewSubmissionDraftRequest {
    templateId: number;
}

export interface SubmissionsPUBLICAPIApiCreateNewSubmissionDraft1Request {
    templateIdentifier: string;
    createdDate?: string;
}

export interface SubmissionsPUBLICAPIApiDeleteSubmissionAttachmentRequest {
    submissionId: number;
    attachmentId: number;
}

export interface SubmissionsPUBLICAPIApiGenerateExcelFile1Request {
    requestBody: { [key: string]: string; };
    page?: number;
    size?: number;
    sort?: string;
}

export interface SubmissionsPUBLICAPIApiGeneratePdf1Request {
    submissionId: number;
}

export interface SubmissionsPUBLICAPIApiGenerateReportRequest {
    limit?: number;
}

export interface SubmissionsPUBLICAPIApiGetMonitoringPointsRequest {
    submissionId: number;
    setMonitoringPointRecord: SetMonitoringPointRecord;
}

export interface SubmissionsPUBLICAPIApiGetMonitoringPoints1Request {
    submissionId: number;
}

export interface SubmissionsPUBLICAPIApiGetSubmissionRequest {
    submissionId: number;
}

export interface SubmissionsPUBLICAPIApiGetSubmissionAttachmentsRequest {
    submissionId: number;
}

export interface SubmissionsPUBLICAPIApiGetSubmissionEditRequest {
    submissionId: number;
    url?: string;
}

export interface SubmissionsPUBLICAPIApiGetSubmissionHtmlRequest {
    submissionId: number;
    url?: string;
}

export interface SubmissionsPUBLICAPIApiGetSubmissionReadOnlyRequest {
    submissionId: number;
    url?: string;
}

export interface SubmissionsPUBLICAPIApiGetSubmissionStateRequest {
    submissionId: number;
}

export interface SubmissionsPUBLICAPIApiGetSubmissionVersionsRequest {
    submissionId: number;
}

export interface SubmissionsPUBLICAPIApiImportOldXmlRequest {
    charset: string;
    file: Blob;
    createdDate?: string;
}

export interface SubmissionsPUBLICAPIApiRejectSubmissionRequest {
    submissionId: number;
}

export interface SubmissionsPUBLICAPIApiSaveSubmissionDraftRequest {
    submissionId: number;
    xmlData: string;
}

export interface SubmissionsPUBLICAPIApiSearchSubmissionsRequest {
    requestBody: { [key: string]: string; };
    page?: number;
    size?: number;
    sort?: string;
}

export interface SubmissionsPUBLICAPIApiSearchSubmissionsByCriteriaRequest {
    requestBody: { [key: string]: string; };
    page?: number;
    size?: number;
    sort?: string;
}

export interface SubmissionsPUBLICAPIApiSubmitRequest {
    xmlData: string;
    submissionId: number;
}

export interface SubmissionsPUBLICAPIApiUpdateSubmissionByReviewerRequest {
    submissionId: number;
    xmlData: string;
}

export interface SubmissionsPUBLICAPIApiUpdateSubmissionByReviewerV2Request {
    submissionId: number;
    body: string;
}

export interface SubmissionsPUBLICAPIApiUploadSubmissionAttachmentRequest {
    submissionId: string;
    file: Blob;
}

/**
 * 
 */
export class SubmissionsPUBLICAPIApi extends runtime.BaseAPI {

    /**
     * Approve a submission by id.
     * Approve submission
     */
    async approveSubmissionRaw(requestParameters: SubmissionsPUBLICAPIApiApproveSubmissionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.submissionId === null || requestParameters.submissionId === undefined) {
            throw new runtime.RequiredError('submissionId','Required parameter requestParameters.submissionId was null or undefined when calling approveSubmission.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/submissions/v1/approve/{submissionId}`.replace(`{${"submissionId"}}`, encodeURIComponent(String(requestParameters.submissionId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Approve a submission by id.
     * Approve submission
     */
    async approveSubmission(submissionId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.approveSubmissionRaw({ submissionId: submissionId }, initOverrides);
        return await response.value();
    }

    /**
     * Assign the submission to SHMU admin user.
     * Assign submission
     */
    async assignSubmissionRaw(requestParameters: SubmissionsPUBLICAPIApiAssignSubmissionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.submissionId === null || requestParameters.submissionId === undefined) {
            throw new runtime.RequiredError('submissionId','Required parameter requestParameters.submissionId was null or undefined when calling assignSubmission.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/submissions/v1/assign/{submissionId}`.replace(`{${"submissionId"}}`, encodeURIComponent(String(requestParameters.submissionId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Assign the submission to SHMU admin user.
     * Assign submission
     */
    async assignSubmission(submissionId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.assignSubmissionRaw({ submissionId: submissionId }, initOverrides);
    }

    /**
     */
    async createNewSubmissionDraftRaw(requestParameters: SubmissionsPUBLICAPIApiCreateNewSubmissionDraftRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.templateId === null || requestParameters.templateId === undefined) {
            throw new runtime.RequiredError('templateId','Required parameter requestParameters.templateId was null or undefined when calling createNewSubmissionDraft.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/submissions/v1/create-submission-from/{templateId}`.replace(`{${"templateId"}}`, encodeURIComponent(String(requestParameters.templateId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async createNewSubmissionDraft(templateId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.createNewSubmissionDraftRaw({ templateId: templateId }, initOverrides);
        return await response.value();
    }

    /**
     * Creates empty submission by template identifier and returs submission id.
     * Create empty submission
     */
    async createNewSubmissionDraft1Raw(requestParameters: SubmissionsPUBLICAPIApiCreateNewSubmissionDraft1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.templateIdentifier === null || requestParameters.templateIdentifier === undefined) {
            throw new runtime.RequiredError('templateIdentifier','Required parameter requestParameters.templateIdentifier was null or undefined when calling createNewSubmissionDraft1.');
        }

        const queryParameters: any = {};

        if (requestParameters.createdDate !== undefined) {
            queryParameters['createdDate'] = requestParameters.createdDate;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/submissions/v1/create-submission-by-identifier/{templateIdentifier}`.replace(`{${"templateIdentifier"}}`, encodeURIComponent(String(requestParameters.templateIdentifier))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Creates empty submission by template identifier and returs submission id.
     * Create empty submission
     */
    async createNewSubmissionDraft1(templateIdentifier: string, createdDate?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.createNewSubmissionDraft1Raw({ templateIdentifier: templateIdentifier, createdDate: createdDate }, initOverrides);
        return await response.value();
    }

    /**
     * Delete submission attachment.
     * Delete submission attachment
     */
    async deleteSubmissionAttachmentRaw(requestParameters: SubmissionsPUBLICAPIApiDeleteSubmissionAttachmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.submissionId === null || requestParameters.submissionId === undefined) {
            throw new runtime.RequiredError('submissionId','Required parameter requestParameters.submissionId was null or undefined when calling deleteSubmissionAttachment.');
        }

        if (requestParameters.attachmentId === null || requestParameters.attachmentId === undefined) {
            throw new runtime.RequiredError('attachmentId','Required parameter requestParameters.attachmentId was null or undefined when calling deleteSubmissionAttachment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/submissions/v1/{submissionId}/delete-attachment/{attachmentId}`.replace(`{${"submissionId"}}`, encodeURIComponent(String(requestParameters.submissionId))).replace(`{${"attachmentId"}}`, encodeURIComponent(String(requestParameters.attachmentId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Delete submission attachment.
     * Delete submission attachment
     */
    async deleteSubmissionAttachment(submissionId: number, attachmentId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.deleteSubmissionAttachmentRaw({ submissionId: submissionId, attachmentId: attachmentId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async generateExcelFile1Raw(requestParameters: SubmissionsPUBLICAPIApiGenerateExcelFile1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling generateExcelFile1.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/submissions/export`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async generateExcelFile1(requestBody: { [key: string]: string; }, page?: number, size?: number, sort?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.generateExcelFile1Raw({ requestBody: requestBody, page: page, size: size, sort: sort }, initOverrides);
        return await response.value();
    }

    /**
     * Generates a PDF file from the submission.
     * Generate PDF
     */
    async generatePdf1Raw(requestParameters: SubmissionsPUBLICAPIApiGeneratePdf1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.submissionId === null || requestParameters.submissionId === undefined) {
            throw new runtime.RequiredError('submissionId','Required parameter requestParameters.submissionId was null or undefined when calling generatePdf1.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/submissions/v1/generatePdf/{submissionId}`.replace(`{${"submissionId"}}`, encodeURIComponent(String(requestParameters.submissionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Generates a PDF file from the submission.
     * Generate PDF
     */
    async generatePdf1(submissionId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.generatePdf1Raw({ submissionId: submissionId }, initOverrides);
        return await response.value();
    }

    /**
     * Generates and excel file with 100 received submission
     * Generate XLSx report
     */
    async generateReportRaw(requestParameters: SubmissionsPUBLICAPIApiGenerateReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/submissions/v1/generateReport`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Generates and excel file with 100 received submission
     * Generate XLSx report
     */
    async generateReport(limit?: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.generateReportRaw({ limit: limit }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getMonitoringPointsRaw(requestParameters: SubmissionsPUBLICAPIApiGetMonitoringPointsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.submissionId === null || requestParameters.submissionId === undefined) {
            throw new runtime.RequiredError('submissionId','Required parameter requestParameters.submissionId was null or undefined when calling getMonitoringPoints.');
        }

        if (requestParameters.setMonitoringPointRecord === null || requestParameters.setMonitoringPointRecord === undefined) {
            throw new runtime.RequiredError('setMonitoringPointRecord','Required parameter requestParameters.setMonitoringPointRecord was null or undefined when calling getMonitoringPoints.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/submissions/v1/set-monitoring-point/{submissionId}`.replace(`{${"submissionId"}}`, encodeURIComponent(String(requestParameters.submissionId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetMonitoringPointRecordToJSON(requestParameters.setMonitoringPointRecord),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async getMonitoringPoints(submissionId: number, setMonitoringPointRecord: SetMonitoringPointRecord, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.getMonitoringPointsRaw({ submissionId: submissionId, setMonitoringPointRecord: setMonitoringPointRecord }, initOverrides);
    }

    /**
     */
    async getMonitoringPoints1Raw(requestParameters: SubmissionsPUBLICAPIApiGetMonitoringPoints1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PairMonitoringPointsResponse>> {
        if (requestParameters.submissionId === null || requestParameters.submissionId === undefined) {
            throw new runtime.RequiredError('submissionId','Required parameter requestParameters.submissionId was null or undefined when calling getMonitoringPoints1.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/submissions/v1/get-monitoring-points/{submissionId}`.replace(`{${"submissionId"}}`, encodeURIComponent(String(requestParameters.submissionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PairMonitoringPointsResponseFromJSON(jsonValue));
    }

    /**
     */
    async getMonitoringPoints1(submissionId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PairMonitoringPointsResponse> {
        const response = await this.getMonitoringPoints1Raw({ submissionId: submissionId }, initOverrides);
        return await response.value();
    }

    /**
     * Get submission data in JSON
     */
    async getSubmissionRaw(requestParameters: SubmissionsPUBLICAPIApiGetSubmissionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.submissionId === null || requestParameters.submissionId === undefined) {
            throw new runtime.RequiredError('submissionId','Required parameter requestParameters.submissionId was null or undefined when calling getSubmission.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/submissions/v1/{submissionId}`.replace(`{${"submissionId"}}`, encodeURIComponent(String(requestParameters.submissionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Get submission data in JSON
     */
    async getSubmission(submissionId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.getSubmissionRaw({ submissionId: submissionId }, initOverrides);
        return await response.value();
    }

    /**
     * Get list of attachments of submission by id.
     * Get submission attachments
     */
    async getSubmissionAttachmentsRaw(requestParameters: SubmissionsPUBLICAPIApiGetSubmissionAttachmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SubmissionAttachmentRecord>>> {
        if (requestParameters.submissionId === null || requestParameters.submissionId === undefined) {
            throw new runtime.RequiredError('submissionId','Required parameter requestParameters.submissionId was null or undefined when calling getSubmissionAttachments.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/submissions/v1/{submissionId}/attachments`.replace(`{${"submissionId"}}`, encodeURIComponent(String(requestParameters.submissionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SubmissionAttachmentRecordFromJSON));
    }

    /**
     * Get list of attachments of submission by id.
     * Get submission attachments
     */
    async getSubmissionAttachments(submissionId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SubmissionAttachmentRecord>> {
        const response = await this.getSubmissionAttachmentsRaw({ submissionId: submissionId }, initOverrides);
        return await response.value();
    }

    /**
     * Returns the submission in edit mode HTML format.
     * Get submission in edit mode HTML
     */
    async getSubmissionEditRaw(requestParameters: SubmissionsPUBLICAPIApiGetSubmissionEditRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.submissionId === null || requestParameters.submissionId === undefined) {
            throw new runtime.RequiredError('submissionId','Required parameter requestParameters.submissionId was null or undefined when calling getSubmissionEdit.');
        }

        const queryParameters: any = {};

        if (requestParameters.url !== undefined) {
            queryParameters['url'] = requestParameters.url;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/submissions/v1/html/{submissionId}/edit`.replace(`{${"submissionId"}}`, encodeURIComponent(String(requestParameters.submissionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Returns the submission in edit mode HTML format.
     * Get submission in edit mode HTML
     */
    async getSubmissionEdit(submissionId: number, url?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.getSubmissionEditRaw({ submissionId: submissionId, url: url }, initOverrides);
        return await response.value();
    }

    /**
     * Returns the submission in HTML format.
     * Get submission in HTML
     */
    async getSubmissionHtmlRaw(requestParameters: SubmissionsPUBLICAPIApiGetSubmissionHtmlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.submissionId === null || requestParameters.submissionId === undefined) {
            throw new runtime.RequiredError('submissionId','Required parameter requestParameters.submissionId was null or undefined when calling getSubmissionHtml.');
        }

        const queryParameters: any = {};

        if (requestParameters.url !== undefined) {
            queryParameters['url'] = requestParameters.url;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/submissions/v1/html/{submissionId}`.replace(`{${"submissionId"}}`, encodeURIComponent(String(requestParameters.submissionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Returns the submission in HTML format.
     * Get submission in HTML
     */
    async getSubmissionHtml(submissionId: number, url?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.getSubmissionHtmlRaw({ submissionId: submissionId, url: url }, initOverrides);
        return await response.value();
    }

    /**
     * Returns the submission in read only HTML format.
     * Get submission in read only HTML
     */
    async getSubmissionReadOnlyRaw(requestParameters: SubmissionsPUBLICAPIApiGetSubmissionReadOnlyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.submissionId === null || requestParameters.submissionId === undefined) {
            throw new runtime.RequiredError('submissionId','Required parameter requestParameters.submissionId was null or undefined when calling getSubmissionReadOnly.');
        }

        const queryParameters: any = {};

        if (requestParameters.url !== undefined) {
            queryParameters['url'] = requestParameters.url;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/submissions/v1/html/{submissionId}/read-only`.replace(`{${"submissionId"}}`, encodeURIComponent(String(requestParameters.submissionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Returns the submission in read only HTML format.
     * Get submission in read only HTML
     */
    async getSubmissionReadOnly(submissionId: number, url?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.getSubmissionReadOnlyRaw({ submissionId: submissionId, url: url }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getSubmissionStateRaw(requestParameters: SubmissionsPUBLICAPIApiGetSubmissionStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.submissionId === null || requestParameters.submissionId === undefined) {
            throw new runtime.RequiredError('submissionId','Required parameter requestParameters.submissionId was null or undefined when calling getSubmissionState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/submissions/v1/{submissionId}/state`.replace(`{${"submissionId"}}`, encodeURIComponent(String(requestParameters.submissionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async getSubmissionState(submissionId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.getSubmissionStateRaw({ submissionId: submissionId }, initOverrides);
        return await response.value();
    }

    /**
     * Returns a list of submission versions
     */
    async getSubmissionVersionsRaw(requestParameters: SubmissionsPUBLICAPIApiGetSubmissionVersionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SubmissionContentRecord>>> {
        if (requestParameters.submissionId === null || requestParameters.submissionId === undefined) {
            throw new runtime.RequiredError('submissionId','Required parameter requestParameters.submissionId was null or undefined when calling getSubmissionVersions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/submissions/v1/get-versions/{submissionId}`.replace(`{${"submissionId"}}`, encodeURIComponent(String(requestParameters.submissionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SubmissionContentRecordFromJSON));
    }

    /**
     * Returns a list of submission versions
     */
    async getSubmissionVersions(submissionId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SubmissionContentRecord>> {
        const response = await this.getSubmissionVersionsRaw({ submissionId: submissionId }, initOverrides);
        return await response.value();
    }

    /**
     * Upload old xml, returns a list of transformed eforms
     */
    async importOldXmlRaw(requestParameters: SubmissionsPUBLICAPIApiImportOldXmlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TransformedEform>>> {
        if (requestParameters.charset === null || requestParameters.charset === undefined) {
            throw new runtime.RequiredError('charset','Required parameter requestParameters.charset was null or undefined when calling importOldXml.');
        }

        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling importOldXml.');
        }

        const queryParameters: any = {};

        if (requestParameters.charset !== undefined) {
            queryParameters['charset'] = requestParameters.charset;
        }

        if (requestParameters.createdDate !== undefined) {
            queryParameters['createdDate'] = requestParameters.createdDate;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/api/integration/submissions/v1/import-old-xml`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TransformedEformFromJSON));
    }

    /**
     * Upload old xml, returns a list of transformed eforms
     */
    async importOldXml(charset: string, file: Blob, createdDate?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TransformedEform>> {
        const response = await this.importOldXmlRaw({ charset: charset, file: file, createdDate: createdDate }, initOverrides);
        return await response.value();
    }

    /**
     * Reject submission by reviewer
     */
    async rejectSubmissionRaw(requestParameters: SubmissionsPUBLICAPIApiRejectSubmissionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.submissionId === null || requestParameters.submissionId === undefined) {
            throw new runtime.RequiredError('submissionId','Required parameter requestParameters.submissionId was null or undefined when calling rejectSubmission.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/submissions/v1/reject/{submissionId}`.replace(`{${"submissionId"}}`, encodeURIComponent(String(requestParameters.submissionId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Reject submission by reviewer
     */
    async rejectSubmission(submissionId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.rejectSubmissionRaw({ submissionId: submissionId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async saveSubmissionDraftRaw(requestParameters: SubmissionsPUBLICAPIApiSaveSubmissionDraftRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.submissionId === null || requestParameters.submissionId === undefined) {
            throw new runtime.RequiredError('submissionId','Required parameter requestParameters.submissionId was null or undefined when calling saveSubmissionDraft.');
        }

        if (requestParameters.xmlData === null || requestParameters.xmlData === undefined) {
            throw new runtime.RequiredError('xmlData','Required parameter requestParameters.xmlData was null or undefined when calling saveSubmissionDraft.');
        }

        const queryParameters: any = {};

        if (requestParameters.xmlData !== undefined) {
            queryParameters['xmlData'] = requestParameters.xmlData;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/submissions/v1/save-submission-draft/{submissionId}`.replace(`{${"submissionId"}}`, encodeURIComponent(String(requestParameters.submissionId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async saveSubmissionDraft(submissionId: number, xmlData: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.saveSubmissionDraftRaw({ submissionId: submissionId, xmlData: xmlData }, initOverrides);
    }

    /**
     * Returns a page of submissions matching the search criteria.
     * Search submissions
     */
    async searchSubmissionsRaw(requestParameters: SubmissionsPUBLICAPIApiSearchSubmissionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageSubmissionRecord>> {
        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling searchSubmissions.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/submissions/v1/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageSubmissionRecordFromJSON(jsonValue));
    }

    /**
     * Returns a page of submissions matching the search criteria.
     * Search submissions
     */
    async searchSubmissions(requestBody: { [key: string]: string; }, page?: number, size?: number, sort?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageSubmissionRecord> {
        const response = await this.searchSubmissionsRaw({ requestBody: requestBody, page: page, size: size, sort: sort }, initOverrides);
        return await response.value();
    }

    /**
     * Returns a page of submissions matching the search criteria.
     * Search submissions by criteria
     */
    async searchSubmissionsByCriteriaRaw(requestParameters: SubmissionsPUBLICAPIApiSearchSubmissionsByCriteriaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageSubmissionRecord>> {
        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling searchSubmissionsByCriteria.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/submissions/v1/search-by-criteria`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageSubmissionRecordFromJSON(jsonValue));
    }

    /**
     * Returns a page of submissions matching the search criteria.
     * Search submissions by criteria
     */
    async searchSubmissionsByCriteria(requestBody: { [key: string]: string; }, page?: number, size?: number, sort?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageSubmissionRecord> {
        const response = await this.searchSubmissionsByCriteriaRaw({ requestBody: requestBody, page: page, size: size, sort: sort }, initOverrides);
        return await response.value();
    }

    /**
     * Submit submission draft.
     * Submit submission draft.
     */
    async submitRaw(requestParameters: SubmissionsPUBLICAPIApiSubmitRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.xmlData === null || requestParameters.xmlData === undefined) {
            throw new runtime.RequiredError('xmlData','Required parameter requestParameters.xmlData was null or undefined when calling submit.');
        }

        if (requestParameters.submissionId === null || requestParameters.submissionId === undefined) {
            throw new runtime.RequiredError('submissionId','Required parameter requestParameters.submissionId was null or undefined when calling submit.');
        }

        const queryParameters: any = {};

        if (requestParameters.xmlData !== undefined) {
            queryParameters['xmlData'] = requestParameters.xmlData;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/submissions/v1/submit/{submissionId}`.replace(`{${"submissionId"}}`, encodeURIComponent(String(requestParameters.submissionId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Submit submission draft.
     * Submit submission draft.
     */
    async submit(xmlData: string, submissionId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.submitRaw({ xmlData: xmlData, submissionId: submissionId }, initOverrides);
        return await response.value();
    }

    /**
     * Update the submission.
     * Update submission by SHMU admin user, this creates new version
     */
    async updateSubmissionByReviewerRaw(requestParameters: SubmissionsPUBLICAPIApiUpdateSubmissionByReviewerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.submissionId === null || requestParameters.submissionId === undefined) {
            throw new runtime.RequiredError('submissionId','Required parameter requestParameters.submissionId was null or undefined when calling updateSubmissionByReviewer.');
        }

        if (requestParameters.xmlData === null || requestParameters.xmlData === undefined) {
            throw new runtime.RequiredError('xmlData','Required parameter requestParameters.xmlData was null or undefined when calling updateSubmissionByReviewer.');
        }

        const queryParameters: any = {};

        if (requestParameters.xmlData !== undefined) {
            queryParameters['xmlData'] = requestParameters.xmlData;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/submissions/v1/update-by-reviewer/{submissionId}`.replace(`{${"submissionId"}}`, encodeURIComponent(String(requestParameters.submissionId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Update the submission.
     * Update submission by SHMU admin user, this creates new version
     */
    async updateSubmissionByReviewer(submissionId: number, xmlData: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.updateSubmissionByReviewerRaw({ submissionId: submissionId, xmlData: xmlData }, initOverrides);
        return await response.value();
    }

    /**
     * Update the submission.
     * Update submission by SHMU admin user, this creates new version
     */
    async updateSubmissionByReviewerV2Raw(requestParameters: SubmissionsPUBLICAPIApiUpdateSubmissionByReviewerV2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.submissionId === null || requestParameters.submissionId === undefined) {
            throw new runtime.RequiredError('submissionId','Required parameter requestParameters.submissionId was null or undefined when calling updateSubmissionByReviewerV2.');
        }

        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling updateSubmissionByReviewerV2.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/xml';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/submissions/v2/update-by-reviewer/{submissionId}`.replace(`{${"submissionId"}}`, encodeURIComponent(String(requestParameters.submissionId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Update the submission.
     * Update submission by SHMU admin user, this creates new version
     */
    async updateSubmissionByReviewerV2(submissionId: number, body: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.updateSubmissionByReviewerV2Raw({ submissionId: submissionId, body: body }, initOverrides);
        return await response.value();
    }

    /**
     * Upload submission attachment.
     * Upload submission attachment
     */
    async uploadSubmissionAttachmentRaw(requestParameters: SubmissionsPUBLICAPIApiUploadSubmissionAttachmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.submissionId === null || requestParameters.submissionId === undefined) {
            throw new runtime.RequiredError('submissionId','Required parameter requestParameters.submissionId was null or undefined when calling uploadSubmissionAttachment.');
        }

        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling uploadSubmissionAttachment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/api/integration/submissions/v1/{submissionId}/upload-attachment`.replace(`{${"submissionId"}}`, encodeURIComponent(String(requestParameters.submissionId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Upload submission attachment.
     * Upload submission attachment
     */
    async uploadSubmissionAttachment(submissionId: string, file: Blob, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.uploadSubmissionAttachmentRaw({ submissionId: submissionId, file: file }, initOverrides);
        return await response.value();
    }

}
