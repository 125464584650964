/* tslint:disable */
/* eslint-disable */
/**
 * Data import metadata
 * Administration of metadata for import
 *
 * The version of the OpenAPI document: 1.0
 * Contact: schvarcz@bb.microcomp.sk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MonitoringPointSearchDefinitionItemRequest
 */
export interface MonitoringPointSearchDefinitionItemRequest {
    /**
     * 
     * @type {string}
     * @memberof MonitoringPointSearchDefinitionItemRequest
     */
    catalogAttributeCode?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitoringPointSearchDefinitionItemRequest
     */
    rowAttributeCode?: string;
}

/**
 * Check if a given object implements the MonitoringPointSearchDefinitionItemRequest interface.
 */
export function instanceOfMonitoringPointSearchDefinitionItemRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitoringPointSearchDefinitionItemRequestFromJSON(json: any): MonitoringPointSearchDefinitionItemRequest {
    return MonitoringPointSearchDefinitionItemRequestFromJSONTyped(json, false);
}

export function MonitoringPointSearchDefinitionItemRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitoringPointSearchDefinitionItemRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'catalogAttributeCode': !exists(json, 'catalogAttributeCode') ? undefined : json['catalogAttributeCode'],
        'rowAttributeCode': !exists(json, 'rowAttributeCode') ? undefined : json['rowAttributeCode'],
    };
}

export function MonitoringPointSearchDefinitionItemRequestToJSON(value?: MonitoringPointSearchDefinitionItemRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'catalogAttributeCode': value.catalogAttributeCode,
        'rowAttributeCode': value.rowAttributeCode,
    };
}

