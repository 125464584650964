/* tslint:disable */
/* eslint-disable */
/**
 * Catalog API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.31.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserGroupResult
 */
export interface UserGroupResult {
    /**
     * 
     * @type {string}
     * @memberof UserGroupResult
     */
    preferredUsername: string;
    /**
     * 
     * @type {string}
     * @memberof UserGroupResult
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof UserGroupResult
     */
    note?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserGroupResult
     */
    section: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof UserGroupResult
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof UserGroupResult
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof UserGroupResult
     */
    totalCount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UserGroupResult
     */
    active?: boolean;
}

/**
 * Check if a given object implements the UserGroupResult interface.
 */
export function instanceOfUserGroupResult(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "preferredUsername" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "section" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "count" in value;

    return isInstance;
}

export function UserGroupResultFromJSON(json: any): UserGroupResult {
    return UserGroupResultFromJSONTyped(json, false);
}

export function UserGroupResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserGroupResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'preferredUsername': json['preferredUsername'],
        'name': json['name'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'section': json['section'],
        'id': json['id'],
        'count': json['count'],
        'totalCount': !exists(json, 'totalCount') ? undefined : json['totalCount'],
        'active': !exists(json, 'active') ? undefined : json['active'],
    };
}

export function UserGroupResultToJSON(value?: UserGroupResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'preferredUsername': value.preferredUsername,
        'name': value.name,
        'note': value.note,
        'section': value.section,
        'id': value.id,
        'count': value.count,
        'totalCount': value.totalCount,
        'active': value.active,
    };
}

