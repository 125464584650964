/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WaterStructTempReductRecord
 */
export interface WaterStructTempReductRecord {
    /**
     * 
     * @type {number}
     * @memberof WaterStructTempReductRecord
     */
    id?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructTempReductRecord
     */
    flow?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructTempReductRecord
     */
    tempReduct?: number | null;
}

/**
 * Check if a given object implements the WaterStructTempReductRecord interface.
 */
export function instanceOfWaterStructTempReductRecord(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WaterStructTempReductRecordFromJSON(json: any): WaterStructTempReductRecord {
    return WaterStructTempReductRecordFromJSONTyped(json, false);
}

export function WaterStructTempReductRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): WaterStructTempReductRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'flow': !exists(json, 'flow') ? undefined : json['flow'],
        'tempReduct': !exists(json, 'tempReduct') ? undefined : json['tempReduct'],
    };
}

export function WaterStructTempReductRecordToJSON(value?: WaterStructTempReductRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'flow': value.flow,
        'tempReduct': value.tempReduct,
    };
}

