/* tslint:disable */
/* eslint-disable */
/**
 * Catalog API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.31.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeterminantCodeInternationalItem
 */
export interface DeterminantCodeInternationalItem {
    /**
     * 
     * @type {string}
     * @memberof DeterminantCodeInternationalItem
     */
    detCodeInt: string;
}

/**
 * Check if a given object implements the DeterminantCodeInternationalItem interface.
 */
export function instanceOfDeterminantCodeInternationalItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "detCodeInt" in value;

    return isInstance;
}

export function DeterminantCodeInternationalItemFromJSON(json: any): DeterminantCodeInternationalItem {
    return DeterminantCodeInternationalItemFromJSONTyped(json, false);
}

export function DeterminantCodeInternationalItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeterminantCodeInternationalItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'detCodeInt': json['detCodeInt'],
    };
}

export function DeterminantCodeInternationalItemToJSON(value?: DeterminantCodeInternationalItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'detCodeInt': value.detCodeInt,
    };
}

