/* tslint:disable */
/* eslint-disable */
/**
 * Data import
 * Modul for importing data to Data Registry.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: schvarcz@bb.microcomp.sk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ImportSummaryRowResponse
 */
export interface ImportSummaryRowResponse {
    /**
     * 
     * @type {string}
     * @memberof ImportSummaryRowResponse
     */
    monPointCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportSummaryRowResponse
     */
    dateTimeFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportSummaryRowResponse
     */
    dateTimeTo?: string;
    /**
     * 
     * @type {number}
     * @memberof ImportSummaryRowResponse
     */
    numberOfRecords?: number;
    /**
     * 
     * @type {number}
     * @memberof ImportSummaryRowResponse
     */
    percentageOfDuplicatesInDr?: number;
    /**
     * 
     * @type {number}
     * @memberof ImportSummaryRowResponse
     */
    percentageOfDuplicatesInImportFile?: number;
    /**
     * 
     * @type {number}
     * @memberof ImportSummaryRowResponse
     */
    percentageOfDuplicatesInImportFileByKeys?: number;
}

/**
 * Check if a given object implements the ImportSummaryRowResponse interface.
 */
export function instanceOfImportSummaryRowResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ImportSummaryRowResponseFromJSON(json: any): ImportSummaryRowResponse {
    return ImportSummaryRowResponseFromJSONTyped(json, false);
}

export function ImportSummaryRowResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImportSummaryRowResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'monPointCode': !exists(json, 'monPointCode') ? undefined : json['monPointCode'],
        'dateTimeFrom': !exists(json, 'dateTimeFrom') ? undefined : json['dateTimeFrom'],
        'dateTimeTo': !exists(json, 'dateTimeTo') ? undefined : json['dateTimeTo'],
        'numberOfRecords': !exists(json, 'numberOfRecords') ? undefined : json['numberOfRecords'],
        'percentageOfDuplicatesInDr': !exists(json, 'percentageOfDuplicatesInDr') ? undefined : json['percentageOfDuplicatesInDr'],
        'percentageOfDuplicatesInImportFile': !exists(json, 'percentageOfDuplicatesInImportFile') ? undefined : json['percentageOfDuplicatesInImportFile'],
        'percentageOfDuplicatesInImportFileByKeys': !exists(json, 'percentageOfDuplicatesInImportFileByKeys') ? undefined : json['percentageOfDuplicatesInImportFileByKeys'],
    };
}

export function ImportSummaryRowResponseToJSON(value?: ImportSummaryRowResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'monPointCode': value.monPointCode,
        'dateTimeFrom': value.dateTimeFrom,
        'dateTimeTo': value.dateTimeTo,
        'numberOfRecords': value.numberOfRecords,
        'percentageOfDuplicatesInDr': value.percentageOfDuplicatesInDr,
        'percentageOfDuplicatesInImportFile': value.percentageOfDuplicatesInImportFile,
        'percentageOfDuplicatesInImportFileByKeys': value.percentageOfDuplicatesInImportFileByKeys,
    };
}

