import React, { useContext, useEffect, useMemo, useState } from 'react';

import { PreferencesContext } from '../../store/preferences-context';
import useBreakPoint, { Breakpoint } from '../../hooks/use-break-point';
import ScrollToTop from 'react-scroll-to-top';
import {
    ArrowDownOnSquareIcon,
    ArrowTrendingUpIcon,
    ChartPieIcon,
    ChevronUpIcon,
    Cog6ToothIcon,
    CpuChipIcon,
    DocumentTextIcon,
    EyeDropperIcon,
    MagnifyingGlassCircleIcon,
    QueueListIcon,
    RectangleStackIcon,
    ScaleIcon,
    StarIcon,
    UserGroupIcon,
    UsersIcon
} from '@heroicons/react/24/outline';
import { Link, LinkProps, Outlet } from 'react-router-dom';
import { MenuItem, NavBar, Separator } from '@mc-ui/react-components';
import logo from '../../assets/logo.svg';
import logoRed from '../../assets/logo-red.svg';
import LoadingOverlay from '../common/LoadingOverlay';
import useRole, { ROLE_APPLICATION, ROLE_DOMAIN, ROLES } from '../../hooks/use-role';
import { allMonPointRoles, editingMonPointRoles } from '../monitoringPoint/MonPointsDefinitions';
import ErrorBoundary from '../common/ErrorBoundary';

type SideMenuProps = {
    minimized: boolean;
    setMinimized: React.Dispatch<React.SetStateAction<boolean>>;
};

const SideMenu: React.FC<SideMenuProps> = ({ minimized, setMinimized }) => {
    const { favorites } = useContext(PreferencesContext);
    const { hasSome, hasSomeDomainRole, hasSomeAppRole } = useRole();

    const items = useMemo(() => {
        const menuItems: (MenuItem<typeof Link> | Separator)[] = [
            {
                label: 'Obľúbené',
                icon: StarIcon,
                children: favorites.map((f) => {
                    return {
                        label: f.name,
                        to: f.url,
                        state: {
                            shouldReset: true
                        }
                    };
                })
            }
        ];

        // Importy
        if (
            (hasSome([ROLES.ADMINISTRATOR, ROLES.CODELIST_WRITE, ...editingMonPointRoles]) || hasSomeAppRole(ROLE_APPLICATION.IMPORT)) &&
            !hasSome([ROLES.WATER_LAW_RECORDS_EXTERNAL_WRITE])
        ) {
            let importChildren: (
                | MenuItem<React.ForwardRefExoticComponent<LinkProps & React.RefAttributes<HTMLAnchorElement>>>
                | 'separator'
            )[] = [];

            if (hasSome([ROLES.ADMINISTRATOR, ROLES.CODELIST_WRITE])) {
                importChildren.push(
                    // {
                    //     label: 'Číselníky',
                    //     to: '/import/codelist/documents',
                    //     state: {
                    //         shouldReset: true
                    //     }
                    // },
                    {
                        label: 'Analytické metódy',
                        to: '/import/methodic/documents',
                        state: {
                            shouldReset: true
                        }
                    }
                );
            }

            if (hasSome(editingMonPointRoles)) {
                importChildren.push({
                    label: 'Monitorovacie miesta',
                    to: '/import/monpoint/documents',
                    state: {
                        shouldReset: true
                    }
                });
            }

            if (hasSome([ROLES.ADMINISTRATOR]) || hasSomeAppRole(ROLE_APPLICATION.IMPORT)) {
                importChildren.push('separator');

                if (hasSomeDomainRole(ROLE_DOMAIN.SK_SHMU_ODPADOVE_VODY_KVALITA, ROLE_APPLICATION.IMPORT) || hasSome(ROLES.ADMINISTRATOR)) {
                    importChildren.push({
                        label: 'Odpadové vody',
                        children: [
                            {
                                label: 'Kvalita',
                                to: '/import/data/sk.shmu.odpadove-vody.kvalita',
                                state: {
                                    shouldReset: true
                                }
                            }
                        ]
                    });
                }

                if (
                    hasSomeDomainRole(
                        [
                            ROLE_DOMAIN.SK_SHMU_OZNAMOVACIA_POVINNOST_VYPUSTANIE_ODPADOVE_VODY,
                            ROLE_DOMAIN.SK_SHMU_OZNAMOVACIA_POVINNOST_ODBERY_PV,
                            ROLE_DOMAIN.SK_SHMU_OZNAMOVACIA_POVINNOST_ODBERY_PZV
                        ],
                        ROLE_APPLICATION.IMPORT
                    ) ||
                    hasSome(ROLES.ADMINISTRATOR)
                ) {
                    const mandatoryReports: MenuItem<typeof Link> = {
                        label: 'Oznamovacia povinnosť',
                        children: []
                    };

                    if (
                        hasSomeDomainRole(ROLE_DOMAIN.SK_SHMU_OZNAMOVACIA_POVINNOST_VYPUSTANIE_ODPADOVE_VODY, ROLE_APPLICATION.IMPORT) ||
                        hasSome(ROLES.ADMINISTRATOR)
                    ) {
                        mandatoryReports.children.push({
                            label: 'Vypúšťanie odpadovej vody',
                            to: '/import/data/sk.shmu.oznamovacia-povinnost.vypustanie-odpadove-vody',
                            state: {
                                shouldReset: true
                            }
                        });
                    }
                    if (
                        hasSomeDomainRole(ROLE_DOMAIN.SK_SHMU_OZNAMOVACIA_POVINNOST_ODBERY_PV, ROLE_APPLICATION.IMPORT) ||
                        hasSome(ROLES.ADMINISTRATOR)
                    ) {
                        mandatoryReports.children.push({
                            label: 'Odbery PV',
                            to: '/import/data/sk.shmu.oznamovacia-povinnost.odbery-pv',
                            state: {
                                shouldReset: true
                            }
                        });
                    }
                    if (
                        hasSomeDomainRole(ROLE_DOMAIN.SK_SHMU_OZNAMOVACIA_POVINNOST_ODBERY_PZV, ROLE_APPLICATION.IMPORT) ||
                        hasSome(ROLES.ADMINISTRATOR)
                    ) {
                        mandatoryReports.children.push({
                            label: 'Odbery PZV',
                            to: '/import/data/sk.shmu.oznamovacia-povinnost.odbery-pzv',
                            state: {
                                shouldReset: true
                            }
                        });
                    }
                    importChildren.push(mandatoryReports);
                }

                if (
                    hasSomeDomainRole(
                        [
                            ROLE_DOMAIN.SK_SHMU_POVRCHOVE_VODY_KVALITA_PV,
                            ROLE_DOMAIN.SK_SHMU_POVRCHOVE_VODY_KVANTITA_PV_KVANTITA_PV,
                            ROLE_DOMAIN.SK_SHMU_POVRCHOVE_VODY_KVANTITA_PV_VHB_KVANTITA_PV
                        ],
                        ROLE_APPLICATION.IMPORT
                    ) ||
                    hasSome(ROLES.ADMINISTRATOR)
                ) {
                    const surfaceWaters: MenuItem<typeof Link> = {
                        label: 'Povrchové vody',
                        children: []
                    };
                    if (
                        hasSomeDomainRole(ROLE_DOMAIN.SK_SHMU_POVRCHOVE_VODY_KVALITA_PV, ROLE_APPLICATION.IMPORT) ||
                        hasSome(ROLES.ADMINISTRATOR)
                    ) {
                        surfaceWaters.children.push({
                            label: 'Kvalita PV',
                            to: '/import/data/sk.shmu.povrchove-vody.kvalita-pv',
                            state: {
                                shouldReset: true
                            }
                        });
                    }
                    if (
                        hasSomeDomainRole(
                            [
                                ROLE_DOMAIN.SK_SHMU_POVRCHOVE_VODY_KVANTITA_PV_KVANTITA_PV,
                                ROLE_DOMAIN.SK_SHMU_POVRCHOVE_VODY_KVANTITA_PV_VHB_KVANTITA_PV
                            ],
                            ROLE_APPLICATION.IMPORT
                        ) ||
                        hasSome(ROLES.ADMINISTRATOR)
                    ) {
                        const surfaceWatersQuantity: MenuItem<typeof Link> = {
                            label: 'Kvantita PV',
                            children: []
                        };
                        if (
                            hasSomeDomainRole(ROLE_DOMAIN.SK_SHMU_POVRCHOVE_VODY_KVANTITA_PV_KVANTITA_PV, ROLE_APPLICATION.IMPORT) ||
                            hasSome(ROLES.ADMINISTRATOR)
                        ) {
                            surfaceWatersQuantity.children.push({
                                label: 'Kvantita PV',
                                to: '/import/data/sk.shmu.povrchove-vody.kvantita-pv.kvantita-pv',
                                state: {
                                    shouldReset: true
                                }
                            });
                        }
                        if (
                            hasSomeDomainRole(ROLE_DOMAIN.SK_SHMU_POVRCHOVE_VODY_KVANTITA_PV_VHB_KVANTITA_PV, ROLE_APPLICATION.IMPORT) ||
                            hasSome(ROLES.ADMINISTRATOR)
                        ) {
                            surfaceWatersQuantity.children.push({
                                label: 'VHB Kvantita PV',
                                to: '/import/data/sk.shmu.povrchove-vody.kvantita-pv.vhb-kvantita-pv',
                                state: {
                                    shouldReset: true
                                }
                            });
                        }
                        surfaceWaters.children.push(surfaceWatersQuantity);
                    }
                    importChildren.push(surfaceWaters);
                }
            }

            if (
                hasSomeDomainRole(
                    [
                        ROLE_DOMAIN.SK_SHMU_PODZEMNE_VODY_KVALITA_PZV,
                        ROLE_DOMAIN.SK_SHMU_PODZEMNE_VODY_KVANTITA_PZV_KVANTITA_PZV,
                        ROLE_DOMAIN.SK_SHMU_PODZEMNE_VODY_KVANTITA_PZV_VHB_KVANTITA_PZV
                    ],
                    ROLE_APPLICATION.IMPORT
                ) ||
                hasSome(ROLES.ADMINISTRATOR)
            ) {
                const undergroundWaters: MenuItem<typeof Link> = {
                    label: 'Podzemné vody',
                    children: []
                };
                if (
                    hasSomeDomainRole(ROLE_DOMAIN.SK_SHMU_PODZEMNE_VODY_KVALITA_PZV, ROLE_APPLICATION.IMPORT) ||
                    hasSome(ROLES.ADMINISTRATOR)
                ) {
                    undergroundWaters.children.push({
                        label: 'Kvalita PZV',
                        to: '/import/data/sk.shmu.podzemne-vody.kvalita-pzv',
                        state: {
                            shouldReset: true
                        }
                    });
                }
                if (
                    hasSomeDomainRole(
                        [
                            ROLE_DOMAIN.SK_SHMU_PODZEMNE_VODY_KVANTITA_PZV_KVANTITA_PZV,
                            ROLE_DOMAIN.SK_SHMU_PODZEMNE_VODY_KVANTITA_PZV_VHB_KVANTITA_PZV
                        ],
                        ROLE_APPLICATION.IMPORT
                    ) ||
                    hasSome(ROLES.ADMINISTRATOR)
                ) {
                    const undergroundWatersQuantity: MenuItem<typeof Link> = {
                        label: 'Kvantita PZV',
                        children: []
                    };
                    if (
                        hasSomeDomainRole(ROLE_DOMAIN.SK_SHMU_PODZEMNE_VODY_KVANTITA_PZV_KVANTITA_PZV, ROLE_APPLICATION.IMPORT) ||
                        hasSome(ROLES.ADMINISTRATOR)
                    ) {
                        undergroundWatersQuantity.children.push({
                            label: 'Kvantita PZV',
                            to: '/import/data/sk.shmu.podzemne-vody.kvantita-pzv.kvantita-pzv',
                            state: {
                                shouldReset: true
                            }
                        });
                    }
                    if (
                        hasSomeDomainRole(ROLE_DOMAIN.SK_SHMU_PODZEMNE_VODY_KVANTITA_PZV_VHB_KVANTITA_PZV, ROLE_APPLICATION.IMPORT) ||
                        hasSome(ROLES.ADMINISTRATOR)
                    ) {
                        undergroundWatersQuantity.children.push({
                            label: 'VHB Kvantita PZV',
                            to: '/import/data/sk.shmu.podzemne-vody.kvantita-pzv.vhb-kvantita-pzv',
                            state: {
                                shouldReset: true
                            }
                        });
                    }
                    undergroundWaters.children.push(undergroundWatersQuantity);
                }
                importChildren.push(undergroundWaters);
            }

            importChildren.push({
                label: 'Zoznam importov dát',
                to: '/import/data/documents',
                state: {
                    shouldReset: true
                }
            });

            if (hasSome([ROLES.ADMINISTRATOR])) {
                importChildren.push('separator', {
                    label: 'Metadátová definícia importov',
                    to: '/import/metadata',
                    icon: CpuChipIcon,
                    state: {
                        shouldReset: true
                    }
                });
            }

            menuItems.push({
                label: 'Importy',
                icon: ArrowDownOnSquareIcon,
                children: importChildren
            });
        }

        // Prehľady
        if (
            (hasSome(ROLES.ADMINISTRATOR) || hasSomeAppRole(ROLE_APPLICATION.OVERVIEW)) &&
            !hasSome([ROLES.WATER_LAW_RECORDS_EXTERNAL_WRITE])
        ) {
            const overview: MenuItem<typeof Link> = {
                label: 'Prehľady',
                icon: MagnifyingGlassCircleIcon,
                children: []
            };

            if (hasSomeDomainRole(ROLE_DOMAIN.SK_SHMU_ODPADOVE_VODY_KVALITA, ROLE_APPLICATION.OVERVIEW) || hasSome(ROLES.ADMINISTRATOR)) {
                overview.children!.push({
                    label: 'Odpadové vody',
                    children: [
                        {
                            label: 'Kvalita',
                            to: '/overview/sk.shmu.odpadove-vody.kvalita',
                            state: {
                                shouldReset: true
                            }
                        }
                    ]
                });
            }

            if (
                hasSomeDomainRole(
                    [
                        ROLE_DOMAIN.SK_SHMU_OZNAMOVACIA_POVINNOST_ODBERY_PV,
                        ROLE_DOMAIN.SK_SHMU_OZNAMOVACIA_POVINNOST_ODBERY_PZV,
                        ROLE_DOMAIN.SK_SHMU_OZNAMOVACIA_POVINNOST_VYPUSTANIE_ODPADOVE_VODY
                    ],
                    ROLE_APPLICATION.OVERVIEW
                ) ||
                hasSome(ROLES.ADMINISTRATOR)
            ) {
                const mandatoryReports: MenuItem<typeof Link> = {
                    label: 'Oznamovacia povinnosť',
                    children: []
                };
                if (
                    hasSomeDomainRole(ROLE_DOMAIN.SK_SHMU_OZNAMOVACIA_POVINNOST_VYPUSTANIE_ODPADOVE_VODY, ROLE_APPLICATION.OVERVIEW) ||
                    hasSome(ROLES.ADMINISTRATOR)
                ) {
                    mandatoryReports.children.push({
                        label: 'Vypúšťanie odpadovej vody',
                        to: '/overview/sk.shmu.oznamovacia-povinnost.vypustanie-odpadove-vody',
                        state: {
                            shouldReset: true
                        }
                    });
                }
                if (
                    hasSomeDomainRole(ROLE_DOMAIN.SK_SHMU_OZNAMOVACIA_POVINNOST_ODBERY_PV, ROLE_APPLICATION.OVERVIEW) ||
                    hasSome(ROLES.ADMINISTRATOR)
                ) {
                    mandatoryReports.children.push({
                        label: 'Odbery PV',
                        to: '/overview/sk.shmu.oznamovacia-povinnost.odbery-pv',
                        state: {
                            shouldReset: true
                        }
                    });
                }
                if (
                    hasSomeDomainRole(ROLE_DOMAIN.SK_SHMU_OZNAMOVACIA_POVINNOST_ODBERY_PZV, ROLE_APPLICATION.OVERVIEW) ||
                    hasSome(ROLES.ADMINISTRATOR)
                ) {
                    mandatoryReports.children.push({
                        label: 'Odbery PZV',
                        to: '/overview/sk.shmu.oznamovacia-povinnost.odbery-pzv',
                        state: {
                            shouldReset: true
                        }
                    });
                }
                overview.children!.push(mandatoryReports);
            }

            if (
                hasSomeDomainRole(
                    [
                        ROLE_DOMAIN.SK_SHMU_POVRCHOVE_VODY_KVALITA_PV,
                        ROLE_DOMAIN.SK_SHMU_POVRCHOVE_VODY_KVANTITA_PV_KVANTITA_PV,
                        ROLE_DOMAIN.SK_SHMU_POVRCHOVE_VODY_KVANTITA_PV_MUTNOST_VODY,
                        ROLE_DOMAIN.SK_SHMU_POVRCHOVE_VODY_KVANTITA_PV_VHB_KVANTITA_PV
                    ],
                    ROLE_APPLICATION.OVERVIEW
                ) ||
                hasSome(ROLES.ADMINISTRATOR)
            ) {
                const surfaceWaters: MenuItem<typeof Link> = {
                    label: 'Povrchové vody',
                    children: []
                };
                if (
                    hasSomeDomainRole(ROLE_DOMAIN.SK_SHMU_POVRCHOVE_VODY_KVALITA_PV, ROLE_APPLICATION.OVERVIEW) ||
                    hasSome(ROLES.ADMINISTRATOR)
                ) {
                    surfaceWaters.children.push({
                        label: 'Kvalita PV',
                        to: '/overview/sk.shmu.povrchove-vody.kvalita-pv',
                        state: {
                            shouldReset: true
                        }
                    });
                }
                if (
                    hasSomeDomainRole(
                        [
                            ROLE_DOMAIN.SK_SHMU_POVRCHOVE_VODY_KVANTITA_PV_KVANTITA_PV,
                            ROLE_DOMAIN.SK_SHMU_POVRCHOVE_VODY_KVANTITA_PV_MUTNOST_VODY,
                            ROLE_DOMAIN.SK_SHMU_POVRCHOVE_VODY_KVANTITA_PV_VHB_KVANTITA_PV
                        ],
                        ROLE_APPLICATION.OVERVIEW
                    ) ||
                    hasSome(ROLES.ADMINISTRATOR)
                ) {
                    const surfaceWatersQuantity: MenuItem<typeof Link> = {
                        label: 'Kvantita PV',
                        children: []
                    };
                    if (
                        hasSomeDomainRole(ROLE_DOMAIN.SK_SHMU_POVRCHOVE_VODY_KVANTITA_PV_KVANTITA_PV, ROLE_APPLICATION.OVERVIEW) ||
                        hasSome(ROLES.ADMINISTRATOR)
                    ) {
                        surfaceWatersQuantity.children.push({
                            label: 'Kvantita PV',
                            to: '/overview/sk.shmu.povrchove-vody.kvantita-pv.kvantita-pv',
                            state: {
                                shouldReset: true
                            }
                        });
                    }
                    if (
                        hasSomeDomainRole(ROLE_DOMAIN.SK_SHMU_POVRCHOVE_VODY_KVANTITA_PV_VHB_KVANTITA_PV, ROLE_APPLICATION.OVERVIEW) ||
                        hasSome(ROLES.ADMINISTRATOR)
                    ) {
                        surfaceWatersQuantity.children.push({
                            label: 'VHB Kvantita PV',
                            to: '/overview/sk.shmu.povrchove-vody.kvantita-pv.vhb-kvantita-pv',
                            state: {
                                shouldReset: true
                            }
                        });
                    }
                    if (
                        hasSomeDomainRole(ROLE_DOMAIN.SK_SHMU_POVRCHOVE_VODY_KVANTITA_PV_MUTNOST_VODY, ROLE_APPLICATION.OVERVIEW) ||
                        hasSome(ROLES.ADMINISTRATOR)
                    ) {
                        surfaceWatersQuantity.children.push({
                            label: 'Mútnosť vody',
                            to: '/overview/sk.shmu.povrchove-vody.kvantita-pv.mutnost-vody',
                            state: {
                                shouldReset: true
                            }
                        });
                    }
                    // Bug #14264 - sk.shmu.povrchove-vody.kvantita-pv.hydromorfologia tento typ sem nikdy nepridavat, nie su ani nebudu tam prehlady!!!
                    surfaceWaters.children.push(surfaceWatersQuantity);
                }
                overview.children!.push(surfaceWaters);
            }

            if (
                hasSomeDomainRole(
                    [
                        ROLE_DOMAIN.SK_SHMU_PODZEMNE_VODY_KVALITA_PZV,
                        ROLE_DOMAIN.SK_SHMU_PODZEMNE_VODY_KVANTITA_PZV_KVANTITA_PZV,
                        ROLE_DOMAIN.SK_SHMU_PODZEMNE_VODY_KVANTITA_PZV_VHB_KVANTITA_PZV
                    ],
                    ROLE_APPLICATION.OVERVIEW
                ) ||
                hasSome(ROLES.ADMINISTRATOR)
            ) {
                const undergroundWaters: MenuItem<typeof Link> = {
                    label: 'Podzemné vody',
                    children: []
                };
                if (
                    hasSomeDomainRole(ROLE_DOMAIN.SK_SHMU_PODZEMNE_VODY_KVALITA_PZV, ROLE_APPLICATION.OVERVIEW) ||
                    hasSome(ROLES.ADMINISTRATOR)
                ) {
                    undergroundWaters.children.push({
                        label: 'Kvalita PZV',
                        to: '/overview/sk.shmu.podzemne-vody.kvalita-pzv',
                        state: {
                            shouldReset: true
                        }
                    });
                }
                if (
                    hasSomeDomainRole(
                        [
                            ROLE_DOMAIN.SK_SHMU_PODZEMNE_VODY_KVANTITA_PZV_KVANTITA_PZV,
                            ROLE_DOMAIN.SK_SHMU_PODZEMNE_VODY_KVANTITA_PZV_VHB_KVANTITA_PZV
                        ],
                        ROLE_APPLICATION.OVERVIEW
                    ) ||
                    hasSome(ROLES.ADMINISTRATOR)
                ) {
                    const undergroundWatersQuantity: MenuItem<typeof Link> = {
                        label: 'Kvantita PZV',
                        children: []
                    };
                    if (
                        hasSomeDomainRole(ROLE_DOMAIN.SK_SHMU_PODZEMNE_VODY_KVANTITA_PZV_KVANTITA_PZV, ROLE_APPLICATION.OVERVIEW) ||
                        hasSome(ROLES.ADMINISTRATOR)
                    ) {
                        undergroundWatersQuantity.children.push({
                            label: 'Kvantita PZV',
                            to: '/overview/sk.shmu.podzemne-vody.kvantita-pzv.kvantita-pzv',
                            state: {
                                shouldReset: true
                            }
                        });
                    }
                    if (
                        hasSomeDomainRole(ROLE_DOMAIN.SK_SHMU_PODZEMNE_VODY_KVANTITA_PZV_VHB_KVANTITA_PZV, ROLE_APPLICATION.OVERVIEW) ||
                        hasSome(ROLES.ADMINISTRATOR)
                    ) {
                        undergroundWatersQuantity.children.push({
                            label: 'VHB Kvantita PZV',
                            to: '/overview/sk.shmu.podzemne-vody.kvantita-pzv.vhb-kvantita-pzv',
                            state: {
                                shouldReset: true
                            }
                        });
                    }
                    undergroundWaters.children.push(undergroundWatersQuantity);
                }
                overview.children!.push(undergroundWaters);
            }

            overview.children!.push('separator');
            overview.children!.push({
                label: 'Rozpracované prehľady',
                to: '/overview/data',
                state: {
                    shouldReset: true
                }
            });

            if (hasSome(ROLES.ADMINISTRATOR)) {
                overview.children!.push('separator');
                overview.children!.push({
                    label: 'Metadáta prehľadov',
                    to: '/overview/metadata',
                    icon: CpuChipIcon,
                    state: {
                        shouldReset: true
                    }
                });
            }

            menuItems.push(overview);
        }

        // Reporty
        if (!hasSome([ROLES.WATER_LAW_RECORDS_EXTERNAL_WRITE]) && ENV.SUPERSET_URL) {
            menuItems.push({
                label: 'Reporty',
                icon: ChartPieIcon,
                to: ENV.SUPERSET_URL,
                target: '_blank'
            });
        }

        // Oznamovacia povinnosť
        if (
            hasSome([
                ROLES.ADMINISTRATOR,
                ROLES.MANDATORY_REPORTS_PDZV_READ,
                ROLES.MANDATORY_REPORTS_PDZV_WRITE,
                ROLES.MANDATORY_REPORTS_PV_READ,
                ROLES.MANDATORY_REPORTS_PV_WRITE
            ]) &&
            !hasSome([ROLES.WATER_LAW_RECORDS_EXTERNAL_WRITE])
        ) {
            menuItems.push({
                label: 'Došlé oznámenia',
                icon: DocumentTextIcon,
                to: '/mandatoryReports',
                state: {
                    shouldReset: true
                }
            });
        }

        // Hydromorfologické protokoly
        if (
            hasSome([ROLES.ADMINISTRATOR, ROLES.HYDRO_MORPHOLOGY_READ, ROLES.HYDRO_MORPHOLOGY_WRITE]) &&
            !hasSome([ROLES.WATER_LAW_RECORDS_EXTERNAL_WRITE])
        ) {
            menuItems.push({
                label: 'Hydromorfológia',
                icon: ArrowTrendingUpIcon,
                to: '/hydroMorphProtocols',
                state: {
                    shouldReset: true
                }
            });
        }

        // Vodoprávna evidencia
        if (
            hasSome([
                ROLES.ADMINISTRATOR,
                ROLES.WATER_LAW_RECORDS_READ,
                ROLES.WATER_LAW_RECORDS_WRITE,
                ROLES.WATER_LAW_RECORDS_EXTERNAL_WRITE
            ])
        ) {
            menuItems.push({
                label: 'Vodoprávna evidencia',
                icon: ScaleIcon,
                to: '/waterLawRecords',
                state: {
                    shouldReset: true
                }
            });
        }

        // Posudková činnosť
        if (
            hasSome([ROLES.ADMINISTRATOR, ROLES.ASSESSMENTS_READ, ROLES.ASSESSMENTS_WRITE]) &&
            !hasSome([ROLES.WATER_LAW_RECORDS_EXTERNAL_WRITE])
        ) {
            menuItems.push({
                label: 'Vytváranie posudkov',
                icon: EyeDropperIcon,
                to: '/assessments',
                state: {
                    shouldReset: true
                }
            });
        }

        // Používateľské skupiny
        if (
            hasSome([...allMonPointRoles, ROLES.CODELIST_READ, ROLES.CODELIST_WRITE]) &&
            !hasSome([ROLES.WATER_LAW_RECORDS_EXTERNAL_WRITE])
        ) {
            let userGroupChildren = [];
            if (hasSome([ROLES.ADMINISTRATOR, ROLES.CODELIST_READ, ROLES.CODELIST_WRITE])) {
                userGroupChildren.push({
                    label: 'Číselníky',
                    to: '/userGroup/codelist',
                    state: {
                        shouldReset: true
                    }
                });
            }
            if (hasSome(allMonPointRoles)) {
                userGroupChildren.push({
                    label: 'Katalógy',
                    to: '/userGroup/catalog',
                    state: {
                        shouldReset: true
                    }
                });
            }
            menuItems.push({
                label: 'Používateľské skupiny',
                icon: UserGroupIcon,
                children: userGroupChildren
            });
        }

        // Číselníky
        if (
            hasSome([ROLES.ADMINISTRATOR, ROLES.CODELIST_READ, ROLES.CODELIST_WRITE]) &&
            !hasSome([ROLES.WATER_LAW_RECORDS_EXTERNAL_WRITE])
        ) {
            menuItems.push({
                label: 'Číselníky',
                icon: QueueListIcon,
                to: '/codelist',
                state: {
                    shouldReset: true
                }
            });
        }

        // Katalógy
        if (
            hasSome([
                ...allMonPointRoles,
                ROLES.ENVIRONMENT_READ,
                ROLES.ENVIRONMENT_WRITE,
                ROLES.OPERATOR_AND_INSTALLATION_READ,
                ROLES.OPERATOR_AND_INSTALLATION_WRITE,
                ROLES.WATER_SHAPE_READ,
                ROLES.WATER_SHAPE_WRITE,
                ROLES.CODELIST_READ, // Koli - Praca s metodikami, metodama a ukazovatelmi
                ROLES.CODELIST_WRITE // Koli - Praca s metodikami, metodama a ukazovatelmi
            ])
        ) {
            let catalogChildren = [];
            if (hasSome(allMonPointRoles) && !hasSome([ROLES.WATER_LAW_RECORDS_EXTERNAL_WRITE])) {
                catalogChildren.push({
                    label: 'Monitorovacie miesta',
                    to: '/monpoint/criteria',
                    state: {
                        shouldReset: true
                    }
                });
            }
            if (
                hasSome([ROLES.ADMINISTRATOR, ROLES.ENVIRONMENT_READ, ROLES.ENVIRONMENT_WRITE]) &&
                !hasSome([ROLES.WATER_LAW_RECORDS_EXTERNAL_WRITE])
            ) {
                catalogChildren.push({
                    label: 'Vodné toky',
                    to: '/environment/criteria',
                    state: {
                        shouldReset: true
                    }
                });
            }
            if (
                hasSome([ROLES.ADMINISTRATOR, ROLES.WATER_SHAPE_READ, ROLES.WATER_SHAPE_WRITE]) &&
                !hasSome([ROLES.WATER_LAW_RECORDS_EXTERNAL_WRITE])
            ) {
                catalogChildren.push({
                    label: 'Vodné útvary',
                    to: '/waterShape',
                    state: {
                        shouldReset: true
                    }
                });
            }
            if (hasSome([ROLES.ADMINISTRATOR, ROLES.OPERATOR_AND_INSTALLATION_READ, ROLES.OPERATOR_AND_INSTALLATION_WRITE])) {
                catalogChildren.push(
                    {
                        label: 'Prevádzkovatelia a prevádzky',
                        to: '/operator',
                        state: {
                            shouldReset: true
                        }
                    },
                    {
                        label: 'Prevádzky a prevádzkovatelia',
                        to: '/installation',
                        state: {
                            shouldReset: true
                        }
                    }
                );
            }

            if (
                hasSome([ROLES.ADMINISTRATOR, ROLES.WATER_SHAPE_READ, ROLES.WATER_SHAPE_WRITE]) &&
                !hasSome([ROLES.WATER_LAW_RECORDS_EXTERNAL_WRITE])
            ) {
                catalogChildren.push({
                    label: 'Pozaďové hodnoty PV',
                    to: '/threshold',
                    state: {
                        shouldReset: true
                    }
                });
            }
            if (
                hasSome([ROLES.ADMINISTRATOR, ROLES.CODELIST_READ, ROLES.CODELIST_WRITE]) &&
                !hasSome([ROLES.WATER_LAW_RECORDS_EXTERNAL_WRITE])
            ) {
                catalogChildren.push({
                    label: 'Analytické metódy, skupiny a ukazovatele',
                    to: '/methodic',
                    state: {
                        shouldReset: true
                    }
                });
            }
            menuItems.push({
                label: 'Katalógy',
                icon: RectangleStackIcon,
                children: catalogChildren
            });
        }

        menuItems.push('separator');

        menuItems.push({
            label: 'Nastavenia',
            icon: Cog6ToothIcon,
            to: '/settings/appearance',
            state: {
                shouldReset: true
            }
        });

        if (!hasSome([ROLES.WATER_LAW_RECORDS_EXTERNAL_WRITE])) {
            menuItems.push({
                label: 'Používatelia',
                icon: UsersIcon,
                to: '/users',
                state: {
                    shouldReset: true
                }
            });
        }

        return menuItems;
    }, [favorites, hasSome, hasSomeAppRole, hasSomeDomainRole]);

    return (
        <NavBar
            logo={{
                src: ENV.TEST_SERVER === 1 ? logoRed : logo
            }}
            itemAs={Link}
            items={items}
            minimized={minimized}
            toggleMinimized={() => setMinimized((prev) => !prev)}
        />
    );
};

const Base: React.FC = () => {
    const { defaultMinimizedMenu } = useContext(PreferencesContext);
    const breakpoint = useBreakPoint(Breakpoint.lg);
    const [manualMenuSize, setManualMenuSize] = useState(false);
    const [minimized, setMinimized] = useState(breakpoint ? defaultMinimizedMenu : true);

    useEffect(() => {
        if (!manualMenuSize) {
            if (breakpoint) {
                setMinimized(defaultMinimizedMenu);
            } else {
                setMinimized(true);
            }
        }
    }, [breakpoint, manualMenuSize, defaultMinimizedMenu]);

    return (
        <div className='antialiased'>
            <ErrorBoundary>
                <SideMenu
                    minimized={minimized}
                    setMinimized={(val) => {
                        setMinimized(val);
                        setManualMenuSize(true);
                    }}
                />
                <div className={`flex min-h-full flex-col ${minimized ? 'pl-14' : 'pl-52'}`}>
                    <React.Suspense
                        fallback={
                            <LoadingOverlay
                                loading={true}
                                fullscreen={true}
                            />
                        }
                    >
                        <main className='flex min-h-full flex-col pb-20'>
                            <ScrollToTop
                                smooth
                                className='!rounded-full'
                                component={
                                    <div className='rounded-full bg-mc-blue-300 h-full w-full flex items-center text-center'>
                                        <ChevronUpIcon className='w-6 h-6 text-white mx-auto' />
                                    </div>
                                }
                            />
                            <Outlet />
                        </main>
                    </React.Suspense>
                </div>
            </ErrorBoundary>
        </div>
    );
};

export default Base;
