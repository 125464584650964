/* tslint:disable */
/* eslint-disable */
/**
 * Catalog API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.31.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Attachment,
  BalanceCodeCriteria,
  BooleanResponse,
  EndpointExceptionBody,
  Monitoring,
  MonitoringCodelistItemRequired,
  MonitoringExistSo,
  MonitoringPointCriteria,
  MonitoringPointInput,
  MonitoringPointResult,
  MonitoringPointUpdate,
  PageBalanceCodeResult,
  PageMonitoringPointForInstallationResult,
  PageMonitoringPointResult,
  PageMonitoringPointSimpleResult,
  PointCategory,
  PointCategoryCodelistItemRequired,
} from '../models/index';
import {
    AttachmentFromJSON,
    AttachmentToJSON,
    BalanceCodeCriteriaFromJSON,
    BalanceCodeCriteriaToJSON,
    BooleanResponseFromJSON,
    BooleanResponseToJSON,
    EndpointExceptionBodyFromJSON,
    EndpointExceptionBodyToJSON,
    MonitoringFromJSON,
    MonitoringToJSON,
    MonitoringCodelistItemRequiredFromJSON,
    MonitoringCodelistItemRequiredToJSON,
    MonitoringExistSoFromJSON,
    MonitoringExistSoToJSON,
    MonitoringPointCriteriaFromJSON,
    MonitoringPointCriteriaToJSON,
    MonitoringPointInputFromJSON,
    MonitoringPointInputToJSON,
    MonitoringPointResultFromJSON,
    MonitoringPointResultToJSON,
    MonitoringPointUpdateFromJSON,
    MonitoringPointUpdateToJSON,
    PageBalanceCodeResultFromJSON,
    PageBalanceCodeResultToJSON,
    PageMonitoringPointForInstallationResultFromJSON,
    PageMonitoringPointForInstallationResultToJSON,
    PageMonitoringPointResultFromJSON,
    PageMonitoringPointResultToJSON,
    PageMonitoringPointSimpleResultFromJSON,
    PageMonitoringPointSimpleResultToJSON,
    PointCategoryFromJSON,
    PointCategoryToJSON,
    PointCategoryCodelistItemRequiredFromJSON,
    PointCategoryCodelistItemRequiredToJSON,
} from '../models/index';

export interface MonitoringPointApiCreateMonitoringRequest {
    historyGroupId: number;
    monitoring: Monitoring;
}

export interface MonitoringPointApiCreateMonitoringPointRequest {
    monitoringPointInput: MonitoringPointInput;
}

export interface MonitoringPointApiCreatePointCategoryRequest {
    historyGroupId: number;
    pointCategory: PointCategory;
}

export interface MonitoringPointApiDeleteAttachmentRequest {
    id: number;
}

export interface MonitoringPointApiDeleteMonitoringRequest {
    id: number;
}

export interface MonitoringPointApiDeleteMonitoringPointRequest {
    id: number;
}

export interface MonitoringPointApiDeletePointCategoryRequest {
    id: number;
}

export interface MonitoringPointApiExistMonitoringPointByCodeRequest {
    monitoringExistSo: MonitoringExistSo;
}

export interface MonitoringPointApiExistMonitoringPointByCode1Request {
    code: string;
}

export interface MonitoringPointApiFindBalanceCodeBySearchCriteriaRequest {
    balanceCodeCriteria: BalanceCodeCriteria;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface MonitoringPointApiFindMonitoringPointBySearchCriteriaRequest {
    monitoringPointCriteria: MonitoringPointCriteria;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface MonitoringPointApiFindMonitoringPointForInstallationBySearchCriteriaRequest {
    monitoringPointCriteria: MonitoringPointCriteria;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface MonitoringPointApiFindMonitoringPointSimpleBySearchCriteriaRequest {
    monitoringPointCriteria: MonitoringPointCriteria;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface MonitoringPointApiGetAllMonitoringPointByHistoryGroupIdRequest {
    historyGroupId: number;
}

export interface MonitoringPointApiGetAllMonitoringPointsRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface MonitoringPointApiGetFileRequest {
    id: number;
}

export interface MonitoringPointApiGetMonitoringByIdRequest {
    id: number;
}

export interface MonitoringPointApiGetMonitoringPointByCodeAndDateRequest {
    code: string;
    date: string;
    montPointType: number;
}

export interface MonitoringPointApiGetMonitoringPointByCodeAndDateTimeRequest {
    code: string;
    dateTime: string;
    montPointType: number;
}

export interface MonitoringPointApiGetMonitoringPointByHistoryGroupIdAndDateTimeRequest {
    historyGroupId: number;
    dateTime: string;
}

export interface MonitoringPointApiGetMonitoringPointByIdRequest {
    id: number;
}

export interface MonitoringPointApiGetMonitoringsByHistoryGroupIdRequest {
    historyGroupId: number;
}

export interface MonitoringPointApiGetPointCategoriesByHistoryGroupIdRequest {
    historyGroupId: number;
}

export interface MonitoringPointApiGetPointCategoryByIdRequest {
    id: number;
}

export interface MonitoringPointApiNewVersionMonitoringPointRequest {
    id: number;
    monitoringPointUpdate: MonitoringPointUpdate;
}

export interface MonitoringPointApiRetrieveDeletedMonitoringPointsRequest {
    id: number;
}

export interface MonitoringPointApiUpdateMonitoringRequest {
    monitoring: Monitoring;
}

export interface MonitoringPointApiUpdateMonitoringPointRequest {
    id: number;
    monitoringPointUpdate: MonitoringPointUpdate;
}

export interface MonitoringPointApiUpdatePointCategoryRequest {
    pointCategory: PointCategory;
}

export interface MonitoringPointApiUploadRequest {
    id: number;
    name: string;
    multipartFile: Blob;
}

export interface MonitoringPointApiValidateMonitoringPointRequest {
    monitoringPointInput: MonitoringPointInput;
}

export interface MonitoringPointApiValidateUpdateMonitoringPointRequest {
    id: number;
    monitoringPointUpdate: MonitoringPointUpdate;
}

/**
 * 
 */
export class MonitoringPointApi extends runtime.BaseAPI {

    /**
     */
    async createMonitoringRaw(requestParameters: MonitoringPointApiCreateMonitoringRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Monitoring>> {
        if (requestParameters.historyGroupId === null || requestParameters.historyGroupId === undefined) {
            throw new runtime.RequiredError('historyGroupId','Required parameter requestParameters.historyGroupId was null or undefined when calling createMonitoring.');
        }

        if (requestParameters.monitoring === null || requestParameters.monitoring === undefined) {
            throw new runtime.RequiredError('monitoring','Required parameter requestParameters.monitoring was null or undefined when calling createMonitoring.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/monitoring-point/monitoring/{historyGroupId}`.replace(`{${"historyGroupId"}}`, encodeURIComponent(String(requestParameters.historyGroupId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MonitoringToJSON(requestParameters.monitoring),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MonitoringFromJSON(jsonValue));
    }

    /**
     */
    async createMonitoring(historyGroupId: number, monitoring: Monitoring, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Monitoring> {
        const response = await this.createMonitoringRaw({ historyGroupId: historyGroupId, monitoring: monitoring }, initOverrides);
        return await response.value();
    }

    /**
     */
    async createMonitoringPointRaw(requestParameters: MonitoringPointApiCreateMonitoringPointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MonitoringPointResult>> {
        if (requestParameters.monitoringPointInput === null || requestParameters.monitoringPointInput === undefined) {
            throw new runtime.RequiredError('monitoringPointInput','Required parameter requestParameters.monitoringPointInput was null or undefined when calling createMonitoringPoint.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/monitoring-point/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MonitoringPointInputToJSON(requestParameters.monitoringPointInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MonitoringPointResultFromJSON(jsonValue));
    }

    /**
     */
    async createMonitoringPoint(monitoringPointInput: MonitoringPointInput, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MonitoringPointResult> {
        const response = await this.createMonitoringPointRaw({ monitoringPointInput: monitoringPointInput }, initOverrides);
        return await response.value();
    }

    /**
     */
    async createPointCategoryRaw(requestParameters: MonitoringPointApiCreatePointCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PointCategory>> {
        if (requestParameters.historyGroupId === null || requestParameters.historyGroupId === undefined) {
            throw new runtime.RequiredError('historyGroupId','Required parameter requestParameters.historyGroupId was null or undefined when calling createPointCategory.');
        }

        if (requestParameters.pointCategory === null || requestParameters.pointCategory === undefined) {
            throw new runtime.RequiredError('pointCategory','Required parameter requestParameters.pointCategory was null or undefined when calling createPointCategory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/monitoring-point/point-category/{historyGroupId}`.replace(`{${"historyGroupId"}}`, encodeURIComponent(String(requestParameters.historyGroupId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PointCategoryToJSON(requestParameters.pointCategory),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PointCategoryFromJSON(jsonValue));
    }

    /**
     */
    async createPointCategory(historyGroupId: number, pointCategory: PointCategory, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PointCategory> {
        const response = await this.createPointCategoryRaw({ historyGroupId: historyGroupId, pointCategory: pointCategory }, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteAttachmentRaw(requestParameters: MonitoringPointApiDeleteAttachmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteAttachment.');
        }

        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/monitoring-point/attachment/`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async deleteAttachment(id: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.deleteAttachmentRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteMonitoringRaw(requestParameters: MonitoringPointApiDeleteMonitoringRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteMonitoring.');
        }

        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/monitoring-point/monitoring/`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async deleteMonitoring(id: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.deleteMonitoringRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteMonitoringPointRaw(requestParameters: MonitoringPointApiDeleteMonitoringPointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MonitoringPointResult>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteMonitoringPoint.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/monitoring-point/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MonitoringPointResultFromJSON(jsonValue));
    }

    /**
     */
    async deleteMonitoringPoint(id: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MonitoringPointResult> {
        const response = await this.deleteMonitoringPointRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async deletePointCategoryRaw(requestParameters: MonitoringPointApiDeletePointCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deletePointCategory.');
        }

        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/monitoring-point/point-category/`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async deletePointCategory(id: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.deletePointCategoryRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async existMonitoringPointByCodeRaw(requestParameters: MonitoringPointApiExistMonitoringPointByCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanResponse>> {
        if (requestParameters.monitoringExistSo === null || requestParameters.monitoringExistSo === undefined) {
            throw new runtime.RequiredError('monitoringExistSo','Required parameter requestParameters.monitoringExistSo was null or undefined when calling existMonitoringPointByCode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/monitoring-point/monitoring/monitoring-exists`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MonitoringExistSoToJSON(requestParameters.monitoringExistSo),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanResponseFromJSON(jsonValue));
    }

    /**
     */
    async existMonitoringPointByCode(monitoringExistSo: MonitoringExistSo, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanResponse> {
        const response = await this.existMonitoringPointByCodeRaw({ monitoringExistSo: monitoringExistSo }, initOverrides);
        return await response.value();
    }

    /**
     */
    async existMonitoringPointByCode1Raw(requestParameters: MonitoringPointApiExistMonitoringPointByCode1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanResponse>> {
        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling existMonitoringPointByCode1.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/monitoring-point/code-exist/{code}`.replace(`{${"code"}}`, encodeURIComponent(String(requestParameters.code))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanResponseFromJSON(jsonValue));
    }

    /**
     */
    async existMonitoringPointByCode1(code: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanResponse> {
        const response = await this.existMonitoringPointByCode1Raw({ code: code }, initOverrides);
        return await response.value();
    }

    /**
     */
    async findBalanceCodeBySearchCriteriaRaw(requestParameters: MonitoringPointApiFindBalanceCodeBySearchCriteriaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageBalanceCodeResult>> {
        if (requestParameters.balanceCodeCriteria === null || requestParameters.balanceCodeCriteria === undefined) {
            throw new runtime.RequiredError('balanceCodeCriteria','Required parameter requestParameters.balanceCodeCriteria was null or undefined when calling findBalanceCodeBySearchCriteria.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/monitoring-point/by-balance-code`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BalanceCodeCriteriaToJSON(requestParameters.balanceCodeCriteria),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageBalanceCodeResultFromJSON(jsonValue));
    }

    /**
     */
    async findBalanceCodeBySearchCriteria(balanceCodeCriteria: BalanceCodeCriteria, page?: number, size?: number, sort?: Array<string>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageBalanceCodeResult> {
        const response = await this.findBalanceCodeBySearchCriteriaRaw({ balanceCodeCriteria: balanceCodeCriteria, page: page, size: size, sort: sort }, initOverrides);
        return await response.value();
    }

    /**
     */
    async findMonitoringPointBySearchCriteriaRaw(requestParameters: MonitoringPointApiFindMonitoringPointBySearchCriteriaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageMonitoringPointResult>> {
        if (requestParameters.monitoringPointCriteria === null || requestParameters.monitoringPointCriteria === undefined) {
            throw new runtime.RequiredError('monitoringPointCriteria','Required parameter requestParameters.monitoringPointCriteria was null or undefined when calling findMonitoringPointBySearchCriteria.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/monitoring-point/by-criteria`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MonitoringPointCriteriaToJSON(requestParameters.monitoringPointCriteria),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageMonitoringPointResultFromJSON(jsonValue));
    }

    /**
     */
    async findMonitoringPointBySearchCriteria(monitoringPointCriteria: MonitoringPointCriteria, page?: number, size?: number, sort?: Array<string>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageMonitoringPointResult> {
        const response = await this.findMonitoringPointBySearchCriteriaRaw({ monitoringPointCriteria: monitoringPointCriteria, page: page, size: size, sort: sort }, initOverrides);
        return await response.value();
    }

    /**
     */
    async findMonitoringPointForInstallationBySearchCriteriaRaw(requestParameters: MonitoringPointApiFindMonitoringPointForInstallationBySearchCriteriaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageMonitoringPointForInstallationResult>> {
        if (requestParameters.monitoringPointCriteria === null || requestParameters.monitoringPointCriteria === undefined) {
            throw new runtime.RequiredError('monitoringPointCriteria','Required parameter requestParameters.monitoringPointCriteria was null or undefined when calling findMonitoringPointForInstallationBySearchCriteria.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/monitoring-point/for-installation-by-criteria`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MonitoringPointCriteriaToJSON(requestParameters.monitoringPointCriteria),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageMonitoringPointForInstallationResultFromJSON(jsonValue));
    }

    /**
     */
    async findMonitoringPointForInstallationBySearchCriteria(monitoringPointCriteria: MonitoringPointCriteria, page?: number, size?: number, sort?: Array<string>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageMonitoringPointForInstallationResult> {
        const response = await this.findMonitoringPointForInstallationBySearchCriteriaRaw({ monitoringPointCriteria: monitoringPointCriteria, page: page, size: size, sort: sort }, initOverrides);
        return await response.value();
    }

    /**
     */
    async findMonitoringPointSimpleBySearchCriteriaRaw(requestParameters: MonitoringPointApiFindMonitoringPointSimpleBySearchCriteriaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageMonitoringPointSimpleResult>> {
        if (requestParameters.monitoringPointCriteria === null || requestParameters.monitoringPointCriteria === undefined) {
            throw new runtime.RequiredError('monitoringPointCriteria','Required parameter requestParameters.monitoringPointCriteria was null or undefined when calling findMonitoringPointSimpleBySearchCriteria.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/monitoring-point/simple-by-criteria`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MonitoringPointCriteriaToJSON(requestParameters.monitoringPointCriteria),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageMonitoringPointSimpleResultFromJSON(jsonValue));
    }

    /**
     */
    async findMonitoringPointSimpleBySearchCriteria(monitoringPointCriteria: MonitoringPointCriteria, page?: number, size?: number, sort?: Array<string>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageMonitoringPointSimpleResult> {
        const response = await this.findMonitoringPointSimpleBySearchCriteriaRaw({ monitoringPointCriteria: monitoringPointCriteria, page: page, size: size, sort: sort }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getAllMonitoringPointByHistoryGroupIdRaw(requestParameters: MonitoringPointApiGetAllMonitoringPointByHistoryGroupIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MonitoringPointResult>>> {
        if (requestParameters.historyGroupId === null || requestParameters.historyGroupId === undefined) {
            throw new runtime.RequiredError('historyGroupId','Required parameter requestParameters.historyGroupId was null or undefined when calling getAllMonitoringPointByHistoryGroupId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/monitoring-point/list/{historyGroupId}`.replace(`{${"historyGroupId"}}`, encodeURIComponent(String(requestParameters.historyGroupId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MonitoringPointResultFromJSON));
    }

    /**
     */
    async getAllMonitoringPointByHistoryGroupId(historyGroupId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MonitoringPointResult>> {
        const response = await this.getAllMonitoringPointByHistoryGroupIdRaw({ historyGroupId: historyGroupId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getAllMonitoringPointsRaw(requestParameters: MonitoringPointApiGetAllMonitoringPointsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageMonitoringPointResult>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/monitoring-point/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageMonitoringPointResultFromJSON(jsonValue));
    }

    /**
     */
    async getAllMonitoringPoints(page?: number, size?: number, sort?: Array<string>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageMonitoringPointResult> {
        const response = await this.getAllMonitoringPointsRaw({ page: page, size: size, sort: sort }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getFileRaw(requestParameters: MonitoringPointApiGetFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getFile.');
        }

        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/monitoring-point/attachment/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async getFile(id: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.getFileRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getMonitoringByIdRaw(requestParameters: MonitoringPointApiGetMonitoringByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Monitoring>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getMonitoringById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/monitoring-point/monitoring/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MonitoringFromJSON(jsonValue));
    }

    /**
     */
    async getMonitoringById(id: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Monitoring> {
        const response = await this.getMonitoringByIdRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getMonitoringPointByCodeAndDateRaw(requestParameters: MonitoringPointApiGetMonitoringPointByCodeAndDateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MonitoringPointResult>> {
        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling getMonitoringPointByCodeAndDate.');
        }

        if (requestParameters.date === null || requestParameters.date === undefined) {
            throw new runtime.RequiredError('date','Required parameter requestParameters.date was null or undefined when calling getMonitoringPointByCodeAndDate.');
        }

        if (requestParameters.montPointType === null || requestParameters.montPointType === undefined) {
            throw new runtime.RequiredError('montPointType','Required parameter requestParameters.montPointType was null or undefined when calling getMonitoringPointByCodeAndDate.');
        }

        const queryParameters: any = {};

        if (requestParameters.montPointType !== undefined) {
            queryParameters['montPointType'] = requestParameters.montPointType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/monitoring-point/by-date/{code}/{date}`.replace(`{${"code"}}`, encodeURIComponent(String(requestParameters.code))).replace(`{${"date"}}`, encodeURIComponent(String(requestParameters.date))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MonitoringPointResultFromJSON(jsonValue));
    }

    /**
     */
    async getMonitoringPointByCodeAndDate(code: string, date: string, montPointType: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MonitoringPointResult> {
        const response = await this.getMonitoringPointByCodeAndDateRaw({ code: code, date: date, montPointType: montPointType }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getMonitoringPointByCodeAndDateTimeRaw(requestParameters: MonitoringPointApiGetMonitoringPointByCodeAndDateTimeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MonitoringPointResult>> {
        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling getMonitoringPointByCodeAndDateTime.');
        }

        if (requestParameters.dateTime === null || requestParameters.dateTime === undefined) {
            throw new runtime.RequiredError('dateTime','Required parameter requestParameters.dateTime was null or undefined when calling getMonitoringPointByCodeAndDateTime.');
        }

        if (requestParameters.montPointType === null || requestParameters.montPointType === undefined) {
            throw new runtime.RequiredError('montPointType','Required parameter requestParameters.montPointType was null or undefined when calling getMonitoringPointByCodeAndDateTime.');
        }

        const queryParameters: any = {};

        if (requestParameters.montPointType !== undefined) {
            queryParameters['montPointType'] = requestParameters.montPointType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/monitoring-point/by-date-time/{code}/{dateTime}`.replace(`{${"code"}}`, encodeURIComponent(String(requestParameters.code))).replace(`{${"dateTime"}}`, encodeURIComponent(String(requestParameters.dateTime))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MonitoringPointResultFromJSON(jsonValue));
    }

    /**
     */
    async getMonitoringPointByCodeAndDateTime(code: string, dateTime: string, montPointType: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MonitoringPointResult> {
        const response = await this.getMonitoringPointByCodeAndDateTimeRaw({ code: code, dateTime: dateTime, montPointType: montPointType }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getMonitoringPointByHistoryGroupIdAndDateTimeRaw(requestParameters: MonitoringPointApiGetMonitoringPointByHistoryGroupIdAndDateTimeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MonitoringPointResult>> {
        if (requestParameters.historyGroupId === null || requestParameters.historyGroupId === undefined) {
            throw new runtime.RequiredError('historyGroupId','Required parameter requestParameters.historyGroupId was null or undefined when calling getMonitoringPointByHistoryGroupIdAndDateTime.');
        }

        if (requestParameters.dateTime === null || requestParameters.dateTime === undefined) {
            throw new runtime.RequiredError('dateTime','Required parameter requestParameters.dateTime was null or undefined when calling getMonitoringPointByHistoryGroupIdAndDateTime.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/monitoring-point/by-history-group-id-date-time/{historyGroupId}/{dateTime}`.replace(`{${"historyGroupId"}}`, encodeURIComponent(String(requestParameters.historyGroupId))).replace(`{${"dateTime"}}`, encodeURIComponent(String(requestParameters.dateTime))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MonitoringPointResultFromJSON(jsonValue));
    }

    /**
     */
    async getMonitoringPointByHistoryGroupIdAndDateTime(historyGroupId: number, dateTime: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MonitoringPointResult> {
        const response = await this.getMonitoringPointByHistoryGroupIdAndDateTimeRaw({ historyGroupId: historyGroupId, dateTime: dateTime }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getMonitoringPointByIdRaw(requestParameters: MonitoringPointApiGetMonitoringPointByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MonitoringPointResult>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getMonitoringPointById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/monitoring-point/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MonitoringPointResultFromJSON(jsonValue));
    }

    /**
     */
    async getMonitoringPointById(id: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MonitoringPointResult> {
        const response = await this.getMonitoringPointByIdRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getMonitoringsByHistoryGroupIdRaw(requestParameters: MonitoringPointApiGetMonitoringsByHistoryGroupIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MonitoringCodelistItemRequired>>> {
        if (requestParameters.historyGroupId === null || requestParameters.historyGroupId === undefined) {
            throw new runtime.RequiredError('historyGroupId','Required parameter requestParameters.historyGroupId was null or undefined when calling getMonitoringsByHistoryGroupId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/monitoring-point/monitoring/all-by-history-group-id/{historyGroupId}`.replace(`{${"historyGroupId"}}`, encodeURIComponent(String(requestParameters.historyGroupId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MonitoringCodelistItemRequiredFromJSON));
    }

    /**
     */
    async getMonitoringsByHistoryGroupId(historyGroupId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MonitoringCodelistItemRequired>> {
        const response = await this.getMonitoringsByHistoryGroupIdRaw({ historyGroupId: historyGroupId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPointCategoriesByHistoryGroupIdRaw(requestParameters: MonitoringPointApiGetPointCategoriesByHistoryGroupIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PointCategoryCodelistItemRequired>>> {
        if (requestParameters.historyGroupId === null || requestParameters.historyGroupId === undefined) {
            throw new runtime.RequiredError('historyGroupId','Required parameter requestParameters.historyGroupId was null or undefined when calling getPointCategoriesByHistoryGroupId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/monitoring-point/point-category/history-group-id/{historyGroupId}`.replace(`{${"historyGroupId"}}`, encodeURIComponent(String(requestParameters.historyGroupId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PointCategoryCodelistItemRequiredFromJSON));
    }

    /**
     */
    async getPointCategoriesByHistoryGroupId(historyGroupId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PointCategoryCodelistItemRequired>> {
        const response = await this.getPointCategoriesByHistoryGroupIdRaw({ historyGroupId: historyGroupId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPointCategoryByIdRaw(requestParameters: MonitoringPointApiGetPointCategoryByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PointCategory>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getPointCategoryById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/monitoring-point/point-category/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PointCategoryFromJSON(jsonValue));
    }

    /**
     */
    async getPointCategoryById(id: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PointCategory> {
        const response = await this.getPointCategoryByIdRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async newVersionMonitoringPointRaw(requestParameters: MonitoringPointApiNewVersionMonitoringPointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MonitoringPointResult>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling newVersionMonitoringPoint.');
        }

        if (requestParameters.monitoringPointUpdate === null || requestParameters.monitoringPointUpdate === undefined) {
            throw new runtime.RequiredError('monitoringPointUpdate','Required parameter requestParameters.monitoringPointUpdate was null or undefined when calling newVersionMonitoringPoint.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/monitoring-point/new-version/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MonitoringPointUpdateToJSON(requestParameters.monitoringPointUpdate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MonitoringPointResultFromJSON(jsonValue));
    }

    /**
     */
    async newVersionMonitoringPoint(id: number, monitoringPointUpdate: MonitoringPointUpdate, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MonitoringPointResult> {
        const response = await this.newVersionMonitoringPointRaw({ id: id, monitoringPointUpdate: monitoringPointUpdate }, initOverrides);
        return await response.value();
    }

    /**
     */
    async retrieveDeletedMonitoringPointsRaw(requestParameters: MonitoringPointApiRetrieveDeletedMonitoringPointsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MonitoringPointResult>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling retrieveDeletedMonitoringPoints.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/monitoring-point/revert/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MonitoringPointResultFromJSON(jsonValue));
    }

    /**
     */
    async retrieveDeletedMonitoringPoints(id: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MonitoringPointResult> {
        const response = await this.retrieveDeletedMonitoringPointsRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateAddressRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/monitoring-point/monitoring/update-address`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async updateAddress(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.updateAddressRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async updateMonitoringRaw(requestParameters: MonitoringPointApiUpdateMonitoringRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Monitoring>> {
        if (requestParameters.monitoring === null || requestParameters.monitoring === undefined) {
            throw new runtime.RequiredError('monitoring','Required parameter requestParameters.monitoring was null or undefined when calling updateMonitoring.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/monitoring-point/monitoring/`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MonitoringToJSON(requestParameters.monitoring),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MonitoringFromJSON(jsonValue));
    }

    /**
     */
    async updateMonitoring(monitoring: Monitoring, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Monitoring> {
        const response = await this.updateMonitoringRaw({ monitoring: monitoring }, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateMonitoringPointRaw(requestParameters: MonitoringPointApiUpdateMonitoringPointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MonitoringPointResult>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateMonitoringPoint.');
        }

        if (requestParameters.monitoringPointUpdate === null || requestParameters.monitoringPointUpdate === undefined) {
            throw new runtime.RequiredError('monitoringPointUpdate','Required parameter requestParameters.monitoringPointUpdate was null or undefined when calling updateMonitoringPoint.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/monitoring-point/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MonitoringPointUpdateToJSON(requestParameters.monitoringPointUpdate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MonitoringPointResultFromJSON(jsonValue));
    }

    /**
     */
    async updateMonitoringPoint(id: number, monitoringPointUpdate: MonitoringPointUpdate, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MonitoringPointResult> {
        const response = await this.updateMonitoringPointRaw({ id: id, monitoringPointUpdate: monitoringPointUpdate }, initOverrides);
        return await response.value();
    }

    /**
     */
    async updatePointCategoryRaw(requestParameters: MonitoringPointApiUpdatePointCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PointCategory>> {
        if (requestParameters.pointCategory === null || requestParameters.pointCategory === undefined) {
            throw new runtime.RequiredError('pointCategory','Required parameter requestParameters.pointCategory was null or undefined when calling updatePointCategory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/monitoring-point/point-category/`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PointCategoryToJSON(requestParameters.pointCategory),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PointCategoryFromJSON(jsonValue));
    }

    /**
     */
    async updatePointCategory(pointCategory: PointCategory, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PointCategory> {
        const response = await this.updatePointCategoryRaw({ pointCategory: pointCategory }, initOverrides);
        return await response.value();
    }

    /**
     */
    async uploadRaw(requestParameters: MonitoringPointApiUploadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Attachment>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling upload.');
        }

        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling upload.');
        }

        if (requestParameters.multipartFile === null || requestParameters.multipartFile === undefined) {
            throw new runtime.RequiredError('multipartFile','Required parameter requestParameters.multipartFile was null or undefined when calling upload.');
        }

        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.multipartFile !== undefined) {
            formParams.append('multipartFile', requestParameters.multipartFile as any);
        }

        const response = await this.request({
            path: `/monitoring-point/attachment/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AttachmentFromJSON(jsonValue));
    }

    /**
     */
    async upload(id: number, name: string, multipartFile: Blob, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Attachment> {
        const response = await this.uploadRaw({ id: id, name: name, multipartFile: multipartFile }, initOverrides);
        return await response.value();
    }

    /**
     */
    async validateMonitoringPointRaw(requestParameters: MonitoringPointApiValidateMonitoringPointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.monitoringPointInput === null || requestParameters.monitoringPointInput === undefined) {
            throw new runtime.RequiredError('monitoringPointInput','Required parameter requestParameters.monitoringPointInput was null or undefined when calling validateMonitoringPoint.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/monitoring-point/validate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MonitoringPointInputToJSON(requestParameters.monitoringPointInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async validateMonitoringPoint(monitoringPointInput: MonitoringPointInput, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.validateMonitoringPointRaw({ monitoringPointInput: monitoringPointInput }, initOverrides);
    }

    /**
     */
    async validateUpdateMonitoringPointRaw(requestParameters: MonitoringPointApiValidateUpdateMonitoringPointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling validateUpdateMonitoringPoint.');
        }

        if (requestParameters.monitoringPointUpdate === null || requestParameters.monitoringPointUpdate === undefined) {
            throw new runtime.RequiredError('monitoringPointUpdate','Required parameter requestParameters.monitoringPointUpdate was null or undefined when calling validateUpdateMonitoringPoint.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/monitoring-point/validate/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MonitoringPointUpdateToJSON(requestParameters.monitoringPointUpdate),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async validateUpdateMonitoringPoint(id: number, monitoringPointUpdate: MonitoringPointUpdate, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.validateUpdateMonitoringPointRaw({ id: id, monitoringPointUpdate: monitoringPointUpdate }, initOverrides);
    }

}
