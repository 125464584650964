/* tslint:disable */
/* eslint-disable */
/**
 * Data import metadata
 * Administration of metadata for import
 *
 * The version of the OpenAPI document: 1.0
 * Contact: schvarcz@bb.microcomp.sk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DataRegistryAttributeIdentificationResponse
 */
export interface DataRegistryAttributeIdentificationResponse {
    /**
     * 
     * @type {string}
     * @memberof DataRegistryAttributeIdentificationResponse
     */
    dataRegistryProductAttributeCode?: string;
    /**
     * 
     * @type {string}
     * @memberof DataRegistryAttributeIdentificationResponse
     */
    dataRegistryProductAttributeIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof DataRegistryAttributeIdentificationResponse
     */
    controlReferenceAttributeType?: DataRegistryAttributeIdentificationResponseControlReferenceAttributeTypeEnum;
}


/**
 * @export
 */
export const DataRegistryAttributeIdentificationResponseControlReferenceAttributeTypeEnum = {
    Year: 'YEAR',
    Date: 'DATE',
    Source: 'SOURCE',
    ReferenceCode: 'REFERENCE_CODE',
    ReferenceValue: 'REFERENCE_VALUE',
    Time: 'TIME',
    Stage: 'STAGE',
    Temp: 'TEMP',
    Yield: 'YIELD',
    MonPointId: 'MON_POINT_ID',
    FrequencyCode: 'FREQUENCY_CODE',
    AttributeCode: 'ATTRIBUTE_CODE',
    ReferenceAttribute: 'REFERENCE_ATTRIBUTE',
    WaterTypeCode: 'WATER_TYPE_CODE',
    SampleTypeCode: 'SAMPLE_TYPE_CODE',
    DeterminantCode: 'DETERMINANT_CODE',
    PollutionKind: 'POLLUTION_KIND',
    ExtremeStage: 'EXTREME_STAGE',
    ExtremeTemp: 'EXTREME_TEMP',
    ExtremeMin: 'EXTREME_MIN',
    ExtremeMax: 'EXTREME_MAX',
    ExtremeYield: 'EXTREME_YIELD',
    Discharge: 'DISCHARGE',
    ExtremeDischarge: 'EXTREME_DISCHARGE',
    ValueNum: 'VALUE_NUM',
    ValueStr: 'VALUE_STR',
    DetLimit: 'DET_LIMIT',
    ValueUnit: 'VALUE_UNIT',
    DeterminantUnit: 'DETERMINANT_UNIT',
    MeasuringInterval: 'MEASURING_INTERVAL',
    UndergroundWaterDepth: 'UNDERGROUND_WATER_DEPTH',
    UndergroundWaterHeight: 'UNDERGROUND_WATER_HEIGHT',
    ValidFrom: 'VALID_FROM',
    ValidTo: 'VALID_TO',
    SeasonFrom: 'SEASON_FROM',
    SeasonTo: 'SEASON_TO'
} as const;
export type DataRegistryAttributeIdentificationResponseControlReferenceAttributeTypeEnum = typeof DataRegistryAttributeIdentificationResponseControlReferenceAttributeTypeEnum[keyof typeof DataRegistryAttributeIdentificationResponseControlReferenceAttributeTypeEnum];


/**
 * Check if a given object implements the DataRegistryAttributeIdentificationResponse interface.
 */
export function instanceOfDataRegistryAttributeIdentificationResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DataRegistryAttributeIdentificationResponseFromJSON(json: any): DataRegistryAttributeIdentificationResponse {
    return DataRegistryAttributeIdentificationResponseFromJSONTyped(json, false);
}

export function DataRegistryAttributeIdentificationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataRegistryAttributeIdentificationResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dataRegistryProductAttributeCode': !exists(json, 'dataRegistryProductAttributeCode') ? undefined : json['dataRegistryProductAttributeCode'],
        'dataRegistryProductAttributeIdentifier': !exists(json, 'dataRegistryProductAttributeIdentifier') ? undefined : json['dataRegistryProductAttributeIdentifier'],
        'controlReferenceAttributeType': !exists(json, 'controlReferenceAttributeType') ? undefined : json['controlReferenceAttributeType'],
    };
}

export function DataRegistryAttributeIdentificationResponseToJSON(value?: DataRegistryAttributeIdentificationResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dataRegistryProductAttributeCode': value.dataRegistryProductAttributeCode,
        'dataRegistryProductAttributeIdentifier': value.dataRegistryProductAttributeIdentifier,
        'controlReferenceAttributeType': value.controlReferenceAttributeType,
    };
}

