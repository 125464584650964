/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WaterWithdrawalRecord
 */
export interface WaterWithdrawalRecord {
    /**
     * 
     * @type {any}
     * @memberof WaterWithdrawalRecord
     */
    balanceCode?: any;
    /**
     * 
     * @type {any}
     * @memberof WaterWithdrawalRecord
     */
    continuityCodeBackward?: any;
    /**
     * 
     * @type {any}
     * @memberof WaterWithdrawalRecord
     */
    continuityCodeForward?: any;
    /**
     * 
     * @type {any}
     * @memberof WaterWithdrawalRecord
     */
    dopsCode?: any;
    /**
     * 
     * @type {any}
     * @memberof WaterWithdrawalRecord
     */
    guaranteedFlow?: any;
    /**
     * 
     * @type {any}
     * @memberof WaterWithdrawalRecord
     */
    hydrofundCode?: any;
    /**
     * 
     * @type {number}
     * @memberof WaterWithdrawalRecord
     */
    orderNumber?: number;
    /**
     * 
     * @type {boolean}
     * @memberof WaterWithdrawalRecord
     */
    permit?: boolean;
    /**
     * 
     * @type {any}
     * @memberof WaterWithdrawalRecord
     */
    siteEstablishmentDate?: any;
    /**
     * 
     * @type {any}
     * @memberof WaterWithdrawalRecord
     */
    specificPurposeType?: any;
    /**
     * 
     * @type {any}
     * @memberof WaterWithdrawalRecord
     */
    waterLawDecisionNumber?: any;
    /**
     * 
     * @type {any}
     * @memberof WaterWithdrawalRecord
     */
    waterType?: any;
    /**
     * 
     * @type {any}
     * @memberof WaterWithdrawalRecord
     */
    installation?: any;
    /**
     * 
     * @type {any}
     * @memberof WaterWithdrawalRecord
     */
    naceType?: any;
    /**
     * 
     * @type {any}
     * @memberof WaterWithdrawalRecord
     */
    nace2Type?: any;
}

/**
 * Check if a given object implements the WaterWithdrawalRecord interface.
 */
export function instanceOfWaterWithdrawalRecord(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WaterWithdrawalRecordFromJSON(json: any): WaterWithdrawalRecord {
    return WaterWithdrawalRecordFromJSONTyped(json, false);
}

export function WaterWithdrawalRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): WaterWithdrawalRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'balanceCode': !exists(json, 'balanceCode') ? undefined : json['balanceCode'],
        'continuityCodeBackward': !exists(json, 'continuityCodeBackward') ? undefined : json['continuityCodeBackward'],
        'continuityCodeForward': !exists(json, 'continuityCodeForward') ? undefined : json['continuityCodeForward'],
        'dopsCode': !exists(json, 'dopsCode') ? undefined : json['dopsCode'],
        'guaranteedFlow': !exists(json, 'guaranteedFlow') ? undefined : json['guaranteedFlow'],
        'hydrofundCode': !exists(json, 'hydrofundCode') ? undefined : json['hydrofundCode'],
        'orderNumber': !exists(json, 'orderNumber') ? undefined : json['orderNumber'],
        'permit': !exists(json, 'permit') ? undefined : json['permit'],
        'siteEstablishmentDate': !exists(json, 'siteEstablishmentDate') ? undefined : json['siteEstablishmentDate'],
        'specificPurposeType': !exists(json, 'specificPurposeType') ? undefined : json['specificPurposeType'],
        'waterLawDecisionNumber': !exists(json, 'waterLawDecisionNumber') ? undefined : json['waterLawDecisionNumber'],
        'waterType': !exists(json, 'waterType') ? undefined : json['waterType'],
        'installation': !exists(json, 'installation') ? undefined : json['installation'],
        'naceType': !exists(json, 'naceType') ? undefined : json['naceType'],
        'nace2Type': !exists(json, 'nace2Type') ? undefined : json['nace2Type'],
    };
}

export function WaterWithdrawalRecordToJSON(value?: WaterWithdrawalRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'balanceCode': value.balanceCode,
        'continuityCodeBackward': value.continuityCodeBackward,
        'continuityCodeForward': value.continuityCodeForward,
        'dopsCode': value.dopsCode,
        'guaranteedFlow': value.guaranteedFlow,
        'hydrofundCode': value.hydrofundCode,
        'orderNumber': value.orderNumber,
        'permit': value.permit,
        'siteEstablishmentDate': value.siteEstablishmentDate,
        'specificPurposeType': value.specificPurposeType,
        'waterLawDecisionNumber': value.waterLawDecisionNumber,
        'waterType': value.waterType,
        'installation': value.installation,
        'naceType': value.naceType,
        'nace2Type': value.nace2Type,
    };
}

