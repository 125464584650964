/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CodeListItemBasicRecord } from './CodeListItemBasicRecord';
import {
    CodeListItemBasicRecordFromJSON,
    CodeListItemBasicRecordFromJSONTyped,
    CodeListItemBasicRecordToJSON,
} from './CodeListItemBasicRecord';

/**
 * 
 * @export
 * @interface HydroAnalysisSsuResponseRecord
 */
export interface HydroAnalysisSsuResponseRecord {
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisSsuResponseRecord
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisSsuResponseRecord
     */
    largeWoodyDebris?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisSsuResponseRecord
     */
    artificialBedFeatures?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisSsuResponseRecord
     */
    stretchObscuration?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisSsuResponseRecord
     */
    riparianVegetationL?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisSsuResponseRecord
     */
    riparianVegetationR?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisSsuResponseRecord
     */
    bankStabilizationL?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisSsuResponseRecord
     */
    bankStabilizationR?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisSsuResponseRecord
     */
    bankProfileL?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisSsuResponseRecord
     */
    bankProfileR?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisSsuResponseRecord
     */
    floodedAreaL?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisSsuResponseRecord
     */
    floodedAreaR?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisSsuResponseRecord
     */
    naturalVegetationL?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisSsuResponseRecord
     */
    naturalVegetationR?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisSsuResponseRecord
     */
    varInWidthW?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisSsuResponseRecord
     */
    varInWidthS?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisSsuResponseRecord
     */
    stretchMaxDebt?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisSsuResponseRecord
     */
    bedElement?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisSsuResponseRecord
     */
    substrate?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisSsuResponseRecord
     */
    flowType?: number;
    /**
     * 
     * @type {boolean}
     * @memberof HydroAnalysisSsuResponseRecord
     */
    largeWoodyDebrisNotRelevant?: boolean;
    /**
     * 
     * @type {Array<CodeListItemBasicRecord>}
     * @memberof HydroAnalysisSsuResponseRecord
     */
    bedElementItems?: Array<CodeListItemBasicRecord>;
    /**
     * 
     * @type {Array<CodeListItemBasicRecord>}
     * @memberof HydroAnalysisSsuResponseRecord
     */
    substrateItems?: Array<CodeListItemBasicRecord>;
    /**
     * 
     * @type {Array<CodeListItemBasicRecord>}
     * @memberof HydroAnalysisSsuResponseRecord
     */
    flowTypeItems?: Array<CodeListItemBasicRecord>;
}

/**
 * Check if a given object implements the HydroAnalysisSsuResponseRecord interface.
 */
export function instanceOfHydroAnalysisSsuResponseRecord(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function HydroAnalysisSsuResponseRecordFromJSON(json: any): HydroAnalysisSsuResponseRecord {
    return HydroAnalysisSsuResponseRecordFromJSONTyped(json, false);
}

export function HydroAnalysisSsuResponseRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): HydroAnalysisSsuResponseRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'largeWoodyDebris': !exists(json, 'largeWoodyDebris') ? undefined : json['largeWoodyDebris'],
        'artificialBedFeatures': !exists(json, 'artificialBedFeatures') ? undefined : json['artificialBedFeatures'],
        'stretchObscuration': !exists(json, 'stretchObscuration') ? undefined : json['stretchObscuration'],
        'riparianVegetationL': !exists(json, 'riparianVegetationL') ? undefined : json['riparianVegetationL'],
        'riparianVegetationR': !exists(json, 'riparianVegetationR') ? undefined : json['riparianVegetationR'],
        'bankStabilizationL': !exists(json, 'bankStabilizationL') ? undefined : json['bankStabilizationL'],
        'bankStabilizationR': !exists(json, 'bankStabilizationR') ? undefined : json['bankStabilizationR'],
        'bankProfileL': !exists(json, 'bankProfileL') ? undefined : json['bankProfileL'],
        'bankProfileR': !exists(json, 'bankProfileR') ? undefined : json['bankProfileR'],
        'floodedAreaL': !exists(json, 'floodedAreaL') ? undefined : json['floodedAreaL'],
        'floodedAreaR': !exists(json, 'floodedAreaR') ? undefined : json['floodedAreaR'],
        'naturalVegetationL': !exists(json, 'naturalVegetationL') ? undefined : json['naturalVegetationL'],
        'naturalVegetationR': !exists(json, 'naturalVegetationR') ? undefined : json['naturalVegetationR'],
        'varInWidthW': !exists(json, 'varInWidthW') ? undefined : json['varInWidthW'],
        'varInWidthS': !exists(json, 'varInWidthS') ? undefined : json['varInWidthS'],
        'stretchMaxDebt': !exists(json, 'stretchMaxDebt') ? undefined : json['stretchMaxDebt'],
        'bedElement': !exists(json, 'bedElement') ? undefined : json['bedElement'],
        'substrate': !exists(json, 'substrate') ? undefined : json['substrate'],
        'flowType': !exists(json, 'flowType') ? undefined : json['flowType'],
        'largeWoodyDebrisNotRelevant': !exists(json, 'largeWoodyDebrisNotRelevant') ? undefined : json['largeWoodyDebrisNotRelevant'],
        'bedElementItems': !exists(json, 'bedElementItems') ? undefined : ((json['bedElementItems'] as Array<any>).map(CodeListItemBasicRecordFromJSON)),
        'substrateItems': !exists(json, 'substrateItems') ? undefined : ((json['substrateItems'] as Array<any>).map(CodeListItemBasicRecordFromJSON)),
        'flowTypeItems': !exists(json, 'flowTypeItems') ? undefined : ((json['flowTypeItems'] as Array<any>).map(CodeListItemBasicRecordFromJSON)),
    };
}

export function HydroAnalysisSsuResponseRecordToJSON(value?: HydroAnalysisSsuResponseRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'largeWoodyDebris': value.largeWoodyDebris,
        'artificialBedFeatures': value.artificialBedFeatures,
        'stretchObscuration': value.stretchObscuration,
        'riparianVegetationL': value.riparianVegetationL,
        'riparianVegetationR': value.riparianVegetationR,
        'bankStabilizationL': value.bankStabilizationL,
        'bankStabilizationR': value.bankStabilizationR,
        'bankProfileL': value.bankProfileL,
        'bankProfileR': value.bankProfileR,
        'floodedAreaL': value.floodedAreaL,
        'floodedAreaR': value.floodedAreaR,
        'naturalVegetationL': value.naturalVegetationL,
        'naturalVegetationR': value.naturalVegetationR,
        'varInWidthW': value.varInWidthW,
        'varInWidthS': value.varInWidthS,
        'stretchMaxDebt': value.stretchMaxDebt,
        'bedElement': value.bedElement,
        'substrate': value.substrate,
        'flowType': value.flowType,
        'largeWoodyDebrisNotRelevant': value.largeWoodyDebrisNotRelevant,
        'bedElementItems': value.bedElementItems === undefined ? undefined : ((value.bedElementItems as Array<any>).map(CodeListItemBasicRecordToJSON)),
        'substrateItems': value.substrateItems === undefined ? undefined : ((value.substrateItems as Array<any>).map(CodeListItemBasicRecordToJSON)),
        'flowTypeItems': value.flowTypeItems === undefined ? undefined : ((value.flowTypeItems as Array<any>).map(CodeListItemBasicRecordToJSON)),
    };
}

