/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { QualityAssessmentDeterminantRecord } from './QualityAssessmentDeterminantRecord';
import {
    QualityAssessmentDeterminantRecordFromJSON,
    QualityAssessmentDeterminantRecordFromJSONTyped,
    QualityAssessmentDeterminantRecordToJSON,
} from './QualityAssessmentDeterminantRecord';
import type { QualityAssessmentFileRecord } from './QualityAssessmentFileRecord';
import {
    QualityAssessmentFileRecordFromJSON,
    QualityAssessmentFileRecordFromJSONTyped,
    QualityAssessmentFileRecordToJSON,
} from './QualityAssessmentFileRecord';

/**
 * 
 * @export
 * @interface QualityAssessmentRequest
 */
export interface QualityAssessmentRequest {
    /**
     * 
     * @type {number}
     * @memberof QualityAssessmentRequest
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof QualityAssessmentRequest
     */
    arn?: string;
    /**
     * 
     * @type {string}
     * @memberof QualityAssessmentRequest
     */
    environment?: string;
    /**
     * 
     * @type {string}
     * @memberof QualityAssessmentRequest
     */
    applicant?: string;
    /**
     * 
     * @type {string}
     * @memberof QualityAssessmentRequest
     */
    fileNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof QualityAssessmentRequest
     */
    sampleNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof QualityAssessmentRequest
     */
    deliveryDate?: string;
    /**
     * 
     * @type {string}
     * @memberof QualityAssessmentRequest
     */
    issueDate?: string;
    /**
     * 
     * @type {string}
     * @memberof QualityAssessmentRequest
     */
    address?: string;
    /**
     * 
     * @type {number}
     * @memberof QualityAssessmentRequest
     */
    price?: number;
    /**
     * 
     * @type {number}
     * @memberof QualityAssessmentRequest
     */
    qokQ355?: number;
    /**
     * 
     * @type {number}
     * @memberof QualityAssessmentRequest
     */
    qok?: number;
    /**
     * 
     * @type {number}
     * @memberof QualityAssessmentRequest
     */
    q355?: number;
    /**
     * 
     * @type {string}
     * @memberof QualityAssessmentRequest
     */
    flowSpeed?: string;
    /**
     * 
     * @type {string}
     * @memberof QualityAssessmentRequest
     */
    temperature?: string;
    /**
     * 
     * @type {string}
     * @memberof QualityAssessmentRequest
     */
    pH?: string;
    /**
     * 
     * @type {string}
     * @memberof QualityAssessmentRequest
     */
    conductivity?: string;
    /**
     * 
     * @type {string}
     * @memberof QualityAssessmentRequest
     */
    o2?: string;
    /**
     * 
     * @type {string}
     * @memberof QualityAssessmentRequest
     */
    o2Perc?: string;
    /**
     * 
     * @type {string}
     * @memberof QualityAssessmentRequest
     */
    note?: string;
    /**
     * 
     * @type {string}
     * @memberof QualityAssessmentRequest
     */
    withdrawDate?: string;
    /**
     * 
     * @type {string}
     * @memberof QualityAssessmentRequest
     */
    state?: QualityAssessmentRequestStateEnum;
    /**
     * 
     * @type {Array<QualityAssessmentFileRecord>}
     * @memberof QualityAssessmentRequest
     */
    qualityAssessmentFiles?: Array<QualityAssessmentFileRecord>;
    /**
     * 
     * @type {Array<QualityAssessmentDeterminantRecord>}
     * @memberof QualityAssessmentRequest
     */
    qualityAssessmentDeterminants?: Array<QualityAssessmentDeterminantRecord>;
    /**
     * 
     * @type {number}
     * @memberof QualityAssessmentRequest
     */
    assessmentTypeCode?: number;
    /**
     * 
     * @type {number}
     * @memberof QualityAssessmentRequest
     */
    instBranchCode?: number;
    /**
     * 
     * @type {number}
     * @memberof QualityAssessmentRequest
     */
    color?: number;
    /**
     * 
     * @type {number}
     * @memberof QualityAssessmentRequest
     */
    smell?: number;
    /**
     * 
     * @type {number}
     * @memberof QualityAssessmentRequest
     */
    turbidity?: number;
    /**
     * 
     * @type {number}
     * @memberof QualityAssessmentRequest
     */
    solver?: number;
    /**
     * 
     * @type {number}
     * @memberof QualityAssessmentRequest
     */
    statusItemCode?: number;
    /**
     * 
     * @type {string}
     * @memberof QualityAssessmentRequest
     */
    monPointCode?: string;
    /**
     * 
     * @type {number}
     * @memberof QualityAssessmentRequest
     */
    monPointId?: number;
}


/**
 * @export
 */
export const QualityAssessmentRequestStateEnum = {
    Progress: 'PROGRESS',
    Written: 'WRITTEN',
    Deleted: 'DELETED'
} as const;
export type QualityAssessmentRequestStateEnum = typeof QualityAssessmentRequestStateEnum[keyof typeof QualityAssessmentRequestStateEnum];


/**
 * Check if a given object implements the QualityAssessmentRequest interface.
 */
export function instanceOfQualityAssessmentRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function QualityAssessmentRequestFromJSON(json: any): QualityAssessmentRequest {
    return QualityAssessmentRequestFromJSONTyped(json, false);
}

export function QualityAssessmentRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): QualityAssessmentRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'arn': !exists(json, 'arn') ? undefined : json['arn'],
        'environment': !exists(json, 'environment') ? undefined : json['environment'],
        'applicant': !exists(json, 'applicant') ? undefined : json['applicant'],
        'fileNumber': !exists(json, 'fileNumber') ? undefined : json['fileNumber'],
        'sampleNumber': !exists(json, 'sampleNumber') ? undefined : json['sampleNumber'],
        'deliveryDate': !exists(json, 'deliveryDate') ? undefined : json['deliveryDate'],
        'issueDate': !exists(json, 'issueDate') ? undefined : json['issueDate'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'price': !exists(json, 'price') ? undefined : json['price'],
        'qokQ355': !exists(json, 'qokQ355') ? undefined : json['qokQ355'],
        'qok': !exists(json, 'qok') ? undefined : json['qok'],
        'q355': !exists(json, 'q355') ? undefined : json['q355'],
        'flowSpeed': !exists(json, 'flowSpeed') ? undefined : json['flowSpeed'],
        'temperature': !exists(json, 'temperature') ? undefined : json['temperature'],
        'pH': !exists(json, 'pH') ? undefined : json['pH'],
        'conductivity': !exists(json, 'conductivity') ? undefined : json['conductivity'],
        'o2': !exists(json, 'o2') ? undefined : json['o2'],
        'o2Perc': !exists(json, 'o2Perc') ? undefined : json['o2Perc'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'withdrawDate': !exists(json, 'withdrawDate') ? undefined : json['withdrawDate'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'qualityAssessmentFiles': !exists(json, 'qualityAssessmentFiles') ? undefined : ((json['qualityAssessmentFiles'] as Array<any>).map(QualityAssessmentFileRecordFromJSON)),
        'qualityAssessmentDeterminants': !exists(json, 'qualityAssessmentDeterminants') ? undefined : ((json['qualityAssessmentDeterminants'] as Array<any>).map(QualityAssessmentDeterminantRecordFromJSON)),
        'assessmentTypeCode': !exists(json, 'assessmentTypeCode') ? undefined : json['assessmentTypeCode'],
        'instBranchCode': !exists(json, 'instBranchCode') ? undefined : json['instBranchCode'],
        'color': !exists(json, 'color') ? undefined : json['color'],
        'smell': !exists(json, 'smell') ? undefined : json['smell'],
        'turbidity': !exists(json, 'turbidity') ? undefined : json['turbidity'],
        'solver': !exists(json, 'solver') ? undefined : json['solver'],
        'statusItemCode': !exists(json, 'statusItemCode') ? undefined : json['statusItemCode'],
        'monPointCode': !exists(json, 'monPointCode') ? undefined : json['monPointCode'],
        'monPointId': !exists(json, 'monPointId') ? undefined : json['monPointId'],
    };
}

export function QualityAssessmentRequestToJSON(value?: QualityAssessmentRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'arn': value.arn,
        'environment': value.environment,
        'applicant': value.applicant,
        'fileNumber': value.fileNumber,
        'sampleNumber': value.sampleNumber,
        'deliveryDate': value.deliveryDate,
        'issueDate': value.issueDate,
        'address': value.address,
        'price': value.price,
        'qokQ355': value.qokQ355,
        'qok': value.qok,
        'q355': value.q355,
        'flowSpeed': value.flowSpeed,
        'temperature': value.temperature,
        'pH': value.pH,
        'conductivity': value.conductivity,
        'o2': value.o2,
        'o2Perc': value.o2Perc,
        'note': value.note,
        'withdrawDate': value.withdrawDate,
        'state': value.state,
        'qualityAssessmentFiles': value.qualityAssessmentFiles === undefined ? undefined : ((value.qualityAssessmentFiles as Array<any>).map(QualityAssessmentFileRecordToJSON)),
        'qualityAssessmentDeterminants': value.qualityAssessmentDeterminants === undefined ? undefined : ((value.qualityAssessmentDeterminants as Array<any>).map(QualityAssessmentDeterminantRecordToJSON)),
        'assessmentTypeCode': value.assessmentTypeCode,
        'instBranchCode': value.instBranchCode,
        'color': value.color,
        'smell': value.smell,
        'turbidity': value.turbidity,
        'solver': value.solver,
        'statusItemCode': value.statusItemCode,
        'monPointCode': value.monPointCode,
        'monPointId': value.monPointId,
    };
}

