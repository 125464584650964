/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WaterStructSewerRequestRecord
 */
export interface WaterStructSewerRequestRecord {
    /**
     * 
     * @type {number}
     * @memberof WaterStructSewerRequestRecord
     */
    id?: number | null;
    /**
     * 
     * @type {string}
     * @memberof WaterStructSewerRequestRecord
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructSewerRequestRecord
     */
    systCode?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructSewerRequestRecord
     */
    characterCode?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructSewerRequestRecord
     */
    supplies?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructSewerRequestRecord
     */
    typeCode?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructSewerRequestRecord
     */
    length?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructSewerRequestRecord
     */
    lengthByType?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructSewerRequestRecord
     */
    minSize?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructSewerRequestRecord
     */
    maxSize?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructSewerRequestRecord
     */
    chamberNum?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructSewerRequestRecord
     */
    stationNum?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructSewerRequestRecord
     */
    waterTypeCode?: number | null;
    /**
     * 
     * @type {string}
     * @memberof WaterStructSewerRequestRecord
     */
    production?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructSewerRequestRecord
     */
    permObjectCode?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof WaterStructSewerRequestRecord
     */
    testOper?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructSewerRequestRecord
     */
    testDuration?: number | null;
}

/**
 * Check if a given object implements the WaterStructSewerRequestRecord interface.
 */
export function instanceOfWaterStructSewerRequestRecord(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WaterStructSewerRequestRecordFromJSON(json: any): WaterStructSewerRequestRecord {
    return WaterStructSewerRequestRecordFromJSONTyped(json, false);
}

export function WaterStructSewerRequestRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): WaterStructSewerRequestRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'systCode': !exists(json, 'systCode') ? undefined : json['systCode'],
        'characterCode': !exists(json, 'characterCode') ? undefined : json['characterCode'],
        'supplies': !exists(json, 'supplies') ? undefined : json['supplies'],
        'typeCode': !exists(json, 'typeCode') ? undefined : json['typeCode'],
        'length': !exists(json, 'length') ? undefined : json['length'],
        'lengthByType': !exists(json, 'lengthByType') ? undefined : json['lengthByType'],
        'minSize': !exists(json, 'minSize') ? undefined : json['minSize'],
        'maxSize': !exists(json, 'maxSize') ? undefined : json['maxSize'],
        'chamberNum': !exists(json, 'chamberNum') ? undefined : json['chamberNum'],
        'stationNum': !exists(json, 'stationNum') ? undefined : json['stationNum'],
        'waterTypeCode': !exists(json, 'waterTypeCode') ? undefined : json['waterTypeCode'],
        'production': !exists(json, 'production') ? undefined : json['production'],
        'permObjectCode': !exists(json, 'permObjectCode') ? undefined : json['permObjectCode'],
        'testOper': !exists(json, 'testOper') ? undefined : json['testOper'],
        'testDuration': !exists(json, 'testDuration') ? undefined : json['testDuration'],
    };
}

export function WaterStructSewerRequestRecordToJSON(value?: WaterStructSewerRequestRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'systCode': value.systCode,
        'characterCode': value.characterCode,
        'supplies': value.supplies,
        'typeCode': value.typeCode,
        'length': value.length,
        'lengthByType': value.lengthByType,
        'minSize': value.minSize,
        'maxSize': value.maxSize,
        'chamberNum': value.chamberNum,
        'stationNum': value.stationNum,
        'waterTypeCode': value.waterTypeCode,
        'production': value.production,
        'permObjectCode': value.permObjectCode,
        'testOper': value.testOper,
        'testDuration': value.testDuration,
    };
}

