/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CodeListItemBasicRecord } from './CodeListItemBasicRecord';
import {
    CodeListItemBasicRecordFromJSON,
    CodeListItemBasicRecordFromJSONTyped,
    CodeListItemBasicRecordToJSON,
} from './CodeListItemBasicRecord';

/**
 * 
 * @export
 * @interface WaterStructWatertankResponseRecord
 */
export interface WaterStructWatertankResponseRecord {
    /**
     * 
     * @type {number}
     * @memberof WaterStructWatertankResponseRecord
     */
    id?: number | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof WaterStructWatertankResponseRecord
     */
    waterTank?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructWatertankResponseRecord
     */
    volume?: number | null;
}

/**
 * Check if a given object implements the WaterStructWatertankResponseRecord interface.
 */
export function instanceOfWaterStructWatertankResponseRecord(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WaterStructWatertankResponseRecordFromJSON(json: any): WaterStructWatertankResponseRecord {
    return WaterStructWatertankResponseRecordFromJSONTyped(json, false);
}

export function WaterStructWatertankResponseRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): WaterStructWatertankResponseRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'waterTank': !exists(json, 'waterTank') ? undefined : CodeListItemBasicRecordFromJSON(json['waterTank']),
        'volume': !exists(json, 'volume') ? undefined : json['volume'],
    };
}

export function WaterStructWatertankResponseRecordToJSON(value?: WaterStructWatertankResponseRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'waterTank': CodeListItemBasicRecordToJSON(value.waterTank),
        'volume': value.volume,
    };
}

