/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { HydroAnalysisScoreCalculationSsu } from './HydroAnalysisScoreCalculationSsu';
import {
    HydroAnalysisScoreCalculationSsuFromJSON,
    HydroAnalysisScoreCalculationSsuFromJSONTyped,
    HydroAnalysisScoreCalculationSsuToJSON,
} from './HydroAnalysisScoreCalculationSsu';

/**
 * 
 * @export
 * @interface HydroAnalysisScoreCalculationRequest
 */
export interface HydroAnalysisScoreCalculationRequest {
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisScoreCalculationRequest
     */
    channelSinuosity?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisScoreCalculationRequest
     */
    channelType?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisScoreCalculationRequest
     */
    channelShortening?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisScoreCalculationRequest
     */
    hydrology?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisScoreCalculationRequest
     */
    morphology?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisScoreCalculationRequest
     */
    continuity?: number;
    /**
     * 
     * @type {Array<HydroAnalysisScoreCalculationSsu>}
     * @memberof HydroAnalysisScoreCalculationRequest
     */
    scoreCalculationSsuList?: Array<HydroAnalysisScoreCalculationSsu>;
}

/**
 * Check if a given object implements the HydroAnalysisScoreCalculationRequest interface.
 */
export function instanceOfHydroAnalysisScoreCalculationRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function HydroAnalysisScoreCalculationRequestFromJSON(json: any): HydroAnalysisScoreCalculationRequest {
    return HydroAnalysisScoreCalculationRequestFromJSONTyped(json, false);
}

export function HydroAnalysisScoreCalculationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): HydroAnalysisScoreCalculationRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'channelSinuosity': !exists(json, 'channelSinuosity') ? undefined : json['channelSinuosity'],
        'channelType': !exists(json, 'channelType') ? undefined : json['channelType'],
        'channelShortening': !exists(json, 'channelShortening') ? undefined : json['channelShortening'],
        'hydrology': !exists(json, 'hydrology') ? undefined : json['hydrology'],
        'morphology': !exists(json, 'morphology') ? undefined : json['morphology'],
        'continuity': !exists(json, 'continuity') ? undefined : json['continuity'],
        'scoreCalculationSsuList': !exists(json, 'scoreCalculationSsuList') ? undefined : ((json['scoreCalculationSsuList'] as Array<any>).map(HydroAnalysisScoreCalculationSsuFromJSON)),
    };
}

export function HydroAnalysisScoreCalculationRequestToJSON(value?: HydroAnalysisScoreCalculationRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'channelSinuosity': value.channelSinuosity,
        'channelType': value.channelType,
        'channelShortening': value.channelShortening,
        'hydrology': value.hydrology,
        'morphology': value.morphology,
        'continuity': value.continuity,
        'scoreCalculationSsuList': value.scoreCalculationSsuList === undefined ? undefined : ((value.scoreCalculationSsuList as Array<any>).map(HydroAnalysisScoreCalculationSsuToJSON)),
    };
}

