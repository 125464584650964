/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DangerSubstanceRequestRecord } from './DangerSubstanceRequestRecord';
import {
    DangerSubstanceRequestRecordFromJSON,
    DangerSubstanceRequestRecordFromJSONTyped,
    DangerSubstanceRequestRecordToJSON,
} from './DangerSubstanceRequestRecord';
import type { NewPlaceRequestRecord } from './NewPlaceRequestRecord';
import {
    NewPlaceRequestRecordFromJSON,
    NewPlaceRequestRecordFromJSONTyped,
    NewPlaceRequestRecordToJSON,
} from './NewPlaceRequestRecord';
import type { QualityTabRequestRecord } from './QualityTabRequestRecord';
import {
    QualityTabRequestRecordFromJSON,
    QualityTabRequestRecordFromJSONTyped,
    QualityTabRequestRecordToJSON,
} from './QualityTabRequestRecord';

/**
 * 
 * @export
 * @interface WaterEvidenceAppendixRequestRecord
 */
export interface WaterEvidenceAppendixRequestRecord {
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    id?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    documentTypeCode?: number | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    dateFrom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    dateTo?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    monitoringPointId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    placeDesc?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    placeName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    streamCode?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    streamId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    recStreamCode?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    recStreamId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    riverKm?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    recRiverKm?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    positionCode?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    waterUseCode?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    waterOriginCode?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    waterStructCode?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    objectTypeCode?: number | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    objectName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    objectAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    objectCity?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    objectZip?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    qtyMinBalFlow?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    qtyMinLevel?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    qtyMaxLevel?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    qtyAvFlow?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    qtyMaxFlow?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    qtyYearFlow?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    qtyMeasUnitCode?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    qtyYear?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    qtyMonth?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    qtyDay?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    qtyMaxFall?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    qtyVolume?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    numMonths?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    numDays?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    numHours?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    qtyQuantAcc?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    measMethodCode?: number | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    measDevice?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    measFreqYr?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    measFreqQt?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    measFreqQl?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    quantWater?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    waterCatCode?: number | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    samplePlace?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    waterTypeCode?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    tempDecSt?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    tempDecWt?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    maxTempDec?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    recMonitoringPointId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    recTypeCode?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    sourceSize?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    protArea?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    sludgeHandCode?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    sludgeStabCode?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    sludgeDrainCode?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    janPerm?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    janQ?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    febPerm?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    febQ?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    marPerm?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    marQ?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    aprPerm?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    aprQ?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    mayPerm?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    mayQ?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    junPerm?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    junQ?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    julPerm?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    julQ?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    augPerm?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    augQ?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    sepPerm?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    sepQ?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    octPerm?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    octQ?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    novPerm?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    novQ?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    decPerm?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    decQ?: number | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    note?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    mainName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    mainCity?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    mainDescription?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    permDesc?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    sampleTypeCodes?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    purifMethodCodes?: Array<number> | null;
    /**
     * 
     * @type {Array<QualityTabRequestRecord>}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    qualityTabRecords?: Array<QualityTabRequestRecord> | null;
    /**
     * 
     * @type {Array<DangerSubstanceRequestRecord>}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    dangerSubstanceRecords?: Array<DangerSubstanceRequestRecord> | null;
    /**
     * 
     * @type {NewPlaceRequestRecord}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    newPlace?: NewPlaceRequestRecord | null;
    /**
     * 
     * @type {NewPlaceRequestRecord}
     * @memberof WaterEvidenceAppendixRequestRecord
     */
    newRecPlace?: NewPlaceRequestRecord | null;
}

/**
 * Check if a given object implements the WaterEvidenceAppendixRequestRecord interface.
 */
export function instanceOfWaterEvidenceAppendixRequestRecord(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WaterEvidenceAppendixRequestRecordFromJSON(json: any): WaterEvidenceAppendixRequestRecord {
    return WaterEvidenceAppendixRequestRecordFromJSONTyped(json, false);
}

export function WaterEvidenceAppendixRequestRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): WaterEvidenceAppendixRequestRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'documentTypeCode': !exists(json, 'documentTypeCode') ? undefined : json['documentTypeCode'],
        'dateFrom': !exists(json, 'dateFrom') ? undefined : json['dateFrom'],
        'dateTo': !exists(json, 'dateTo') ? undefined : json['dateTo'],
        'monitoringPointId': !exists(json, 'monitoringPointId') ? undefined : json['monitoringPointId'],
        'placeDesc': !exists(json, 'placeDesc') ? undefined : json['placeDesc'],
        'placeName': !exists(json, 'placeName') ? undefined : json['placeName'],
        'streamCode': !exists(json, 'streamCode') ? undefined : json['streamCode'],
        'streamId': !exists(json, 'streamId') ? undefined : json['streamId'],
        'recStreamCode': !exists(json, 'recStreamCode') ? undefined : json['recStreamCode'],
        'recStreamId': !exists(json, 'recStreamId') ? undefined : json['recStreamId'],
        'riverKm': !exists(json, 'riverKm') ? undefined : json['riverKm'],
        'recRiverKm': !exists(json, 'recRiverKm') ? undefined : json['recRiverKm'],
        'positionCode': !exists(json, 'positionCode') ? undefined : json['positionCode'],
        'waterUseCode': !exists(json, 'waterUseCode') ? undefined : json['waterUseCode'],
        'waterOriginCode': !exists(json, 'waterOriginCode') ? undefined : json['waterOriginCode'],
        'waterStructCode': !exists(json, 'waterStructCode') ? undefined : json['waterStructCode'],
        'objectTypeCode': !exists(json, 'objectTypeCode') ? undefined : json['objectTypeCode'],
        'objectName': !exists(json, 'objectName') ? undefined : json['objectName'],
        'objectAddress': !exists(json, 'objectAddress') ? undefined : json['objectAddress'],
        'objectCity': !exists(json, 'objectCity') ? undefined : json['objectCity'],
        'objectZip': !exists(json, 'objectZip') ? undefined : json['objectZip'],
        'qtyMinBalFlow': !exists(json, 'qtyMinBalFlow') ? undefined : json['qtyMinBalFlow'],
        'qtyMinLevel': !exists(json, 'qtyMinLevel') ? undefined : json['qtyMinLevel'],
        'qtyMaxLevel': !exists(json, 'qtyMaxLevel') ? undefined : json['qtyMaxLevel'],
        'qtyAvFlow': !exists(json, 'qtyAvFlow') ? undefined : json['qtyAvFlow'],
        'qtyMaxFlow': !exists(json, 'qtyMaxFlow') ? undefined : json['qtyMaxFlow'],
        'qtyYearFlow': !exists(json, 'qtyYearFlow') ? undefined : json['qtyYearFlow'],
        'qtyMeasUnitCode': !exists(json, 'qtyMeasUnitCode') ? undefined : json['qtyMeasUnitCode'],
        'qtyYear': !exists(json, 'qtyYear') ? undefined : json['qtyYear'],
        'qtyMonth': !exists(json, 'qtyMonth') ? undefined : json['qtyMonth'],
        'qtyDay': !exists(json, 'qtyDay') ? undefined : json['qtyDay'],
        'qtyMaxFall': !exists(json, 'qtyMaxFall') ? undefined : json['qtyMaxFall'],
        'qtyVolume': !exists(json, 'qtyVolume') ? undefined : json['qtyVolume'],
        'numMonths': !exists(json, 'numMonths') ? undefined : json['numMonths'],
        'numDays': !exists(json, 'numDays') ? undefined : json['numDays'],
        'numHours': !exists(json, 'numHours') ? undefined : json['numHours'],
        'qtyQuantAcc': !exists(json, 'qtyQuantAcc') ? undefined : json['qtyQuantAcc'],
        'measMethodCode': !exists(json, 'measMethodCode') ? undefined : json['measMethodCode'],
        'measDevice': !exists(json, 'measDevice') ? undefined : json['measDevice'],
        'measFreqYr': !exists(json, 'measFreqYr') ? undefined : json['measFreqYr'],
        'measFreqQt': !exists(json, 'measFreqQt') ? undefined : json['measFreqQt'],
        'measFreqQl': !exists(json, 'measFreqQl') ? undefined : json['measFreqQl'],
        'quantWater': !exists(json, 'quantWater') ? undefined : json['quantWater'],
        'waterCatCode': !exists(json, 'waterCatCode') ? undefined : json['waterCatCode'],
        'samplePlace': !exists(json, 'samplePlace') ? undefined : json['samplePlace'],
        'waterTypeCode': !exists(json, 'waterTypeCode') ? undefined : json['waterTypeCode'],
        'tempDecSt': !exists(json, 'tempDecSt') ? undefined : json['tempDecSt'],
        'tempDecWt': !exists(json, 'tempDecWt') ? undefined : json['tempDecWt'],
        'maxTempDec': !exists(json, 'maxTempDec') ? undefined : json['maxTempDec'],
        'recMonitoringPointId': !exists(json, 'recMonitoringPointId') ? undefined : json['recMonitoringPointId'],
        'recTypeCode': !exists(json, 'recTypeCode') ? undefined : json['recTypeCode'],
        'sourceSize': !exists(json, 'sourceSize') ? undefined : json['sourceSize'],
        'protArea': !exists(json, 'protArea') ? undefined : json['protArea'],
        'sludgeHandCode': !exists(json, 'sludgeHandCode') ? undefined : json['sludgeHandCode'],
        'sludgeStabCode': !exists(json, 'sludgeStabCode') ? undefined : json['sludgeStabCode'],
        'sludgeDrainCode': !exists(json, 'sludgeDrainCode') ? undefined : json['sludgeDrainCode'],
        'janPerm': !exists(json, 'janPerm') ? undefined : json['janPerm'],
        'janQ': !exists(json, 'janQ') ? undefined : json['janQ'],
        'febPerm': !exists(json, 'febPerm') ? undefined : json['febPerm'],
        'febQ': !exists(json, 'febQ') ? undefined : json['febQ'],
        'marPerm': !exists(json, 'marPerm') ? undefined : json['marPerm'],
        'marQ': !exists(json, 'marQ') ? undefined : json['marQ'],
        'aprPerm': !exists(json, 'aprPerm') ? undefined : json['aprPerm'],
        'aprQ': !exists(json, 'aprQ') ? undefined : json['aprQ'],
        'mayPerm': !exists(json, 'mayPerm') ? undefined : json['mayPerm'],
        'mayQ': !exists(json, 'mayQ') ? undefined : json['mayQ'],
        'junPerm': !exists(json, 'junPerm') ? undefined : json['junPerm'],
        'junQ': !exists(json, 'junQ') ? undefined : json['junQ'],
        'julPerm': !exists(json, 'julPerm') ? undefined : json['julPerm'],
        'julQ': !exists(json, 'julQ') ? undefined : json['julQ'],
        'augPerm': !exists(json, 'augPerm') ? undefined : json['augPerm'],
        'augQ': !exists(json, 'augQ') ? undefined : json['augQ'],
        'sepPerm': !exists(json, 'sepPerm') ? undefined : json['sepPerm'],
        'sepQ': !exists(json, 'sepQ') ? undefined : json['sepQ'],
        'octPerm': !exists(json, 'octPerm') ? undefined : json['octPerm'],
        'octQ': !exists(json, 'octQ') ? undefined : json['octQ'],
        'novPerm': !exists(json, 'novPerm') ? undefined : json['novPerm'],
        'novQ': !exists(json, 'novQ') ? undefined : json['novQ'],
        'decPerm': !exists(json, 'decPerm') ? undefined : json['decPerm'],
        'decQ': !exists(json, 'decQ') ? undefined : json['decQ'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'mainName': !exists(json, 'mainName') ? undefined : json['mainName'],
        'mainCity': !exists(json, 'mainCity') ? undefined : json['mainCity'],
        'mainDescription': !exists(json, 'mainDescription') ? undefined : json['mainDescription'],
        'permDesc': !exists(json, 'permDesc') ? undefined : json['permDesc'],
        'sampleTypeCodes': !exists(json, 'sampleTypeCodes') ? undefined : json['sampleTypeCodes'],
        'purifMethodCodes': !exists(json, 'purifMethodCodes') ? undefined : json['purifMethodCodes'],
        'qualityTabRecords': !exists(json, 'qualityTabRecords') ? undefined : (json['qualityTabRecords'] === null ? null : (json['qualityTabRecords'] as Array<any>).map(QualityTabRequestRecordFromJSON)),
        'dangerSubstanceRecords': !exists(json, 'dangerSubstanceRecords') ? undefined : (json['dangerSubstanceRecords'] === null ? null : (json['dangerSubstanceRecords'] as Array<any>).map(DangerSubstanceRequestRecordFromJSON)),
        'newPlace': !exists(json, 'newPlace') ? undefined : NewPlaceRequestRecordFromJSON(json['newPlace']),
        'newRecPlace': !exists(json, 'newRecPlace') ? undefined : NewPlaceRequestRecordFromJSON(json['newRecPlace']),
    };
}

export function WaterEvidenceAppendixRequestRecordToJSON(value?: WaterEvidenceAppendixRequestRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'documentTypeCode': value.documentTypeCode,
        'dateFrom': value.dateFrom,
        'dateTo': value.dateTo,
        'monitoringPointId': value.monitoringPointId,
        'placeDesc': value.placeDesc,
        'placeName': value.placeName,
        'streamCode': value.streamCode,
        'streamId': value.streamId,
        'recStreamCode': value.recStreamCode,
        'recStreamId': value.recStreamId,
        'riverKm': value.riverKm,
        'recRiverKm': value.recRiverKm,
        'positionCode': value.positionCode,
        'waterUseCode': value.waterUseCode,
        'waterOriginCode': value.waterOriginCode,
        'waterStructCode': value.waterStructCode,
        'objectTypeCode': value.objectTypeCode,
        'objectName': value.objectName,
        'objectAddress': value.objectAddress,
        'objectCity': value.objectCity,
        'objectZip': value.objectZip,
        'qtyMinBalFlow': value.qtyMinBalFlow,
        'qtyMinLevel': value.qtyMinLevel,
        'qtyMaxLevel': value.qtyMaxLevel,
        'qtyAvFlow': value.qtyAvFlow,
        'qtyMaxFlow': value.qtyMaxFlow,
        'qtyYearFlow': value.qtyYearFlow,
        'qtyMeasUnitCode': value.qtyMeasUnitCode,
        'qtyYear': value.qtyYear,
        'qtyMonth': value.qtyMonth,
        'qtyDay': value.qtyDay,
        'qtyMaxFall': value.qtyMaxFall,
        'qtyVolume': value.qtyVolume,
        'numMonths': value.numMonths,
        'numDays': value.numDays,
        'numHours': value.numHours,
        'qtyQuantAcc': value.qtyQuantAcc,
        'measMethodCode': value.measMethodCode,
        'measDevice': value.measDevice,
        'measFreqYr': value.measFreqYr,
        'measFreqQt': value.measFreqQt,
        'measFreqQl': value.measFreqQl,
        'quantWater': value.quantWater,
        'waterCatCode': value.waterCatCode,
        'samplePlace': value.samplePlace,
        'waterTypeCode': value.waterTypeCode,
        'tempDecSt': value.tempDecSt,
        'tempDecWt': value.tempDecWt,
        'maxTempDec': value.maxTempDec,
        'recMonitoringPointId': value.recMonitoringPointId,
        'recTypeCode': value.recTypeCode,
        'sourceSize': value.sourceSize,
        'protArea': value.protArea,
        'sludgeHandCode': value.sludgeHandCode,
        'sludgeStabCode': value.sludgeStabCode,
        'sludgeDrainCode': value.sludgeDrainCode,
        'janPerm': value.janPerm,
        'janQ': value.janQ,
        'febPerm': value.febPerm,
        'febQ': value.febQ,
        'marPerm': value.marPerm,
        'marQ': value.marQ,
        'aprPerm': value.aprPerm,
        'aprQ': value.aprQ,
        'mayPerm': value.mayPerm,
        'mayQ': value.mayQ,
        'junPerm': value.junPerm,
        'junQ': value.junQ,
        'julPerm': value.julPerm,
        'julQ': value.julQ,
        'augPerm': value.augPerm,
        'augQ': value.augQ,
        'sepPerm': value.sepPerm,
        'sepQ': value.sepQ,
        'octPerm': value.octPerm,
        'octQ': value.octQ,
        'novPerm': value.novPerm,
        'novQ': value.novQ,
        'decPerm': value.decPerm,
        'decQ': value.decQ,
        'note': value.note,
        'mainName': value.mainName,
        'mainCity': value.mainCity,
        'mainDescription': value.mainDescription,
        'permDesc': value.permDesc,
        'sampleTypeCodes': value.sampleTypeCodes,
        'purifMethodCodes': value.purifMethodCodes,
        'qualityTabRecords': value.qualityTabRecords === undefined ? undefined : (value.qualityTabRecords === null ? null : (value.qualityTabRecords as Array<any>).map(QualityTabRequestRecordToJSON)),
        'dangerSubstanceRecords': value.dangerSubstanceRecords === undefined ? undefined : (value.dangerSubstanceRecords === null ? null : (value.dangerSubstanceRecords as Array<any>).map(DangerSubstanceRequestRecordToJSON)),
        'newPlace': NewPlaceRequestRecordToJSON(value.newPlace),
        'newRecPlace': NewPlaceRequestRecordToJSON(value.newRecPlace),
    };
}

