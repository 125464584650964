/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WaterEvidenceAttachmentBasicRecord } from './WaterEvidenceAttachmentBasicRecord';
import {
    WaterEvidenceAttachmentBasicRecordFromJSON,
    WaterEvidenceAttachmentBasicRecordFromJSONTyped,
    WaterEvidenceAttachmentBasicRecordToJSON,
} from './WaterEvidenceAttachmentBasicRecord';

/**
 * 
 * @export
 * @interface WaterEvidenceDocumentChangeRequestRecord
 */
export interface WaterEvidenceDocumentChangeRequestRecord {
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceDocumentChangeRequestRecord
     */
    number?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceDocumentChangeRequestRecord
     */
    note?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceDocumentChangeRequestRecord
     */
    dateFrom?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceDocumentChangeRequestRecord
     */
    documentTypeCode?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceDocumentChangeRequestRecord
     */
    districtCode?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceDocumentChangeRequestRecord
     */
    regionCode?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceDocumentChangeRequestRecord
     */
    sizpCode?: number | null;
    /**
     * 
     * @type {Array<WaterEvidenceAttachmentBasicRecord>}
     * @memberof WaterEvidenceDocumentChangeRequestRecord
     */
    appendixesToChange?: Array<WaterEvidenceAttachmentBasicRecord> | null;
}

/**
 * Check if a given object implements the WaterEvidenceDocumentChangeRequestRecord interface.
 */
export function instanceOfWaterEvidenceDocumentChangeRequestRecord(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WaterEvidenceDocumentChangeRequestRecordFromJSON(json: any): WaterEvidenceDocumentChangeRequestRecord {
    return WaterEvidenceDocumentChangeRequestRecordFromJSONTyped(json, false);
}

export function WaterEvidenceDocumentChangeRequestRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): WaterEvidenceDocumentChangeRequestRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'number': !exists(json, 'number') ? undefined : json['number'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'dateFrom': !exists(json, 'dateFrom') ? undefined : json['dateFrom'],
        'documentTypeCode': !exists(json, 'documentTypeCode') ? undefined : json['documentTypeCode'],
        'districtCode': !exists(json, 'districtCode') ? undefined : json['districtCode'],
        'regionCode': !exists(json, 'regionCode') ? undefined : json['regionCode'],
        'sizpCode': !exists(json, 'sizpCode') ? undefined : json['sizpCode'],
        'appendixesToChange': !exists(json, 'appendixesToChange') ? undefined : (json['appendixesToChange'] === null ? null : (json['appendixesToChange'] as Array<any>).map(WaterEvidenceAttachmentBasicRecordFromJSON)),
    };
}

export function WaterEvidenceDocumentChangeRequestRecordToJSON(value?: WaterEvidenceDocumentChangeRequestRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'number': value.number,
        'note': value.note,
        'dateFrom': value.dateFrom,
        'documentTypeCode': value.documentTypeCode,
        'districtCode': value.districtCode,
        'regionCode': value.regionCode,
        'sizpCode': value.sizpCode,
        'appendixesToChange': value.appendixesToChange === undefined ? undefined : (value.appendixesToChange === null ? null : (value.appendixesToChange as Array<any>).map(WaterEvidenceAttachmentBasicRecordToJSON)),
    };
}

