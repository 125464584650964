/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LevelRecord
 */
export interface LevelRecord {
    /**
     * 
     * @type {string}
     * @memberof LevelRecord
     */
    level1?: string;
    /**
     * 
     * @type {string}
     * @memberof LevelRecord
     */
    level2?: string;
    /**
     * 
     * @type {string}
     * @memberof LevelRecord
     */
    level3?: string;
    /**
     * 
     * @type {string}
     * @memberof LevelRecord
     */
    level4?: string;
    /**
     * 
     * @type {string}
     * @memberof LevelRecord
     */
    level5?: string;
    /**
     * 
     * @type {string}
     * @memberof LevelRecord
     */
    level6?: string;
    /**
     * 
     * @type {string}
     * @memberof LevelRecord
     */
    level7?: string;
    /**
     * 
     * @type {string}
     * @memberof LevelRecord
     */
    level8?: string;
    /**
     * 
     * @type {string}
     * @memberof LevelRecord
     */
    level9?: string;
    /**
     * 
     * @type {string}
     * @memberof LevelRecord
     */
    level10?: string;
    /**
     * 
     * @type {string}
     * @memberof LevelRecord
     */
    level11?: string;
    /**
     * 
     * @type {string}
     * @memberof LevelRecord
     */
    level12?: string;
    /**
     * 
     * @type {string}
     * @memberof LevelRecord
     */
    level13?: string;
    /**
     * 
     * @type {string}
     * @memberof LevelRecord
     */
    level14?: string;
    /**
     * 
     * @type {string}
     * @memberof LevelRecord
     */
    level15?: string;
}

/**
 * Check if a given object implements the LevelRecord interface.
 */
export function instanceOfLevelRecord(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function LevelRecordFromJSON(json: any): LevelRecord {
    return LevelRecordFromJSONTyped(json, false);
}

export function LevelRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): LevelRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'level1': !exists(json, 'level1') ? undefined : json['level1'],
        'level2': !exists(json, 'level2') ? undefined : json['level2'],
        'level3': !exists(json, 'level3') ? undefined : json['level3'],
        'level4': !exists(json, 'level4') ? undefined : json['level4'],
        'level5': !exists(json, 'level5') ? undefined : json['level5'],
        'level6': !exists(json, 'level6') ? undefined : json['level6'],
        'level7': !exists(json, 'level7') ? undefined : json['level7'],
        'level8': !exists(json, 'level8') ? undefined : json['level8'],
        'level9': !exists(json, 'level9') ? undefined : json['level9'],
        'level10': !exists(json, 'level10') ? undefined : json['level10'],
        'level11': !exists(json, 'level11') ? undefined : json['level11'],
        'level12': !exists(json, 'level12') ? undefined : json['level12'],
        'level13': !exists(json, 'level13') ? undefined : json['level13'],
        'level14': !exists(json, 'level14') ? undefined : json['level14'],
        'level15': !exists(json, 'level15') ? undefined : json['level15'],
    };
}

export function LevelRecordToJSON(value?: LevelRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'level1': value.level1,
        'level2': value.level2,
        'level3': value.level3,
        'level4': value.level4,
        'level5': value.level5,
        'level6': value.level6,
        'level7': value.level7,
        'level8': value.level8,
        'level9': value.level9,
        'level10': value.level10,
        'level11': value.level11,
        'level12': value.level12,
        'level13': value.level13,
        'level14': value.level14,
        'level15': value.level15,
    };
}

