/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SubmissionContentRecord
 */
export interface SubmissionContentRecord {
    /**
     * 
     * @type {number}
     * @memberof SubmissionContentRecord
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof SubmissionContentRecord
     */
    submissionId?: number;
    /**
     * 
     * @type {string}
     * @memberof SubmissionContentRecord
     */
    type?: SubmissionContentRecordTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SubmissionContentRecord
     */
    submissionType?: string;
    /**
     * 
     * @type {number}
     * @memberof SubmissionContentRecord
     */
    version?: number;
    /**
     * 
     * @type {string}
     * @memberof SubmissionContentRecord
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof SubmissionContentRecord
     */
    createdTimestamp?: string;
    /**
     * 
     * @type {string}
     * @memberof SubmissionContentRecord
     */
    updatedTimestamp?: string;
    /**
     * 
     * @type {string}
     * @memberof SubmissionContentRecord
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof SubmissionContentRecord
     */
    personType?: string;
    /**
     * 
     * @type {string}
     * @memberof SubmissionContentRecord
     */
    place?: string;
    /**
     * 
     * @type {string}
     * @memberof SubmissionContentRecord
     */
    installation?: string;
}


/**
 * @export
 */
export const SubmissionContentRecordTypeEnum = {
    ClientSubmission: 'CLIENT_SUBMISSION',
    ReviewerSubmission: 'REVIEWER_SUBMISSION'
} as const;
export type SubmissionContentRecordTypeEnum = typeof SubmissionContentRecordTypeEnum[keyof typeof SubmissionContentRecordTypeEnum];


/**
 * Check if a given object implements the SubmissionContentRecord interface.
 */
export function instanceOfSubmissionContentRecord(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SubmissionContentRecordFromJSON(json: any): SubmissionContentRecord {
    return SubmissionContentRecordFromJSONTyped(json, false);
}

export function SubmissionContentRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubmissionContentRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'submissionId': !exists(json, 'submissionId') ? undefined : json['submissionId'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'submissionType': !exists(json, 'submissionType') ? undefined : json['submissionType'],
        'version': !exists(json, 'version') ? undefined : json['version'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'createdTimestamp': !exists(json, 'createdTimestamp') ? undefined : json['createdTimestamp'],
        'updatedTimestamp': !exists(json, 'updatedTimestamp') ? undefined : json['updatedTimestamp'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'personType': !exists(json, 'personType') ? undefined : json['personType'],
        'place': !exists(json, 'place') ? undefined : json['place'],
        'installation': !exists(json, 'installation') ? undefined : json['installation'],
    };
}

export function SubmissionContentRecordToJSON(value?: SubmissionContentRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'submissionId': value.submissionId,
        'type': value.type,
        'submissionType': value.submissionType,
        'version': value.version,
        'createdBy': value.createdBy,
        'createdTimestamp': value.createdTimestamp,
        'updatedTimestamp': value.updatedTimestamp,
        'name': value.name,
        'personType': value.personType,
        'place': value.place,
        'installation': value.installation,
    };
}

