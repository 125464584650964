/* tslint:disable */
/* eslint-disable */
/**
 * Catalog API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.31.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  EndpointExceptionBody,
  MonitoringPointCriteria,
  PageUserGroupResult,
  PageUserGroupSimpleResult,
  UserGroupCriteria,
  UserGroupInputSo,
  UserGroupResult,
  UserGroupUpdateSo,
} from '../models/index';
import {
    EndpointExceptionBodyFromJSON,
    EndpointExceptionBodyToJSON,
    MonitoringPointCriteriaFromJSON,
    MonitoringPointCriteriaToJSON,
    PageUserGroupResultFromJSON,
    PageUserGroupResultToJSON,
    PageUserGroupSimpleResultFromJSON,
    PageUserGroupSimpleResultToJSON,
    UserGroupCriteriaFromJSON,
    UserGroupCriteriaToJSON,
    UserGroupInputSoFromJSON,
    UserGroupInputSoToJSON,
    UserGroupResultFromJSON,
    UserGroupResultToJSON,
    UserGroupUpdateSoFromJSON,
    UserGroupUpdateSoToJSON,
} from '../models/index';

export interface UserGroupApiCreateUserGroupRequest {
    userGroupInputSo: UserGroupInputSo;
}

export interface UserGroupApiDeleteUserGroupByIdRequest {
    id: number;
}

export interface UserGroupApiFindSimpleUserGroupByCriteriaRequest {
    userGroupCriteria: UserGroupCriteria;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface UserGroupApiFindUserGroupByCriteriaRequest {
    userGroupCriteria: UserGroupCriteria;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface UserGroupApiGetCountOfMonitoringPointInUserGroupByUserGroupIdRequest {
    userGroupId: number;
}

export interface UserGroupApiGetUserGroupByIdRequest {
    id: number;
}

export interface UserGroupApiSaveMonitoringPointInUserGroupRequest {
    userGroupId: number;
    monitoringPointCriteria: MonitoringPointCriteria;
}

export interface UserGroupApiUpdateUserGroupByIdRequest {
    id: number;
    userGroupUpdateSo: UserGroupUpdateSo;
}

/**
 * 
 */
export class UserGroupApi extends runtime.BaseAPI {

    /**
     */
    async createUserGroupRaw(requestParameters: UserGroupApiCreateUserGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserGroupResult>> {
        if (requestParameters.userGroupInputSo === null || requestParameters.userGroupInputSo === undefined) {
            throw new runtime.RequiredError('userGroupInputSo','Required parameter requestParameters.userGroupInputSo was null or undefined when calling createUserGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user-group/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserGroupInputSoToJSON(requestParameters.userGroupInputSo),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserGroupResultFromJSON(jsonValue));
    }

    /**
     */
    async createUserGroup(userGroupInputSo: UserGroupInputSo, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserGroupResult> {
        const response = await this.createUserGroupRaw({ userGroupInputSo: userGroupInputSo }, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteUserGroupByIdRaw(requestParameters: UserGroupApiDeleteUserGroupByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteUserGroupById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user-group/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteUserGroupById(id: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteUserGroupByIdRaw({ id: id }, initOverrides);
    }

    /**
     */
    async findSimpleUserGroupByCriteriaRaw(requestParameters: UserGroupApiFindSimpleUserGroupByCriteriaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageUserGroupSimpleResult>> {
        if (requestParameters.userGroupCriteria === null || requestParameters.userGroupCriteria === undefined) {
            throw new runtime.RequiredError('userGroupCriteria','Required parameter requestParameters.userGroupCriteria was null or undefined when calling findSimpleUserGroupByCriteria.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user-group/simple-by-criteria`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserGroupCriteriaToJSON(requestParameters.userGroupCriteria),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageUserGroupSimpleResultFromJSON(jsonValue));
    }

    /**
     */
    async findSimpleUserGroupByCriteria(userGroupCriteria: UserGroupCriteria, page?: number, size?: number, sort?: Array<string>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageUserGroupSimpleResult> {
        const response = await this.findSimpleUserGroupByCriteriaRaw({ userGroupCriteria: userGroupCriteria, page: page, size: size, sort: sort }, initOverrides);
        return await response.value();
    }

    /**
     */
    async findUserGroupByCriteriaRaw(requestParameters: UserGroupApiFindUserGroupByCriteriaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageUserGroupResult>> {
        if (requestParameters.userGroupCriteria === null || requestParameters.userGroupCriteria === undefined) {
            throw new runtime.RequiredError('userGroupCriteria','Required parameter requestParameters.userGroupCriteria was null or undefined when calling findUserGroupByCriteria.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user-group/by-criteria`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserGroupCriteriaToJSON(requestParameters.userGroupCriteria),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageUserGroupResultFromJSON(jsonValue));
    }

    /**
     */
    async findUserGroupByCriteria(userGroupCriteria: UserGroupCriteria, page?: number, size?: number, sort?: Array<string>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageUserGroupResult> {
        const response = await this.findUserGroupByCriteriaRaw({ userGroupCriteria: userGroupCriteria, page: page, size: size, sort: sort }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getCountOfMonitoringPointInUserGroupByUserGroupIdRaw(requestParameters: UserGroupApiGetCountOfMonitoringPointInUserGroupByUserGroupIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.userGroupId === null || requestParameters.userGroupId === undefined) {
            throw new runtime.RequiredError('userGroupId','Required parameter requestParameters.userGroupId was null or undefined when calling getCountOfMonitoringPointInUserGroupByUserGroupId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user-group/count/{userGroupId}`.replace(`{${"userGroupId"}}`, encodeURIComponent(String(requestParameters.userGroupId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async getCountOfMonitoringPointInUserGroupByUserGroupId(userGroupId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.getCountOfMonitoringPointInUserGroupByUserGroupIdRaw({ userGroupId: userGroupId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getUserGroupByIdRaw(requestParameters: UserGroupApiGetUserGroupByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserGroupResult>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getUserGroupById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user-group/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserGroupResultFromJSON(jsonValue));
    }

    /**
     */
    async getUserGroupById(id: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserGroupResult> {
        const response = await this.getUserGroupByIdRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async saveMonitoringPointInUserGroupRaw(requestParameters: UserGroupApiSaveMonitoringPointInUserGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userGroupId === null || requestParameters.userGroupId === undefined) {
            throw new runtime.RequiredError('userGroupId','Required parameter requestParameters.userGroupId was null or undefined when calling saveMonitoringPointInUserGroup.');
        }

        if (requestParameters.monitoringPointCriteria === null || requestParameters.monitoringPointCriteria === undefined) {
            throw new runtime.RequiredError('monitoringPointCriteria','Required parameter requestParameters.monitoringPointCriteria was null or undefined when calling saveMonitoringPointInUserGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user-group/save-mon-point-in-user-group/{userGroupId}`.replace(`{${"userGroupId"}}`, encodeURIComponent(String(requestParameters.userGroupId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MonitoringPointCriteriaToJSON(requestParameters.monitoringPointCriteria),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async saveMonitoringPointInUserGroup(userGroupId: number, monitoringPointCriteria: MonitoringPointCriteria, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.saveMonitoringPointInUserGroupRaw({ userGroupId: userGroupId, monitoringPointCriteria: monitoringPointCriteria }, initOverrides);
    }

    /**
     */
    async updateUserGroupByIdRaw(requestParameters: UserGroupApiUpdateUserGroupByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserGroupResult>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateUserGroupById.');
        }

        if (requestParameters.userGroupUpdateSo === null || requestParameters.userGroupUpdateSo === undefined) {
            throw new runtime.RequiredError('userGroupUpdateSo','Required parameter requestParameters.userGroupUpdateSo was null or undefined when calling updateUserGroupById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user-group/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserGroupUpdateSoToJSON(requestParameters.userGroupUpdateSo),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserGroupResultFromJSON(jsonValue));
    }

    /**
     */
    async updateUserGroupById(id: number, userGroupUpdateSo: UserGroupUpdateSo, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserGroupResult> {
        const response = await this.updateUserGroupByIdRaw({ id: id, userGroupUpdateSo: userGroupUpdateSo }, initOverrides);
        return await response.value();
    }

}
