/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WaterStructChannelRecord } from './WaterStructChannelRecord';
import {
    WaterStructChannelRecordFromJSON,
    WaterStructChannelRecordFromJSONTyped,
    WaterStructChannelRecordToJSON,
} from './WaterStructChannelRecord';
import type { WaterStructConduitRequestRecord } from './WaterStructConduitRequestRecord';
import {
    WaterStructConduitRequestRecordFromJSON,
    WaterStructConduitRequestRecordFromJSONTyped,
    WaterStructConduitRequestRecordToJSON,
} from './WaterStructConduitRequestRecord';
import type { WaterStructCovRequestRecord } from './WaterStructCovRequestRecord';
import {
    WaterStructCovRequestRecordFromJSON,
    WaterStructCovRequestRecordFromJSONTyped,
    WaterStructCovRequestRecordToJSON,
} from './WaterStructCovRequestRecord';
import type { WaterStructDamRequestRecord } from './WaterStructDamRequestRecord';
import {
    WaterStructDamRequestRecordFromJSON,
    WaterStructDamRequestRecordFromJSONTyped,
    WaterStructDamRequestRecordToJSON,
} from './WaterStructDamRequestRecord';
import type { WaterStructDuctRequestRecord } from './WaterStructDuctRequestRecord';
import {
    WaterStructDuctRequestRecordFromJSON,
    WaterStructDuctRequestRecordFromJSONTyped,
    WaterStructDuctRequestRecordToJSON,
} from './WaterStructDuctRequestRecord';
import type { WaterStructEmbankRequestRecord } from './WaterStructEmbankRequestRecord';
import {
    WaterStructEmbankRequestRecordFromJSON,
    WaterStructEmbankRequestRecordFromJSONTyped,
    WaterStructEmbankRequestRecordToJSON,
} from './WaterStructEmbankRequestRecord';
import type { WaterStructLockRecord } from './WaterStructLockRecord';
import {
    WaterStructLockRecordFromJSON,
    WaterStructLockRecordFromJSONTyped,
    WaterStructLockRecordToJSON,
} from './WaterStructLockRecord';
import type { WaterStructObjectRequestRecord } from './WaterStructObjectRequestRecord';
import {
    WaterStructObjectRequestRecordFromJSON,
    WaterStructObjectRequestRecordFromJSONTyped,
    WaterStructObjectRequestRecordToJSON,
} from './WaterStructObjectRequestRecord';
import type { WaterStructPortRecord } from './WaterStructPortRecord';
import {
    WaterStructPortRecordFromJSON,
    WaterStructPortRecordFromJSONTyped,
    WaterStructPortRecordToJSON,
} from './WaterStructPortRecord';
import type { WaterStructPowerPlantRequestRecord } from './WaterStructPowerPlantRequestRecord';
import {
    WaterStructPowerPlantRequestRecordFromJSON,
    WaterStructPowerPlantRequestRecordFromJSONTyped,
    WaterStructPowerPlantRequestRecordToJSON,
} from './WaterStructPowerPlantRequestRecord';
import type { WaterStructPushRequestRecord } from './WaterStructPushRequestRecord';
import {
    WaterStructPushRequestRecordFromJSON,
    WaterStructPushRequestRecordFromJSONTyped,
    WaterStructPushRequestRecordToJSON,
} from './WaterStructPushRequestRecord';
import type { WaterStructReservoirRequestRecord } from './WaterStructReservoirRequestRecord';
import {
    WaterStructReservoirRequestRecordFromJSON,
    WaterStructReservoirRequestRecordFromJSONTyped,
    WaterStructReservoirRequestRecordToJSON,
} from './WaterStructReservoirRequestRecord';
import type { WaterStructSewerRequestRecord } from './WaterStructSewerRequestRecord';
import {
    WaterStructSewerRequestRecordFromJSON,
    WaterStructSewerRequestRecordFromJSONTyped,
    WaterStructSewerRequestRecordToJSON,
} from './WaterStructSewerRequestRecord';
import type { WaterStructTankRequestRecord } from './WaterStructTankRequestRecord';
import {
    WaterStructTankRequestRecordFromJSON,
    WaterStructTankRequestRecordFromJSONTyped,
    WaterStructTankRequestRecordToJSON,
} from './WaterStructTankRequestRecord';
import type { WaterStructTempReductRecord } from './WaterStructTempReductRecord';
import {
    WaterStructTempReductRecordFromJSON,
    WaterStructTempReductRecordFromJSONTyped,
    WaterStructTempReductRecordToJSON,
} from './WaterStructTempReductRecord';
import type { WaterStructTreatmentRequestRecord } from './WaterStructTreatmentRequestRecord';
import {
    WaterStructTreatmentRequestRecordFromJSON,
    WaterStructTreatmentRequestRecordFromJSONTyped,
    WaterStructTreatmentRequestRecordToJSON,
} from './WaterStructTreatmentRequestRecord';
import type { WaterStructWatertankRequestRecord } from './WaterStructWatertankRequestRecord';
import {
    WaterStructWatertankRequestRecordFromJSON,
    WaterStructWatertankRequestRecordFromJSONTyped,
    WaterStructWatertankRequestRecordToJSON,
} from './WaterStructWatertankRequestRecord';
import type { WaterStructZdrzRecord } from './WaterStructZdrzRecord';
import {
    WaterStructZdrzRecordFromJSON,
    WaterStructZdrzRecordFromJSONTyped,
    WaterStructZdrzRecordToJSON,
} from './WaterStructZdrzRecord';

/**
 * 
 * @export
 * @interface WaterStructAppendixRequestRecord
 */
export interface WaterStructAppendixRequestRecord {
    /**
     * 
     * @type {number}
     * @memberof WaterStructAppendixRequestRecord
     */
    id?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructAppendixRequestRecord
     */
    docTypeCode?: number | null;
    /**
     * 
     * @type {string}
     * @memberof WaterStructAppendixRequestRecord
     */
    dateFrom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterStructAppendixRequestRecord
     */
    dateTo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterStructAppendixRequestRecord
     */
    structName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterStructAppendixRequestRecord
     */
    hydrOrder?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructAppendixRequestRecord
     */
    tbdCatCode?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof WaterStructAppendixRequestRecord
     */
    manipOrder?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructAppendixRequestRecord
     */
    waterStructCode?: number | null;
    /**
     * 
     * @type {string}
     * @memberof WaterStructAppendixRequestRecord
     */
    note?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructAppendixRequestRecord
     */
    structUseCode?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructAppendixRequestRecord
     */
    waterOriginCode?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructAppendixRequestRecord
     */
    basinArea?: number | null;
    /**
     * 
     * @type {string}
     * @memberof WaterStructAppendixRequestRecord
     */
    streamCode?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructAppendixRequestRecord
     */
    streamId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructAppendixRequestRecord
     */
    riverKmBegin?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructAppendixRequestRecord
     */
    riverKmEnd?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructAppendixRequestRecord
     */
    posBeginXCoord?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructAppendixRequestRecord
     */
    posBeginYCoord?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructAppendixRequestRecord
     */
    posEndXCoord?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructAppendixRequestRecord
     */
    posEndYCoord?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructAppendixRequestRecord
     */
    positionCode?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructAppendixRequestRecord
     */
    streamLength?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructAppendixRequestRecord
     */
    maxFlow?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof WaterStructAppendixRequestRecord
     */
    isCov?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructAppendixRequestRecord
     */
    embankLen?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructAppendixRequestRecord
     */
    embankHght?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructAppendixRequestRecord
     */
    irrigTypeCode?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructAppendixRequestRecord
     */
    irrigArea?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructAppendixRequestRecord
     */
    landCultureCode?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructAppendixRequestRecord
     */
    conduitLen?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructAppendixRequestRecord
     */
    ductLen?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructAppendixRequestRecord
     */
    accumVolume?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructAppendixRequestRecord
     */
    waterDrainCode?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructAppendixRequestRecord
     */
    dischargeTypeCode?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructAppendixRequestRecord
     */
    drainArea?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructAppendixRequestRecord
     */
    channelLen?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructAppendixRequestRecord
     */
    sectionLen?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructAppendixRequestRecord
     */
    shipDeviceCode?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructAppendixRequestRecord
     */
    waterBleedingCode?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructAppendixRequestRecord
     */
    sludgeSedCode?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructAppendixRequestRecord
     */
    sludgeBedTypeCode?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof WaterStructAppendixRequestRecord
     */
    isTakingOffMonit?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof WaterStructAppendixRequestRecord
     */
    isLeakMonit?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructAppendixRequestRecord
     */
    objManagerCode?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructAppendixRequestRecord
     */
    measDevTypeCode?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructAppendixRequestRecord
     */
    wellCode?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructAppendixRequestRecord
     */
    wellDepth?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructAppendixRequestRecord
     */
    rapidLen?: number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof WaterStructAppendixRequestRecord
     */
    sampleTypeCodes?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof WaterStructAppendixRequestRecord
     */
    purifMethodCodes?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof WaterStructAppendixRequestRecord
     */
    measParamCodes?: Array<number> | null;
    /**
     * 
     * @type {WaterStructEmbankRequestRecord}
     * @memberof WaterStructAppendixRequestRecord
     */
    embank?: WaterStructEmbankRequestRecord | null;
    /**
     * 
     * @type {WaterStructReservoirRequestRecord}
     * @memberof WaterStructAppendixRequestRecord
     */
    reservoir?: WaterStructReservoirRequestRecord | null;
    /**
     * 
     * @type {WaterStructDamRequestRecord}
     * @memberof WaterStructAppendixRequestRecord
     */
    dam?: WaterStructDamRequestRecord | null;
    /**
     * 
     * @type {WaterStructZdrzRecord}
     * @memberof WaterStructAppendixRequestRecord
     */
    zdrz?: WaterStructZdrzRecord | null;
    /**
     * 
     * @type {WaterStructDuctRequestRecord}
     * @memberof WaterStructAppendixRequestRecord
     */
    duct?: WaterStructDuctRequestRecord | null;
    /**
     * 
     * @type {WaterStructObjectRequestRecord}
     * @memberof WaterStructAppendixRequestRecord
     */
    object?: WaterStructObjectRequestRecord | null;
    /**
     * 
     * @type {WaterStructTreatmentRequestRecord}
     * @memberof WaterStructAppendixRequestRecord
     */
    treatment?: WaterStructTreatmentRequestRecord | null;
    /**
     * 
     * @type {WaterStructWatertankRequestRecord}
     * @memberof WaterStructAppendixRequestRecord
     */
    watertank?: WaterStructWatertankRequestRecord | null;
    /**
     * 
     * @type {WaterStructPushRequestRecord}
     * @memberof WaterStructAppendixRequestRecord
     */
    push?: WaterStructPushRequestRecord | null;
    /**
     * 
     * @type {WaterStructSewerRequestRecord}
     * @memberof WaterStructAppendixRequestRecord
     */
    sewer?: WaterStructSewerRequestRecord | null;
    /**
     * 
     * @type {WaterStructTempReductRecord}
     * @memberof WaterStructAppendixRequestRecord
     */
    tempReduct?: WaterStructTempReductRecord | null;
    /**
     * 
     * @type {WaterStructTankRequestRecord}
     * @memberof WaterStructAppendixRequestRecord
     */
    tank?: WaterStructTankRequestRecord | null;
    /**
     * 
     * @type {WaterStructCovRequestRecord}
     * @memberof WaterStructAppendixRequestRecord
     */
    cov?: WaterStructCovRequestRecord | null;
    /**
     * 
     * @type {WaterStructLockRecord}
     * @memberof WaterStructAppendixRequestRecord
     */
    lock?: WaterStructLockRecord | null;
    /**
     * 
     * @type {WaterStructChannelRecord}
     * @memberof WaterStructAppendixRequestRecord
     */
    channel?: WaterStructChannelRecord | null;
    /**
     * 
     * @type {WaterStructPowerPlantRequestRecord}
     * @memberof WaterStructAppendixRequestRecord
     */
    powerPlant?: WaterStructPowerPlantRequestRecord | null;
    /**
     * 
     * @type {WaterStructConduitRequestRecord}
     * @memberof WaterStructAppendixRequestRecord
     */
    conduit?: WaterStructConduitRequestRecord | null;
    /**
     * 
     * @type {WaterStructPortRecord}
     * @memberof WaterStructAppendixRequestRecord
     */
    port?: WaterStructPortRecord | null;
}

/**
 * Check if a given object implements the WaterStructAppendixRequestRecord interface.
 */
export function instanceOfWaterStructAppendixRequestRecord(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WaterStructAppendixRequestRecordFromJSON(json: any): WaterStructAppendixRequestRecord {
    return WaterStructAppendixRequestRecordFromJSONTyped(json, false);
}

export function WaterStructAppendixRequestRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): WaterStructAppendixRequestRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'docTypeCode': !exists(json, 'docTypeCode') ? undefined : json['docTypeCode'],
        'dateFrom': !exists(json, 'dateFrom') ? undefined : json['dateFrom'],
        'dateTo': !exists(json, 'dateTo') ? undefined : json['dateTo'],
        'structName': !exists(json, 'structName') ? undefined : json['structName'],
        'hydrOrder': !exists(json, 'hydrOrder') ? undefined : json['hydrOrder'],
        'tbdCatCode': !exists(json, 'tbdCatCode') ? undefined : json['tbdCatCode'],
        'manipOrder': !exists(json, 'manipOrder') ? undefined : json['manipOrder'],
        'waterStructCode': !exists(json, 'waterStructCode') ? undefined : json['waterStructCode'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'structUseCode': !exists(json, 'structUseCode') ? undefined : json['structUseCode'],
        'waterOriginCode': !exists(json, 'waterOriginCode') ? undefined : json['waterOriginCode'],
        'basinArea': !exists(json, 'basinArea') ? undefined : json['basinArea'],
        'streamCode': !exists(json, 'streamCode') ? undefined : json['streamCode'],
        'streamId': !exists(json, 'streamId') ? undefined : json['streamId'],
        'riverKmBegin': !exists(json, 'riverKmBegin') ? undefined : json['riverKmBegin'],
        'riverKmEnd': !exists(json, 'riverKmEnd') ? undefined : json['riverKmEnd'],
        'posBeginXCoord': !exists(json, 'posBeginXCoord') ? undefined : json['posBeginXCoord'],
        'posBeginYCoord': !exists(json, 'posBeginYCoord') ? undefined : json['posBeginYCoord'],
        'posEndXCoord': !exists(json, 'posEndXCoord') ? undefined : json['posEndXCoord'],
        'posEndYCoord': !exists(json, 'posEndYCoord') ? undefined : json['posEndYCoord'],
        'positionCode': !exists(json, 'positionCode') ? undefined : json['positionCode'],
        'streamLength': !exists(json, 'streamLength') ? undefined : json['streamLength'],
        'maxFlow': !exists(json, 'maxFlow') ? undefined : json['maxFlow'],
        'isCov': !exists(json, 'isCov') ? undefined : json['isCov'],
        'embankLen': !exists(json, 'embankLen') ? undefined : json['embankLen'],
        'embankHght': !exists(json, 'embankHght') ? undefined : json['embankHght'],
        'irrigTypeCode': !exists(json, 'irrigTypeCode') ? undefined : json['irrigTypeCode'],
        'irrigArea': !exists(json, 'irrigArea') ? undefined : json['irrigArea'],
        'landCultureCode': !exists(json, 'landCultureCode') ? undefined : json['landCultureCode'],
        'conduitLen': !exists(json, 'conduitLen') ? undefined : json['conduitLen'],
        'ductLen': !exists(json, 'ductLen') ? undefined : json['ductLen'],
        'accumVolume': !exists(json, 'accumVolume') ? undefined : json['accumVolume'],
        'waterDrainCode': !exists(json, 'waterDrainCode') ? undefined : json['waterDrainCode'],
        'dischargeTypeCode': !exists(json, 'dischargeTypeCode') ? undefined : json['dischargeTypeCode'],
        'drainArea': !exists(json, 'drainArea') ? undefined : json['drainArea'],
        'channelLen': !exists(json, 'channelLen') ? undefined : json['channelLen'],
        'sectionLen': !exists(json, 'sectionLen') ? undefined : json['sectionLen'],
        'shipDeviceCode': !exists(json, 'shipDeviceCode') ? undefined : json['shipDeviceCode'],
        'waterBleedingCode': !exists(json, 'waterBleedingCode') ? undefined : json['waterBleedingCode'],
        'sludgeSedCode': !exists(json, 'sludgeSedCode') ? undefined : json['sludgeSedCode'],
        'sludgeBedTypeCode': !exists(json, 'sludgeBedTypeCode') ? undefined : json['sludgeBedTypeCode'],
        'isTakingOffMonit': !exists(json, 'isTakingOffMonit') ? undefined : json['isTakingOffMonit'],
        'isLeakMonit': !exists(json, 'isLeakMonit') ? undefined : json['isLeakMonit'],
        'objManagerCode': !exists(json, 'objManagerCode') ? undefined : json['objManagerCode'],
        'measDevTypeCode': !exists(json, 'measDevTypeCode') ? undefined : json['measDevTypeCode'],
        'wellCode': !exists(json, 'wellCode') ? undefined : json['wellCode'],
        'wellDepth': !exists(json, 'wellDepth') ? undefined : json['wellDepth'],
        'rapidLen': !exists(json, 'rapidLen') ? undefined : json['rapidLen'],
        'sampleTypeCodes': !exists(json, 'sampleTypeCodes') ? undefined : json['sampleTypeCodes'],
        'purifMethodCodes': !exists(json, 'purifMethodCodes') ? undefined : json['purifMethodCodes'],
        'measParamCodes': !exists(json, 'measParamCodes') ? undefined : json['measParamCodes'],
        'embank': !exists(json, 'embank') ? undefined : WaterStructEmbankRequestRecordFromJSON(json['embank']),
        'reservoir': !exists(json, 'reservoir') ? undefined : WaterStructReservoirRequestRecordFromJSON(json['reservoir']),
        'dam': !exists(json, 'dam') ? undefined : WaterStructDamRequestRecordFromJSON(json['dam']),
        'zdrz': !exists(json, 'zdrz') ? undefined : WaterStructZdrzRecordFromJSON(json['zdrz']),
        'duct': !exists(json, 'duct') ? undefined : WaterStructDuctRequestRecordFromJSON(json['duct']),
        'object': !exists(json, 'object') ? undefined : WaterStructObjectRequestRecordFromJSON(json['object']),
        'treatment': !exists(json, 'treatment') ? undefined : WaterStructTreatmentRequestRecordFromJSON(json['treatment']),
        'watertank': !exists(json, 'watertank') ? undefined : WaterStructWatertankRequestRecordFromJSON(json['watertank']),
        'push': !exists(json, 'push') ? undefined : WaterStructPushRequestRecordFromJSON(json['push']),
        'sewer': !exists(json, 'sewer') ? undefined : WaterStructSewerRequestRecordFromJSON(json['sewer']),
        'tempReduct': !exists(json, 'tempReduct') ? undefined : WaterStructTempReductRecordFromJSON(json['tempReduct']),
        'tank': !exists(json, 'tank') ? undefined : WaterStructTankRequestRecordFromJSON(json['tank']),
        'cov': !exists(json, 'cov') ? undefined : WaterStructCovRequestRecordFromJSON(json['cov']),
        'lock': !exists(json, 'lock') ? undefined : WaterStructLockRecordFromJSON(json['lock']),
        'channel': !exists(json, 'channel') ? undefined : WaterStructChannelRecordFromJSON(json['channel']),
        'powerPlant': !exists(json, 'powerPlant') ? undefined : WaterStructPowerPlantRequestRecordFromJSON(json['powerPlant']),
        'conduit': !exists(json, 'conduit') ? undefined : WaterStructConduitRequestRecordFromJSON(json['conduit']),
        'port': !exists(json, 'port') ? undefined : WaterStructPortRecordFromJSON(json['port']),
    };
}

export function WaterStructAppendixRequestRecordToJSON(value?: WaterStructAppendixRequestRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'docTypeCode': value.docTypeCode,
        'dateFrom': value.dateFrom,
        'dateTo': value.dateTo,
        'structName': value.structName,
        'hydrOrder': value.hydrOrder,
        'tbdCatCode': value.tbdCatCode,
        'manipOrder': value.manipOrder,
        'waterStructCode': value.waterStructCode,
        'note': value.note,
        'structUseCode': value.structUseCode,
        'waterOriginCode': value.waterOriginCode,
        'basinArea': value.basinArea,
        'streamCode': value.streamCode,
        'streamId': value.streamId,
        'riverKmBegin': value.riverKmBegin,
        'riverKmEnd': value.riverKmEnd,
        'posBeginXCoord': value.posBeginXCoord,
        'posBeginYCoord': value.posBeginYCoord,
        'posEndXCoord': value.posEndXCoord,
        'posEndYCoord': value.posEndYCoord,
        'positionCode': value.positionCode,
        'streamLength': value.streamLength,
        'maxFlow': value.maxFlow,
        'isCov': value.isCov,
        'embankLen': value.embankLen,
        'embankHght': value.embankHght,
        'irrigTypeCode': value.irrigTypeCode,
        'irrigArea': value.irrigArea,
        'landCultureCode': value.landCultureCode,
        'conduitLen': value.conduitLen,
        'ductLen': value.ductLen,
        'accumVolume': value.accumVolume,
        'waterDrainCode': value.waterDrainCode,
        'dischargeTypeCode': value.dischargeTypeCode,
        'drainArea': value.drainArea,
        'channelLen': value.channelLen,
        'sectionLen': value.sectionLen,
        'shipDeviceCode': value.shipDeviceCode,
        'waterBleedingCode': value.waterBleedingCode,
        'sludgeSedCode': value.sludgeSedCode,
        'sludgeBedTypeCode': value.sludgeBedTypeCode,
        'isTakingOffMonit': value.isTakingOffMonit,
        'isLeakMonit': value.isLeakMonit,
        'objManagerCode': value.objManagerCode,
        'measDevTypeCode': value.measDevTypeCode,
        'wellCode': value.wellCode,
        'wellDepth': value.wellDepth,
        'rapidLen': value.rapidLen,
        'sampleTypeCodes': value.sampleTypeCodes,
        'purifMethodCodes': value.purifMethodCodes,
        'measParamCodes': value.measParamCodes,
        'embank': WaterStructEmbankRequestRecordToJSON(value.embank),
        'reservoir': WaterStructReservoirRequestRecordToJSON(value.reservoir),
        'dam': WaterStructDamRequestRecordToJSON(value.dam),
        'zdrz': WaterStructZdrzRecordToJSON(value.zdrz),
        'duct': WaterStructDuctRequestRecordToJSON(value.duct),
        'object': WaterStructObjectRequestRecordToJSON(value.object),
        'treatment': WaterStructTreatmentRequestRecordToJSON(value.treatment),
        'watertank': WaterStructWatertankRequestRecordToJSON(value.watertank),
        'push': WaterStructPushRequestRecordToJSON(value.push),
        'sewer': WaterStructSewerRequestRecordToJSON(value.sewer),
        'tempReduct': WaterStructTempReductRecordToJSON(value.tempReduct),
        'tank': WaterStructTankRequestRecordToJSON(value.tank),
        'cov': WaterStructCovRequestRecordToJSON(value.cov),
        'lock': WaterStructLockRecordToJSON(value.lock),
        'channel': WaterStructChannelRecordToJSON(value.channel),
        'powerPlant': WaterStructPowerPlantRequestRecordToJSON(value.powerPlant),
        'conduit': WaterStructConduitRequestRecordToJSON(value.conduit),
        'port': WaterStructPortRecordToJSON(value.port),
    };
}

