/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface HydroAnalysisPrefillRequest
 */
export interface HydroAnalysisPrefillRequest {
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisPrefillRequest
     */
    protocolId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof HydroAnalysisPrefillRequest
     */
    monitoringPointCode?: string;
}

/**
 * Check if a given object implements the HydroAnalysisPrefillRequest interface.
 */
export function instanceOfHydroAnalysisPrefillRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function HydroAnalysisPrefillRequestFromJSON(json: any): HydroAnalysisPrefillRequest {
    return HydroAnalysisPrefillRequestFromJSONTyped(json, false);
}

export function HydroAnalysisPrefillRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): HydroAnalysisPrefillRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'protocolId': !exists(json, 'protocolId') ? undefined : json['protocolId'],
        'monitoringPointCode': !exists(json, 'monitoringPointCode') ? undefined : json['monitoringPointCode'],
    };
}

export function HydroAnalysisPrefillRequestToJSON(value?: HydroAnalysisPrefillRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'protocolId': value.protocolId,
        'monitoringPointCode': value.monitoringPointCode,
    };
}

