/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GeneralTypeRecord } from './GeneralTypeRecord';
import {
    GeneralTypeRecordFromJSON,
    GeneralTypeRecordFromJSONTyped,
    GeneralTypeRecordToJSON,
} from './GeneralTypeRecord';
import type { GpsRecord } from './GpsRecord';
import {
    GpsRecordFromJSON,
    GpsRecordFromJSONTyped,
    GpsRecordToJSON,
} from './GpsRecord';
import type { OtherRecord } from './OtherRecord';
import {
    OtherRecordFromJSON,
    OtherRecordFromJSONTyped,
    OtherRecordToJSON,
} from './OtherRecord';
import type { TechnicalAttributeRecord } from './TechnicalAttributeRecord';
import {
    TechnicalAttributeRecordFromJSON,
    TechnicalAttributeRecordFromJSONTyped,
    TechnicalAttributeRecordToJSON,
} from './TechnicalAttributeRecord';
import type { WaterCourseRecord } from './WaterCourseRecord';
import {
    WaterCourseRecordFromJSON,
    WaterCourseRecordFromJSONTyped,
    WaterCourseRecordToJSON,
} from './WaterCourseRecord';
import type { WaterWithdrawalRecord } from './WaterWithdrawalRecord';
import {
    WaterWithdrawalRecordFromJSON,
    WaterWithdrawalRecordFromJSONTyped,
    WaterWithdrawalRecordToJSON,
} from './WaterWithdrawalRecord';

/**
 * 
 * @export
 * @interface MonitoringRecord
 */
export interface MonitoringRecord {
    /**
     * 
     * @type {string}
     * @memberof MonitoringRecord
     */
    name?: string;
    /**
     * 
     * @type {GeneralTypeRecord}
     * @memberof MonitoringRecord
     */
    basinType?: GeneralTypeRecord;
    /**
     * 
     * @type {GeneralTypeRecord}
     * @memberof MonitoringRecord
     */
    partialBasinType?: GeneralTypeRecord;
    /**
     * 
     * @type {GeneralTypeRecord}
     * @memberof MonitoringRecord
     */
    subBasinType?: GeneralTypeRecord;
    /**
     * 
     * @type {GeneralTypeRecord}
     * @memberof MonitoringRecord
     */
    assesmentBasinType?: GeneralTypeRecord;
    /**
     * 
     * @type {string}
     * @memberof MonitoringRecord
     */
    groupId?: string;
    /**
     * 
     * @type {GeneralTypeRecord}
     * @memberof MonitoringRecord
     */
    hydrologicalCodeType?: GeneralTypeRecord;
    /**
     * 
     * @type {GeneralTypeRecord}
     * @memberof MonitoringRecord
     */
    instBranchType?: GeneralTypeRecord;
    /**
     * 
     * @type {string}
     * @memberof MonitoringRecord
     */
    locationDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitoringRecord
     */
    mapSheetNumber?: string;
    /**
     * 
     * @type {GeneralTypeRecord}
     * @memberof MonitoringRecord
     */
    montPointType?: GeneralTypeRecord;
    /**
     * 
     * @type {string}
     * @memberof MonitoringRecord
     */
    placeName?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitoringRecord
     */
    note?: string;
    /**
     * 
     * @type {GeneralTypeRecord}
     * @memberof MonitoringRecord
     */
    positionType?: GeneralTypeRecord;
    /**
     * 
     * @type {GeneralTypeRecord}
     * @memberof MonitoringRecord
     */
    statusType?: GeneralTypeRecord;
    /**
     * 
     * @type {GeneralTypeRecord}
     * @memberof MonitoringRecord
     */
    countryType?: GeneralTypeRecord;
    /**
     * 
     * @type {GeneralTypeRecord}
     * @memberof MonitoringRecord
     */
    areaType?: GeneralTypeRecord;
    /**
     * 
     * @type {GeneralTypeRecord}
     * @memberof MonitoringRecord
     */
    regionType?: GeneralTypeRecord;
    /**
     * 
     * @type {GeneralTypeRecord}
     * @memberof MonitoringRecord
     */
    districtType?: GeneralTypeRecord;
    /**
     * 
     * @type {GeneralTypeRecord}
     * @memberof MonitoringRecord
     */
    municipalityType?: GeneralTypeRecord;
    /**
     * 
     * @type {any}
     * @memberof MonitoringRecord
     */
    municipalityForeignType?: any;
    /**
     * 
     * @type {any}
     * @memberof MonitoringRecord
     */
    landRegisterType?: any;
    /**
     * 
     * @type {string}
     * @memberof MonitoringRecord
     */
    effectiveTo?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitoringRecord
     */
    effectiveFrom?: string;
    /**
     * 
     * @type {any}
     * @memberof MonitoringRecord
     */
    undergroundWater?: any;
    /**
     * 
     * @type {any}
     * @memberof MonitoringRecord
     */
    attachments?: any;
    /**
     * 
     * @type {GpsRecord}
     * @memberof MonitoringRecord
     */
    gps?: GpsRecord;
    /**
     * 
     * @type {OtherRecord}
     * @memberof MonitoringRecord
     */
    other?: OtherRecord;
    /**
     * 
     * @type {number}
     * @memberof MonitoringRecord
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof MonitoringRecord
     */
    monitoringPointId?: number;
    /**
     * 
     * @type {string}
     * @memberof MonitoringRecord
     */
    code?: string;
    /**
     * 
     * @type {WaterCourseRecord}
     * @memberof MonitoringRecord
     */
    waterCourse?: WaterCourseRecord;
    /**
     * 
     * @type {WaterWithdrawalRecord}
     * @memberof MonitoringRecord
     */
    waterWithdrawal?: WaterWithdrawalRecord;
    /**
     * 
     * @type {any}
     * @memberof MonitoringRecord
     */
    monitorings?: any;
    /**
     * 
     * @type {any}
     * @memberof MonitoringRecord
     */
    pointCategories?: any;
    /**
     * 
     * @type {TechnicalAttributeRecord}
     * @memberof MonitoringRecord
     */
    technicalAttribute?: TechnicalAttributeRecord;
    /**
     * 
     * @type {boolean}
     * @memberof MonitoringRecord
     */
    deleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MonitoringRecord
     */
    version?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitoringRecord
     */
    createdTimestamp?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitoringRecord
     */
    updatedTimestamp?: string;
    /**
     * 
     * @type {number}
     * @memberof MonitoringRecord
     */
    shmuId?: number;
}

/**
 * Check if a given object implements the MonitoringRecord interface.
 */
export function instanceOfMonitoringRecord(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MonitoringRecordFromJSON(json: any): MonitoringRecord {
    return MonitoringRecordFromJSONTyped(json, false);
}

export function MonitoringRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitoringRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'basinType': !exists(json, 'basinType') ? undefined : GeneralTypeRecordFromJSON(json['basinType']),
        'partialBasinType': !exists(json, 'partialBasinType') ? undefined : GeneralTypeRecordFromJSON(json['partialBasinType']),
        'subBasinType': !exists(json, 'subBasinType') ? undefined : GeneralTypeRecordFromJSON(json['subBasinType']),
        'assesmentBasinType': !exists(json, 'assesmentBasinType') ? undefined : GeneralTypeRecordFromJSON(json['assesmentBasinType']),
        'groupId': !exists(json, 'groupId') ? undefined : json['groupId'],
        'hydrologicalCodeType': !exists(json, 'hydrologicalCodeType') ? undefined : GeneralTypeRecordFromJSON(json['hydrologicalCodeType']),
        'instBranchType': !exists(json, 'instBranchType') ? undefined : GeneralTypeRecordFromJSON(json['instBranchType']),
        'locationDescription': !exists(json, 'locationDescription') ? undefined : json['locationDescription'],
        'mapSheetNumber': !exists(json, 'mapSheetNumber') ? undefined : json['mapSheetNumber'],
        'montPointType': !exists(json, 'montPointType') ? undefined : GeneralTypeRecordFromJSON(json['montPointType']),
        'placeName': !exists(json, 'placeName') ? undefined : json['placeName'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'positionType': !exists(json, 'positionType') ? undefined : GeneralTypeRecordFromJSON(json['positionType']),
        'statusType': !exists(json, 'statusType') ? undefined : GeneralTypeRecordFromJSON(json['statusType']),
        'countryType': !exists(json, 'countryType') ? undefined : GeneralTypeRecordFromJSON(json['countryType']),
        'areaType': !exists(json, 'areaType') ? undefined : GeneralTypeRecordFromJSON(json['areaType']),
        'regionType': !exists(json, 'regionType') ? undefined : GeneralTypeRecordFromJSON(json['regionType']),
        'districtType': !exists(json, 'districtType') ? undefined : GeneralTypeRecordFromJSON(json['districtType']),
        'municipalityType': !exists(json, 'municipalityType') ? undefined : GeneralTypeRecordFromJSON(json['municipalityType']),
        'municipalityForeignType': !exists(json, 'municipalityForeignType') ? undefined : json['municipalityForeignType'],
        'landRegisterType': !exists(json, 'landRegisterType') ? undefined : json['landRegisterType'],
        'effectiveTo': !exists(json, 'effectiveTo') ? undefined : json['effectiveTo'],
        'effectiveFrom': !exists(json, 'effectiveFrom') ? undefined : json['effectiveFrom'],
        'undergroundWater': !exists(json, 'undergroundWater') ? undefined : json['undergroundWater'],
        'attachments': !exists(json, 'attachments') ? undefined : json['attachments'],
        'gps': !exists(json, 'gps') ? undefined : GpsRecordFromJSON(json['gps']),
        'other': !exists(json, 'other') ? undefined : OtherRecordFromJSON(json['other']),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'monitoringPointId': !exists(json, 'monitoringPointId') ? undefined : json['monitoringPointId'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'waterCourse': !exists(json, 'waterCourse') ? undefined : WaterCourseRecordFromJSON(json['waterCourse']),
        'waterWithdrawal': !exists(json, 'waterWithdrawal') ? undefined : WaterWithdrawalRecordFromJSON(json['waterWithdrawal']),
        'monitorings': !exists(json, 'monitorings') ? undefined : json['monitorings'],
        'pointCategories': !exists(json, 'pointCategories') ? undefined : json['pointCategories'],
        'technicalAttribute': !exists(json, 'technicalAttribute') ? undefined : TechnicalAttributeRecordFromJSON(json['technicalAttribute']),
        'deleted': !exists(json, 'deleted') ? undefined : json['deleted'],
        'version': !exists(json, 'version') ? undefined : json['version'],
        'createdTimestamp': !exists(json, 'createdTimestamp') ? undefined : json['createdTimestamp'],
        'updatedTimestamp': !exists(json, 'updatedTimestamp') ? undefined : json['updatedTimestamp'],
        'shmuId': !exists(json, 'shmuId') ? undefined : json['shmuId'],
    };
}

export function MonitoringRecordToJSON(value?: MonitoringRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'basinType': GeneralTypeRecordToJSON(value.basinType),
        'partialBasinType': GeneralTypeRecordToJSON(value.partialBasinType),
        'subBasinType': GeneralTypeRecordToJSON(value.subBasinType),
        'assesmentBasinType': GeneralTypeRecordToJSON(value.assesmentBasinType),
        'groupId': value.groupId,
        'hydrologicalCodeType': GeneralTypeRecordToJSON(value.hydrologicalCodeType),
        'instBranchType': GeneralTypeRecordToJSON(value.instBranchType),
        'locationDescription': value.locationDescription,
        'mapSheetNumber': value.mapSheetNumber,
        'montPointType': GeneralTypeRecordToJSON(value.montPointType),
        'placeName': value.placeName,
        'note': value.note,
        'positionType': GeneralTypeRecordToJSON(value.positionType),
        'statusType': GeneralTypeRecordToJSON(value.statusType),
        'countryType': GeneralTypeRecordToJSON(value.countryType),
        'areaType': GeneralTypeRecordToJSON(value.areaType),
        'regionType': GeneralTypeRecordToJSON(value.regionType),
        'districtType': GeneralTypeRecordToJSON(value.districtType),
        'municipalityType': GeneralTypeRecordToJSON(value.municipalityType),
        'municipalityForeignType': value.municipalityForeignType,
        'landRegisterType': value.landRegisterType,
        'effectiveTo': value.effectiveTo,
        'effectiveFrom': value.effectiveFrom,
        'undergroundWater': value.undergroundWater,
        'attachments': value.attachments,
        'gps': GpsRecordToJSON(value.gps),
        'other': OtherRecordToJSON(value.other),
        'id': value.id,
        'monitoringPointId': value.monitoringPointId,
        'code': value.code,
        'waterCourse': WaterCourseRecordToJSON(value.waterCourse),
        'waterWithdrawal': WaterWithdrawalRecordToJSON(value.waterWithdrawal),
        'monitorings': value.monitorings,
        'pointCategories': value.pointCategories,
        'technicalAttribute': TechnicalAttributeRecordToJSON(value.technicalAttribute),
        'deleted': value.deleted,
        'version': value.version,
        'createdTimestamp': value.createdTimestamp,
        'updatedTimestamp': value.updatedTimestamp,
        'shmuId': value.shmuId,
    };
}

