/* tslint:disable */
/* eslint-disable */
/**
 * Overview API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ColumnDefinitionSo
 */
export interface ColumnDefinitionSo {
    /**
     * 
     * @type {string}
     * @memberof ColumnDefinitionSo
     */
    previewReference?: string;
    /**
     * 
     * @type {string}
     * @memberof ColumnDefinitionSo
     */
    note?: string;
    /**
     * 
     * @type {string}
     * @memberof ColumnDefinitionSo
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ColumnDefinitionSo
     */
    nullable?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ColumnDefinitionSo
     */
    key?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ColumnDefinitionSo
     */
    type?: ColumnDefinitionSoTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof ColumnDefinitionSo
     */
    length?: number;
    /**
     * 
     * @type {number}
     * @memberof ColumnDefinitionSo
     */
    decimalDigits?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ColumnDefinitionSo
     */
    editable?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ColumnDefinitionSo
     */
    indexed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ColumnDefinitionSo
     */
    hidden?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ColumnDefinitionSo
     */
    searchable?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ColumnDefinitionSo
     */
    sortable?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ColumnDefinitionSo
     */
    dateColumn?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ColumnDefinitionSo
     */
    timeColumn?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ColumnDefinitionSo
     */
    visibleWhenAdding?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ColumnDefinitionSo
     */
    identifiedByColumn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ColumnDefinitionSo
     */
    source?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ColumnDefinitionSo
     */
    sourceColumn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ColumnDefinitionSo
     */
    equation?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ColumnDefinitionSo
     */
    multiChoice?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ColumnDefinitionSo
     */
    referencedView?: string;
    /**
     * 
     * @type {string}
     * @memberof ColumnDefinitionSo
     */
    referencedViewColumn?: string;
    /**
     * 
     * @type {string}
     * @memberof ColumnDefinitionSo
     */
    roundingType?: string;
    /**
     * 
     * @type {number}
     * @memberof ColumnDefinitionSo
     */
    rounding?: number;
    /**
     * 
     * @type {number}
     * @memberof ColumnDefinitionSo
     */
    significantFigures?: number;
    /**
     * 
     * @type {number}
     * @memberof ColumnDefinitionSo
     */
    significantFigures2?: number;
    /**
     * 
     * @type {string}
     * @memberof ColumnDefinitionSo
     */
    warning?: string;
    /**
     * 
     * @type {string}
     * @memberof ColumnDefinitionSo
     */
    defaultValue?: string;
    /**
     * 
     * @type {string}
     * @memberof ColumnDefinitionSo
     */
    groupUpdateByColumn?: string;
    /**
     * 
     * @type {string}
     * @memberof ColumnDefinitionSo
     */
    liveCalculation?: string;
}


/**
 * @export
 */
export const ColumnDefinitionSoTypeEnum = {
    Bit: 'BIT',
    Tinyint: 'TINYINT',
    Smallint: 'SMALLINT',
    Integer: 'INTEGER',
    Bigint: 'BIGINT',
    Real: 'REAL',
    Float: 'FLOAT',
    Double: 'DOUBLE',
    Numeric: 'NUMERIC',
    Decimal: 'DECIMAL',
    Char: 'CHAR',
    Varchar: 'VARCHAR',
    Longvarchar: 'LONGVARCHAR',
    Date: 'DATE',
    Time: 'TIME',
    Timestamp: 'TIMESTAMP',
    Binary: 'BINARY',
    Varbinary: 'VARBINARY',
    Longvarbinary: 'LONGVARBINARY',
    Null: 'NULL',
    Other: 'OTHER',
    JavaObject: 'JAVA_OBJECT',
    Blob: 'BLOB',
    Clob: 'CLOB',
    Boolean: 'BOOLEAN',
    Rowid: 'ROWID',
    Nchar: 'NCHAR',
    Nvarchar: 'NVARCHAR',
    Longnvarchar: 'LONGNVARCHAR',
    Nclob: 'NCLOB',
    Sqlxml: 'SQLXML',
    TimeWithTimezone: 'TIME_WITH_TIMEZONE',
    TimestampWithTimezone: 'TIMESTAMP_WITH_TIMEZONE',
    Bfile: 'BFILE',
    DmsFileUuid: 'DMS_FILE_UUID'
} as const;
export type ColumnDefinitionSoTypeEnum = typeof ColumnDefinitionSoTypeEnum[keyof typeof ColumnDefinitionSoTypeEnum];


/**
 * Check if a given object implements the ColumnDefinitionSo interface.
 */
export function instanceOfColumnDefinitionSo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ColumnDefinitionSoFromJSON(json: any): ColumnDefinitionSo {
    return ColumnDefinitionSoFromJSONTyped(json, false);
}

export function ColumnDefinitionSoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ColumnDefinitionSo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'previewReference': !exists(json, 'previewReference') ? undefined : json['previewReference'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'nullable': !exists(json, 'nullable') ? undefined : json['nullable'],
        'key': !exists(json, 'key') ? undefined : json['key'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'length': !exists(json, 'length') ? undefined : json['length'],
        'decimalDigits': !exists(json, 'decimalDigits') ? undefined : json['decimalDigits'],
        'editable': !exists(json, 'editable') ? undefined : json['editable'],
        'indexed': !exists(json, 'indexed') ? undefined : json['indexed'],
        'hidden': !exists(json, 'hidden') ? undefined : json['hidden'],
        'searchable': !exists(json, 'searchable') ? undefined : json['searchable'],
        'sortable': !exists(json, 'sortable') ? undefined : json['sortable'],
        'dateColumn': !exists(json, 'dateColumn') ? undefined : json['dateColumn'],
        'timeColumn': !exists(json, 'timeColumn') ? undefined : json['timeColumn'],
        'visibleWhenAdding': !exists(json, 'visibleWhenAdding') ? undefined : json['visibleWhenAdding'],
        'identifiedByColumn': !exists(json, 'identifiedByColumn') ? undefined : json['identifiedByColumn'],
        'source': !exists(json, 'source') ? undefined : json['source'],
        'sourceColumn': !exists(json, 'sourceColumn') ? undefined : json['sourceColumn'],
        'equation': !exists(json, 'equation') ? undefined : json['equation'],
        'multiChoice': !exists(json, 'multiChoice') ? undefined : json['multiChoice'],
        'referencedView': !exists(json, 'referencedView') ? undefined : json['referencedView'],
        'referencedViewColumn': !exists(json, 'referencedViewColumn') ? undefined : json['referencedViewColumn'],
        'roundingType': !exists(json, 'roundingType') ? undefined : json['roundingType'],
        'rounding': !exists(json, 'rounding') ? undefined : json['rounding'],
        'significantFigures': !exists(json, 'significantFigures') ? undefined : json['significantFigures'],
        'significantFigures2': !exists(json, 'significantFigures2') ? undefined : json['significantFigures2'],
        'warning': !exists(json, 'warning') ? undefined : json['warning'],
        'defaultValue': !exists(json, 'defaultValue') ? undefined : json['defaultValue'],
        'groupUpdateByColumn': !exists(json, 'groupUpdateByColumn') ? undefined : json['groupUpdateByColumn'],
        'liveCalculation': !exists(json, 'liveCalculation') ? undefined : json['liveCalculation'],
    };
}

export function ColumnDefinitionSoToJSON(value?: ColumnDefinitionSo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'previewReference': value.previewReference,
        'note': value.note,
        'name': value.name,
        'nullable': value.nullable,
        'key': value.key,
        'type': value.type,
        'length': value.length,
        'decimalDigits': value.decimalDigits,
        'editable': value.editable,
        'indexed': value.indexed,
        'hidden': value.hidden,
        'searchable': value.searchable,
        'sortable': value.sortable,
        'dateColumn': value.dateColumn,
        'timeColumn': value.timeColumn,
        'visibleWhenAdding': value.visibleWhenAdding,
        'identifiedByColumn': value.identifiedByColumn,
        'source': value.source,
        'sourceColumn': value.sourceColumn,
        'equation': value.equation,
        'multiChoice': value.multiChoice,
        'referencedView': value.referencedView,
        'referencedViewColumn': value.referencedViewColumn,
        'roundingType': value.roundingType,
        'rounding': value.rounding,
        'significantFigures': value.significantFigures,
        'significantFigures2': value.significantFigures2,
        'warning': value.warning,
        'defaultValue': value.defaultValue,
        'groupUpdateByColumn': value.groupUpdateByColumn,
        'liveCalculation': value.liveCalculation,
    };
}

