/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WaterStructChannelRecord
 */
export interface WaterStructChannelRecord {
    /**
     * 
     * @type {number}
     * @memberof WaterStructChannelRecord
     */
    id?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructChannelRecord
     */
    length?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructChannelRecord
     */
    width?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructChannelRecord
     */
    depth?: number | null;
}

/**
 * Check if a given object implements the WaterStructChannelRecord interface.
 */
export function instanceOfWaterStructChannelRecord(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WaterStructChannelRecordFromJSON(json: any): WaterStructChannelRecord {
    return WaterStructChannelRecordFromJSONTyped(json, false);
}

export function WaterStructChannelRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): WaterStructChannelRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'length': !exists(json, 'length') ? undefined : json['length'],
        'width': !exists(json, 'width') ? undefined : json['width'],
        'depth': !exists(json, 'depth') ? undefined : json['depth'],
    };
}

export function WaterStructChannelRecordToJSON(value?: WaterStructChannelRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'length': value.length,
        'width': value.width,
        'depth': value.depth,
    };
}

