/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface HydroAnalysisScoreCalculationSsu
 */
export interface HydroAnalysisScoreCalculationSsu {
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisScoreCalculationSsu
     */
    bedElement?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisScoreCalculationSsu
     */
    substrate?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisScoreCalculationSsu
     */
    flowType?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisScoreCalculationSsu
     */
    largeWoodyDebris?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisScoreCalculationSsu
     */
    artificialBedFeatures?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisScoreCalculationSsu
     */
    riparianVegetationL?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisScoreCalculationSsu
     */
    riparianVegetationR?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisScoreCalculationSsu
     */
    bankStabilizationL?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisScoreCalculationSsu
     */
    bankStabilizationR?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisScoreCalculationSsu
     */
    bankProfileL?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisScoreCalculationSsu
     */
    bankProfileR?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisScoreCalculationSsu
     */
    floodedAreaL?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisScoreCalculationSsu
     */
    floodedAreaR?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisScoreCalculationSsu
     */
    naturalVegetationL?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisScoreCalculationSsu
     */
    naturalVegetationR?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisScoreCalculationSsu
     */
    varInWidthW?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisScoreCalculationSsu
     */
    varInWidthS?: number;
}

/**
 * Check if a given object implements the HydroAnalysisScoreCalculationSsu interface.
 */
export function instanceOfHydroAnalysisScoreCalculationSsu(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function HydroAnalysisScoreCalculationSsuFromJSON(json: any): HydroAnalysisScoreCalculationSsu {
    return HydroAnalysisScoreCalculationSsuFromJSONTyped(json, false);
}

export function HydroAnalysisScoreCalculationSsuFromJSONTyped(json: any, ignoreDiscriminator: boolean): HydroAnalysisScoreCalculationSsu {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bedElement': !exists(json, 'bedElement') ? undefined : json['bedElement'],
        'substrate': !exists(json, 'substrate') ? undefined : json['substrate'],
        'flowType': !exists(json, 'flowType') ? undefined : json['flowType'],
        'largeWoodyDebris': !exists(json, 'largeWoodyDebris') ? undefined : json['largeWoodyDebris'],
        'artificialBedFeatures': !exists(json, 'artificialBedFeatures') ? undefined : json['artificialBedFeatures'],
        'riparianVegetationL': !exists(json, 'riparianVegetationL') ? undefined : json['riparianVegetationL'],
        'riparianVegetationR': !exists(json, 'riparianVegetationR') ? undefined : json['riparianVegetationR'],
        'bankStabilizationL': !exists(json, 'bankStabilizationL') ? undefined : json['bankStabilizationL'],
        'bankStabilizationR': !exists(json, 'bankStabilizationR') ? undefined : json['bankStabilizationR'],
        'bankProfileL': !exists(json, 'bankProfileL') ? undefined : json['bankProfileL'],
        'bankProfileR': !exists(json, 'bankProfileR') ? undefined : json['bankProfileR'],
        'floodedAreaL': !exists(json, 'floodedAreaL') ? undefined : json['floodedAreaL'],
        'floodedAreaR': !exists(json, 'floodedAreaR') ? undefined : json['floodedAreaR'],
        'naturalVegetationL': !exists(json, 'naturalVegetationL') ? undefined : json['naturalVegetationL'],
        'naturalVegetationR': !exists(json, 'naturalVegetationR') ? undefined : json['naturalVegetationR'],
        'varInWidthW': !exists(json, 'varInWidthW') ? undefined : json['varInWidthW'],
        'varInWidthS': !exists(json, 'varInWidthS') ? undefined : json['varInWidthS'],
    };
}

export function HydroAnalysisScoreCalculationSsuToJSON(value?: HydroAnalysisScoreCalculationSsu | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bedElement': value.bedElement,
        'substrate': value.substrate,
        'flowType': value.flowType,
        'largeWoodyDebris': value.largeWoodyDebris,
        'artificialBedFeatures': value.artificialBedFeatures,
        'riparianVegetationL': value.riparianVegetationL,
        'riparianVegetationR': value.riparianVegetationR,
        'bankStabilizationL': value.bankStabilizationL,
        'bankStabilizationR': value.bankStabilizationR,
        'bankProfileL': value.bankProfileL,
        'bankProfileR': value.bankProfileR,
        'floodedAreaL': value.floodedAreaL,
        'floodedAreaR': value.floodedAreaR,
        'naturalVegetationL': value.naturalVegetationL,
        'naturalVegetationR': value.naturalVegetationR,
        'varInWidthW': value.varInWidthW,
        'varInWidthS': value.varInWidthS,
    };
}

