/* tslint:disable */
/* eslint-disable */
/**
 * Catalog API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.31.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AddressCodelistItemInput } from './AddressCodelistItemInput';
import {
    AddressCodelistItemInputFromJSON,
    AddressCodelistItemInputFromJSONTyped,
    AddressCodelistItemInputToJSON,
} from './AddressCodelistItemInput';
import type { CodelistItemInput } from './CodelistItemInput';
import {
    CodelistItemInputFromJSON,
    CodelistItemInputFromJSONTyped,
    CodelistItemInputToJSON,
} from './CodelistItemInput';
import type { ContactPerson } from './ContactPerson';
import {
    ContactPersonFromJSON,
    ContactPersonFromJSONTyped,
    ContactPersonToJSON,
} from './ContactPerson';
import type { GeographicData } from './GeographicData';
import {
    GeographicDataFromJSON,
    GeographicDataFromJSONTyped,
    GeographicDataToJSON,
} from './GeographicData';

/**
 * 
 * @export
 * @interface InstallationUpdate
 */
export interface InstallationUpdate {
    /**
     * 
     * @type {number}
     * @memberof InstallationUpdate
     */
    technicalCode: number | null;
    /**
     * 
     * @type {number}
     * @memberof InstallationUpdate
     */
    installationCode: number | null;
    /**
     * 
     * @type {number}
     * @memberof InstallationUpdate
     */
    ippc: number | null;
    /**
     * 
     * @type {string}
     * @memberof InstallationUpdate
     */
    name: string | null;
    /**
     * 
     * @type {string}
     * @memberof InstallationUpdate
     */
    note: string | null;
    /**
     * 
     * @type {AddressCodelistItemInput}
     * @memberof InstallationUpdate
     */
    address: AddressCodelistItemInput | null;
    /**
     * 
     * @type {GeographicData}
     * @memberof InstallationUpdate
     */
    geographicData: GeographicData | null;
    /**
     * 
     * @type {CodelistItemInput}
     * @memberof InstallationUpdate
     */
    naceType: CodelistItemInput | null;
    /**
     * 
     * @type {CodelistItemInput}
     * @memberof InstallationUpdate
     */
    nace2Type: CodelistItemInput | null;
    /**
     * 
     * @type {number}
     * @memberof InstallationUpdate
     */
    operatorId: number;
    /**
     * 
     * @type {string}
     * @memberof InstallationUpdate
     */
    code: string | null;
    /**
     * 
     * @type {Array<ContactPerson>}
     * @memberof InstallationUpdate
     */
    contactPerson: Array<ContactPerson> | null;
}

/**
 * Check if a given object implements the InstallationUpdate interface.
 */
export function instanceOfInstallationUpdate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "technicalCode" in value;
    isInstance = isInstance && "installationCode" in value;
    isInstance = isInstance && "ippc" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "note" in value;
    isInstance = isInstance && "address" in value;
    isInstance = isInstance && "geographicData" in value;
    isInstance = isInstance && "naceType" in value;
    isInstance = isInstance && "nace2Type" in value;
    isInstance = isInstance && "operatorId" in value;
    isInstance = isInstance && "code" in value;
    isInstance = isInstance && "contactPerson" in value;

    return isInstance;
}

export function InstallationUpdateFromJSON(json: any): InstallationUpdate {
    return InstallationUpdateFromJSONTyped(json, false);
}

export function InstallationUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): InstallationUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'technicalCode': json['technicalCode'],
        'installationCode': json['installationCode'],
        'ippc': json['ippc'],
        'name': json['name'],
        'note': json['note'],
        'address': AddressCodelistItemInputFromJSON(json['address']),
        'geographicData': GeographicDataFromJSON(json['geographicData']),
        'naceType': CodelistItemInputFromJSON(json['naceType']),
        'nace2Type': CodelistItemInputFromJSON(json['nace2Type']),
        'operatorId': json['operatorId'],
        'code': json['code'],
        'contactPerson': (json['contactPerson'] === null ? null : (json['contactPerson'] as Array<any>).map(ContactPersonFromJSON)),
    };
}

export function InstallationUpdateToJSON(value?: InstallationUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'technicalCode': value.technicalCode,
        'installationCode': value.installationCode,
        'ippc': value.ippc,
        'name': value.name,
        'note': value.note,
        'address': AddressCodelistItemInputToJSON(value.address),
        'geographicData': GeographicDataToJSON(value.geographicData),
        'naceType': CodelistItemInputToJSON(value.naceType),
        'nace2Type': CodelistItemInputToJSON(value.nace2Type),
        'operatorId': value.operatorId,
        'code': value.code,
        'contactPerson': (value.contactPerson === null ? null : (value.contactPerson as Array<any>).map(ContactPersonToJSON)),
    };
}

