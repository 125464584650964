/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NewOperatorRequestRecord
 */
export interface NewOperatorRequestRecord {
    /**
     * 
     * @type {number}
     * @memberof NewOperatorRequestRecord
     */
    id?: number | null;
    /**
     * 
     * @type {string}
     * @memberof NewOperatorRequestRecord
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NewOperatorRequestRecord
     */
    address?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NewOperatorRequestRecord
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NewOperatorRequestRecord
     */
    cityCode?: number | null;
    /**
     * 
     * @type {string}
     * @memberof NewOperatorRequestRecord
     */
    zip?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NewOperatorRequestRecord
     */
    ico?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NewOperatorRequestRecord
     */
    dic?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NewOperatorRequestRecord
     */
    note?: string | null;
}

/**
 * Check if a given object implements the NewOperatorRequestRecord interface.
 */
export function instanceOfNewOperatorRequestRecord(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function NewOperatorRequestRecordFromJSON(json: any): NewOperatorRequestRecord {
    return NewOperatorRequestRecordFromJSONTyped(json, false);
}

export function NewOperatorRequestRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewOperatorRequestRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'cityCode': !exists(json, 'cityCode') ? undefined : json['cityCode'],
        'zip': !exists(json, 'zip') ? undefined : json['zip'],
        'ico': !exists(json, 'ico') ? undefined : json['ico'],
        'dic': !exists(json, 'dic') ? undefined : json['dic'],
        'note': !exists(json, 'note') ? undefined : json['note'],
    };
}

export function NewOperatorRequestRecordToJSON(value?: NewOperatorRequestRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': value.type,
        'address': value.address,
        'name': value.name,
        'cityCode': value.cityCode,
        'zip': value.zip,
        'ico': value.ico,
        'dic': value.dic,
        'note': value.note,
    };
}

