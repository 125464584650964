/* tslint:disable */
/* eslint-disable */
/**
 * Catalog API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.31.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CodelistItemInput } from './CodelistItemInput';
import {
    CodelistItemInputFromJSON,
    CodelistItemInputFromJSONTyped,
    CodelistItemInputToJSON,
} from './CodelistItemInput';

/**
 * 
 * @export
 * @interface OtherCodelistItemInput
 */
export interface OtherCodelistItemInput {
    /**
     * 
     * @type {CodelistItemInput}
     * @memberof OtherCodelistItemInput
     */
    affectedType: CodelistItemInput | null;
    /**
     * 
     * @type {number}
     * @memberof OtherCodelistItemInput
     */
    altitude?: number;
    /**
     * 
     * @type {number}
     * @memberof OtherCodelistItemInput
     */
    area?: number;
    /**
     * 
     * @type {boolean}
     * @memberof OtherCodelistItemInput
     */
    fictionPlace: boolean | null;
    /**
     * 
     * @type {CodelistItemInput}
     * @memberof OtherCodelistItemInput
     */
    chvoType: CodelistItemInput | null;
    /**
     * 
     * @type {string}
     * @memberof OtherCodelistItemInput
     */
    internationalPlaceCode?: string;
    /**
     * 
     * @type {string}
     * @memberof OtherCodelistItemInput
     */
    replacePlace?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OtherCodelistItemInput
     */
    shs: boolean | null;
}

/**
 * Check if a given object implements the OtherCodelistItemInput interface.
 */
export function instanceOfOtherCodelistItemInput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "affectedType" in value;
    isInstance = isInstance && "fictionPlace" in value;
    isInstance = isInstance && "chvoType" in value;
    isInstance = isInstance && "shs" in value;

    return isInstance;
}

export function OtherCodelistItemInputFromJSON(json: any): OtherCodelistItemInput {
    return OtherCodelistItemInputFromJSONTyped(json, false);
}

export function OtherCodelistItemInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): OtherCodelistItemInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'affectedType': CodelistItemInputFromJSON(json['affectedType']),
        'altitude': !exists(json, 'altitude') ? undefined : json['altitude'],
        'area': !exists(json, 'area') ? undefined : json['area'],
        'fictionPlace': json['fictionPlace'],
        'chvoType': CodelistItemInputFromJSON(json['chvoType']),
        'internationalPlaceCode': !exists(json, 'internationalPlaceCode') ? undefined : json['internationalPlaceCode'],
        'replacePlace': !exists(json, 'replacePlace') ? undefined : json['replacePlace'],
        'shs': json['shs'],
    };
}

export function OtherCodelistItemInputToJSON(value?: OtherCodelistItemInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'affectedType': CodelistItemInputToJSON(value.affectedType),
        'altitude': value.altitude,
        'area': value.area,
        'fictionPlace': value.fictionPlace,
        'chvoType': CodelistItemInputToJSON(value.chvoType),
        'internationalPlaceCode': value.internationalPlaceCode,
        'replacePlace': value.replacePlace,
        'shs': value.shs,
    };
}

