/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CodeListItemBasicRecord } from './CodeListItemBasicRecord';
import {
    CodeListItemBasicRecordFromJSON,
    CodeListItemBasicRecordFromJSONTyped,
    CodeListItemBasicRecordToJSON,
} from './CodeListItemBasicRecord';

/**
 * 
 * @export
 * @interface WaterStructDamResponseRecord
 */
export interface WaterStructDamResponseRecord {
    /**
     * 
     * @type {number}
     * @memberof WaterStructDamResponseRecord
     */
    id?: number | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof WaterStructDamResponseRecord
     */
    type?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof WaterStructDamResponseRecord
     */
    shape?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof WaterStructDamResponseRecord
     */
    structure?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof WaterStructDamResponseRecord
     */
    seal?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructDamResponseRecord
     */
    altitude?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructDamResponseRecord
     */
    height?: number | null;
}

/**
 * Check if a given object implements the WaterStructDamResponseRecord interface.
 */
export function instanceOfWaterStructDamResponseRecord(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WaterStructDamResponseRecordFromJSON(json: any): WaterStructDamResponseRecord {
    return WaterStructDamResponseRecordFromJSONTyped(json, false);
}

export function WaterStructDamResponseRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): WaterStructDamResponseRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'type': !exists(json, 'type') ? undefined : CodeListItemBasicRecordFromJSON(json['type']),
        'shape': !exists(json, 'shape') ? undefined : CodeListItemBasicRecordFromJSON(json['shape']),
        'structure': !exists(json, 'structure') ? undefined : CodeListItemBasicRecordFromJSON(json['structure']),
        'seal': !exists(json, 'seal') ? undefined : CodeListItemBasicRecordFromJSON(json['seal']),
        'altitude': !exists(json, 'altitude') ? undefined : json['altitude'],
        'height': !exists(json, 'height') ? undefined : json['height'],
    };
}

export function WaterStructDamResponseRecordToJSON(value?: WaterStructDamResponseRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': CodeListItemBasicRecordToJSON(value.type),
        'shape': CodeListItemBasicRecordToJSON(value.shape),
        'structure': CodeListItemBasicRecordToJSON(value.structure),
        'seal': CodeListItemBasicRecordToJSON(value.seal),
        'altitude': value.altitude,
        'height': value.height,
    };
}

