/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface HydroAnalysisFloodPlainUseRequestRecord
 */
export interface HydroAnalysisFloodPlainUseRequestRecord {
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisFloodPlainUseRequestRecord
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisFloodPlainUseRequestRecord
     */
    floodPlainUseItemCode?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisFloodPlainUseRequestRecord
     */
    floodPlainUseCount?: number;
}

/**
 * Check if a given object implements the HydroAnalysisFloodPlainUseRequestRecord interface.
 */
export function instanceOfHydroAnalysisFloodPlainUseRequestRecord(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function HydroAnalysisFloodPlainUseRequestRecordFromJSON(json: any): HydroAnalysisFloodPlainUseRequestRecord {
    return HydroAnalysisFloodPlainUseRequestRecordFromJSONTyped(json, false);
}

export function HydroAnalysisFloodPlainUseRequestRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): HydroAnalysisFloodPlainUseRequestRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'floodPlainUseItemCode': !exists(json, 'floodPlainUseItemCode') ? undefined : json['floodPlainUseItemCode'],
        'floodPlainUseCount': !exists(json, 'floodPlainUseCount') ? undefined : json['floodPlainUseCount'],
    };
}

export function HydroAnalysisFloodPlainUseRequestRecordToJSON(value?: HydroAnalysisFloodPlainUseRequestRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'floodPlainUseItemCode': value.floodPlainUseItemCode,
        'floodPlainUseCount': value.floodPlainUseCount,
    };
}

