/* tslint:disable */
/* eslint-disable */
/**
 * Catalog API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.31.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CodelistItemInput } from './CodelistItemInput';
import {
    CodelistItemInputFromJSON,
    CodelistItemInputFromJSONTyped,
    CodelistItemInputToJSON,
} from './CodelistItemInput';

/**
 * 
 * @export
 * @interface Methodic
 */
export interface Methodic {
    /**
     * 
     * @type {number}
     * @memberof Methodic
     */
    sensibility: number | null;
    /**
     * 
     * @type {number}
     * @memberof Methodic
     */
    detectionLimit: number | null;
    /**
     * 
     * @type {number}
     * @memberof Methodic
     */
    uncertaintyFmt: number | null;
    /**
     * 
     * @type {CodelistItemInput}
     * @memberof Methodic
     */
    institutionType: CodelistItemInput | null;
    /**
     * 
     * @type {string}
     * @memberof Methodic
     */
    laboratory: string | null;
    /**
     * 
     * @type {number}
     * @memberof Methodic
     */
    limitValue: number | null;
    /**
     * 
     * @type {CodelistItemInput}
     * @memberof Methodic
     */
    anMethodType: CodelistItemInput | null;
    /**
     * 
     * @type {CodelistItemInput}
     * @memberof Methodic
     */
    anMethodGroupType: CodelistItemInput | null;
    /**
     * 
     * @type {number}
     * @memberof Methodic
     */
    uncertainty: number | null;
    /**
     * 
     * @type {string}
     * @memberof Methodic
     */
    refToLiterature: string | null;
    /**
     * 
     * @type {number}
     * @memberof Methodic
     */
    sensibilityValid: number;
    /**
     * 
     * @type {number}
     * @memberof Methodic
     */
    limitValueValid: number;
    /**
     * 
     * @type {string}
     * @memberof Methodic
     */
    note: string | null;
    /**
     * 
     * @type {CodelistItemInput}
     * @memberof Methodic
     */
    determinantType: CodelistItemInput | null;
    /**
     * 
     * @type {number}
     * @memberof Methodic
     */
    shmuId: number | null;
    /**
     * 
     * @type {string}
     * @memberof Methodic
     */
    effectiveTo: string | null;
    /**
     * 
     * @type {string}
     * @memberof Methodic
     */
    effectiveFrom: string | null;
}

/**
 * Check if a given object implements the Methodic interface.
 */
export function instanceOfMethodic(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "sensibility" in value;
    isInstance = isInstance && "detectionLimit" in value;
    isInstance = isInstance && "uncertaintyFmt" in value;
    isInstance = isInstance && "institutionType" in value;
    isInstance = isInstance && "laboratory" in value;
    isInstance = isInstance && "limitValue" in value;
    isInstance = isInstance && "anMethodType" in value;
    isInstance = isInstance && "anMethodGroupType" in value;
    isInstance = isInstance && "uncertainty" in value;
    isInstance = isInstance && "refToLiterature" in value;
    isInstance = isInstance && "sensibilityValid" in value;
    isInstance = isInstance && "limitValueValid" in value;
    isInstance = isInstance && "note" in value;
    isInstance = isInstance && "determinantType" in value;
    isInstance = isInstance && "shmuId" in value;
    isInstance = isInstance && "effectiveTo" in value;
    isInstance = isInstance && "effectiveFrom" in value;

    return isInstance;
}

export function MethodicFromJSON(json: any): Methodic {
    return MethodicFromJSONTyped(json, false);
}

export function MethodicFromJSONTyped(json: any, ignoreDiscriminator: boolean): Methodic {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sensibility': json['sensibility'],
        'detectionLimit': json['detectionLimit'],
        'uncertaintyFmt': json['uncertaintyFmt'],
        'institutionType': CodelistItemInputFromJSON(json['institutionType']),
        'laboratory': json['laboratory'],
        'limitValue': json['limitValue'],
        'anMethodType': CodelistItemInputFromJSON(json['anMethodType']),
        'anMethodGroupType': CodelistItemInputFromJSON(json['anMethodGroupType']),
        'uncertainty': json['uncertainty'],
        'refToLiterature': json['refToLiterature'],
        'sensibilityValid': json['sensibilityValid'],
        'limitValueValid': json['limitValueValid'],
        'note': json['note'],
        'determinantType': CodelistItemInputFromJSON(json['determinantType']),
        'shmuId': json['shmuId'],
        'effectiveTo': json['effectiveTo'],
        'effectiveFrom': json['effectiveFrom'],
    };
}

export function MethodicToJSON(value?: Methodic | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sensibility': value.sensibility,
        'detectionLimit': value.detectionLimit,
        'uncertaintyFmt': value.uncertaintyFmt,
        'institutionType': CodelistItemInputToJSON(value.institutionType),
        'laboratory': value.laboratory,
        'limitValue': value.limitValue,
        'anMethodType': CodelistItemInputToJSON(value.anMethodType),
        'anMethodGroupType': CodelistItemInputToJSON(value.anMethodGroupType),
        'uncertainty': value.uncertainty,
        'refToLiterature': value.refToLiterature,
        'sensibilityValid': value.sensibilityValid,
        'limitValueValid': value.limitValueValid,
        'note': value.note,
        'determinantType': CodelistItemInputToJSON(value.determinantType),
        'shmuId': value.shmuId,
        'effectiveTo': value.effectiveTo,
        'effectiveFrom': value.effectiveFrom,
    };
}

