/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface QualityAssessmentDeterminantRecord
 */
export interface QualityAssessmentDeterminantRecord {
    /**
     * 
     * @type {number}
     * @memberof QualityAssessmentDeterminantRecord
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof QualityAssessmentDeterminantRecord
     */
    determinantItemCode?: number;
    /**
     * 
     * @type {number}
     * @memberof QualityAssessmentDeterminantRecord
     */
    unitItemCode?: number;
    /**
     * 
     * @type {number}
     * @memberof QualityAssessmentDeterminantRecord
     */
    source?: number;
    /**
     * 
     * @type {string}
     * @memberof QualityAssessmentDeterminantRecord
     */
    value?: string;
    /**
     * 
     * @type {string}
     * @memberof QualityAssessmentDeterminantRecord
     */
    value2?: string;
    /**
     * 
     * @type {string}
     * @memberof QualityAssessmentDeterminantRecord
     */
    conditionOperator?: QualityAssessmentDeterminantRecordConditionOperatorEnum;
    /**
     * 
     * @type {string}
     * @memberof QualityAssessmentDeterminantRecord
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof QualityAssessmentDeterminantRecord
     */
    createdTimestamp?: string;
    /**
     * 
     * @type {string}
     * @memberof QualityAssessmentDeterminantRecord
     */
    updatedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof QualityAssessmentDeterminantRecord
     */
    updatedTimestamp?: string;
}


/**
 * @export
 */
export const QualityAssessmentDeterminantRecordConditionOperatorEnum = {
    Lt: 'LT',
    Le: 'LE',
    Eq: 'EQ',
    Ne: 'NE',
    Ge: 'GE',
    Gt: 'GT',
    In: 'IN',
    Between: 'BETWEEN'
} as const;
export type QualityAssessmentDeterminantRecordConditionOperatorEnum = typeof QualityAssessmentDeterminantRecordConditionOperatorEnum[keyof typeof QualityAssessmentDeterminantRecordConditionOperatorEnum];


/**
 * Check if a given object implements the QualityAssessmentDeterminantRecord interface.
 */
export function instanceOfQualityAssessmentDeterminantRecord(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function QualityAssessmentDeterminantRecordFromJSON(json: any): QualityAssessmentDeterminantRecord {
    return QualityAssessmentDeterminantRecordFromJSONTyped(json, false);
}

export function QualityAssessmentDeterminantRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): QualityAssessmentDeterminantRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'determinantItemCode': !exists(json, 'determinantItemCode') ? undefined : json['determinantItemCode'],
        'unitItemCode': !exists(json, 'unitItemCode') ? undefined : json['unitItemCode'],
        'source': !exists(json, 'source') ? undefined : json['source'],
        'value': !exists(json, 'value') ? undefined : json['value'],
        'value2': !exists(json, 'value2') ? undefined : json['value2'],
        'conditionOperator': !exists(json, 'conditionOperator') ? undefined : json['conditionOperator'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'createdTimestamp': !exists(json, 'createdTimestamp') ? undefined : json['createdTimestamp'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
        'updatedTimestamp': !exists(json, 'updatedTimestamp') ? undefined : json['updatedTimestamp'],
    };
}

export function QualityAssessmentDeterminantRecordToJSON(value?: QualityAssessmentDeterminantRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'determinantItemCode': value.determinantItemCode,
        'unitItemCode': value.unitItemCode,
        'source': value.source,
        'value': value.value,
        'value2': value.value2,
        'conditionOperator': value.conditionOperator,
        'createdBy': value.createdBy,
        'createdTimestamp': value.createdTimestamp,
        'updatedBy': value.updatedBy,
        'updatedTimestamp': value.updatedTimestamp,
    };
}

