/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CodeListItemBasicRecord } from './CodeListItemBasicRecord';
import {
    CodeListItemBasicRecordFromJSON,
    CodeListItemBasicRecordFromJSONTyped,
    CodeListItemBasicRecordToJSON,
} from './CodeListItemBasicRecord';

/**
 * 
 * @export
 * @interface HydroAnalysisFileResponseRecord
 */
export interface HydroAnalysisFileResponseRecord {
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisFileResponseRecord
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof HydroAnalysisFileResponseRecord
     */
    fileUrl?: string;
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisFileResponseRecord
     */
    fileId?: number;
    /**
     * 
     * @type {string}
     * @memberof HydroAnalysisFileResponseRecord
     */
    fileName?: string;
    /**
     * 
     * @type {string}
     * @memberof HydroAnalysisFileResponseRecord
     */
    hydromorphologicalDate?: string;
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisFileResponseRecord
     */
    measueredFlow?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisFileResponseRecord
     */
    flowArea?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisFileResponseRecord
     */
    bankfullWidth?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisFileResponseRecord
     */
    averageProfileSpeed?: number;
    /**
     * 
     * @type {string}
     * @memberof HydroAnalysisFileResponseRecord
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof HydroAnalysisFileResponseRecord
     */
    createdTimestamp?: string;
    /**
     * 
     * @type {string}
     * @memberof HydroAnalysisFileResponseRecord
     */
    updatedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof HydroAnalysisFileResponseRecord
     */
    updatedTimestamp?: string;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof HydroAnalysisFileResponseRecord
     */
    statusItem?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof HydroAnalysisFileResponseRecord
     */
    fileTypeItem?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {string}
     * @memberof HydroAnalysisFileResponseRecord
     */
    fileMappingKey?: string;
}

/**
 * Check if a given object implements the HydroAnalysisFileResponseRecord interface.
 */
export function instanceOfHydroAnalysisFileResponseRecord(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function HydroAnalysisFileResponseRecordFromJSON(json: any): HydroAnalysisFileResponseRecord {
    return HydroAnalysisFileResponseRecordFromJSONTyped(json, false);
}

export function HydroAnalysisFileResponseRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): HydroAnalysisFileResponseRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'fileUrl': !exists(json, 'fileUrl') ? undefined : json['fileUrl'],
        'fileId': !exists(json, 'fileId') ? undefined : json['fileId'],
        'fileName': !exists(json, 'fileName') ? undefined : json['fileName'],
        'hydromorphologicalDate': !exists(json, 'hydromorphologicalDate') ? undefined : json['hydromorphologicalDate'],
        'measueredFlow': !exists(json, 'measueredFlow') ? undefined : json['measueredFlow'],
        'flowArea': !exists(json, 'flowArea') ? undefined : json['flowArea'],
        'bankfullWidth': !exists(json, 'bankfullWidth') ? undefined : json['bankfullWidth'],
        'averageProfileSpeed': !exists(json, 'averageProfileSpeed') ? undefined : json['averageProfileSpeed'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'createdTimestamp': !exists(json, 'createdTimestamp') ? undefined : json['createdTimestamp'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
        'updatedTimestamp': !exists(json, 'updatedTimestamp') ? undefined : json['updatedTimestamp'],
        'statusItem': !exists(json, 'statusItem') ? undefined : CodeListItemBasicRecordFromJSON(json['statusItem']),
        'fileTypeItem': !exists(json, 'fileTypeItem') ? undefined : CodeListItemBasicRecordFromJSON(json['fileTypeItem']),
        'fileMappingKey': !exists(json, 'fileMappingKey') ? undefined : json['fileMappingKey'],
    };
}

export function HydroAnalysisFileResponseRecordToJSON(value?: HydroAnalysisFileResponseRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'fileUrl': value.fileUrl,
        'fileId': value.fileId,
        'fileName': value.fileName,
        'hydromorphologicalDate': value.hydromorphologicalDate,
        'measueredFlow': value.measueredFlow,
        'flowArea': value.flowArea,
        'bankfullWidth': value.bankfullWidth,
        'averageProfileSpeed': value.averageProfileSpeed,
        'createdBy': value.createdBy,
        'createdTimestamp': value.createdTimestamp,
        'updatedBy': value.updatedBy,
        'updatedTimestamp': value.updatedTimestamp,
        'statusItem': CodeListItemBasicRecordToJSON(value.statusItem),
        'fileTypeItem': CodeListItemBasicRecordToJSON(value.fileTypeItem),
        'fileMappingKey': value.fileMappingKey,
    };
}

