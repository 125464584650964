import { FetchSearchSoStatusEnum } from '../openapi/overview';
import {
    getLocalizedBoolean,
    getLocalizedEncoding,
    getLocalizedFileType,
    getLocalizedFixedCodeItemAttributes,
    getLocalizedOverviewStatus,
    getLocalizedSeparator
} from './bundle';
import { EncodingEnum, FileTypeEnum, FixedCodeitemAttributesEnum, SeparatorEnum } from './enums';

// Konštanty
export const BOOLEAN_OPTIONS = [
    { name: getLocalizedBoolean(true), id: 'true' },
    { name: getLocalizedBoolean(false), id: 'false' }
];
export const BOOLEAN_OPTIONS_WITH_NULL = [...BOOLEAN_OPTIONS, { name: getLocalizedBoolean(null), id: 'null' }];
export const OVERVIEW_STATUS_OPTIONS = [
    { name: getLocalizedOverviewStatus(FetchSearchSoStatusEnum.None), id: FetchSearchSoStatusEnum.None },
    { name: getLocalizedOverviewStatus(FetchSearchSoStatusEnum.DownloadQueued), id: FetchSearchSoStatusEnum.DownloadQueued },
    { name: getLocalizedOverviewStatus(FetchSearchSoStatusEnum.DownloadInProgress), id: FetchSearchSoStatusEnum.DownloadInProgress },
    { name: getLocalizedOverviewStatus(FetchSearchSoStatusEnum.DownloadFailed), id: FetchSearchSoStatusEnum.DownloadFailed },
    { name: getLocalizedOverviewStatus(FetchSearchSoStatusEnum.Downloaded), id: FetchSearchSoStatusEnum.Downloaded },
    { name: getLocalizedOverviewStatus(FetchSearchSoStatusEnum.Modified), id: FetchSearchSoStatusEnum.Modified },
    { name: getLocalizedOverviewStatus(FetchSearchSoStatusEnum.StandaloneValidated), id: FetchSearchSoStatusEnum.StandaloneValidated },
    {
        name: getLocalizedOverviewStatus(FetchSearchSoStatusEnum.StandaloneValidationFailed),
        id: FetchSearchSoStatusEnum.StandaloneValidationFailed
    },
    {
        name: getLocalizedOverviewStatus(FetchSearchSoStatusEnum.StandaloneValidationInProgress),
        id: FetchSearchSoStatusEnum.StandaloneValidationInProgress
    },
    {
        name: getLocalizedOverviewStatus(FetchSearchSoStatusEnum.StandaloneValidationQueued),
        id: FetchSearchSoStatusEnum.StandaloneValidationQueued
    },
    { name: getLocalizedOverviewStatus(FetchSearchSoStatusEnum.UpToDateCheckQueued), id: FetchSearchSoStatusEnum.UpToDateCheckQueued },
    {
        name: getLocalizedOverviewStatus(FetchSearchSoStatusEnum.UpToDateCheckInProgress),
        id: FetchSearchSoStatusEnum.UpToDateCheckInProgress
    },
    { name: getLocalizedOverviewStatus(FetchSearchSoStatusEnum.UpToDateCheckFailed), id: FetchSearchSoStatusEnum.UpToDateCheckFailed },
    { name: getLocalizedOverviewStatus(FetchSearchSoStatusEnum.SavingToDrQueued), id: FetchSearchSoStatusEnum.SavingToDrQueued },
    { name: getLocalizedOverviewStatus(FetchSearchSoStatusEnum.SavingToDrInProgress), id: FetchSearchSoStatusEnum.SavingToDrInProgress },
    { name: getLocalizedOverviewStatus(FetchSearchSoStatusEnum.SavingToDrFailed), id: FetchSearchSoStatusEnum.SavingToDrFailed },
    { name: getLocalizedOverviewStatus(FetchSearchSoStatusEnum.SavedToDr), id: FetchSearchSoStatusEnum.SavedToDr }
];
export const SEPARATOR_OPTIONS = Object.values(SeparatorEnum).map((sep) => ({
    id: sep,
    name: getLocalizedSeparator(sep)
}));
export const FILE_TYPE_OPTIONS = Object.values(FileTypeEnum).map((fileType) => ({
    id: fileType,
    name: getLocalizedFileType(fileType)
}));

export const ENCODING_OPTIONS = Object.values(EncodingEnum).map((encoding) => ({
    id: encoding,
    name: getLocalizedEncoding(encoding)
}));
export const BASIC_DEPEND_ATTRIBUTE_OPTIONS = Object.values(FixedCodeitemAttributesEnum).map((att) => ({
    id: att,
    label: getLocalizedFixedCodeItemAttributes(att)
}));
export const REFRESH_INTERVAL = 10000;
export const MAX_POSSIBLE_PAGE_SIZE = 1000000;
export const PAGE_START = 0;
export const CODELISTITEM_FIXED_LENGHT = 6;
export const NUMBER_OF_ENVIRONMENT_LEVELS = 15;

// Premenné prehľadov
export const OVERVIEW_MONITORING_POINT_COLUMN = 'monitoring_point_id';
export const OVERVIEW_COMMENT_COLUMN_PREFIX = 'comment_for_';
export const OVERVIEW_DR_INTERNAL_COLUMN_PREFIX = 'source_id_';
export const OVERVIEW_SOURCE_MONITORING_POINT_PREFIX = 'MONITORING_POINT';
export const OVERVIEW_SOURCE_CODELIST_PREFIX = 'CODELIST';
export const OVERVIEW_SOURCE_DMS_PREFIX = 'DMS';
export const OVERVIEW_SOURCE_ENVIRONMENT_PREFIX = 'ENVIRONMENT';
export const OVERVIEW_SOURCE_COLUMN_DMS_FILE_NOTE = 'note';
export const NOTE_FIELD = '___note';

// Premenné importov
export const ROW_NUMBER_FIELD = '___row_number';

// Špeciálne stĺpce tabuľky
export const CHECKBOX_COLUMN = '___checkbox';
export const ACTIONS_COLUMN = '___actions';
export const STATUS_COLUMN = '___status';
