/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CodeListItemBasicRecord } from './CodeListItemBasicRecord';
import {
    CodeListItemBasicRecordFromJSON,
    CodeListItemBasicRecordFromJSONTyped,
    CodeListItemBasicRecordToJSON,
} from './CodeListItemBasicRecord';

/**
 * 
 * @export
 * @interface HydroAnalysisSoilTypeResponseRecord
 */
export interface HydroAnalysisSoilTypeResponseRecord {
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisSoilTypeResponseRecord
     */
    id?: number;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof HydroAnalysisSoilTypeResponseRecord
     */
    soilTypeItem?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {boolean}
     * @memberof HydroAnalysisSoilTypeResponseRecord
     */
    prevailing?: boolean;
}

/**
 * Check if a given object implements the HydroAnalysisSoilTypeResponseRecord interface.
 */
export function instanceOfHydroAnalysisSoilTypeResponseRecord(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function HydroAnalysisSoilTypeResponseRecordFromJSON(json: any): HydroAnalysisSoilTypeResponseRecord {
    return HydroAnalysisSoilTypeResponseRecordFromJSONTyped(json, false);
}

export function HydroAnalysisSoilTypeResponseRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): HydroAnalysisSoilTypeResponseRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'soilTypeItem': !exists(json, 'soilTypeItem') ? undefined : CodeListItemBasicRecordFromJSON(json['soilTypeItem']),
        'prevailing': !exists(json, 'prevailing') ? undefined : json['prevailing'],
    };
}

export function HydroAnalysisSoilTypeResponseRecordToJSON(value?: HydroAnalysisSoilTypeResponseRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'soilTypeItem': CodeListItemBasicRecordToJSON(value.soilTypeItem),
        'prevailing': value.prevailing,
    };
}

