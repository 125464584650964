/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CodeListItemBasicRecord } from './CodeListItemBasicRecord';
import {
    CodeListItemBasicRecordFromJSON,
    CodeListItemBasicRecordFromJSONTyped,
    CodeListItemBasicRecordToJSON,
} from './CodeListItemBasicRecord';
import type { DangerSubstanceResponseRecord } from './DangerSubstanceResponseRecord';
import {
    DangerSubstanceResponseRecordFromJSON,
    DangerSubstanceResponseRecordFromJSONTyped,
    DangerSubstanceResponseRecordToJSON,
} from './DangerSubstanceResponseRecord';
import type { EnvironmentRecord } from './EnvironmentRecord';
import {
    EnvironmentRecordFromJSON,
    EnvironmentRecordFromJSONTyped,
    EnvironmentRecordToJSON,
} from './EnvironmentRecord';
import type { MonitoringRecord } from './MonitoringRecord';
import {
    MonitoringRecordFromJSON,
    MonitoringRecordFromJSONTyped,
    MonitoringRecordToJSON,
} from './MonitoringRecord';
import type { NewPlaceResponseRecord } from './NewPlaceResponseRecord';
import {
    NewPlaceResponseRecordFromJSON,
    NewPlaceResponseRecordFromJSONTyped,
    NewPlaceResponseRecordToJSON,
} from './NewPlaceResponseRecord';
import type { QualityTabResponseRecord } from './QualityTabResponseRecord';
import {
    QualityTabResponseRecordFromJSON,
    QualityTabResponseRecordFromJSONTyped,
    QualityTabResponseRecordToJSON,
} from './QualityTabResponseRecord';
import type { WaterEvidenceOriginAppendixRecord } from './WaterEvidenceOriginAppendixRecord';
import {
    WaterEvidenceOriginAppendixRecordFromJSON,
    WaterEvidenceOriginAppendixRecordFromJSONTyped,
    WaterEvidenceOriginAppendixRecordToJSON,
} from './WaterEvidenceOriginAppendixRecord';
import type { WaterEvidenceOriginRecord } from './WaterEvidenceOriginRecord';
import {
    WaterEvidenceOriginRecordFromJSON,
    WaterEvidenceOriginRecordFromJSONTyped,
    WaterEvidenceOriginRecordToJSON,
} from './WaterEvidenceOriginRecord';

/**
 * 
 * @export
 * @interface WaterEvidenceAppendixResponseRecord
 */
export interface WaterEvidenceAppendixResponseRecord {
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    id?: number | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    documentType?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {WaterEvidenceOriginRecord}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    changedByDocument?: WaterEvidenceOriginRecord | null;
    /**
     * 
     * @type {WaterEvidenceOriginAppendixRecord}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    originAppendix?: WaterEvidenceOriginAppendixRecord | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    dateFrom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    dateTo?: string | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    status?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {MonitoringRecord}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    place?: MonitoringRecord | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    placeDesc?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    placeName?: string | null;
    /**
     * 
     * @type {EnvironmentRecord}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    stream?: EnvironmentRecord | null;
    /**
     * 
     * @type {EnvironmentRecord}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    recStream?: EnvironmentRecord | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    riverKm?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    recRiverKm?: number | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    position?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    waterUse?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    waterOrigin?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    waterStruct?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    objectType?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    objectName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    objectAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    objectCity?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    objectZip?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    qtyMinBalFlow?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    qtyMinLevel?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    qtyMaxLevel?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    qtyAvFlow?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    qtyMaxFlow?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    qtyYearFlow?: number | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    qtyMeasUnit?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    qtyYear?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    qtyMonth?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    qtyDay?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    qtyMaxFall?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    qtyVolume?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    numMonths?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    numDays?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    numHours?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    qtyQuantAcc?: number | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    measMethod?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    measDevice?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    measFreqYr?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    measFreqQt?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    measFreqQl?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    quantWater?: number | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    waterCat?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    samplePlace?: string | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    waterType?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    tempDecSt?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    tempDecWt?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    maxTempDec?: number | null;
    /**
     * 
     * @type {MonitoringRecord}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    recPlace?: MonitoringRecord | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    recType?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    sourceSize?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    protArea?: boolean | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    sludgeHand?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    sludgeStab?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    sludgeDrain?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {boolean}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    janPerm?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    janQ?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    febPerm?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    febQ?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    marPerm?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    marQ?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    aprPerm?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    aprQ?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    mayPerm?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    mayQ?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    junPerm?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    junQ?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    julPerm?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    julQ?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    augPerm?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    augQ?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    sepPerm?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    sepQ?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    octPerm?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    octQ?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    novPerm?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    novQ?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    decPerm?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    decQ?: number | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    note?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    mainName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    mainCity?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    mainDescription?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    permDesc?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    createdTimeStamp?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    createdBy?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    updatedTimestamp?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    updatedBy?: string | null;
    /**
     * 
     * @type {Array<CodeListItemBasicRecord>}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    sampleTypes?: Array<CodeListItemBasicRecord> | null;
    /**
     * 
     * @type {Array<CodeListItemBasicRecord>}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    purifMethods?: Array<CodeListItemBasicRecord> | null;
    /**
     * 
     * @type {Array<QualityTabResponseRecord>}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    qualityTabRecords?: Array<QualityTabResponseRecord> | null;
    /**
     * 
     * @type {Array<DangerSubstanceResponseRecord>}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    dangerSubstanceRecords?: Array<DangerSubstanceResponseRecord> | null;
    /**
     * 
     * @type {NewPlaceResponseRecord}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    newPlace?: NewPlaceResponseRecord | null;
    /**
     * 
     * @type {NewPlaceResponseRecord}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    newRecPlace?: NewPlaceResponseRecord | null;
    /**
     * 
     * @type {boolean}
     * @memberof WaterEvidenceAppendixResponseRecord
     */
    isPlaceWaitingForPairing?: boolean | null;
}

/**
 * Check if a given object implements the WaterEvidenceAppendixResponseRecord interface.
 */
export function instanceOfWaterEvidenceAppendixResponseRecord(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WaterEvidenceAppendixResponseRecordFromJSON(json: any): WaterEvidenceAppendixResponseRecord {
    return WaterEvidenceAppendixResponseRecordFromJSONTyped(json, false);
}

export function WaterEvidenceAppendixResponseRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): WaterEvidenceAppendixResponseRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'documentType': !exists(json, 'documentType') ? undefined : CodeListItemBasicRecordFromJSON(json['documentType']),
        'changedByDocument': !exists(json, 'changedByDocument') ? undefined : WaterEvidenceOriginRecordFromJSON(json['changedByDocument']),
        'originAppendix': !exists(json, 'originAppendix') ? undefined : WaterEvidenceOriginAppendixRecordFromJSON(json['originAppendix']),
        'dateFrom': !exists(json, 'dateFrom') ? undefined : json['dateFrom'],
        'dateTo': !exists(json, 'dateTo') ? undefined : json['dateTo'],
        'status': !exists(json, 'status') ? undefined : CodeListItemBasicRecordFromJSON(json['status']),
        'place': !exists(json, 'place') ? undefined : MonitoringRecordFromJSON(json['place']),
        'placeDesc': !exists(json, 'placeDesc') ? undefined : json['placeDesc'],
        'placeName': !exists(json, 'placeName') ? undefined : json['placeName'],
        'stream': !exists(json, 'stream') ? undefined : EnvironmentRecordFromJSON(json['stream']),
        'recStream': !exists(json, 'recStream') ? undefined : EnvironmentRecordFromJSON(json['recStream']),
        'riverKm': !exists(json, 'riverKm') ? undefined : json['riverKm'],
        'recRiverKm': !exists(json, 'recRiverKm') ? undefined : json['recRiverKm'],
        'position': !exists(json, 'position') ? undefined : CodeListItemBasicRecordFromJSON(json['position']),
        'waterUse': !exists(json, 'waterUse') ? undefined : CodeListItemBasicRecordFromJSON(json['waterUse']),
        'waterOrigin': !exists(json, 'waterOrigin') ? undefined : CodeListItemBasicRecordFromJSON(json['waterOrigin']),
        'waterStruct': !exists(json, 'waterStruct') ? undefined : CodeListItemBasicRecordFromJSON(json['waterStruct']),
        'objectType': !exists(json, 'objectType') ? undefined : CodeListItemBasicRecordFromJSON(json['objectType']),
        'objectName': !exists(json, 'objectName') ? undefined : json['objectName'],
        'objectAddress': !exists(json, 'objectAddress') ? undefined : json['objectAddress'],
        'objectCity': !exists(json, 'objectCity') ? undefined : json['objectCity'],
        'objectZip': !exists(json, 'objectZip') ? undefined : json['objectZip'],
        'qtyMinBalFlow': !exists(json, 'qtyMinBalFlow') ? undefined : json['qtyMinBalFlow'],
        'qtyMinLevel': !exists(json, 'qtyMinLevel') ? undefined : json['qtyMinLevel'],
        'qtyMaxLevel': !exists(json, 'qtyMaxLevel') ? undefined : json['qtyMaxLevel'],
        'qtyAvFlow': !exists(json, 'qtyAvFlow') ? undefined : json['qtyAvFlow'],
        'qtyMaxFlow': !exists(json, 'qtyMaxFlow') ? undefined : json['qtyMaxFlow'],
        'qtyYearFlow': !exists(json, 'qtyYearFlow') ? undefined : json['qtyYearFlow'],
        'qtyMeasUnit': !exists(json, 'qtyMeasUnit') ? undefined : CodeListItemBasicRecordFromJSON(json['qtyMeasUnit']),
        'qtyYear': !exists(json, 'qtyYear') ? undefined : json['qtyYear'],
        'qtyMonth': !exists(json, 'qtyMonth') ? undefined : json['qtyMonth'],
        'qtyDay': !exists(json, 'qtyDay') ? undefined : json['qtyDay'],
        'qtyMaxFall': !exists(json, 'qtyMaxFall') ? undefined : json['qtyMaxFall'],
        'qtyVolume': !exists(json, 'qtyVolume') ? undefined : json['qtyVolume'],
        'numMonths': !exists(json, 'numMonths') ? undefined : json['numMonths'],
        'numDays': !exists(json, 'numDays') ? undefined : json['numDays'],
        'numHours': !exists(json, 'numHours') ? undefined : json['numHours'],
        'qtyQuantAcc': !exists(json, 'qtyQuantAcc') ? undefined : json['qtyQuantAcc'],
        'measMethod': !exists(json, 'measMethod') ? undefined : CodeListItemBasicRecordFromJSON(json['measMethod']),
        'measDevice': !exists(json, 'measDevice') ? undefined : json['measDevice'],
        'measFreqYr': !exists(json, 'measFreqYr') ? undefined : json['measFreqYr'],
        'measFreqQt': !exists(json, 'measFreqQt') ? undefined : json['measFreqQt'],
        'measFreqQl': !exists(json, 'measFreqQl') ? undefined : json['measFreqQl'],
        'quantWater': !exists(json, 'quantWater') ? undefined : json['quantWater'],
        'waterCat': !exists(json, 'waterCat') ? undefined : CodeListItemBasicRecordFromJSON(json['waterCat']),
        'samplePlace': !exists(json, 'samplePlace') ? undefined : json['samplePlace'],
        'waterType': !exists(json, 'waterType') ? undefined : CodeListItemBasicRecordFromJSON(json['waterType']),
        'tempDecSt': !exists(json, 'tempDecSt') ? undefined : json['tempDecSt'],
        'tempDecWt': !exists(json, 'tempDecWt') ? undefined : json['tempDecWt'],
        'maxTempDec': !exists(json, 'maxTempDec') ? undefined : json['maxTempDec'],
        'recPlace': !exists(json, 'recPlace') ? undefined : MonitoringRecordFromJSON(json['recPlace']),
        'recType': !exists(json, 'recType') ? undefined : CodeListItemBasicRecordFromJSON(json['recType']),
        'sourceSize': !exists(json, 'sourceSize') ? undefined : json['sourceSize'],
        'protArea': !exists(json, 'protArea') ? undefined : json['protArea'],
        'sludgeHand': !exists(json, 'sludgeHand') ? undefined : CodeListItemBasicRecordFromJSON(json['sludgeHand']),
        'sludgeStab': !exists(json, 'sludgeStab') ? undefined : CodeListItemBasicRecordFromJSON(json['sludgeStab']),
        'sludgeDrain': !exists(json, 'sludgeDrain') ? undefined : CodeListItemBasicRecordFromJSON(json['sludgeDrain']),
        'janPerm': !exists(json, 'janPerm') ? undefined : json['janPerm'],
        'janQ': !exists(json, 'janQ') ? undefined : json['janQ'],
        'febPerm': !exists(json, 'febPerm') ? undefined : json['febPerm'],
        'febQ': !exists(json, 'febQ') ? undefined : json['febQ'],
        'marPerm': !exists(json, 'marPerm') ? undefined : json['marPerm'],
        'marQ': !exists(json, 'marQ') ? undefined : json['marQ'],
        'aprPerm': !exists(json, 'aprPerm') ? undefined : json['aprPerm'],
        'aprQ': !exists(json, 'aprQ') ? undefined : json['aprQ'],
        'mayPerm': !exists(json, 'mayPerm') ? undefined : json['mayPerm'],
        'mayQ': !exists(json, 'mayQ') ? undefined : json['mayQ'],
        'junPerm': !exists(json, 'junPerm') ? undefined : json['junPerm'],
        'junQ': !exists(json, 'junQ') ? undefined : json['junQ'],
        'julPerm': !exists(json, 'julPerm') ? undefined : json['julPerm'],
        'julQ': !exists(json, 'julQ') ? undefined : json['julQ'],
        'augPerm': !exists(json, 'augPerm') ? undefined : json['augPerm'],
        'augQ': !exists(json, 'augQ') ? undefined : json['augQ'],
        'sepPerm': !exists(json, 'sepPerm') ? undefined : json['sepPerm'],
        'sepQ': !exists(json, 'sepQ') ? undefined : json['sepQ'],
        'octPerm': !exists(json, 'octPerm') ? undefined : json['octPerm'],
        'octQ': !exists(json, 'octQ') ? undefined : json['octQ'],
        'novPerm': !exists(json, 'novPerm') ? undefined : json['novPerm'],
        'novQ': !exists(json, 'novQ') ? undefined : json['novQ'],
        'decPerm': !exists(json, 'decPerm') ? undefined : json['decPerm'],
        'decQ': !exists(json, 'decQ') ? undefined : json['decQ'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'mainName': !exists(json, 'mainName') ? undefined : json['mainName'],
        'mainCity': !exists(json, 'mainCity') ? undefined : json['mainCity'],
        'mainDescription': !exists(json, 'mainDescription') ? undefined : json['mainDescription'],
        'permDesc': !exists(json, 'permDesc') ? undefined : json['permDesc'],
        'createdTimeStamp': !exists(json, 'createdTimeStamp') ? undefined : json['createdTimeStamp'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'updatedTimestamp': !exists(json, 'updatedTimestamp') ? undefined : json['updatedTimestamp'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
        'sampleTypes': !exists(json, 'sampleTypes') ? undefined : (json['sampleTypes'] === null ? null : (json['sampleTypes'] as Array<any>).map(CodeListItemBasicRecordFromJSON)),
        'purifMethods': !exists(json, 'purifMethods') ? undefined : (json['purifMethods'] === null ? null : (json['purifMethods'] as Array<any>).map(CodeListItemBasicRecordFromJSON)),
        'qualityTabRecords': !exists(json, 'qualityTabRecords') ? undefined : (json['qualityTabRecords'] === null ? null : (json['qualityTabRecords'] as Array<any>).map(QualityTabResponseRecordFromJSON)),
        'dangerSubstanceRecords': !exists(json, 'dangerSubstanceRecords') ? undefined : (json['dangerSubstanceRecords'] === null ? null : (json['dangerSubstanceRecords'] as Array<any>).map(DangerSubstanceResponseRecordFromJSON)),
        'newPlace': !exists(json, 'newPlace') ? undefined : NewPlaceResponseRecordFromJSON(json['newPlace']),
        'newRecPlace': !exists(json, 'newRecPlace') ? undefined : NewPlaceResponseRecordFromJSON(json['newRecPlace']),
        'isPlaceWaitingForPairing': !exists(json, 'isPlaceWaitingForPairing') ? undefined : json['isPlaceWaitingForPairing'],
    };
}

export function WaterEvidenceAppendixResponseRecordToJSON(value?: WaterEvidenceAppendixResponseRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'documentType': CodeListItemBasicRecordToJSON(value.documentType),
        'changedByDocument': WaterEvidenceOriginRecordToJSON(value.changedByDocument),
        'originAppendix': WaterEvidenceOriginAppendixRecordToJSON(value.originAppendix),
        'dateFrom': value.dateFrom,
        'dateTo': value.dateTo,
        'status': CodeListItemBasicRecordToJSON(value.status),
        'place': MonitoringRecordToJSON(value.place),
        'placeDesc': value.placeDesc,
        'placeName': value.placeName,
        'stream': EnvironmentRecordToJSON(value.stream),
        'recStream': EnvironmentRecordToJSON(value.recStream),
        'riverKm': value.riverKm,
        'recRiverKm': value.recRiverKm,
        'position': CodeListItemBasicRecordToJSON(value.position),
        'waterUse': CodeListItemBasicRecordToJSON(value.waterUse),
        'waterOrigin': CodeListItemBasicRecordToJSON(value.waterOrigin),
        'waterStruct': CodeListItemBasicRecordToJSON(value.waterStruct),
        'objectType': CodeListItemBasicRecordToJSON(value.objectType),
        'objectName': value.objectName,
        'objectAddress': value.objectAddress,
        'objectCity': value.objectCity,
        'objectZip': value.objectZip,
        'qtyMinBalFlow': value.qtyMinBalFlow,
        'qtyMinLevel': value.qtyMinLevel,
        'qtyMaxLevel': value.qtyMaxLevel,
        'qtyAvFlow': value.qtyAvFlow,
        'qtyMaxFlow': value.qtyMaxFlow,
        'qtyYearFlow': value.qtyYearFlow,
        'qtyMeasUnit': CodeListItemBasicRecordToJSON(value.qtyMeasUnit),
        'qtyYear': value.qtyYear,
        'qtyMonth': value.qtyMonth,
        'qtyDay': value.qtyDay,
        'qtyMaxFall': value.qtyMaxFall,
        'qtyVolume': value.qtyVolume,
        'numMonths': value.numMonths,
        'numDays': value.numDays,
        'numHours': value.numHours,
        'qtyQuantAcc': value.qtyQuantAcc,
        'measMethod': CodeListItemBasicRecordToJSON(value.measMethod),
        'measDevice': value.measDevice,
        'measFreqYr': value.measFreqYr,
        'measFreqQt': value.measFreqQt,
        'measFreqQl': value.measFreqQl,
        'quantWater': value.quantWater,
        'waterCat': CodeListItemBasicRecordToJSON(value.waterCat),
        'samplePlace': value.samplePlace,
        'waterType': CodeListItemBasicRecordToJSON(value.waterType),
        'tempDecSt': value.tempDecSt,
        'tempDecWt': value.tempDecWt,
        'maxTempDec': value.maxTempDec,
        'recPlace': MonitoringRecordToJSON(value.recPlace),
        'recType': CodeListItemBasicRecordToJSON(value.recType),
        'sourceSize': value.sourceSize,
        'protArea': value.protArea,
        'sludgeHand': CodeListItemBasicRecordToJSON(value.sludgeHand),
        'sludgeStab': CodeListItemBasicRecordToJSON(value.sludgeStab),
        'sludgeDrain': CodeListItemBasicRecordToJSON(value.sludgeDrain),
        'janPerm': value.janPerm,
        'janQ': value.janQ,
        'febPerm': value.febPerm,
        'febQ': value.febQ,
        'marPerm': value.marPerm,
        'marQ': value.marQ,
        'aprPerm': value.aprPerm,
        'aprQ': value.aprQ,
        'mayPerm': value.mayPerm,
        'mayQ': value.mayQ,
        'junPerm': value.junPerm,
        'junQ': value.junQ,
        'julPerm': value.julPerm,
        'julQ': value.julQ,
        'augPerm': value.augPerm,
        'augQ': value.augQ,
        'sepPerm': value.sepPerm,
        'sepQ': value.sepQ,
        'octPerm': value.octPerm,
        'octQ': value.octQ,
        'novPerm': value.novPerm,
        'novQ': value.novQ,
        'decPerm': value.decPerm,
        'decQ': value.decQ,
        'note': value.note,
        'mainName': value.mainName,
        'mainCity': value.mainCity,
        'mainDescription': value.mainDescription,
        'permDesc': value.permDesc,
        'createdTimeStamp': value.createdTimeStamp,
        'createdBy': value.createdBy,
        'updatedTimestamp': value.updatedTimestamp,
        'updatedBy': value.updatedBy,
        'sampleTypes': value.sampleTypes === undefined ? undefined : (value.sampleTypes === null ? null : (value.sampleTypes as Array<any>).map(CodeListItemBasicRecordToJSON)),
        'purifMethods': value.purifMethods === undefined ? undefined : (value.purifMethods === null ? null : (value.purifMethods as Array<any>).map(CodeListItemBasicRecordToJSON)),
        'qualityTabRecords': value.qualityTabRecords === undefined ? undefined : (value.qualityTabRecords === null ? null : (value.qualityTabRecords as Array<any>).map(QualityTabResponseRecordToJSON)),
        'dangerSubstanceRecords': value.dangerSubstanceRecords === undefined ? undefined : (value.dangerSubstanceRecords === null ? null : (value.dangerSubstanceRecords as Array<any>).map(DangerSubstanceResponseRecordToJSON)),
        'newPlace': NewPlaceResponseRecordToJSON(value.newPlace),
        'newRecPlace': NewPlaceResponseRecordToJSON(value.newRecPlace),
        'isPlaceWaitingForPairing': value.isPlaceWaitingForPairing,
    };
}

