/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MonitoringPointRecord } from './MonitoringPointRecord';
import {
    MonitoringPointRecordFromJSON,
    MonitoringPointRecordFromJSONTyped,
    MonitoringPointRecordToJSON,
} from './MonitoringPointRecord';

/**
 * 
 * @export
 * @interface PairMonitoringPointsResponse
 */
export interface PairMonitoringPointsResponse {
    /**
     * 
     * @type {string}
     * @memberof PairMonitoringPointsResponse
     */
    submissionType?: string;
    /**
     * 
     * @type {string}
     * @memberof PairMonitoringPointsResponse
     */
    operatorName?: string;
    /**
     * 
     * @type {string}
     * @memberof PairMonitoringPointsResponse
     */
    installationName?: string;
    /**
     * 
     * @type {string}
     * @memberof PairMonitoringPointsResponse
     */
    personType?: string;
    /**
     * 
     * @type {string}
     * @memberof PairMonitoringPointsResponse
     */
    place?: string;
    /**
     * 
     * @type {string}
     * @memberof PairMonitoringPointsResponse
     */
    date?: string;
    /**
     * 
     * @type {Array<MonitoringPointRecord>}
     * @memberof PairMonitoringPointsResponse
     */
    monitoringPoints?: Array<MonitoringPointRecord>;
}

/**
 * Check if a given object implements the PairMonitoringPointsResponse interface.
 */
export function instanceOfPairMonitoringPointsResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PairMonitoringPointsResponseFromJSON(json: any): PairMonitoringPointsResponse {
    return PairMonitoringPointsResponseFromJSONTyped(json, false);
}

export function PairMonitoringPointsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PairMonitoringPointsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'submissionType': !exists(json, 'submissionType') ? undefined : json['submissionType'],
        'operatorName': !exists(json, 'operatorName') ? undefined : json['operatorName'],
        'installationName': !exists(json, 'installationName') ? undefined : json['installationName'],
        'personType': !exists(json, 'personType') ? undefined : json['personType'],
        'place': !exists(json, 'place') ? undefined : json['place'],
        'date': !exists(json, 'date') ? undefined : json['date'],
        'monitoringPoints': !exists(json, 'monitoringPoints') ? undefined : ((json['monitoringPoints'] as Array<any>).map(MonitoringPointRecordFromJSON)),
    };
}

export function PairMonitoringPointsResponseToJSON(value?: PairMonitoringPointsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'submissionType': value.submissionType,
        'operatorName': value.operatorName,
        'installationName': value.installationName,
        'personType': value.personType,
        'place': value.place,
        'date': value.date,
        'monitoringPoints': value.monitoringPoints === undefined ? undefined : ((value.monitoringPoints as Array<any>).map(MonitoringPointRecordToJSON)),
    };
}

