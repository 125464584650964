/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WaterStructConduitRequestRecord
 */
export interface WaterStructConduitRequestRecord {
    /**
     * 
     * @type {number}
     * @memberof WaterStructConduitRequestRecord
     */
    id?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructConduitRequestRecord
     */
    typeCode?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructConduitRequestRecord
     */
    length?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructConduitRequestRecord
     */
    typeSewCode?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructConduitRequestRecord
     */
    lengthSew?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructConduitRequestRecord
     */
    levelDiff?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructConduitRequestRecord
     */
    desFlow?: number | null;
}

/**
 * Check if a given object implements the WaterStructConduitRequestRecord interface.
 */
export function instanceOfWaterStructConduitRequestRecord(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WaterStructConduitRequestRecordFromJSON(json: any): WaterStructConduitRequestRecord {
    return WaterStructConduitRequestRecordFromJSONTyped(json, false);
}

export function WaterStructConduitRequestRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): WaterStructConduitRequestRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'typeCode': !exists(json, 'typeCode') ? undefined : json['typeCode'],
        'length': !exists(json, 'length') ? undefined : json['length'],
        'typeSewCode': !exists(json, 'typeSewCode') ? undefined : json['typeSewCode'],
        'lengthSew': !exists(json, 'lengthSew') ? undefined : json['lengthSew'],
        'levelDiff': !exists(json, 'levelDiff') ? undefined : json['levelDiff'],
        'desFlow': !exists(json, 'desFlow') ? undefined : json['desFlow'],
    };
}

export function WaterStructConduitRequestRecordToJSON(value?: WaterStructConduitRequestRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'typeCode': value.typeCode,
        'length': value.length,
        'typeSewCode': value.typeSewCode,
        'lengthSew': value.lengthSew,
        'levelDiff': value.levelDiff,
        'desFlow': value.desFlow,
    };
}

