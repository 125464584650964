/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TransformedEform
 */
export interface TransformedEform {
    /**
     * 
     * @type {string}
     * @memberof TransformedEform
     */
    operatorName?: string;
    /**
     * 
     * @type {string}
     * @memberof TransformedEform
     */
    placeName?: string;
    /**
     * 
     * @type {string}
     * @memberof TransformedEform
     */
    installationName?: string;
    /**
     * 
     * @type {string}
     * @memberof TransformedEform
     */
    eformXml?: string;
    /**
     * 
     * @type {string}
     * @memberof TransformedEform
     */
    eformType?: string;
    /**
     * 
     * @type {number}
     * @memberof TransformedEform
     */
    submissionId?: number;
}

/**
 * Check if a given object implements the TransformedEform interface.
 */
export function instanceOfTransformedEform(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TransformedEformFromJSON(json: any): TransformedEform {
    return TransformedEformFromJSONTyped(json, false);
}

export function TransformedEformFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransformedEform {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'operatorName': !exists(json, 'operatorName') ? undefined : json['operatorName'],
        'placeName': !exists(json, 'placeName') ? undefined : json['placeName'],
        'installationName': !exists(json, 'installationName') ? undefined : json['installationName'],
        'eformXml': !exists(json, 'eformXml') ? undefined : json['eformXml'],
        'eformType': !exists(json, 'eformType') ? undefined : json['eformType'],
        'submissionId': !exists(json, 'submissionId') ? undefined : json['submissionId'],
    };
}

export function TransformedEformToJSON(value?: TransformedEform | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'operatorName': value.operatorName,
        'placeName': value.placeName,
        'installationName': value.installationName,
        'eformXml': value.eformXml,
        'eformType': value.eformType,
        'submissionId': value.submissionId,
    };
}

