/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WaterStructZdrzRecord
 */
export interface WaterStructZdrzRecord {
    /**
     * 
     * @type {number}
     * @memberof WaterStructZdrzRecord
     */
    id?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructZdrzRecord
     */
    volume?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructZdrzRecord
     */
    length?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof WaterStructZdrzRecord
     */
    test?: boolean | null;
}

/**
 * Check if a given object implements the WaterStructZdrzRecord interface.
 */
export function instanceOfWaterStructZdrzRecord(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WaterStructZdrzRecordFromJSON(json: any): WaterStructZdrzRecord {
    return WaterStructZdrzRecordFromJSONTyped(json, false);
}

export function WaterStructZdrzRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): WaterStructZdrzRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'volume': !exists(json, 'volume') ? undefined : json['volume'],
        'length': !exists(json, 'length') ? undefined : json['length'],
        'test': !exists(json, 'test') ? undefined : json['test'],
    };
}

export function WaterStructZdrzRecordToJSON(value?: WaterStructZdrzRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'volume': value.volume,
        'length': value.length,
        'test': value.test,
    };
}

