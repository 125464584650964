import React from 'react';

type BadgeProps = {
    color?: 'primary' | 'secondary' | 'success' | 'warning' | 'failure' | 'blue' | 'green' | 'yellow' | 'orange' | 'red';
    size?: 'normal' | 'small';
    variant?: 'normal' | 'rounded' | 'squared';
    width?: 'normal' | 'full';
    height?: 'normal' | 'fixed';
    children?: string | null;
};

const theme = {
    base: 'inline-flex items-center justify-center',
    color: {
        primary: 'text-[#174291] bg-[#D4E2FC] dark:text-[#D4E2FC] dark:bg-[#174291]',
        secondary: 'text-[#757575] bg-[#F9FAFB] dark:text-[#000000] dark:bg-[#757575]',
        success: 'text-[#065F46] bg-[#D1FAE5] dark:text-[#D1FAE5] dark:bg-[#065F46]',
        warning: 'text-[#997328] bg-[#FFF2D9] dark:text-[#000000] dark:bg-[#997328]',
        failure: 'text-[#892C21] bg-[#FADBD7] dark:text-[#FADBD7] dark:bg-[#892C21]',
        blue: 'text-[#174291] bg-[#D4E2FC] dark:text-[#D4E2FC] dark:bg-[#174291]',
        green: 'text-[#065F46] bg-[#D1FAE5] dark:text-[#D1FAE5] dark:bg-[#065F46]',
        yellow: 'text-[#997328] bg-[#FFF2D9] dark:text-[#000000] dark:bg-[#997328]',
        orange: 'text-[#997328] bg-orange-200 dark:text-[#000000] dark:bg-orange-800',
        red: 'text-[#892C21] bg-[#FADBD7] dark:text-[#FADBD7] dark:bg-[#892C21]'
    },
    size: {
        small: 'text-xs font-medium leading-4',
        normal: 'px-2.5 py-0.5 text-xs font-medium leading-4'
    },
    variant: {
        normal: 'rounded-md',
        rounded: 'rounded-full',
        squared: ''
    },
    width: {
        normal: '',
        full: 'w-full'
    },
    height: {
        normal: '',
        fixed: 'h-5'
    }
};

const Badge: React.FC<BadgeProps> = ({
    color = 'primary',
    size = 'normal',
    variant = 'normal',
    width = 'normal',
    height = 'normal',
    children = '-'
}) => {
    return (
        <span
            className={[
                theme['base'],
                theme['color'][color],
                theme['size'][size],
                theme['variant'][variant],
                theme['width'][width],
                theme['height'][height]
            ].join(' ')}
        >
            {children}
        </span>
    );
};

export default Badge;
