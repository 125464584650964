/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ValidationResult
 */
export interface ValidationResult {
    /**
     * 
     * @type {string}
     * @memberof ValidationResult
     */
    result?: ValidationResultResultEnum;
    /**
     * 
     * @type {string}
     * @memberof ValidationResult
     */
    messageCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ValidationResult
     */
    details?: string | null;
}


/**
 * @export
 */
export const ValidationResultResultEnum = {
    Success: 'SUCCESS',
    Warning: 'WARNING',
    Error: 'ERROR',
    Nocheck: 'NOCHECK'
} as const;
export type ValidationResultResultEnum = typeof ValidationResultResultEnum[keyof typeof ValidationResultResultEnum];


/**
 * Check if a given object implements the ValidationResult interface.
 */
export function instanceOfValidationResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ValidationResultFromJSON(json: any): ValidationResult {
    return ValidationResultFromJSONTyped(json, false);
}

export function ValidationResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValidationResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': !exists(json, 'result') ? undefined : json['result'],
        'messageCode': !exists(json, 'messageCode') ? undefined : json['messageCode'],
        'details': !exists(json, 'details') ? undefined : json['details'],
    };
}

export function ValidationResultToJSON(value?: ValidationResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'result': value.result,
        'messageCode': value.messageCode,
        'details': value.details,
    };
}

