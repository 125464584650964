/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SetMonitoringPointRecord
 */
export interface SetMonitoringPointRecord {
    /**
     * 
     * @type {number}
     * @memberof SetMonitoringPointRecord
     */
    index?: number;
    /**
     * 
     * @type {number}
     * @memberof SetMonitoringPointRecord
     */
    monPointId?: number;
    /**
     * 
     * @type {string}
     * @memberof SetMonitoringPointRecord
     */
    monPointName?: string;
}

/**
 * Check if a given object implements the SetMonitoringPointRecord interface.
 */
export function instanceOfSetMonitoringPointRecord(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SetMonitoringPointRecordFromJSON(json: any): SetMonitoringPointRecord {
    return SetMonitoringPointRecordFromJSONTyped(json, false);
}

export function SetMonitoringPointRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetMonitoringPointRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'index': !exists(json, 'index') ? undefined : json['index'],
        'monPointId': !exists(json, 'monPointId') ? undefined : json['monPointId'],
        'monPointName': !exists(json, 'monPointName') ? undefined : json['monPointName'],
    };
}

export function SetMonitoringPointRecordToJSON(value?: SetMonitoringPointRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'index': value.index,
        'monPointId': value.monPointId,
        'monPointName': value.monPointName,
    };
}

