/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CodeListItemBasicRecord } from './CodeListItemBasicRecord';
import {
    CodeListItemBasicRecordFromJSON,
    CodeListItemBasicRecordFromJSONTyped,
    CodeListItemBasicRecordToJSON,
} from './CodeListItemBasicRecord';

/**
 * 
 * @export
 * @interface WaterStructCovResponseRecord
 */
export interface WaterStructCovResponseRecord {
    /**
     * 
     * @type {number}
     * @memberof WaterStructCovResponseRecord
     */
    id?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructCovResponseRecord
     */
    capHydr?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructCovResponseRecord
     */
    capProj?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructCovResponseRecord
     */
    eoNumber?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructCovResponseRecord
     */
    suppliesInhab?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructCovResponseRecord
     */
    avq24?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructCovResponseRecord
     */
    maxqd?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructCovResponseRecord
     */
    maxqh?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructCovResponseRecord
     */
    maxqr?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructCovResponseRecord
     */
    sewqrperc?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructCovResponseRecord
     */
    chskcramount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructCovResponseRecord
     */
    bsk5amount?: number | null;
    /**
     * 
     * @type {string}
     * @memberof WaterStructCovResponseRecord
     */
    municipalities?: string | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof WaterStructCovResponseRecord
     */
    sludgeHand?: CodeListItemBasicRecord | null;
}

/**
 * Check if a given object implements the WaterStructCovResponseRecord interface.
 */
export function instanceOfWaterStructCovResponseRecord(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WaterStructCovResponseRecordFromJSON(json: any): WaterStructCovResponseRecord {
    return WaterStructCovResponseRecordFromJSONTyped(json, false);
}

export function WaterStructCovResponseRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): WaterStructCovResponseRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'capHydr': !exists(json, 'capHydr') ? undefined : json['capHydr'],
        'capProj': !exists(json, 'capProj') ? undefined : json['capProj'],
        'eoNumber': !exists(json, 'eoNumber') ? undefined : json['eoNumber'],
        'suppliesInhab': !exists(json, 'suppliesInhab') ? undefined : json['suppliesInhab'],
        'avq24': !exists(json, 'avq24') ? undefined : json['avq24'],
        'maxqd': !exists(json, 'maxqd') ? undefined : json['maxqd'],
        'maxqh': !exists(json, 'maxqh') ? undefined : json['maxqh'],
        'maxqr': !exists(json, 'maxqr') ? undefined : json['maxqr'],
        'sewqrperc': !exists(json, 'sewqrperc') ? undefined : json['sewqrperc'],
        'chskcramount': !exists(json, 'chskcramount') ? undefined : json['chskcramount'],
        'bsk5amount': !exists(json, 'bsk5amount') ? undefined : json['bsk5amount'],
        'municipalities': !exists(json, 'municipalities') ? undefined : json['municipalities'],
        'sludgeHand': !exists(json, 'sludgeHand') ? undefined : CodeListItemBasicRecordFromJSON(json['sludgeHand']),
    };
}

export function WaterStructCovResponseRecordToJSON(value?: WaterStructCovResponseRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'capHydr': value.capHydr,
        'capProj': value.capProj,
        'eoNumber': value.eoNumber,
        'suppliesInhab': value.suppliesInhab,
        'avq24': value.avq24,
        'maxqd': value.maxqd,
        'maxqh': value.maxqh,
        'maxqr': value.maxqr,
        'sewqrperc': value.sewqrperc,
        'chskcramount': value.chskcramount,
        'bsk5amount': value.bsk5amount,
        'municipalities': value.municipalities,
        'sludgeHand': CodeListItemBasicRecordToJSON(value.sludgeHand),
    };
}

