/* tslint:disable */
/* eslint-disable */
/**
 * Data import metadata
 * Administration of metadata for import
 *
 * The version of the OpenAPI document: 1.0
 * Contact: schvarcz@bb.microcomp.sk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DataRegistryJoinDefinitionRequest
 */
export interface DataRegistryJoinDefinitionRequest {
    /**
     * 
     * @type {string}
     * @memberof DataRegistryJoinDefinitionRequest
     */
    referenceCode?: string;
    /**
     * 
     * @type {string}
     * @memberof DataRegistryJoinDefinitionRequest
     */
    productAttributeCode?: string;
    /**
     * 
     * @type {string}
     * @memberof DataRegistryJoinDefinitionRequest
     */
    joinedReferenceCode?: string;
    /**
     * 
     * @type {string}
     * @memberof DataRegistryJoinDefinitionRequest
     */
    joinedProductAttributeCode?: string;
}

/**
 * Check if a given object implements the DataRegistryJoinDefinitionRequest interface.
 */
export function instanceOfDataRegistryJoinDefinitionRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DataRegistryJoinDefinitionRequestFromJSON(json: any): DataRegistryJoinDefinitionRequest {
    return DataRegistryJoinDefinitionRequestFromJSONTyped(json, false);
}

export function DataRegistryJoinDefinitionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataRegistryJoinDefinitionRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'referenceCode': !exists(json, 'referenceCode') ? undefined : json['referenceCode'],
        'productAttributeCode': !exists(json, 'productAttributeCode') ? undefined : json['productAttributeCode'],
        'joinedReferenceCode': !exists(json, 'joinedReferenceCode') ? undefined : json['joinedReferenceCode'],
        'joinedProductAttributeCode': !exists(json, 'joinedProductAttributeCode') ? undefined : json['joinedProductAttributeCode'],
    };
}

export function DataRegistryJoinDefinitionRequestToJSON(value?: DataRegistryJoinDefinitionRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'referenceCode': value.referenceCode,
        'productAttributeCode': value.productAttributeCode,
        'joinedReferenceCode': value.joinedReferenceCode,
        'joinedProductAttributeCode': value.joinedProductAttributeCode,
    };
}

