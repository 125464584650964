/* tslint:disable */
/* eslint-disable */
/**
 * Catalog API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.31.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InstallationCriteria
 */
export interface InstallationCriteria {
    /**
     * 
     * @type {string}
     * @memberof InstallationCriteria
     */
    searchField?: string;
    /**
     * 
     * @type {number}
     * @memberof InstallationCriteria
     */
    technicalCode?: number;
    /**
     * 
     * @type {string}
     * @memberof InstallationCriteria
     */
    code?: string;
    /**
     * 
     * @type {number}
     * @memberof InstallationCriteria
     */
    installationCode?: number;
    /**
     * 
     * @type {number}
     * @memberof InstallationCriteria
     */
    ippc?: number;
    /**
     * 
     * @type {string}
     * @memberof InstallationCriteria
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof InstallationCriteria
     */
    note?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof InstallationCriteria
     */
    status?: Array<InstallationCriteriaStatusEnum>;
    /**
     * 
     * @type {number}
     * @memberof InstallationCriteria
     */
    naceType?: number;
    /**
     * 
     * @type {number}
     * @memberof InstallationCriteria
     */
    nace2Type?: number;
    /**
     * 
     * @type {number}
     * @memberof InstallationCriteria
     */
    operatorId?: number;
}


/**
 * @export
 */
export const InstallationCriteriaStatusEnum = {
    Delete: 'DELETE',
    Block: 'BLOCK',
    Ok: 'OK',
    Wait: 'WAIT'
} as const;
export type InstallationCriteriaStatusEnum = typeof InstallationCriteriaStatusEnum[keyof typeof InstallationCriteriaStatusEnum];


/**
 * Check if a given object implements the InstallationCriteria interface.
 */
export function instanceOfInstallationCriteria(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function InstallationCriteriaFromJSON(json: any): InstallationCriteria {
    return InstallationCriteriaFromJSONTyped(json, false);
}

export function InstallationCriteriaFromJSONTyped(json: any, ignoreDiscriminator: boolean): InstallationCriteria {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'searchField': !exists(json, 'searchField') ? undefined : json['searchField'],
        'technicalCode': !exists(json, 'technicalCode') ? undefined : json['technicalCode'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'installationCode': !exists(json, 'installationCode') ? undefined : json['installationCode'],
        'ippc': !exists(json, 'ippc') ? undefined : json['ippc'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'naceType': !exists(json, 'naceType') ? undefined : json['naceType'],
        'nace2Type': !exists(json, 'nace2Type') ? undefined : json['nace2Type'],
        'operatorId': !exists(json, 'operatorId') ? undefined : json['operatorId'],
    };
}

export function InstallationCriteriaToJSON(value?: InstallationCriteria | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'searchField': value.searchField,
        'technicalCode': value.technicalCode,
        'code': value.code,
        'installationCode': value.installationCode,
        'ippc': value.ippc,
        'name': value.name,
        'note': value.note,
        'status': value.status,
        'naceType': value.naceType,
        'nace2Type': value.nace2Type,
        'operatorId': value.operatorId,
    };
}

