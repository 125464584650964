/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface HydroAnalysisFileRequestRecord
 */
export interface HydroAnalysisFileRequestRecord {
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisFileRequestRecord
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof HydroAnalysisFileRequestRecord
     */
    fileUrl?: string;
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisFileRequestRecord
     */
    fileId?: number;
    /**
     * 
     * @type {string}
     * @memberof HydroAnalysisFileRequestRecord
     */
    fileName?: string;
    /**
     * 
     * @type {string}
     * @memberof HydroAnalysisFileRequestRecord
     */
    hydromorphologicalDate?: string;
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisFileRequestRecord
     */
    measueredFlow?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisFileRequestRecord
     */
    flowArea?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisFileRequestRecord
     */
    bankfullWidth?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisFileRequestRecord
     */
    averageProfileSpeed?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisFileRequestRecord
     */
    statusItemCode?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisFileRequestRecord
     */
    fileTypeItemCode?: number;
    /**
     * 
     * @type {string}
     * @memberof HydroAnalysisFileRequestRecord
     */
    fileMappingKey?: string;
}

/**
 * Check if a given object implements the HydroAnalysisFileRequestRecord interface.
 */
export function instanceOfHydroAnalysisFileRequestRecord(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function HydroAnalysisFileRequestRecordFromJSON(json: any): HydroAnalysisFileRequestRecord {
    return HydroAnalysisFileRequestRecordFromJSONTyped(json, false);
}

export function HydroAnalysisFileRequestRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): HydroAnalysisFileRequestRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'fileUrl': !exists(json, 'fileUrl') ? undefined : json['fileUrl'],
        'fileId': !exists(json, 'fileId') ? undefined : json['fileId'],
        'fileName': !exists(json, 'fileName') ? undefined : json['fileName'],
        'hydromorphologicalDate': !exists(json, 'hydromorphologicalDate') ? undefined : json['hydromorphologicalDate'],
        'measueredFlow': !exists(json, 'measueredFlow') ? undefined : json['measueredFlow'],
        'flowArea': !exists(json, 'flowArea') ? undefined : json['flowArea'],
        'bankfullWidth': !exists(json, 'bankfullWidth') ? undefined : json['bankfullWidth'],
        'averageProfileSpeed': !exists(json, 'averageProfileSpeed') ? undefined : json['averageProfileSpeed'],
        'statusItemCode': !exists(json, 'statusItemCode') ? undefined : json['statusItemCode'],
        'fileTypeItemCode': !exists(json, 'fileTypeItemCode') ? undefined : json['fileTypeItemCode'],
        'fileMappingKey': !exists(json, 'fileMappingKey') ? undefined : json['fileMappingKey'],
    };
}

export function HydroAnalysisFileRequestRecordToJSON(value?: HydroAnalysisFileRequestRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'fileUrl': value.fileUrl,
        'fileId': value.fileId,
        'fileName': value.fileName,
        'hydromorphologicalDate': value.hydromorphologicalDate,
        'measueredFlow': value.measueredFlow,
        'flowArea': value.flowArea,
        'bankfullWidth': value.bankfullWidth,
        'averageProfileSpeed': value.averageProfileSpeed,
        'statusItemCode': value.statusItemCode,
        'fileTypeItemCode': value.fileTypeItemCode,
        'fileMappingKey': value.fileMappingKey,
    };
}

