/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiExceptionRecord,
  PageQualityAssessmentRecord,
  QualityAssessmentFileRecord,
  QualityAssessmentRecord,
  QualityAssessmentRequest,
} from '../models/index';
import {
    ApiExceptionRecordFromJSON,
    ApiExceptionRecordToJSON,
    PageQualityAssessmentRecordFromJSON,
    PageQualityAssessmentRecordToJSON,
    QualityAssessmentFileRecordFromJSON,
    QualityAssessmentFileRecordToJSON,
    QualityAssessmentRecordFromJSON,
    QualityAssessmentRecordToJSON,
    QualityAssessmentRequestFromJSON,
    QualityAssessmentRequestToJSON,
} from '../models/index';

export interface QualityAssessmentPUBLICAPIApiCreateQualityAssessmentRequest {
    qualityAssessmentRequest: QualityAssessmentRequest;
}

export interface QualityAssessmentPUBLICAPIApiDeleteQualityAssessmentRequest {
    id: number;
}

export interface QualityAssessmentPUBLICAPIApiDeleteQualityAssessmentFileRequest {
    id: number;
    fileId: number;
}

export interface QualityAssessmentPUBLICAPIApiGetQualityAssessmentRequest {
    id: number;
}

export interface QualityAssessmentPUBLICAPIApiGetQualityAssessmentFileRequest {
    id: number;
    fileId: number;
}

export interface QualityAssessmentPUBLICAPIApiGetQualityAssessmentFilesRequest {
    id: number;
}

export interface QualityAssessmentPUBLICAPIApiSearchQualityAssessmentsRequest {
    requestBody: { [key: string]: string; };
    page?: number;
    size?: number;
    sort?: string;
    includedWritten?: boolean;
}

export interface QualityAssessmentPUBLICAPIApiUpdateQualityAssessmentRequest {
    id: number;
    qualityAssessmentRequest: QualityAssessmentRequest;
}

export interface QualityAssessmentPUBLICAPIApiUpdateStateRequest {
    id: number;
}

export interface QualityAssessmentPUBLICAPIApiUploadQualityAssessmentFileRequest {
    id: number;
    file: Blob;
}

/**
 * 
 */
export class QualityAssessmentPUBLICAPIApi extends runtime.BaseAPI {

    /**
     * Returns HTTPS status code 200 if the qualityAssessment was created successfully.
     * create qualityAssessment
     */
    async createQualityAssessmentRaw(requestParameters: QualityAssessmentPUBLICAPIApiCreateQualityAssessmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.qualityAssessmentRequest === null || requestParameters.qualityAssessmentRequest === undefined) {
            throw new runtime.RequiredError('qualityAssessmentRequest','Required parameter requestParameters.qualityAssessmentRequest was null or undefined when calling createQualityAssessment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/quality-assessment/v1/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: QualityAssessmentRequestToJSON(requestParameters.qualityAssessmentRequest),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Returns HTTPS status code 200 if the qualityAssessment was created successfully.
     * create qualityAssessment
     */
    async createQualityAssessment(qualityAssessmentRequest: QualityAssessmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.createQualityAssessmentRaw({ qualityAssessmentRequest: qualityAssessmentRequest }, initOverrides);
        return await response.value();
    }

    /**
     * Delete the quality assessment by provided ID.
     * Delete quality assessment
     */
    async deleteQualityAssessmentRaw(requestParameters: QualityAssessmentPUBLICAPIApiDeleteQualityAssessmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteQualityAssessment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/quality-assessment/v1/{id}/delete`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Delete the quality assessment by provided ID.
     * Delete quality assessment
     */
    async deleteQualityAssessment(id: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.deleteQualityAssessmentRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * Returns HTTP NO_CONTENT if the quality assessment file was deleted successfully.
     * Delete quality assessment file
     */
    async deleteQualityAssessmentFileRaw(requestParameters: QualityAssessmentPUBLICAPIApiDeleteQualityAssessmentFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteQualityAssessmentFile.');
        }

        if (requestParameters.fileId === null || requestParameters.fileId === undefined) {
            throw new runtime.RequiredError('fileId','Required parameter requestParameters.fileId was null or undefined when calling deleteQualityAssessmentFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/quality-assessment/v1/{id}/file/{fileId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"fileId"}}`, encodeURIComponent(String(requestParameters.fileId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Returns HTTP NO_CONTENT if the quality assessment file was deleted successfully.
     * Delete quality assessment file
     */
    async deleteQualityAssessmentFile(id: number, fileId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.deleteQualityAssessmentFileRaw({ id: id, fileId: fileId }, initOverrides);
        return await response.value();
    }

    /**
     * Returns the qualityAssessment by provided ID.
     * Get qualityAssessment
     */
    async getQualityAssessmentRaw(requestParameters: QualityAssessmentPUBLICAPIApiGetQualityAssessmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<QualityAssessmentRecord>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getQualityAssessment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/quality-assessment/v1/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => QualityAssessmentRecordFromJSON(jsonValue));
    }

    /**
     * Returns the qualityAssessment by provided ID.
     * Get qualityAssessment
     */
    async getQualityAssessment(id: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<QualityAssessmentRecord> {
        const response = await this.getQualityAssessmentRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * Returns the quality assessment attachment by provided ID.
     * Get quality assessment attachment by id
     */
    async getQualityAssessmentFileRaw(requestParameters: QualityAssessmentPUBLICAPIApiGetQualityAssessmentFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<QualityAssessmentFileRecord>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getQualityAssessmentFile.');
        }

        if (requestParameters.fileId === null || requestParameters.fileId === undefined) {
            throw new runtime.RequiredError('fileId','Required parameter requestParameters.fileId was null or undefined when calling getQualityAssessmentFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/quality-assessment/v1/{id}/file/{fileId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"fileId"}}`, encodeURIComponent(String(requestParameters.fileId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => QualityAssessmentFileRecordFromJSON(jsonValue));
    }

    /**
     * Returns the quality assessment attachment by provided ID.
     * Get quality assessment attachment by id
     */
    async getQualityAssessmentFile(id: number, fileId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<QualityAssessmentFileRecord> {
        const response = await this.getQualityAssessmentFileRaw({ id: id, fileId: fileId }, initOverrides);
        return await response.value();
    }

    /**
     * Returns all files for quality assessment with provided IDs.
     * Get all files related to quality assessment
     */
    async getQualityAssessmentFilesRaw(requestParameters: QualityAssessmentPUBLICAPIApiGetQualityAssessmentFilesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<QualityAssessmentFileRecord>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getQualityAssessmentFiles.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/quality-assessment/v1/{id}/files`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(QualityAssessmentFileRecordFromJSON));
    }

    /**
     * Returns all files for quality assessment with provided IDs.
     * Get all files related to quality assessment
     */
    async getQualityAssessmentFiles(id: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<QualityAssessmentFileRecord>> {
        const response = await this.getQualityAssessmentFilesRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * Returns a page of quality assessments matching the search criteria.
     * Search quality assessments
     */
    async searchQualityAssessmentsRaw(requestParameters: QualityAssessmentPUBLICAPIApiSearchQualityAssessmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageQualityAssessmentRecord>> {
        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling searchQualityAssessments.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.includedWritten !== undefined) {
            queryParameters['includedWritten'] = requestParameters.includedWritten;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/quality-assessment/v1/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageQualityAssessmentRecordFromJSON(jsonValue));
    }

    /**
     * Returns a page of quality assessments matching the search criteria.
     * Search quality assessments
     */
    async searchQualityAssessments(requestBody: { [key: string]: string; }, page?: number, size?: number, sort?: string, includedWritten?: boolean, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageQualityAssessmentRecord> {
        const response = await this.searchQualityAssessmentsRaw({ requestBody: requestBody, page: page, size: size, sort: sort, includedWritten: includedWritten }, initOverrides);
        return await response.value();
    }

    /**
     * Returns HTTPS status code 200 and updated quality assessment if quality assessment was updated successfully.
     * update quality assessment
     */
    async updateQualityAssessmentRaw(requestParameters: QualityAssessmentPUBLICAPIApiUpdateQualityAssessmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateQualityAssessment.');
        }

        if (requestParameters.qualityAssessmentRequest === null || requestParameters.qualityAssessmentRequest === undefined) {
            throw new runtime.RequiredError('qualityAssessmentRequest','Required parameter requestParameters.qualityAssessmentRequest was null or undefined when calling updateQualityAssessment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/quality-assessment/v1/{id}/update`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: QualityAssessmentRequestToJSON(requestParameters.qualityAssessmentRequest),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Returns HTTPS status code 200 and updated quality assessment if quality assessment was updated successfully.
     * update quality assessment
     */
    async updateQualityAssessment(id: number, qualityAssessmentRequest: QualityAssessmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.updateQualityAssessmentRaw({ id: id, qualityAssessmentRequest: qualityAssessmentRequest }, initOverrides);
        return await response.value();
    }

    /**
     * Returns HTTPS status code 200 and updated state quality assessment if quality assessment was updated successfully.
     * update state quality assessment
     */
    async updateStateRaw(requestParameters: QualityAssessmentPUBLICAPIApiUpdateStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/quality-assessment/v1/{id}/state`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Returns HTTPS status code 200 and updated state quality assessment if quality assessment was updated successfully.
     * update state quality assessment
     */
    async updateState(id: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.updateStateRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * Returns uploaded file ID
     * Upload quality assessment file
     */
    async uploadQualityAssessmentFileRaw(requestParameters: QualityAssessmentPUBLICAPIApiUploadQualityAssessmentFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling uploadQualityAssessmentFile.');
        }

        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling uploadQualityAssessmentFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/api/integration/quality-assessment/v1/{id}/file`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Returns uploaded file ID
     * Upload quality assessment file
     */
    async uploadQualityAssessmentFile(id: number, file: Blob, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.uploadQualityAssessmentFileRaw({ id: id, file: file }, initOverrides);
        return await response.value();
    }

}
