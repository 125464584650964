/* tslint:disable */
/* eslint-disable */
/**
 * Catalog API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.31.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CodelistItem } from './CodelistItem';
import {
    CodelistItemFromJSON,
    CodelistItemFromJSONTyped,
    CodelistItemToJSON,
} from './CodelistItem';

/**
 * 
 * @export
 * @interface WaterShapeSoCodelistItem
 */
export interface WaterShapeSoCodelistItem {
    /**
     * 
     * @type {number}
     * @memberof WaterShapeSoCodelistItem
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof WaterShapeSoCodelistItem
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof WaterShapeSoCodelistItem
     */
    name: string;
    /**
     * 
     * @type {CodelistItem}
     * @memberof WaterShapeSoCodelistItem
     */
    wbCharacterType: CodelistItem | null;
    /**
     * 
     * @type {CodelistItem}
     * @memberof WaterShapeSoCodelistItem
     */
    wbGroupType: CodelistItem | null;
    /**
     * 
     * @type {CodelistItem}
     * @memberof WaterShapeSoCodelistItem
     */
    wbType: CodelistItem | null;
}

/**
 * Check if a given object implements the WaterShapeSoCodelistItem interface.
 */
export function instanceOfWaterShapeSoCodelistItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "code" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "wbCharacterType" in value;
    isInstance = isInstance && "wbGroupType" in value;
    isInstance = isInstance && "wbType" in value;

    return isInstance;
}

export function WaterShapeSoCodelistItemFromJSON(json: any): WaterShapeSoCodelistItem {
    return WaterShapeSoCodelistItemFromJSONTyped(json, false);
}

export function WaterShapeSoCodelistItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): WaterShapeSoCodelistItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'code': json['code'],
        'name': json['name'],
        'wbCharacterType': CodelistItemFromJSON(json['wbCharacterType']),
        'wbGroupType': CodelistItemFromJSON(json['wbGroupType']),
        'wbType': CodelistItemFromJSON(json['wbType']),
    };
}

export function WaterShapeSoCodelistItemToJSON(value?: WaterShapeSoCodelistItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'code': value.code,
        'name': value.name,
        'wbCharacterType': CodelistItemToJSON(value.wbCharacterType),
        'wbGroupType': CodelistItemToJSON(value.wbGroupType),
        'wbType': CodelistItemToJSON(value.wbType),
    };
}

