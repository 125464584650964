/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DangerSubstanceRequestRecord
 */
export interface DangerSubstanceRequestRecord {
    /**
     * 
     * @type {number}
     * @memberof DangerSubstanceRequestRecord
     */
    id?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DangerSubstanceRequestRecord
     */
    dangerSubstanceCode?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DangerSubstanceRequestRecord
     */
    concUnitCode?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DangerSubstanceRequestRecord
     */
    concMonth?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DangerSubstanceRequestRecord
     */
    concDay?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DangerSubstanceRequestRecord
     */
    balUnitCode?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DangerSubstanceRequestRecord
     */
    balMonth?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DangerSubstanceRequestRecord
     */
    balDay?: number | null;
}

/**
 * Check if a given object implements the DangerSubstanceRequestRecord interface.
 */
export function instanceOfDangerSubstanceRequestRecord(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DangerSubstanceRequestRecordFromJSON(json: any): DangerSubstanceRequestRecord {
    return DangerSubstanceRequestRecordFromJSONTyped(json, false);
}

export function DangerSubstanceRequestRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): DangerSubstanceRequestRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'dangerSubstanceCode': !exists(json, 'dangerSubstanceCode') ? undefined : json['dangerSubstanceCode'],
        'concUnitCode': !exists(json, 'concUnitCode') ? undefined : json['concUnitCode'],
        'concMonth': !exists(json, 'concMonth') ? undefined : json['concMonth'],
        'concDay': !exists(json, 'concDay') ? undefined : json['concDay'],
        'balUnitCode': !exists(json, 'balUnitCode') ? undefined : json['balUnitCode'],
        'balMonth': !exists(json, 'balMonth') ? undefined : json['balMonth'],
        'balDay': !exists(json, 'balDay') ? undefined : json['balDay'],
    };
}

export function DangerSubstanceRequestRecordToJSON(value?: DangerSubstanceRequestRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'dangerSubstanceCode': value.dangerSubstanceCode,
        'concUnitCode': value.concUnitCode,
        'concMonth': value.concMonth,
        'concDay': value.concDay,
        'balUnitCode': value.balUnitCode,
        'balMonth': value.balMonth,
        'balDay': value.balDay,
    };
}

