/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GpsRecord
 */
export interface GpsRecord {
    /**
     * 
     * @type {any}
     * @memberof GpsRecord
     */
    coordAcqType?: any;
    /**
     * 
     * @type {number}
     * @memberof GpsRecord
     */
    etrs89Latitude?: number;
    /**
     * 
     * @type {number}
     * @memberof GpsRecord
     */
    etrs89Longitude?: number;
    /**
     * 
     * @type {number}
     * @memberof GpsRecord
     */
    jtskX?: number;
    /**
     * 
     * @type {number}
     * @memberof GpsRecord
     */
    jtskY?: number;
    /**
     * 
     * @type {string}
     * @memberof GpsRecord
     */
    latitude?: string;
    /**
     * 
     * @type {string}
     * @memberof GpsRecord
     */
    longitude?: string;
    /**
     * 
     * @type {number}
     * @memberof GpsRecord
     */
    wgs84Latitude?: number;
    /**
     * 
     * @type {number}
     * @memberof GpsRecord
     */
    wgs84Longitude?: number;
}

/**
 * Check if a given object implements the GpsRecord interface.
 */
export function instanceOfGpsRecord(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GpsRecordFromJSON(json: any): GpsRecord {
    return GpsRecordFromJSONTyped(json, false);
}

export function GpsRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): GpsRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'coordAcqType': !exists(json, 'coordAcqType') ? undefined : json['coordAcqType'],
        'etrs89Latitude': !exists(json, 'etrs89Latitude') ? undefined : json['etrs89Latitude'],
        'etrs89Longitude': !exists(json, 'etrs89Longitude') ? undefined : json['etrs89Longitude'],
        'jtskX': !exists(json, 'jtskX') ? undefined : json['jtskX'],
        'jtskY': !exists(json, 'jtskY') ? undefined : json['jtskY'],
        'latitude': !exists(json, 'latitude') ? undefined : json['latitude'],
        'longitude': !exists(json, 'longitude') ? undefined : json['longitude'],
        'wgs84Latitude': !exists(json, 'wgs84Latitude') ? undefined : json['wgs84Latitude'],
        'wgs84Longitude': !exists(json, 'wgs84Longitude') ? undefined : json['wgs84Longitude'],
    };
}

export function GpsRecordToJSON(value?: GpsRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'coordAcqType': value.coordAcqType,
        'etrs89Latitude': value.etrs89Latitude,
        'etrs89Longitude': value.etrs89Longitude,
        'jtskX': value.jtskX,
        'jtskY': value.jtskY,
        'latitude': value.latitude,
        'longitude': value.longitude,
        'wgs84Latitude': value.wgs84Latitude,
        'wgs84Longitude': value.wgs84Longitude,
    };
}

