/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiExceptionRecord,
  NotificationMailRecord,
} from '../models/index';
import {
    ApiExceptionRecordFromJSON,
    ApiExceptionRecordToJSON,
    NotificationMailRecordFromJSON,
    NotificationMailRecordToJSON,
} from '../models/index';

export interface MailNotificationPUBLICAPIApiSendNotificationEmailsRequest {
    notificationMailRecord: NotificationMailRecord;
}

export interface MailNotificationPUBLICAPIApiSendNotificationEmailsBatchRequest {
    notificationMailRecord: NotificationMailRecord;
}

/**
 * 
 */
export class MailNotificationPUBLICAPIApi extends runtime.BaseAPI {

    /**
     * Send email notification
     */
    async sendNotificationEmailsRaw(requestParameters: MailNotificationPUBLICAPIApiSendNotificationEmailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.notificationMailRecord === null || requestParameters.notificationMailRecord === undefined) {
            throw new runtime.RequiredError('notificationMailRecord','Required parameter requestParameters.notificationMailRecord was null or undefined when calling sendNotificationEmails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/mail-notification/v1/send-notification-emails`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NotificationMailRecordToJSON(requestParameters.notificationMailRecord),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Send email notification
     */
    async sendNotificationEmails(notificationMailRecord: NotificationMailRecord, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sendNotificationEmailsRaw({ notificationMailRecord: notificationMailRecord }, initOverrides);
    }

    /**
     * Send email notification
     */
    async sendNotificationEmailsBatchRaw(requestParameters: MailNotificationPUBLICAPIApiSendNotificationEmailsBatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.notificationMailRecord === null || requestParameters.notificationMailRecord === undefined) {
            throw new runtime.RequiredError('notificationMailRecord','Required parameter requestParameters.notificationMailRecord was null or undefined when calling sendNotificationEmailsBatch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/mail-notification/v1/send-notification-emails-batch`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NotificationMailRecordToJSON(requestParameters.notificationMailRecord),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Send email notification
     */
    async sendNotificationEmailsBatch(notificationMailRecord: NotificationMailRecord, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sendNotificationEmailsBatchRaw({ notificationMailRecord: notificationMailRecord }, initOverrides);
    }

}
