/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SubmissionCatalogRecord } from './SubmissionCatalogRecord';
import {
    SubmissionCatalogRecordFromJSON,
    SubmissionCatalogRecordFromJSONTyped,
    SubmissionCatalogRecordToJSON,
} from './SubmissionCatalogRecord';
import type { SubmissionContentRecord } from './SubmissionContentRecord';
import {
    SubmissionContentRecordFromJSON,
    SubmissionContentRecordFromJSONTyped,
    SubmissionContentRecordToJSON,
} from './SubmissionContentRecord';

/**
 * 
 * @export
 * @interface SubmissionRecord
 */
export interface SubmissionRecord {
    /**
     * 
     * @type {number}
     * @memberof SubmissionRecord
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof SubmissionRecord
     */
    templateId?: number;
    /**
     * 
     * @type {string}
     * @memberof SubmissionRecord
     */
    state?: SubmissionRecordStateEnum;
    /**
     * 
     * @type {Array<SubmissionContentRecord>}
     * @memberof SubmissionRecord
     */
    submissionContents?: Array<SubmissionContentRecord>;
    /**
     * 
     * @type {Array<SubmissionCatalogRecord>}
     * @memberof SubmissionRecord
     */
    submissionCatalogs?: Array<SubmissionCatalogRecord>;
    /**
     * 
     * @type {string}
     * @memberof SubmissionRecord
     */
    username?: string;
    /**
     * 
     * @type {string}
     * @memberof SubmissionRecord
     */
    reviewer?: string;
    /**
     * 
     * @type {string}
     * @memberof SubmissionRecord
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof SubmissionRecord
     */
    orgUnit?: string;
    /**
     * 
     * @type {string}
     * @memberof SubmissionRecord
     */
    version?: string;
    /**
     * 
     * @type {string}
     * @memberof SubmissionRecord
     */
    createdTimestamp?: string;
    /**
     * 
     * @type {string}
     * @memberof SubmissionRecord
     */
    updatedTimestamp?: string;
    /**
     * 
     * @type {string}
     * @memberof SubmissionRecord
     */
    approvedTimestamp?: string;
}


/**
 * @export
 */
export const SubmissionRecordStateEnum = {
    Draft: 'DRAFT',
    WaitingForApproval: 'WAITING_FOR_APPROVAL',
    Assigned: 'ASSIGNED',
    Updated: 'UPDATED',
    Submitted: 'SUBMITTED',
    BeingSent: 'BEING_SENT',
    Integrated: 'INTEGRATED',
    IntegrationFailed: 'INTEGRATION_FAILED',
    Approved: 'APPROVED',
    Rejected: 'REJECTED',
    Cancelled: 'CANCELLED'
} as const;
export type SubmissionRecordStateEnum = typeof SubmissionRecordStateEnum[keyof typeof SubmissionRecordStateEnum];


/**
 * Check if a given object implements the SubmissionRecord interface.
 */
export function instanceOfSubmissionRecord(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SubmissionRecordFromJSON(json: any): SubmissionRecord {
    return SubmissionRecordFromJSONTyped(json, false);
}

export function SubmissionRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubmissionRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'templateId': !exists(json, 'templateId') ? undefined : json['templateId'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'submissionContents': !exists(json, 'submissionContents') ? undefined : ((json['submissionContents'] as Array<any>).map(SubmissionContentRecordFromJSON)),
        'submissionCatalogs': !exists(json, 'submissionCatalogs') ? undefined : ((json['submissionCatalogs'] as Array<any>).map(SubmissionCatalogRecordFromJSON)),
        'username': !exists(json, 'username') ? undefined : json['username'],
        'reviewer': !exists(json, 'reviewer') ? undefined : json['reviewer'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'orgUnit': !exists(json, 'orgUnit') ? undefined : json['orgUnit'],
        'version': !exists(json, 'version') ? undefined : json['version'],
        'createdTimestamp': !exists(json, 'createdTimestamp') ? undefined : json['createdTimestamp'],
        'updatedTimestamp': !exists(json, 'updatedTimestamp') ? undefined : json['updatedTimestamp'],
        'approvedTimestamp': !exists(json, 'approvedTimestamp') ? undefined : json['approvedTimestamp'],
    };
}

export function SubmissionRecordToJSON(value?: SubmissionRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'templateId': value.templateId,
        'state': value.state,
        'submissionContents': value.submissionContents === undefined ? undefined : ((value.submissionContents as Array<any>).map(SubmissionContentRecordToJSON)),
        'submissionCatalogs': value.submissionCatalogs === undefined ? undefined : ((value.submissionCatalogs as Array<any>).map(SubmissionCatalogRecordToJSON)),
        'username': value.username,
        'reviewer': value.reviewer,
        'name': value.name,
        'orgUnit': value.orgUnit,
        'version': value.version,
        'createdTimestamp': value.createdTimestamp,
        'updatedTimestamp': value.updatedTimestamp,
        'approvedTimestamp': value.approvedTimestamp,
    };
}

