/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CodeListItemBasicRecord } from './CodeListItemBasicRecord';
import {
    CodeListItemBasicRecordFromJSON,
    CodeListItemBasicRecordFromJSONTyped,
    CodeListItemBasicRecordToJSON,
} from './CodeListItemBasicRecord';

/**
 * 
 * @export
 * @interface WaterEvidenceWaterStructAppendixBasic
 */
export interface WaterEvidenceWaterStructAppendixBasic {
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceWaterStructAppendixBasic
     */
    type?: WaterEvidenceWaterStructAppendixBasicTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceWaterStructAppendixBasic
     */
    id?: number;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof WaterEvidenceWaterStructAppendixBasic
     */
    status?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof WaterEvidenceWaterStructAppendixBasic
     */
    documentType?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceWaterStructAppendixBasic
     */
    dateFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceWaterStructAppendixBasic
     */
    structName?: string;
}


/**
 * @export
 */
export const WaterEvidenceWaterStructAppendixBasicTypeEnum = {
    WaterEvidence: 'WATER_EVIDENCE',
    WaterStruct: 'WATER_STRUCT',
    Other: 'OTHER',
    WaterStructAppendix: 'WATER_STRUCT_APPENDIX'
} as const;
export type WaterEvidenceWaterStructAppendixBasicTypeEnum = typeof WaterEvidenceWaterStructAppendixBasicTypeEnum[keyof typeof WaterEvidenceWaterStructAppendixBasicTypeEnum];


/**
 * Check if a given object implements the WaterEvidenceWaterStructAppendixBasic interface.
 */
export function instanceOfWaterEvidenceWaterStructAppendixBasic(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WaterEvidenceWaterStructAppendixBasicFromJSON(json: any): WaterEvidenceWaterStructAppendixBasic {
    return WaterEvidenceWaterStructAppendixBasicFromJSONTyped(json, false);
}

export function WaterEvidenceWaterStructAppendixBasicFromJSONTyped(json: any, ignoreDiscriminator: boolean): WaterEvidenceWaterStructAppendixBasic {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': !exists(json, 'type') ? undefined : json['type'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'status': !exists(json, 'status') ? undefined : CodeListItemBasicRecordFromJSON(json['status']),
        'documentType': !exists(json, 'documentType') ? undefined : CodeListItemBasicRecordFromJSON(json['documentType']),
        'dateFrom': !exists(json, 'dateFrom') ? undefined : json['dateFrom'],
        'structName': !exists(json, 'structName') ? undefined : json['structName'],
    };
}

export function WaterEvidenceWaterStructAppendixBasicToJSON(value?: WaterEvidenceWaterStructAppendixBasic | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'id': value.id,
        'status': CodeListItemBasicRecordToJSON(value.status),
        'documentType': CodeListItemBasicRecordToJSON(value.documentType),
        'dateFrom': value.dateFrom,
        'structName': value.structName,
    };
}

