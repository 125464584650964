/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CityType } from './CityType';
import {
    CityTypeFromJSON,
    CityTypeFromJSONTyped,
    CityTypeToJSON,
} from './CityType';
import type { CountryType } from './CountryType';
import {
    CountryTypeFromJSON,
    CountryTypeFromJSONTyped,
    CountryTypeToJSON,
} from './CountryType';
import type { DistrictType } from './DistrictType';
import {
    DistrictTypeFromJSON,
    DistrictTypeFromJSONTyped,
    DistrictTypeToJSON,
} from './DistrictType';
import type { LandRegistryType } from './LandRegistryType';
import {
    LandRegistryTypeFromJSON,
    LandRegistryTypeFromJSONTyped,
    LandRegistryTypeToJSON,
} from './LandRegistryType';

/**
 * 
 * @export
 * @interface AddressRecord
 */
export interface AddressRecord {
    /**
     * 
     * @type {string}
     * @memberof AddressRecord
     */
    street?: string;
    /**
     * 
     * @type {CityType}
     * @memberof AddressRecord
     */
    cityType?: CityType;
    /**
     * 
     * @type {string}
     * @memberof AddressRecord
     */
    zip?: string;
    /**
     * 
     * @type {CountryType}
     * @memberof AddressRecord
     */
    countryType?: CountryType;
    /**
     * 
     * @type {DistrictType}
     * @memberof AddressRecord
     */
    districtType?: DistrictType;
    /**
     * 
     * @type {LandRegistryType}
     * @memberof AddressRecord
     */
    landRegistryType?: LandRegistryType;
}

/**
 * Check if a given object implements the AddressRecord interface.
 */
export function instanceOfAddressRecord(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AddressRecordFromJSON(json: any): AddressRecord {
    return AddressRecordFromJSONTyped(json, false);
}

export function AddressRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddressRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'street': !exists(json, 'street') ? undefined : json['street'],
        'cityType': !exists(json, 'cityType') ? undefined : CityTypeFromJSON(json['cityType']),
        'zip': !exists(json, 'zip') ? undefined : json['zip'],
        'countryType': !exists(json, 'countryType') ? undefined : CountryTypeFromJSON(json['countryType']),
        'districtType': !exists(json, 'districtType') ? undefined : DistrictTypeFromJSON(json['districtType']),
        'landRegistryType': !exists(json, 'landRegistryType') ? undefined : LandRegistryTypeFromJSON(json['landRegistryType']),
    };
}

export function AddressRecordToJSON(value?: AddressRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'street': value.street,
        'cityType': CityTypeToJSON(value.cityType),
        'zip': value.zip,
        'countryType': CountryTypeToJSON(value.countryType),
        'districtType': DistrictTypeToJSON(value.districtType),
        'landRegistryType': LandRegistryTypeToJSON(value.landRegistryType),
    };
}

