/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CodeListItemBasicRecord } from './CodeListItemBasicRecord';
import {
    CodeListItemBasicRecordFromJSON,
    CodeListItemBasicRecordFromJSONTyped,
    CodeListItemBasicRecordToJSON,
} from './CodeListItemBasicRecord';

/**
 * 
 * @export
 * @interface HydroAnalysisFloodPlainUseResponseRecord
 */
export interface HydroAnalysisFloodPlainUseResponseRecord {
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisFloodPlainUseResponseRecord
     */
    id?: number;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof HydroAnalysisFloodPlainUseResponseRecord
     */
    floodPlainUseItem?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisFloodPlainUseResponseRecord
     */
    floodPlainUseCount?: number;
}

/**
 * Check if a given object implements the HydroAnalysisFloodPlainUseResponseRecord interface.
 */
export function instanceOfHydroAnalysisFloodPlainUseResponseRecord(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function HydroAnalysisFloodPlainUseResponseRecordFromJSON(json: any): HydroAnalysisFloodPlainUseResponseRecord {
    return HydroAnalysisFloodPlainUseResponseRecordFromJSONTyped(json, false);
}

export function HydroAnalysisFloodPlainUseResponseRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): HydroAnalysisFloodPlainUseResponseRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'floodPlainUseItem': !exists(json, 'floodPlainUseItem') ? undefined : CodeListItemBasicRecordFromJSON(json['floodPlainUseItem']),
        'floodPlainUseCount': !exists(json, 'floodPlainUseCount') ? undefined : json['floodPlainUseCount'],
    };
}

export function HydroAnalysisFloodPlainUseResponseRecordToJSON(value?: HydroAnalysisFloodPlainUseResponseRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'floodPlainUseItem': CodeListItemBasicRecordToJSON(value.floodPlainUseItem),
        'floodPlainUseCount': value.floodPlainUseCount,
    };
}

