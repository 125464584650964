/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NotificationMailRecord
 */
export interface NotificationMailRecord {
    /**
     * 
     * @type {Array<string>}
     * @memberof NotificationMailRecord
     */
    recipients?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof NotificationMailRecord
     */
    subject?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationMailRecord
     */
    text?: string;
}

/**
 * Check if a given object implements the NotificationMailRecord interface.
 */
export function instanceOfNotificationMailRecord(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function NotificationMailRecordFromJSON(json: any): NotificationMailRecord {
    return NotificationMailRecordFromJSONTyped(json, false);
}

export function NotificationMailRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationMailRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'recipients': !exists(json, 'recipients') ? undefined : json['recipients'],
        'subject': !exists(json, 'subject') ? undefined : json['subject'],
        'text': !exists(json, 'text') ? undefined : json['text'],
    };
}

export function NotificationMailRecordToJSON(value?: NotificationMailRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'recipients': value.recipients,
        'subject': value.subject,
        'text': value.text,
    };
}

