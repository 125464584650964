/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CodeListItemBasicRecord } from './CodeListItemBasicRecord';
import {
    CodeListItemBasicRecordFromJSON,
    CodeListItemBasicRecordFromJSONTyped,
    CodeListItemBasicRecordToJSON,
} from './CodeListItemBasicRecord';
import type { FileRecord } from './FileRecord';
import {
    FileRecordFromJSON,
    FileRecordFromJSONTyped,
    FileRecordToJSON,
} from './FileRecord';
import type { HydroAnalysisFileResponseRecord } from './HydroAnalysisFileResponseRecord';
import {
    HydroAnalysisFileResponseRecordFromJSON,
    HydroAnalysisFileResponseRecordFromJSONTyped,
    HydroAnalysisFileResponseRecordToJSON,
} from './HydroAnalysisFileResponseRecord';
import type { HydroAnalysisFloodPlainUseResponseRecord } from './HydroAnalysisFloodPlainUseResponseRecord';
import {
    HydroAnalysisFloodPlainUseResponseRecordFromJSON,
    HydroAnalysisFloodPlainUseResponseRecordFromJSONTyped,
    HydroAnalysisFloodPlainUseResponseRecordToJSON,
} from './HydroAnalysisFloodPlainUseResponseRecord';
import type { HydroAnalysisGeologyResponseRecord } from './HydroAnalysisGeologyResponseRecord';
import {
    HydroAnalysisGeologyResponseRecordFromJSON,
    HydroAnalysisGeologyResponseRecordFromJSONTyped,
    HydroAnalysisGeologyResponseRecordToJSON,
} from './HydroAnalysisGeologyResponseRecord';
import type { HydroAnalysisLadUseResponseRecord } from './HydroAnalysisLadUseResponseRecord';
import {
    HydroAnalysisLadUseResponseRecordFromJSON,
    HydroAnalysisLadUseResponseRecordFromJSONTyped,
    HydroAnalysisLadUseResponseRecordToJSON,
} from './HydroAnalysisLadUseResponseRecord';
import type { HydroAnalysisRiparianResponseRecord } from './HydroAnalysisRiparianResponseRecord';
import {
    HydroAnalysisRiparianResponseRecordFromJSON,
    HydroAnalysisRiparianResponseRecordFromJSONTyped,
    HydroAnalysisRiparianResponseRecordToJSON,
} from './HydroAnalysisRiparianResponseRecord';
import type { HydroAnalysisScoreResponseRecord } from './HydroAnalysisScoreResponseRecord';
import {
    HydroAnalysisScoreResponseRecordFromJSON,
    HydroAnalysisScoreResponseRecordFromJSONTyped,
    HydroAnalysisScoreResponseRecordToJSON,
} from './HydroAnalysisScoreResponseRecord';
import type { HydroAnalysisSoilTypeResponseRecord } from './HydroAnalysisSoilTypeResponseRecord';
import {
    HydroAnalysisSoilTypeResponseRecordFromJSON,
    HydroAnalysisSoilTypeResponseRecordFromJSONTyped,
    HydroAnalysisSoilTypeResponseRecordToJSON,
} from './HydroAnalysisSoilTypeResponseRecord';
import type { MonitoringRecord } from './MonitoringRecord';
import {
    MonitoringRecordFromJSON,
    MonitoringRecordFromJSONTyped,
    MonitoringRecordToJSON,
} from './MonitoringRecord';

/**
 * 
 * @export
 * @interface HydroMorphologyProtocolResponseRecord
 */
export interface HydroMorphologyProtocolResponseRecord {
    /**
     * 
     * @type {number}
     * @memberof HydroMorphologyProtocolResponseRecord
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof HydroMorphologyProtocolResponseRecord
     */
    analysisDate?: string;
    /**
     * 
     * @type {number}
     * @memberof HydroMorphologyProtocolResponseRecord
     */
    catchmentArea?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroMorphologyProtocolResponseRecord
     */
    distanceToSource?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroMorphologyProtocolResponseRecord
     */
    meanSlope?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroMorphologyProtocolResponseRecord
     */
    wettedWidth?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroMorphologyProtocolResponseRecord
     */
    bankfullWidth?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroMorphologyProtocolResponseRecord
     */
    maxDepthTo1meter?: number;
    /**
     * 
     * @type {boolean}
     * @memberof HydroMorphologyProtocolResponseRecord
     */
    dryRiverBed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof HydroMorphologyProtocolResponseRecord
     */
    maxDepthOver1meter?: boolean;
    /**
     * 
     * @type {number}
     * @memberof HydroMorphologyProtocolResponseRecord
     */
    migrationBarriersU?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroMorphologyProtocolResponseRecord
     */
    migrationBarriersUHeight?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroMorphologyProtocolResponseRecord
     */
    migrationBarriersUDistance?: number;
    /**
     * 
     * @type {string}
     * @memberof HydroMorphologyProtocolResponseRecord
     */
    migrationBarriersUNote?: string;
    /**
     * 
     * @type {number}
     * @memberof HydroMorphologyProtocolResponseRecord
     */
    migrationBarriersD?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroMorphologyProtocolResponseRecord
     */
    migrationBarriersDHeight?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroMorphologyProtocolResponseRecord
     */
    migrationBarriersDDistance?: number;
    /**
     * 
     * @type {string}
     * @memberof HydroMorphologyProtocolResponseRecord
     */
    migrationBarriersDNote?: string;
    /**
     * 
     * @type {number}
     * @memberof HydroMorphologyProtocolResponseRecord
     */
    topographyMax?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroMorphologyProtocolResponseRecord
     */
    topographyMin?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroMorphologyProtocolResponseRecord
     */
    avgFlow?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroMorphologyProtocolResponseRecord
     */
    maxFlow?: number;
    /**
     * 
     * @type {string}
     * @memberof HydroMorphologyProtocolResponseRecord
     */
    maxFlowDate?: string;
    /**
     * 
     * @type {number}
     * @memberof HydroMorphologyProtocolResponseRecord
     */
    minFlow?: number;
    /**
     * 
     * @type {string}
     * @memberof HydroMorphologyProtocolResponseRecord
     */
    minFlowDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof HydroMorphologyProtocolResponseRecord
     */
    minFlowRep?: boolean;
    /**
     * 
     * @type {string}
     * @memberof HydroMorphologyProtocolResponseRecord
     */
    note?: string;
    /**
     * 
     * @type {string}
     * @memberof HydroMorphologyProtocolResponseRecord
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof HydroMorphologyProtocolResponseRecord
     */
    updatedBy?: string;
    /**
     * 
     * @type {MonitoringRecord}
     * @memberof HydroMorphologyProtocolResponseRecord
     */
    monPoint?: MonitoringRecord | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof HydroMorphologyProtocolResponseRecord
     */
    researcherItem?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof HydroMorphologyProtocolResponseRecord
     */
    depthVariationItem?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof HydroMorphologyProtocolResponseRecord
     */
    vegetationItem?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof HydroMorphologyProtocolResponseRecord
     */
    channelPlanTypeItem?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof HydroMorphologyProtocolResponseRecord
     */
    channelPlanItem?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof HydroMorphologyProtocolResponseRecord
     */
    valleyFormItem?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof HydroMorphologyProtocolResponseRecord
     */
    migrationBarriersUTypesItem?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof HydroMorphologyProtocolResponseRecord
     */
    migrationBarriersUStructureItem?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof HydroMorphologyProtocolResponseRecord
     */
    migrationBarriersDTypesItem?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof HydroMorphologyProtocolResponseRecord
     */
    migrationBarriersDStructureItem?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof HydroMorphologyProtocolResponseRecord
     */
    migrationRegimeDiffGwItem?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof HydroMorphologyProtocolResponseRecord
     */
    migrationRegimeDiffSwItem?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof HydroMorphologyProtocolResponseRecord
     */
    statusItem?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {Array<CodeListItemBasicRecord>}
     * @memberof HydroMorphologyProtocolResponseRecord
     */
    riverUseItems?: Array<CodeListItemBasicRecord>;
    /**
     * 
     * @type {Array<CodeListItemBasicRecord>}
     * @memberof HydroMorphologyProtocolResponseRecord
     */
    crossSectionItems?: Array<CodeListItemBasicRecord>;
    /**
     * 
     * @type {Array<CodeListItemBasicRecord>}
     * @memberof HydroMorphologyProtocolResponseRecord
     */
    bankStabilizationItems?: Array<CodeListItemBasicRecord>;
    /**
     * 
     * @type {Array<HydroAnalysisRiparianResponseRecord>}
     * @memberof HydroMorphologyProtocolResponseRecord
     */
    riparianZoneItems?: Array<HydroAnalysisRiparianResponseRecord>;
    /**
     * 
     * @type {Array<HydroAnalysisFloodPlainUseResponseRecord>}
     * @memberof HydroMorphologyProtocolResponseRecord
     */
    floodplainZoneItems?: Array<HydroAnalysisFloodPlainUseResponseRecord>;
    /**
     * 
     * @type {Array<HydroAnalysisGeologyResponseRecord>}
     * @memberof HydroMorphologyProtocolResponseRecord
     */
    geologyItems?: Array<HydroAnalysisGeologyResponseRecord>;
    /**
     * 
     * @type {Array<HydroAnalysisSoilTypeResponseRecord>}
     * @memberof HydroMorphologyProtocolResponseRecord
     */
    soilTypeItems?: Array<HydroAnalysisSoilTypeResponseRecord>;
    /**
     * 
     * @type {Array<HydroAnalysisLadUseResponseRecord>}
     * @memberof HydroMorphologyProtocolResponseRecord
     */
    ladUseItems?: Array<HydroAnalysisLadUseResponseRecord>;
    /**
     * 
     * @type {Array<HydroAnalysisFileResponseRecord>}
     * @memberof HydroMorphologyProtocolResponseRecord
     */
    hydroAnalysisFiles?: Array<HydroAnalysisFileResponseRecord>;
    /**
     * 
     * @type {HydroAnalysisScoreResponseRecord}
     * @memberof HydroMorphologyProtocolResponseRecord
     */
    hydroAnalysisScore?: HydroAnalysisScoreResponseRecord;
    /**
     * 
     * @type {Array<FileRecord>}
     * @memberof HydroMorphologyProtocolResponseRecord
     */
    photos?: Array<FileRecord>;
}

/**
 * Check if a given object implements the HydroMorphologyProtocolResponseRecord interface.
 */
export function instanceOfHydroMorphologyProtocolResponseRecord(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function HydroMorphologyProtocolResponseRecordFromJSON(json: any): HydroMorphologyProtocolResponseRecord {
    return HydroMorphologyProtocolResponseRecordFromJSONTyped(json, false);
}

export function HydroMorphologyProtocolResponseRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): HydroMorphologyProtocolResponseRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'analysisDate': !exists(json, 'analysisDate') ? undefined : json['analysisDate'],
        'catchmentArea': !exists(json, 'catchmentArea') ? undefined : json['catchmentArea'],
        'distanceToSource': !exists(json, 'distanceToSource') ? undefined : json['distanceToSource'],
        'meanSlope': !exists(json, 'meanSlope') ? undefined : json['meanSlope'],
        'wettedWidth': !exists(json, 'wettedWidth') ? undefined : json['wettedWidth'],
        'bankfullWidth': !exists(json, 'bankfullWidth') ? undefined : json['bankfullWidth'],
        'maxDepthTo1meter': !exists(json, 'maxDepthTo1meter') ? undefined : json['maxDepthTo1meter'],
        'dryRiverBed': !exists(json, 'dryRiverBed') ? undefined : json['dryRiverBed'],
        'maxDepthOver1meter': !exists(json, 'maxDepthOver1meter') ? undefined : json['maxDepthOver1meter'],
        'migrationBarriersU': !exists(json, 'migrationBarriers_u') ? undefined : json['migrationBarriers_u'],
        'migrationBarriersUHeight': !exists(json, 'migrationBarriers_u_height') ? undefined : json['migrationBarriers_u_height'],
        'migrationBarriersUDistance': !exists(json, 'migrationBarriers_u_distance') ? undefined : json['migrationBarriers_u_distance'],
        'migrationBarriersUNote': !exists(json, 'migrationBarriers_u_note') ? undefined : json['migrationBarriers_u_note'],
        'migrationBarriersD': !exists(json, 'migrationBarriers_d') ? undefined : json['migrationBarriers_d'],
        'migrationBarriersDHeight': !exists(json, 'migrationBarriers_d_height') ? undefined : json['migrationBarriers_d_height'],
        'migrationBarriersDDistance': !exists(json, 'migrationBarriers_d_distance') ? undefined : json['migrationBarriers_d_distance'],
        'migrationBarriersDNote': !exists(json, 'migrationBarriers_d_note') ? undefined : json['migrationBarriers_d_note'],
        'topographyMax': !exists(json, 'topographyMax') ? undefined : json['topographyMax'],
        'topographyMin': !exists(json, 'topographyMin') ? undefined : json['topographyMin'],
        'avgFlow': !exists(json, 'avgFlow') ? undefined : json['avgFlow'],
        'maxFlow': !exists(json, 'maxFlow') ? undefined : json['maxFlow'],
        'maxFlowDate': !exists(json, 'maxFlowDate') ? undefined : json['maxFlowDate'],
        'minFlow': !exists(json, 'minFlow') ? undefined : json['minFlow'],
        'minFlowDate': !exists(json, 'minFlowDate') ? undefined : json['minFlowDate'],
        'minFlowRep': !exists(json, 'minFlowRep') ? undefined : json['minFlowRep'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
        'monPoint': !exists(json, 'monPoint') ? undefined : MonitoringRecordFromJSON(json['monPoint']),
        'researcherItem': !exists(json, 'researcherItem') ? undefined : CodeListItemBasicRecordFromJSON(json['researcherItem']),
        'depthVariationItem': !exists(json, 'depthVariationItem') ? undefined : CodeListItemBasicRecordFromJSON(json['depthVariationItem']),
        'vegetationItem': !exists(json, 'vegetationItem') ? undefined : CodeListItemBasicRecordFromJSON(json['vegetationItem']),
        'channelPlanTypeItem': !exists(json, 'channelPlanTypeItem') ? undefined : CodeListItemBasicRecordFromJSON(json['channelPlanTypeItem']),
        'channelPlanItem': !exists(json, 'channelPlanItem') ? undefined : CodeListItemBasicRecordFromJSON(json['channelPlanItem']),
        'valleyFormItem': !exists(json, 'valleyFormItem') ? undefined : CodeListItemBasicRecordFromJSON(json['valleyFormItem']),
        'migrationBarriersUTypesItem': !exists(json, 'migrationBarriersUTypesItem') ? undefined : CodeListItemBasicRecordFromJSON(json['migrationBarriersUTypesItem']),
        'migrationBarriersUStructureItem': !exists(json, 'migrationBarriersUStructureItem') ? undefined : CodeListItemBasicRecordFromJSON(json['migrationBarriersUStructureItem']),
        'migrationBarriersDTypesItem': !exists(json, 'migrationBarriersDTypesItem') ? undefined : CodeListItemBasicRecordFromJSON(json['migrationBarriersDTypesItem']),
        'migrationBarriersDStructureItem': !exists(json, 'migrationBarriersDStructureItem') ? undefined : CodeListItemBasicRecordFromJSON(json['migrationBarriersDStructureItem']),
        'migrationRegimeDiffGwItem': !exists(json, 'migrationRegimeDiffGwItem') ? undefined : CodeListItemBasicRecordFromJSON(json['migrationRegimeDiffGwItem']),
        'migrationRegimeDiffSwItem': !exists(json, 'migrationRegimeDiffSwItem') ? undefined : CodeListItemBasicRecordFromJSON(json['migrationRegimeDiffSwItem']),
        'statusItem': !exists(json, 'statusItem') ? undefined : CodeListItemBasicRecordFromJSON(json['statusItem']),
        'riverUseItems': !exists(json, 'riverUseItems') ? undefined : ((json['riverUseItems'] as Array<any>).map(CodeListItemBasicRecordFromJSON)),
        'crossSectionItems': !exists(json, 'crossSectionItems') ? undefined : ((json['crossSectionItems'] as Array<any>).map(CodeListItemBasicRecordFromJSON)),
        'bankStabilizationItems': !exists(json, 'bankStabilizationItems') ? undefined : ((json['bankStabilizationItems'] as Array<any>).map(CodeListItemBasicRecordFromJSON)),
        'riparianZoneItems': !exists(json, 'riparianZoneItems') ? undefined : ((json['riparianZoneItems'] as Array<any>).map(HydroAnalysisRiparianResponseRecordFromJSON)),
        'floodplainZoneItems': !exists(json, 'floodplainZoneItems') ? undefined : ((json['floodplainZoneItems'] as Array<any>).map(HydroAnalysisFloodPlainUseResponseRecordFromJSON)),
        'geologyItems': !exists(json, 'geologyItems') ? undefined : ((json['geologyItems'] as Array<any>).map(HydroAnalysisGeologyResponseRecordFromJSON)),
        'soilTypeItems': !exists(json, 'soilTypeItems') ? undefined : ((json['soilTypeItems'] as Array<any>).map(HydroAnalysisSoilTypeResponseRecordFromJSON)),
        'ladUseItems': !exists(json, 'ladUseItems') ? undefined : ((json['ladUseItems'] as Array<any>).map(HydroAnalysisLadUseResponseRecordFromJSON)),
        'hydroAnalysisFiles': !exists(json, 'hydroAnalysisFiles') ? undefined : ((json['hydroAnalysisFiles'] as Array<any>).map(HydroAnalysisFileResponseRecordFromJSON)),
        'hydroAnalysisScore': !exists(json, 'hydroAnalysisScore') ? undefined : HydroAnalysisScoreResponseRecordFromJSON(json['hydroAnalysisScore']),
        'photos': !exists(json, 'photos') ? undefined : ((json['photos'] as Array<any>).map(FileRecordFromJSON)),
    };
}

export function HydroMorphologyProtocolResponseRecordToJSON(value?: HydroMorphologyProtocolResponseRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'analysisDate': value.analysisDate,
        'catchmentArea': value.catchmentArea,
        'distanceToSource': value.distanceToSource,
        'meanSlope': value.meanSlope,
        'wettedWidth': value.wettedWidth,
        'bankfullWidth': value.bankfullWidth,
        'maxDepthTo1meter': value.maxDepthTo1meter,
        'dryRiverBed': value.dryRiverBed,
        'maxDepthOver1meter': value.maxDepthOver1meter,
        'migrationBarriers_u': value.migrationBarriersU,
        'migrationBarriers_u_height': value.migrationBarriersUHeight,
        'migrationBarriers_u_distance': value.migrationBarriersUDistance,
        'migrationBarriers_u_note': value.migrationBarriersUNote,
        'migrationBarriers_d': value.migrationBarriersD,
        'migrationBarriers_d_height': value.migrationBarriersDHeight,
        'migrationBarriers_d_distance': value.migrationBarriersDDistance,
        'migrationBarriers_d_note': value.migrationBarriersDNote,
        'topographyMax': value.topographyMax,
        'topographyMin': value.topographyMin,
        'avgFlow': value.avgFlow,
        'maxFlow': value.maxFlow,
        'maxFlowDate': value.maxFlowDate,
        'minFlow': value.minFlow,
        'minFlowDate': value.minFlowDate,
        'minFlowRep': value.minFlowRep,
        'note': value.note,
        'createdBy': value.createdBy,
        'updatedBy': value.updatedBy,
        'monPoint': MonitoringRecordToJSON(value.monPoint),
        'researcherItem': CodeListItemBasicRecordToJSON(value.researcherItem),
        'depthVariationItem': CodeListItemBasicRecordToJSON(value.depthVariationItem),
        'vegetationItem': CodeListItemBasicRecordToJSON(value.vegetationItem),
        'channelPlanTypeItem': CodeListItemBasicRecordToJSON(value.channelPlanTypeItem),
        'channelPlanItem': CodeListItemBasicRecordToJSON(value.channelPlanItem),
        'valleyFormItem': CodeListItemBasicRecordToJSON(value.valleyFormItem),
        'migrationBarriersUTypesItem': CodeListItemBasicRecordToJSON(value.migrationBarriersUTypesItem),
        'migrationBarriersUStructureItem': CodeListItemBasicRecordToJSON(value.migrationBarriersUStructureItem),
        'migrationBarriersDTypesItem': CodeListItemBasicRecordToJSON(value.migrationBarriersDTypesItem),
        'migrationBarriersDStructureItem': CodeListItemBasicRecordToJSON(value.migrationBarriersDStructureItem),
        'migrationRegimeDiffGwItem': CodeListItemBasicRecordToJSON(value.migrationRegimeDiffGwItem),
        'migrationRegimeDiffSwItem': CodeListItemBasicRecordToJSON(value.migrationRegimeDiffSwItem),
        'statusItem': CodeListItemBasicRecordToJSON(value.statusItem),
        'riverUseItems': value.riverUseItems === undefined ? undefined : ((value.riverUseItems as Array<any>).map(CodeListItemBasicRecordToJSON)),
        'crossSectionItems': value.crossSectionItems === undefined ? undefined : ((value.crossSectionItems as Array<any>).map(CodeListItemBasicRecordToJSON)),
        'bankStabilizationItems': value.bankStabilizationItems === undefined ? undefined : ((value.bankStabilizationItems as Array<any>).map(CodeListItemBasicRecordToJSON)),
        'riparianZoneItems': value.riparianZoneItems === undefined ? undefined : ((value.riparianZoneItems as Array<any>).map(HydroAnalysisRiparianResponseRecordToJSON)),
        'floodplainZoneItems': value.floodplainZoneItems === undefined ? undefined : ((value.floodplainZoneItems as Array<any>).map(HydroAnalysisFloodPlainUseResponseRecordToJSON)),
        'geologyItems': value.geologyItems === undefined ? undefined : ((value.geologyItems as Array<any>).map(HydroAnalysisGeologyResponseRecordToJSON)),
        'soilTypeItems': value.soilTypeItems === undefined ? undefined : ((value.soilTypeItems as Array<any>).map(HydroAnalysisSoilTypeResponseRecordToJSON)),
        'ladUseItems': value.ladUseItems === undefined ? undefined : ((value.ladUseItems as Array<any>).map(HydroAnalysisLadUseResponseRecordToJSON)),
        'hydroAnalysisFiles': value.hydroAnalysisFiles === undefined ? undefined : ((value.hydroAnalysisFiles as Array<any>).map(HydroAnalysisFileResponseRecordToJSON)),
        'hydroAnalysisScore': HydroAnalysisScoreResponseRecordToJSON(value.hydroAnalysisScore),
        'photos': value.photos === undefined ? undefined : ((value.photos as Array<any>).map(FileRecordToJSON)),
    };
}

