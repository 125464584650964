/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface QualityAssessmentFileRecord
 */
export interface QualityAssessmentFileRecord {
    /**
     * 
     * @type {number}
     * @memberof QualityAssessmentFileRecord
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof QualityAssessmentFileRecord
     */
    fileName?: string;
    /**
     * 
     * @type {string}
     * @memberof QualityAssessmentFileRecord
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof QualityAssessmentFileRecord
     */
    createdTimestamp?: string;
    /**
     * 
     * @type {string}
     * @memberof QualityAssessmentFileRecord
     */
    updatedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof QualityAssessmentFileRecord
     */
    updatedTimestamp?: string;
    /**
     * 
     * @type {number}
     * @memberof QualityAssessmentFileRecord
     */
    statusItemCode?: number;
    /**
     * 
     * @type {string}
     * @memberof QualityAssessmentFileRecord
     */
    fileMime?: string;
    /**
     * 
     * @type {string}
     * @memberof QualityAssessmentFileRecord
     */
    content?: string;
}

/**
 * Check if a given object implements the QualityAssessmentFileRecord interface.
 */
export function instanceOfQualityAssessmentFileRecord(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function QualityAssessmentFileRecordFromJSON(json: any): QualityAssessmentFileRecord {
    return QualityAssessmentFileRecordFromJSONTyped(json, false);
}

export function QualityAssessmentFileRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): QualityAssessmentFileRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'fileName': !exists(json, 'fileName') ? undefined : json['fileName'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'createdTimestamp': !exists(json, 'createdTimestamp') ? undefined : json['createdTimestamp'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
        'updatedTimestamp': !exists(json, 'updatedTimestamp') ? undefined : json['updatedTimestamp'],
        'statusItemCode': !exists(json, 'statusItemCode') ? undefined : json['statusItemCode'],
        'fileMime': !exists(json, 'fileMime') ? undefined : json['fileMime'],
        'content': !exists(json, 'content') ? undefined : json['content'],
    };
}

export function QualityAssessmentFileRecordToJSON(value?: QualityAssessmentFileRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'fileName': value.fileName,
        'createdBy': value.createdBy,
        'createdTimestamp': value.createdTimestamp,
        'updatedBy': value.updatedBy,
        'updatedTimestamp': value.updatedTimestamp,
        'statusItemCode': value.statusItemCode,
        'fileMime': value.fileMime,
        'content': value.content,
    };
}

