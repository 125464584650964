/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CodeListItemBasicRecord } from './CodeListItemBasicRecord';
import {
    CodeListItemBasicRecordFromJSON,
    CodeListItemBasicRecordFromJSONTyped,
    CodeListItemBasicRecordToJSON,
} from './CodeListItemBasicRecord';

/**
 * 
 * @export
 * @interface WaterStructSewerResponseRecord
 */
export interface WaterStructSewerResponseRecord {
    /**
     * 
     * @type {number}
     * @memberof WaterStructSewerResponseRecord
     */
    id?: number | null;
    /**
     * 
     * @type {string}
     * @memberof WaterStructSewerResponseRecord
     */
    name?: string | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof WaterStructSewerResponseRecord
     */
    syst?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof WaterStructSewerResponseRecord
     */
    character?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructSewerResponseRecord
     */
    supplies?: number | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof WaterStructSewerResponseRecord
     */
    type?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructSewerResponseRecord
     */
    length?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructSewerResponseRecord
     */
    lengthByType?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructSewerResponseRecord
     */
    minSize?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructSewerResponseRecord
     */
    maxSize?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructSewerResponseRecord
     */
    chamberNum?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructSewerResponseRecord
     */
    stationNum?: number | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof WaterStructSewerResponseRecord
     */
    waterType?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {string}
     * @memberof WaterStructSewerResponseRecord
     */
    production?: string | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof WaterStructSewerResponseRecord
     */
    permObject?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {boolean}
     * @memberof WaterStructSewerResponseRecord
     */
    testOper?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructSewerResponseRecord
     */
    testDuration?: number | null;
}

/**
 * Check if a given object implements the WaterStructSewerResponseRecord interface.
 */
export function instanceOfWaterStructSewerResponseRecord(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WaterStructSewerResponseRecordFromJSON(json: any): WaterStructSewerResponseRecord {
    return WaterStructSewerResponseRecordFromJSONTyped(json, false);
}

export function WaterStructSewerResponseRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): WaterStructSewerResponseRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'syst': !exists(json, 'syst') ? undefined : CodeListItemBasicRecordFromJSON(json['syst']),
        'character': !exists(json, 'character') ? undefined : CodeListItemBasicRecordFromJSON(json['character']),
        'supplies': !exists(json, 'supplies') ? undefined : json['supplies'],
        'type': !exists(json, 'type') ? undefined : CodeListItemBasicRecordFromJSON(json['type']),
        'length': !exists(json, 'length') ? undefined : json['length'],
        'lengthByType': !exists(json, 'lengthByType') ? undefined : json['lengthByType'],
        'minSize': !exists(json, 'minSize') ? undefined : json['minSize'],
        'maxSize': !exists(json, 'maxSize') ? undefined : json['maxSize'],
        'chamberNum': !exists(json, 'chamberNum') ? undefined : json['chamberNum'],
        'stationNum': !exists(json, 'stationNum') ? undefined : json['stationNum'],
        'waterType': !exists(json, 'waterType') ? undefined : CodeListItemBasicRecordFromJSON(json['waterType']),
        'production': !exists(json, 'production') ? undefined : json['production'],
        'permObject': !exists(json, 'permObject') ? undefined : CodeListItemBasicRecordFromJSON(json['permObject']),
        'testOper': !exists(json, 'testOper') ? undefined : json['testOper'],
        'testDuration': !exists(json, 'testDuration') ? undefined : json['testDuration'],
    };
}

export function WaterStructSewerResponseRecordToJSON(value?: WaterStructSewerResponseRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'syst': CodeListItemBasicRecordToJSON(value.syst),
        'character': CodeListItemBasicRecordToJSON(value.character),
        'supplies': value.supplies,
        'type': CodeListItemBasicRecordToJSON(value.type),
        'length': value.length,
        'lengthByType': value.lengthByType,
        'minSize': value.minSize,
        'maxSize': value.maxSize,
        'chamberNum': value.chamberNum,
        'stationNum': value.stationNum,
        'waterType': CodeListItemBasicRecordToJSON(value.waterType),
        'production': value.production,
        'permObject': CodeListItemBasicRecordToJSON(value.permObject),
        'testOper': value.testOper,
        'testDuration': value.testDuration,
    };
}

