/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CodeListItemBasicRecord } from './CodeListItemBasicRecord';
import {
    CodeListItemBasicRecordFromJSON,
    CodeListItemBasicRecordFromJSONTyped,
    CodeListItemBasicRecordToJSON,
} from './CodeListItemBasicRecord';

/**
 * 
 * @export
 * @interface HydroAnalysisLadUseResponseRecord
 */
export interface HydroAnalysisLadUseResponseRecord {
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisLadUseResponseRecord
     */
    id?: number;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof HydroAnalysisLadUseResponseRecord
     */
    landUseItem?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {boolean}
     * @memberof HydroAnalysisLadUseResponseRecord
     */
    prevailing?: boolean;
}

/**
 * Check if a given object implements the HydroAnalysisLadUseResponseRecord interface.
 */
export function instanceOfHydroAnalysisLadUseResponseRecord(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function HydroAnalysisLadUseResponseRecordFromJSON(json: any): HydroAnalysisLadUseResponseRecord {
    return HydroAnalysisLadUseResponseRecordFromJSONTyped(json, false);
}

export function HydroAnalysisLadUseResponseRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): HydroAnalysisLadUseResponseRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'landUseItem': !exists(json, 'landUseItem') ? undefined : CodeListItemBasicRecordFromJSON(json['landUseItem']),
        'prevailing': !exists(json, 'prevailing') ? undefined : json['prevailing'],
    };
}

export function HydroAnalysisLadUseResponseRecordToJSON(value?: HydroAnalysisLadUseResponseRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'landUseItem': CodeListItemBasicRecordToJSON(value.landUseItem),
        'prevailing': value.prevailing,
    };
}

