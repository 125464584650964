/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CodeListItemBasicRecord } from './CodeListItemBasicRecord';
import {
    CodeListItemBasicRecordFromJSON,
    CodeListItemBasicRecordFromJSONTyped,
    CodeListItemBasicRecordToJSON,
} from './CodeListItemBasicRecord';

/**
 * 
 * @export
 * @interface WaterStructTreatmentResponseRecord
 */
export interface WaterStructTreatmentResponseRecord {
    /**
     * 
     * @type {number}
     * @memberof WaterStructTreatmentResponseRecord
     */
    id?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructTreatmentResponseRecord
     */
    avCap?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructTreatmentResponseRecord
     */
    maxCap?: number | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof WaterStructTreatmentResponseRecord
     */
    waterOrigin?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof WaterStructTreatmentResponseRecord
     */
    waterCat?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof WaterStructTreatmentResponseRecord
     */
    waterModif?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof WaterStructTreatmentResponseRecord
     */
    sludgeHand?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof WaterStructTreatmentResponseRecord
     */
    sludgeStab?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof WaterStructTreatmentResponseRecord
     */
    sludgeDrain?: CodeListItemBasicRecord | null;
}

/**
 * Check if a given object implements the WaterStructTreatmentResponseRecord interface.
 */
export function instanceOfWaterStructTreatmentResponseRecord(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WaterStructTreatmentResponseRecordFromJSON(json: any): WaterStructTreatmentResponseRecord {
    return WaterStructTreatmentResponseRecordFromJSONTyped(json, false);
}

export function WaterStructTreatmentResponseRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): WaterStructTreatmentResponseRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'avCap': !exists(json, 'avCap') ? undefined : json['avCap'],
        'maxCap': !exists(json, 'maxCap') ? undefined : json['maxCap'],
        'waterOrigin': !exists(json, 'waterOrigin') ? undefined : CodeListItemBasicRecordFromJSON(json['waterOrigin']),
        'waterCat': !exists(json, 'waterCat') ? undefined : CodeListItemBasicRecordFromJSON(json['waterCat']),
        'waterModif': !exists(json, 'waterModif') ? undefined : CodeListItemBasicRecordFromJSON(json['waterModif']),
        'sludgeHand': !exists(json, 'sludgeHand') ? undefined : CodeListItemBasicRecordFromJSON(json['sludgeHand']),
        'sludgeStab': !exists(json, 'sludgeStab') ? undefined : CodeListItemBasicRecordFromJSON(json['sludgeStab']),
        'sludgeDrain': !exists(json, 'sludgeDrain') ? undefined : CodeListItemBasicRecordFromJSON(json['sludgeDrain']),
    };
}

export function WaterStructTreatmentResponseRecordToJSON(value?: WaterStructTreatmentResponseRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'avCap': value.avCap,
        'maxCap': value.maxCap,
        'waterOrigin': CodeListItemBasicRecordToJSON(value.waterOrigin),
        'waterCat': CodeListItemBasicRecordToJSON(value.waterCat),
        'waterModif': CodeListItemBasicRecordToJSON(value.waterModif),
        'sludgeHand': CodeListItemBasicRecordToJSON(value.sludgeHand),
        'sludgeStab': CodeListItemBasicRecordToJSON(value.sludgeStab),
        'sludgeDrain': CodeListItemBasicRecordToJSON(value.sludgeDrain),
    };
}

