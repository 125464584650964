/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CodeListItemBasicRecord } from './CodeListItemBasicRecord';
import {
    CodeListItemBasicRecordFromJSON,
    CodeListItemBasicRecordFromJSONTyped,
    CodeListItemBasicRecordToJSON,
} from './CodeListItemBasicRecord';

/**
 * 
 * @export
 * @interface NewOperatorResponseRecord
 */
export interface NewOperatorResponseRecord {
    /**
     * 
     * @type {number}
     * @memberof NewOperatorResponseRecord
     */
    id?: number | null;
    /**
     * 
     * @type {string}
     * @memberof NewOperatorResponseRecord
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NewOperatorResponseRecord
     */
    address?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NewOperatorResponseRecord
     */
    name?: string | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof NewOperatorResponseRecord
     */
    city?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {string}
     * @memberof NewOperatorResponseRecord
     */
    zip?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NewOperatorResponseRecord
     */
    ico?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NewOperatorResponseRecord
     */
    dic?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NewOperatorResponseRecord
     */
    note?: string | null;
}

/**
 * Check if a given object implements the NewOperatorResponseRecord interface.
 */
export function instanceOfNewOperatorResponseRecord(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function NewOperatorResponseRecordFromJSON(json: any): NewOperatorResponseRecord {
    return NewOperatorResponseRecordFromJSONTyped(json, false);
}

export function NewOperatorResponseRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewOperatorResponseRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'city': !exists(json, 'city') ? undefined : CodeListItemBasicRecordFromJSON(json['city']),
        'zip': !exists(json, 'zip') ? undefined : json['zip'],
        'ico': !exists(json, 'ico') ? undefined : json['ico'],
        'dic': !exists(json, 'dic') ? undefined : json['dic'],
        'note': !exists(json, 'note') ? undefined : json['note'],
    };
}

export function NewOperatorResponseRecordToJSON(value?: NewOperatorResponseRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': value.type,
        'address': value.address,
        'name': value.name,
        'city': CodeListItemBasicRecordToJSON(value.city),
        'zip': value.zip,
        'ico': value.ico,
        'dic': value.dic,
        'note': value.note,
    };
}

