/* tslint:disable */
/* eslint-disable */
/**
 * Catalog API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.31.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MapPluginInfo
 */
export interface MapPluginInfo {
    /**
     * 
     * @type {string}
     * @memberof MapPluginInfo
     */
    lastModify: string | null;
    /**
     * 
     * @type {string}
     * @memberof MapPluginInfo
     */
    nextModify: string | null;
}

/**
 * Check if a given object implements the MapPluginInfo interface.
 */
export function instanceOfMapPluginInfo(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "lastModify" in value;
    isInstance = isInstance && "nextModify" in value;

    return isInstance;
}

export function MapPluginInfoFromJSON(json: any): MapPluginInfo {
    return MapPluginInfoFromJSONTyped(json, false);
}

export function MapPluginInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MapPluginInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lastModify': json['lastModify'],
        'nextModify': json['nextModify'],
    };
}

export function MapPluginInfoToJSON(value?: MapPluginInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lastModify': value.lastModify,
        'nextModify': value.nextModify,
    };
}

