/* tslint:disable */
/* eslint-disable */
/**
 * Catalog API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.31.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Attachment } from './Attachment';
import {
    AttachmentFromJSON,
    AttachmentFromJSONTyped,
    AttachmentToJSON,
} from './Attachment';
import type { CodelistItem } from './CodelistItem';
import {
    CodelistItemFromJSON,
    CodelistItemFromJSONTyped,
    CodelistItemToJSON,
} from './CodelistItem';
import type { GPSCodelistItem } from './GPSCodelistItem';
import {
    GPSCodelistItemFromJSON,
    GPSCodelistItemFromJSONTyped,
    GPSCodelistItemToJSON,
} from './GPSCodelistItem';
import type { MonitoringCodelistItemRequired } from './MonitoringCodelistItemRequired';
import {
    MonitoringCodelistItemRequiredFromJSON,
    MonitoringCodelistItemRequiredFromJSONTyped,
    MonitoringCodelistItemRequiredToJSON,
} from './MonitoringCodelistItemRequired';
import type { OtherCodelistItem } from './OtherCodelistItem';
import {
    OtherCodelistItemFromJSON,
    OtherCodelistItemFromJSONTyped,
    OtherCodelistItemToJSON,
} from './OtherCodelistItem';
import type { Place } from './Place';
import {
    PlaceFromJSON,
    PlaceFromJSONTyped,
    PlaceToJSON,
} from './Place';
import type { PointCategoryCodelistItemRequired } from './PointCategoryCodelistItemRequired';
import {
    PointCategoryCodelistItemRequiredFromJSON,
    PointCategoryCodelistItemRequiredFromJSONTyped,
    PointCategoryCodelistItemRequiredToJSON,
} from './PointCategoryCodelistItemRequired';
import type { TechnicalAttribute } from './TechnicalAttribute';
import {
    TechnicalAttributeFromJSON,
    TechnicalAttributeFromJSONTyped,
    TechnicalAttributeToJSON,
} from './TechnicalAttribute';
import type { UndergroundWaterCodelistItemGwBodyItem } from './UndergroundWaterCodelistItemGwBodyItem';
import {
    UndergroundWaterCodelistItemGwBodyItemFromJSON,
    UndergroundWaterCodelistItemGwBodyItemFromJSONTyped,
    UndergroundWaterCodelistItemGwBodyItemToJSON,
} from './UndergroundWaterCodelistItemGwBodyItem';
import type { WaterCourseResultCodelistItem } from './WaterCourseResultCodelistItem';
import {
    WaterCourseResultCodelistItemFromJSON,
    WaterCourseResultCodelistItemFromJSONTyped,
    WaterCourseResultCodelistItemToJSON,
} from './WaterCourseResultCodelistItem';
import type { WaterWithdrawalResultCodelistItem } from './WaterWithdrawalResultCodelistItem';
import {
    WaterWithdrawalResultCodelistItemFromJSON,
    WaterWithdrawalResultCodelistItemFromJSONTyped,
    WaterWithdrawalResultCodelistItemToJSON,
} from './WaterWithdrawalResultCodelistItem';

/**
 * 
 * @export
 * @interface MonitoringPointResult
 */
export interface MonitoringPointResult {
    /**
     * 
     * @type {CodelistItem}
     * @memberof MonitoringPointResult
     */
    basinType?: CodelistItem | null;
    /**
     * 
     * @type {CodelistItem}
     * @memberof MonitoringPointResult
     */
    assesmentBasinType?: CodelistItem | null;
    /**
     * 
     * @type {CodelistItem}
     * @memberof MonitoringPointResult
     */
    partialBasinType?: CodelistItem | null;
    /**
     * 
     * @type {CodelistItem}
     * @memberof MonitoringPointResult
     */
    subBasinType?: CodelistItem | null;
    /**
     * 
     * @type {string}
     * @memberof MonitoringPointResult
     */
    groupId?: string;
    /**
     * 
     * @type {CodelistItem}
     * @memberof MonitoringPointResult
     */
    hydrologicalCodeType?: CodelistItem | null;
    /**
     * 
     * @type {CodelistItem}
     * @memberof MonitoringPointResult
     */
    instBranchType?: CodelistItem | null;
    /**
     * 
     * @type {string}
     * @memberof MonitoringPointResult
     */
    locationDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitoringPointResult
     */
    mapSheetNumber?: string;
    /**
     * 
     * @type {CodelistItem}
     * @memberof MonitoringPointResult
     */
    montPointType?: CodelistItem | null;
    /**
     * 
     * @type {string}
     * @memberof MonitoringPointResult
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitoringPointResult
     */
    placeName?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitoringPointResult
     */
    note?: string;
    /**
     * 
     * @type {CodelistItem}
     * @memberof MonitoringPointResult
     */
    positionType?: CodelistItem | null;
    /**
     * 
     * @type {CodelistItem}
     * @memberof MonitoringPointResult
     */
    statusType?: CodelistItem | null;
    /**
     * 
     * @type {CodelistItem}
     * @memberof MonitoringPointResult
     */
    countryType?: CodelistItem | null;
    /**
     * 
     * @type {CodelistItem}
     * @memberof MonitoringPointResult
     */
    areaType?: CodelistItem | null;
    /**
     * 
     * @type {CodelistItem}
     * @memberof MonitoringPointResult
     */
    regionType?: CodelistItem | null;
    /**
     * 
     * @type {CodelistItem}
     * @memberof MonitoringPointResult
     */
    districtType?: CodelistItem | null;
    /**
     * 
     * @type {CodelistItem}
     * @memberof MonitoringPointResult
     */
    municipalityType?: CodelistItem | null;
    /**
     * 
     * @type {CodelistItem}
     * @memberof MonitoringPointResult
     */
    municipalityForeignType?: CodelistItem | null;
    /**
     * 
     * @type {CodelistItem}
     * @memberof MonitoringPointResult
     */
    landRegisterType?: CodelistItem | null;
    /**
     * 
     * @type {string}
     * @memberof MonitoringPointResult
     */
    effectiveTo?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitoringPointResult
     */
    effectiveFrom?: string;
    /**
     * 
     * @type {UndergroundWaterCodelistItemGwBodyItem}
     * @memberof MonitoringPointResult
     */
    undergroundWater?: UndergroundWaterCodelistItemGwBodyItem;
    /**
     * 
     * @type {GPSCodelistItem}
     * @memberof MonitoringPointResult
     */
    gps?: GPSCodelistItem;
    /**
     * 
     * @type {OtherCodelistItem}
     * @memberof MonitoringPointResult
     */
    other?: OtherCodelistItem;
    /**
     * 
     * @type {number}
     * @memberof MonitoringPointResult
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof MonitoringPointResult
     */
    code: string;
    /**
     * 
     * @type {number}
     * @memberof MonitoringPointResult
     */
    shmuId?: number | null;
    /**
     * 
     * @type {WaterCourseResultCodelistItem}
     * @memberof MonitoringPointResult
     */
    waterCourse?: WaterCourseResultCodelistItem;
    /**
     * 
     * @type {WaterWithdrawalResultCodelistItem}
     * @memberof MonitoringPointResult
     */
    waterWithdrawal?: WaterWithdrawalResultCodelistItem;
    /**
     * 
     * @type {Array<MonitoringCodelistItemRequired>}
     * @memberof MonitoringPointResult
     */
    monitorings?: Array<MonitoringCodelistItemRequired>;
    /**
     * 
     * @type {Array<PointCategoryCodelistItemRequired>}
     * @memberof MonitoringPointResult
     */
    pointCategories?: Array<PointCategoryCodelistItemRequired>;
    /**
     * 
     * @type {Array<Place>}
     * @memberof MonitoringPointResult
     */
    places?: Array<Place>;
    /**
     * 
     * @type {Array<Attachment>}
     * @memberof MonitoringPointResult
     */
    attachments?: Array<Attachment>;
    /**
     * 
     * @type {TechnicalAttribute}
     * @memberof MonitoringPointResult
     */
    technicalAttribute: TechnicalAttribute;
    /**
     * 
     * @type {boolean}
     * @memberof MonitoringPointResult
     */
    historical: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MonitoringPointResult
     */
    deleted: boolean;
    /**
     * 
     * @type {number}
     * @memberof MonitoringPointResult
     */
    version: number;
}

/**
 * Check if a given object implements the MonitoringPointResult interface.
 */
export function instanceOfMonitoringPointResult(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "code" in value;
    isInstance = isInstance && "technicalAttribute" in value;
    isInstance = isInstance && "historical" in value;
    isInstance = isInstance && "deleted" in value;
    isInstance = isInstance && "version" in value;

    return isInstance;
}

export function MonitoringPointResultFromJSON(json: any): MonitoringPointResult {
    return MonitoringPointResultFromJSONTyped(json, false);
}

export function MonitoringPointResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitoringPointResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'basinType': !exists(json, 'basinType') ? undefined : CodelistItemFromJSON(json['basinType']),
        'assesmentBasinType': !exists(json, 'assesmentBasinType') ? undefined : CodelistItemFromJSON(json['assesmentBasinType']),
        'partialBasinType': !exists(json, 'partialBasinType') ? undefined : CodelistItemFromJSON(json['partialBasinType']),
        'subBasinType': !exists(json, 'subBasinType') ? undefined : CodelistItemFromJSON(json['subBasinType']),
        'groupId': !exists(json, 'groupId') ? undefined : json['groupId'],
        'hydrologicalCodeType': !exists(json, 'hydrologicalCodeType') ? undefined : CodelistItemFromJSON(json['hydrologicalCodeType']),
        'instBranchType': !exists(json, 'instBranchType') ? undefined : CodelistItemFromJSON(json['instBranchType']),
        'locationDescription': !exists(json, 'locationDescription') ? undefined : json['locationDescription'],
        'mapSheetNumber': !exists(json, 'mapSheetNumber') ? undefined : json['mapSheetNumber'],
        'montPointType': !exists(json, 'montPointType') ? undefined : CodelistItemFromJSON(json['montPointType']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'placeName': !exists(json, 'placeName') ? undefined : json['placeName'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'positionType': !exists(json, 'positionType') ? undefined : CodelistItemFromJSON(json['positionType']),
        'statusType': !exists(json, 'statusType') ? undefined : CodelistItemFromJSON(json['statusType']),
        'countryType': !exists(json, 'countryType') ? undefined : CodelistItemFromJSON(json['countryType']),
        'areaType': !exists(json, 'areaType') ? undefined : CodelistItemFromJSON(json['areaType']),
        'regionType': !exists(json, 'regionType') ? undefined : CodelistItemFromJSON(json['regionType']),
        'districtType': !exists(json, 'districtType') ? undefined : CodelistItemFromJSON(json['districtType']),
        'municipalityType': !exists(json, 'municipalityType') ? undefined : CodelistItemFromJSON(json['municipalityType']),
        'municipalityForeignType': !exists(json, 'municipalityForeignType') ? undefined : CodelistItemFromJSON(json['municipalityForeignType']),
        'landRegisterType': !exists(json, 'landRegisterType') ? undefined : CodelistItemFromJSON(json['landRegisterType']),
        'effectiveTo': !exists(json, 'effectiveTo') ? undefined : json['effectiveTo'],
        'effectiveFrom': !exists(json, 'effectiveFrom') ? undefined : json['effectiveFrom'],
        'undergroundWater': !exists(json, 'undergroundWater') ? undefined : UndergroundWaterCodelistItemGwBodyItemFromJSON(json['undergroundWater']),
        'gps': !exists(json, 'gps') ? undefined : GPSCodelistItemFromJSON(json['gps']),
        'other': !exists(json, 'other') ? undefined : OtherCodelistItemFromJSON(json['other']),
        'id': json['id'],
        'code': json['code'],
        'shmuId': !exists(json, 'shmuId') ? undefined : json['shmuId'],
        'waterCourse': !exists(json, 'waterCourse') ? undefined : WaterCourseResultCodelistItemFromJSON(json['waterCourse']),
        'waterWithdrawal': !exists(json, 'waterWithdrawal') ? undefined : WaterWithdrawalResultCodelistItemFromJSON(json['waterWithdrawal']),
        'monitorings': !exists(json, 'monitorings') ? undefined : ((json['monitorings'] as Array<any>).map(MonitoringCodelistItemRequiredFromJSON)),
        'pointCategories': !exists(json, 'pointCategories') ? undefined : ((json['pointCategories'] as Array<any>).map(PointCategoryCodelistItemRequiredFromJSON)),
        'places': !exists(json, 'places') ? undefined : ((json['places'] as Array<any>).map(PlaceFromJSON)),
        'attachments': !exists(json, 'attachments') ? undefined : ((json['attachments'] as Array<any>).map(AttachmentFromJSON)),
        'technicalAttribute': TechnicalAttributeFromJSON(json['technicalAttribute']),
        'historical': json['historical'],
        'deleted': json['deleted'],
        'version': json['version'],
    };
}

export function MonitoringPointResultToJSON(value?: MonitoringPointResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'basinType': CodelistItemToJSON(value.basinType),
        'assesmentBasinType': CodelistItemToJSON(value.assesmentBasinType),
        'partialBasinType': CodelistItemToJSON(value.partialBasinType),
        'subBasinType': CodelistItemToJSON(value.subBasinType),
        'groupId': value.groupId,
        'hydrologicalCodeType': CodelistItemToJSON(value.hydrologicalCodeType),
        'instBranchType': CodelistItemToJSON(value.instBranchType),
        'locationDescription': value.locationDescription,
        'mapSheetNumber': value.mapSheetNumber,
        'montPointType': CodelistItemToJSON(value.montPointType),
        'name': value.name,
        'placeName': value.placeName,
        'note': value.note,
        'positionType': CodelistItemToJSON(value.positionType),
        'statusType': CodelistItemToJSON(value.statusType),
        'countryType': CodelistItemToJSON(value.countryType),
        'areaType': CodelistItemToJSON(value.areaType),
        'regionType': CodelistItemToJSON(value.regionType),
        'districtType': CodelistItemToJSON(value.districtType),
        'municipalityType': CodelistItemToJSON(value.municipalityType),
        'municipalityForeignType': CodelistItemToJSON(value.municipalityForeignType),
        'landRegisterType': CodelistItemToJSON(value.landRegisterType),
        'effectiveTo': value.effectiveTo,
        'effectiveFrom': value.effectiveFrom,
        'undergroundWater': UndergroundWaterCodelistItemGwBodyItemToJSON(value.undergroundWater),
        'gps': GPSCodelistItemToJSON(value.gps),
        'other': OtherCodelistItemToJSON(value.other),
        'id': value.id,
        'code': value.code,
        'shmuId': value.shmuId,
        'waterCourse': WaterCourseResultCodelistItemToJSON(value.waterCourse),
        'waterWithdrawal': WaterWithdrawalResultCodelistItemToJSON(value.waterWithdrawal),
        'monitorings': value.monitorings === undefined ? undefined : ((value.monitorings as Array<any>).map(MonitoringCodelistItemRequiredToJSON)),
        'pointCategories': value.pointCategories === undefined ? undefined : ((value.pointCategories as Array<any>).map(PointCategoryCodelistItemRequiredToJSON)),
        'places': value.places === undefined ? undefined : ((value.places as Array<any>).map(PlaceToJSON)),
        'attachments': value.attachments === undefined ? undefined : ((value.attachments as Array<any>).map(AttachmentToJSON)),
        'technicalAttribute': TechnicalAttributeToJSON(value.technicalAttribute),
        'historical': value.historical,
        'deleted': value.deleted,
        'version': value.version,
    };
}

