/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CodeListItemBasicRecord } from './CodeListItemBasicRecord';
import {
    CodeListItemBasicRecordFromJSON,
    CodeListItemBasicRecordFromJSONTyped,
    CodeListItemBasicRecordToJSON,
} from './CodeListItemBasicRecord';
import type { WaterEvidenceOriginAppendixRecord } from './WaterEvidenceOriginAppendixRecord';
import {
    WaterEvidenceOriginAppendixRecordFromJSON,
    WaterEvidenceOriginAppendixRecordFromJSONTyped,
    WaterEvidenceOriginAppendixRecordToJSON,
} from './WaterEvidenceOriginAppendixRecord';
import type { WaterEvidenceOriginRecord } from './WaterEvidenceOriginRecord';
import {
    WaterEvidenceOriginRecordFromJSON,
    WaterEvidenceOriginRecordFromJSONTyped,
    WaterEvidenceOriginRecordToJSON,
} from './WaterEvidenceOriginRecord';
import type { WaterStructAppendixResponseRecord } from './WaterStructAppendixResponseRecord';
import {
    WaterStructAppendixResponseRecordFromJSON,
    WaterStructAppendixResponseRecordFromJSONTyped,
    WaterStructAppendixResponseRecordToJSON,
} from './WaterStructAppendixResponseRecord';

/**
 * 
 * @export
 * @interface WaterStructResponseRecord
 */
export interface WaterStructResponseRecord {
    /**
     * 
     * @type {number}
     * @memberof WaterStructResponseRecord
     */
    id?: number | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof WaterStructResponseRecord
     */
    docType?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {WaterEvidenceOriginRecord}
     * @memberof WaterStructResponseRecord
     */
    changedByDocument?: WaterEvidenceOriginRecord | null;
    /**
     * 
     * @type {WaterEvidenceOriginAppendixRecord}
     * @memberof WaterStructResponseRecord
     */
    originAppendix?: WaterEvidenceOriginAppendixRecord | null;
    /**
     * 
     * @type {string}
     * @memberof WaterStructResponseRecord
     */
    dateFrom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterStructResponseRecord
     */
    dateTo?: string | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof WaterStructResponseRecord
     */
    status?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {string}
     * @memberof WaterStructResponseRecord
     */
    note?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterStructResponseRecord
     */
    mainName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterStructResponseRecord
     */
    mainCity?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterStructResponseRecord
     */
    mainDescr?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterStructResponseRecord
     */
    relName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterStructResponseRecord
     */
    relCity?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterStructResponseRecord
     */
    relDescr?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterStructResponseRecord
     */
    createdTimestamp?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterStructResponseRecord
     */
    createdBy?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterStructResponseRecord
     */
    updatedTimestamp?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterStructResponseRecord
     */
    updatedBy?: string | null;
    /**
     * 
     * @type {Array<WaterStructAppendixResponseRecord>}
     * @memberof WaterStructResponseRecord
     */
    appendixes?: Array<WaterStructAppendixResponseRecord> | null;
}

/**
 * Check if a given object implements the WaterStructResponseRecord interface.
 */
export function instanceOfWaterStructResponseRecord(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WaterStructResponseRecordFromJSON(json: any): WaterStructResponseRecord {
    return WaterStructResponseRecordFromJSONTyped(json, false);
}

export function WaterStructResponseRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): WaterStructResponseRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'docType': !exists(json, 'docType') ? undefined : CodeListItemBasicRecordFromJSON(json['docType']),
        'changedByDocument': !exists(json, 'changedByDocument') ? undefined : WaterEvidenceOriginRecordFromJSON(json['changedByDocument']),
        'originAppendix': !exists(json, 'originAppendix') ? undefined : WaterEvidenceOriginAppendixRecordFromJSON(json['originAppendix']),
        'dateFrom': !exists(json, 'dateFrom') ? undefined : json['dateFrom'],
        'dateTo': !exists(json, 'dateTo') ? undefined : json['dateTo'],
        'status': !exists(json, 'status') ? undefined : CodeListItemBasicRecordFromJSON(json['status']),
        'note': !exists(json, 'note') ? undefined : json['note'],
        'mainName': !exists(json, 'mainName') ? undefined : json['mainName'],
        'mainCity': !exists(json, 'mainCity') ? undefined : json['mainCity'],
        'mainDescr': !exists(json, 'mainDescr') ? undefined : json['mainDescr'],
        'relName': !exists(json, 'relName') ? undefined : json['relName'],
        'relCity': !exists(json, 'relCity') ? undefined : json['relCity'],
        'relDescr': !exists(json, 'relDescr') ? undefined : json['relDescr'],
        'createdTimestamp': !exists(json, 'createdTimestamp') ? undefined : json['createdTimestamp'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'updatedTimestamp': !exists(json, 'updatedTimestamp') ? undefined : json['updatedTimestamp'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
        'appendixes': !exists(json, 'appendixes') ? undefined : (json['appendixes'] === null ? null : (json['appendixes'] as Array<any>).map(WaterStructAppendixResponseRecordFromJSON)),
    };
}

export function WaterStructResponseRecordToJSON(value?: WaterStructResponseRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'docType': CodeListItemBasicRecordToJSON(value.docType),
        'changedByDocument': WaterEvidenceOriginRecordToJSON(value.changedByDocument),
        'originAppendix': WaterEvidenceOriginAppendixRecordToJSON(value.originAppendix),
        'dateFrom': value.dateFrom,
        'dateTo': value.dateTo,
        'status': CodeListItemBasicRecordToJSON(value.status),
        'note': value.note,
        'mainName': value.mainName,
        'mainCity': value.mainCity,
        'mainDescr': value.mainDescr,
        'relName': value.relName,
        'relCity': value.relCity,
        'relDescr': value.relDescr,
        'createdTimestamp': value.createdTimestamp,
        'createdBy': value.createdBy,
        'updatedTimestamp': value.updatedTimestamp,
        'updatedBy': value.updatedBy,
        'appendixes': value.appendixes === undefined ? undefined : (value.appendixes === null ? null : (value.appendixes as Array<any>).map(WaterStructAppendixResponseRecordToJSON)),
    };
}

