/* tslint:disable */
/* eslint-disable */
/**
 * Catalog API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.31.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CodelistItem } from './CodelistItem';
import {
    CodelistItemFromJSON,
    CodelistItemFromJSONTyped,
    CodelistItemToJSON,
} from './CodelistItem';

/**
 * 
 * @export
 * @interface WaterShapeSimple
 */
export interface WaterShapeSimple {
    /**
     * 
     * @type {number}
     * @memberof WaterShapeSimple
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof WaterShapeSimple
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof WaterShapeSimple
     */
    code: string;
    /**
     * 
     * @type {CodelistItem}
     * @memberof WaterShapeSimple
     */
    cycleType: CodelistItem | null;
}

/**
 * Check if a given object implements the WaterShapeSimple interface.
 */
export function instanceOfWaterShapeSimple(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "code" in value;
    isInstance = isInstance && "cycleType" in value;

    return isInstance;
}

export function WaterShapeSimpleFromJSON(json: any): WaterShapeSimple {
    return WaterShapeSimpleFromJSONTyped(json, false);
}

export function WaterShapeSimpleFromJSONTyped(json: any, ignoreDiscriminator: boolean): WaterShapeSimple {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'code': json['code'],
        'cycleType': CodelistItemFromJSON(json['cycleType']),
    };
}

export function WaterShapeSimpleToJSON(value?: WaterShapeSimple | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'code': value.code,
        'cycleType': CodelistItemToJSON(value.cycleType),
    };
}

