/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OtherRecord
 */
export interface OtherRecord {
    /**
     * 
     * @type {any}
     * @memberof OtherRecord
     */
    affectedType?: any;
    /**
     * 
     * @type {number}
     * @memberof OtherRecord
     */
    altitude?: number;
    /**
     * 
     * @type {number}
     * @memberof OtherRecord
     */
    area?: number;
    /**
     * 
     * @type {any}
     * @memberof OtherRecord
     */
    chvoType?: any;
    /**
     * 
     * @type {any}
     * @memberof OtherRecord
     */
    fictionPlace?: any;
    /**
     * 
     * @type {string}
     * @memberof OtherRecord
     */
    internationalPlaceCode?: string;
    /**
     * 
     * @type {any}
     * @memberof OtherRecord
     */
    replacePlace?: any;
    /**
     * 
     * @type {boolean}
     * @memberof OtherRecord
     */
    shs?: boolean;
}

/**
 * Check if a given object implements the OtherRecord interface.
 */
export function instanceOfOtherRecord(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function OtherRecordFromJSON(json: any): OtherRecord {
    return OtherRecordFromJSONTyped(json, false);
}

export function OtherRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): OtherRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'affectedType': !exists(json, 'affectedType') ? undefined : json['affectedType'],
        'altitude': !exists(json, 'altitude') ? undefined : json['altitude'],
        'area': !exists(json, 'area') ? undefined : json['area'],
        'chvoType': !exists(json, 'chvoType') ? undefined : json['chvoType'],
        'fictionPlace': !exists(json, 'fictionPlace') ? undefined : json['fictionPlace'],
        'internationalPlaceCode': !exists(json, 'internationalPlaceCode') ? undefined : json['internationalPlaceCode'],
        'replacePlace': !exists(json, 'replacePlace') ? undefined : json['replacePlace'],
        'shs': !exists(json, 'shs') ? undefined : json['shs'],
    };
}

export function OtherRecordToJSON(value?: OtherRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'affectedType': value.affectedType,
        'altitude': value.altitude,
        'area': value.area,
        'chvoType': value.chvoType,
        'fictionPlace': value.fictionPlace,
        'internationalPlaceCode': value.internationalPlaceCode,
        'replacePlace': value.replacePlace,
        'shs': value.shs,
    };
}

