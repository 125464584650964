/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CodeListItemBasicRecord } from './CodeListItemBasicRecord';
import {
    CodeListItemBasicRecordFromJSON,
    CodeListItemBasicRecordFromJSONTyped,
    CodeListItemBasicRecordToJSON,
} from './CodeListItemBasicRecord';
import type { MonitoringRecord } from './MonitoringRecord';
import {
    MonitoringRecordFromJSON,
    MonitoringRecordFromJSONTyped,
    MonitoringRecordToJSON,
} from './MonitoringRecord';
import type { QualityAssessmentDeterminantRecord } from './QualityAssessmentDeterminantRecord';
import {
    QualityAssessmentDeterminantRecordFromJSON,
    QualityAssessmentDeterminantRecordFromJSONTyped,
    QualityAssessmentDeterminantRecordToJSON,
} from './QualityAssessmentDeterminantRecord';
import type { QualityAssessmentFileRecord } from './QualityAssessmentFileRecord';
import {
    QualityAssessmentFileRecordFromJSON,
    QualityAssessmentFileRecordFromJSONTyped,
    QualityAssessmentFileRecordToJSON,
} from './QualityAssessmentFileRecord';

/**
 * 
 * @export
 * @interface QualityAssessmentRecord
 */
export interface QualityAssessmentRecord {
    /**
     * 
     * @type {number}
     * @memberof QualityAssessmentRecord
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof QualityAssessmentRecord
     */
    arn?: string;
    /**
     * 
     * @type {string}
     * @memberof QualityAssessmentRecord
     */
    environment?: string;
    /**
     * 
     * @type {string}
     * @memberof QualityAssessmentRecord
     */
    applicant?: string;
    /**
     * 
     * @type {string}
     * @memberof QualityAssessmentRecord
     */
    fileNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof QualityAssessmentRecord
     */
    sampleNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof QualityAssessmentRecord
     */
    deliveryDate?: string;
    /**
     * 
     * @type {string}
     * @memberof QualityAssessmentRecord
     */
    issueDate?: string;
    /**
     * 
     * @type {string}
     * @memberof QualityAssessmentRecord
     */
    address?: string;
    /**
     * 
     * @type {number}
     * @memberof QualityAssessmentRecord
     */
    price?: number;
    /**
     * 
     * @type {number}
     * @memberof QualityAssessmentRecord
     */
    qokQ355?: number;
    /**
     * 
     * @type {number}
     * @memberof QualityAssessmentRecord
     */
    qok?: number;
    /**
     * 
     * @type {number}
     * @memberof QualityAssessmentRecord
     */
    q355?: number;
    /**
     * 
     * @type {string}
     * @memberof QualityAssessmentRecord
     */
    flowSpeed?: string;
    /**
     * 
     * @type {string}
     * @memberof QualityAssessmentRecord
     */
    temperature?: string;
    /**
     * 
     * @type {string}
     * @memberof QualityAssessmentRecord
     */
    pH?: string;
    /**
     * 
     * @type {string}
     * @memberof QualityAssessmentRecord
     */
    conductivity?: string;
    /**
     * 
     * @type {string}
     * @memberof QualityAssessmentRecord
     */
    o2?: string;
    /**
     * 
     * @type {string}
     * @memberof QualityAssessmentRecord
     */
    o2Perc?: string;
    /**
     * 
     * @type {string}
     * @memberof QualityAssessmentRecord
     */
    note?: string;
    /**
     * 
     * @type {string}
     * @memberof QualityAssessmentRecord
     */
    withdrawDate?: string;
    /**
     * 
     * @type {string}
     * @memberof QualityAssessmentRecord
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof QualityAssessmentRecord
     */
    createdTimestamp?: string;
    /**
     * 
     * @type {string}
     * @memberof QualityAssessmentRecord
     */
    updatedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof QualityAssessmentRecord
     */
    state?: QualityAssessmentRecordStateEnum;
    /**
     * 
     * @type {string}
     * @memberof QualityAssessmentRecord
     */
    updatedTimestamp?: string;
    /**
     * 
     * @type {Array<QualityAssessmentFileRecord>}
     * @memberof QualityAssessmentRecord
     */
    qualityAssessmentFiles?: Array<QualityAssessmentFileRecord>;
    /**
     * 
     * @type {Array<QualityAssessmentDeterminantRecord>}
     * @memberof QualityAssessmentRecord
     */
    qualityAssessmentDeterminants?: Array<QualityAssessmentDeterminantRecord>;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof QualityAssessmentRecord
     */
    assessmentTypeCode?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof QualityAssessmentRecord
     */
    instBranchCode?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof QualityAssessmentRecord
     */
    color?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof QualityAssessmentRecord
     */
    smell?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof QualityAssessmentRecord
     */
    turbidity?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof QualityAssessmentRecord
     */
    solver?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {number}
     * @memberof QualityAssessmentRecord
     */
    statusItemCode?: number;
    /**
     * 
     * @type {MonitoringRecord}
     * @memberof QualityAssessmentRecord
     */
    monPoint?: MonitoringRecord | null;
}


/**
 * @export
 */
export const QualityAssessmentRecordStateEnum = {
    Progress: 'PROGRESS',
    Written: 'WRITTEN',
    Deleted: 'DELETED'
} as const;
export type QualityAssessmentRecordStateEnum = typeof QualityAssessmentRecordStateEnum[keyof typeof QualityAssessmentRecordStateEnum];


/**
 * Check if a given object implements the QualityAssessmentRecord interface.
 */
export function instanceOfQualityAssessmentRecord(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function QualityAssessmentRecordFromJSON(json: any): QualityAssessmentRecord {
    return QualityAssessmentRecordFromJSONTyped(json, false);
}

export function QualityAssessmentRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): QualityAssessmentRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'arn': !exists(json, 'arn') ? undefined : json['arn'],
        'environment': !exists(json, 'environment') ? undefined : json['environment'],
        'applicant': !exists(json, 'applicant') ? undefined : json['applicant'],
        'fileNumber': !exists(json, 'fileNumber') ? undefined : json['fileNumber'],
        'sampleNumber': !exists(json, 'sampleNumber') ? undefined : json['sampleNumber'],
        'deliveryDate': !exists(json, 'deliveryDate') ? undefined : json['deliveryDate'],
        'issueDate': !exists(json, 'issueDate') ? undefined : json['issueDate'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'price': !exists(json, 'price') ? undefined : json['price'],
        'qokQ355': !exists(json, 'qokQ355') ? undefined : json['qokQ355'],
        'qok': !exists(json, 'qok') ? undefined : json['qok'],
        'q355': !exists(json, 'q355') ? undefined : json['q355'],
        'flowSpeed': !exists(json, 'flowSpeed') ? undefined : json['flowSpeed'],
        'temperature': !exists(json, 'temperature') ? undefined : json['temperature'],
        'pH': !exists(json, 'pH') ? undefined : json['pH'],
        'conductivity': !exists(json, 'conductivity') ? undefined : json['conductivity'],
        'o2': !exists(json, 'o2') ? undefined : json['o2'],
        'o2Perc': !exists(json, 'o2Perc') ? undefined : json['o2Perc'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'withdrawDate': !exists(json, 'withdrawDate') ? undefined : json['withdrawDate'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'createdTimestamp': !exists(json, 'createdTimestamp') ? undefined : json['createdTimestamp'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'updatedTimestamp': !exists(json, 'updatedTimestamp') ? undefined : json['updatedTimestamp'],
        'qualityAssessmentFiles': !exists(json, 'qualityAssessmentFiles') ? undefined : ((json['qualityAssessmentFiles'] as Array<any>).map(QualityAssessmentFileRecordFromJSON)),
        'qualityAssessmentDeterminants': !exists(json, 'qualityAssessmentDeterminants') ? undefined : ((json['qualityAssessmentDeterminants'] as Array<any>).map(QualityAssessmentDeterminantRecordFromJSON)),
        'assessmentTypeCode': !exists(json, 'assessmentTypeCode') ? undefined : CodeListItemBasicRecordFromJSON(json['assessmentTypeCode']),
        'instBranchCode': !exists(json, 'instBranchCode') ? undefined : CodeListItemBasicRecordFromJSON(json['instBranchCode']),
        'color': !exists(json, 'color') ? undefined : CodeListItemBasicRecordFromJSON(json['color']),
        'smell': !exists(json, 'smell') ? undefined : CodeListItemBasicRecordFromJSON(json['smell']),
        'turbidity': !exists(json, 'turbidity') ? undefined : CodeListItemBasicRecordFromJSON(json['turbidity']),
        'solver': !exists(json, 'solver') ? undefined : CodeListItemBasicRecordFromJSON(json['solver']),
        'statusItemCode': !exists(json, 'statusItemCode') ? undefined : json['statusItemCode'],
        'monPoint': !exists(json, 'monPoint') ? undefined : MonitoringRecordFromJSON(json['monPoint']),
    };
}

export function QualityAssessmentRecordToJSON(value?: QualityAssessmentRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'arn': value.arn,
        'environment': value.environment,
        'applicant': value.applicant,
        'fileNumber': value.fileNumber,
        'sampleNumber': value.sampleNumber,
        'deliveryDate': value.deliveryDate,
        'issueDate': value.issueDate,
        'address': value.address,
        'price': value.price,
        'qokQ355': value.qokQ355,
        'qok': value.qok,
        'q355': value.q355,
        'flowSpeed': value.flowSpeed,
        'temperature': value.temperature,
        'pH': value.pH,
        'conductivity': value.conductivity,
        'o2': value.o2,
        'o2Perc': value.o2Perc,
        'note': value.note,
        'withdrawDate': value.withdrawDate,
        'createdBy': value.createdBy,
        'createdTimestamp': value.createdTimestamp,
        'updatedBy': value.updatedBy,
        'state': value.state,
        'updatedTimestamp': value.updatedTimestamp,
        'qualityAssessmentFiles': value.qualityAssessmentFiles === undefined ? undefined : ((value.qualityAssessmentFiles as Array<any>).map(QualityAssessmentFileRecordToJSON)),
        'qualityAssessmentDeterminants': value.qualityAssessmentDeterminants === undefined ? undefined : ((value.qualityAssessmentDeterminants as Array<any>).map(QualityAssessmentDeterminantRecordToJSON)),
        'assessmentTypeCode': CodeListItemBasicRecordToJSON(value.assessmentTypeCode),
        'instBranchCode': CodeListItemBasicRecordToJSON(value.instBranchCode),
        'color': CodeListItemBasicRecordToJSON(value.color),
        'smell': CodeListItemBasicRecordToJSON(value.smell),
        'turbidity': CodeListItemBasicRecordToJSON(value.turbidity),
        'solver': CodeListItemBasicRecordToJSON(value.solver),
        'statusItemCode': value.statusItemCode,
        'monPoint': MonitoringRecordToJSON(value.monPoint),
    };
}

