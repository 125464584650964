import { useMemo } from 'react';

import { BaseAPI, Configuration } from '../openapi/herki';

import useBearerMiddleware from './use-bearer-middleware';
import useErrorMiddleware from './use-error-middleware';

export type ApiOptions = {
    noErrorMiddleware?: boolean;
};

const useHerkiApi = <T extends BaseAPI>(
    apiConstructor: new (configuration: Configuration) => T,
    options = { noErrorMiddleware: false }
): T => {
    const bearerMiddleware = useBearerMiddleware();
    const errorMiddleware = useErrorMiddleware();

    const { noErrorMiddleware } = options;

    return useMemo<T>(() => {
        const middleware = [bearerMiddleware];
        if (!noErrorMiddleware) {
            middleware.push(errorMiddleware);
        }
        return new apiConstructor(
            new Configuration({
                basePath: ENV.HERKI_URL,
                middleware
            })
        );
    }, [bearerMiddleware, errorMiddleware, apiConstructor, noErrorMiddleware]);
};

export default useHerkiApi;
