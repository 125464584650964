/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CodeListItemBasicRecord } from './CodeListItemBasicRecord';
import {
    CodeListItemBasicRecordFromJSON,
    CodeListItemBasicRecordFromJSONTyped,
    CodeListItemBasicRecordToJSON,
} from './CodeListItemBasicRecord';

/**
 * 
 * @export
 * @interface QualityTabResponseRecord
 */
export interface QualityTabResponseRecord {
    /**
     * 
     * @type {number}
     * @memberof QualityTabResponseRecord
     */
    id?: number | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof QualityTabResponseRecord
     */
    determinant?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {number}
     * @memberof QualityTabResponseRecord
     */
    avgConcentration?: number | null;
    /**
     * 
     * @type {number}
     * @memberof QualityTabResponseRecord
     */
    maxConcentration?: number | null;
    /**
     * 
     * @type {number}
     * @memberof QualityTabResponseRecord
     */
    balAmountDay?: number | null;
    /**
     * 
     * @type {number}
     * @memberof QualityTabResponseRecord
     */
    balAmountYear?: number | null;
}

/**
 * Check if a given object implements the QualityTabResponseRecord interface.
 */
export function instanceOfQualityTabResponseRecord(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function QualityTabResponseRecordFromJSON(json: any): QualityTabResponseRecord {
    return QualityTabResponseRecordFromJSONTyped(json, false);
}

export function QualityTabResponseRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): QualityTabResponseRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'determinant': !exists(json, 'determinant') ? undefined : CodeListItemBasicRecordFromJSON(json['determinant']),
        'avgConcentration': !exists(json, 'avgConcentration') ? undefined : json['avgConcentration'],
        'maxConcentration': !exists(json, 'maxConcentration') ? undefined : json['maxConcentration'],
        'balAmountDay': !exists(json, 'balAmountDay') ? undefined : json['balAmountDay'],
        'balAmountYear': !exists(json, 'balAmountYear') ? undefined : json['balAmountYear'],
    };
}

export function QualityTabResponseRecordToJSON(value?: QualityTabResponseRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'determinant': CodeListItemBasicRecordToJSON(value.determinant),
        'avgConcentration': value.avgConcentration,
        'maxConcentration': value.maxConcentration,
        'balAmountDay': value.balAmountDay,
        'balAmountYear': value.balAmountYear,
    };
}

