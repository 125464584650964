/* tslint:disable */
/* eslint-disable */
/**
 * Data import
 * Modul for importing data to Data Registry.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: schvarcz@bb.microcomp.sk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ValidationReportRowResponse
 */
export interface ValidationReportRowResponse {
    /**
     * 
     * @type {string}
     * @memberof ValidationReportRowResponse
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof ValidationReportRowResponse
     */
    rowNumber?: number;
    /**
     * 
     * @type {string}
     * @memberof ValidationReportRowResponse
     */
    controlCriticalityType?: ValidationReportRowResponseControlCriticalityTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ValidationReportRowResponse
     */
    errorMessage?: string;
}


/**
 * @export
 */
export const ValidationReportRowResponseControlCriticalityTypeEnum = {
    Critical: 'CRITICAL',
    NonCritical: 'NON_CRITICAL',
    Warning: 'WARNING'
} as const;
export type ValidationReportRowResponseControlCriticalityTypeEnum = typeof ValidationReportRowResponseControlCriticalityTypeEnum[keyof typeof ValidationReportRowResponseControlCriticalityTypeEnum];


/**
 * Check if a given object implements the ValidationReportRowResponse interface.
 */
export function instanceOfValidationReportRowResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ValidationReportRowResponseFromJSON(json: any): ValidationReportRowResponse {
    return ValidationReportRowResponseFromJSONTyped(json, false);
}

export function ValidationReportRowResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValidationReportRowResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'rowNumber': !exists(json, 'rowNumber') ? undefined : json['rowNumber'],
        'controlCriticalityType': !exists(json, 'controlCriticalityType') ? undefined : json['controlCriticalityType'],
        'errorMessage': !exists(json, 'errorMessage') ? undefined : json['errorMessage'],
    };
}

export function ValidationReportRowResponseToJSON(value?: ValidationReportRowResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'rowNumber': value.rowNumber,
        'controlCriticalityType': value.controlCriticalityType,
        'errorMessage': value.errorMessage,
    };
}

