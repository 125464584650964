/* tslint:disable */
/* eslint-disable */
/**
 * Catalog API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.31.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Level
 */
export interface Level {
    /**
     * 
     * @type {string}
     * @memberof Level
     */
    level1: string | null;
    /**
     * 
     * @type {string}
     * @memberof Level
     */
    level2: string | null;
    /**
     * 
     * @type {string}
     * @memberof Level
     */
    level3: string | null;
    /**
     * 
     * @type {string}
     * @memberof Level
     */
    level4: string | null;
    /**
     * 
     * @type {string}
     * @memberof Level
     */
    level5: string | null;
    /**
     * 
     * @type {string}
     * @memberof Level
     */
    level6: string | null;
    /**
     * 
     * @type {string}
     * @memberof Level
     */
    level7: string | null;
    /**
     * 
     * @type {string}
     * @memberof Level
     */
    level8: string | null;
    /**
     * 
     * @type {string}
     * @memberof Level
     */
    level9: string | null;
    /**
     * 
     * @type {string}
     * @memberof Level
     */
    level10: string | null;
    /**
     * 
     * @type {string}
     * @memberof Level
     */
    level11: string | null;
    /**
     * 
     * @type {string}
     * @memberof Level
     */
    level12: string | null;
    /**
     * 
     * @type {string}
     * @memberof Level
     */
    level13: string | null;
    /**
     * 
     * @type {string}
     * @memberof Level
     */
    level14: string | null;
    /**
     * 
     * @type {string}
     * @memberof Level
     */
    level15: string | null;
}

/**
 * Check if a given object implements the Level interface.
 */
export function instanceOfLevel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "level1" in value;
    isInstance = isInstance && "level2" in value;
    isInstance = isInstance && "level3" in value;
    isInstance = isInstance && "level4" in value;
    isInstance = isInstance && "level5" in value;
    isInstance = isInstance && "level6" in value;
    isInstance = isInstance && "level7" in value;
    isInstance = isInstance && "level8" in value;
    isInstance = isInstance && "level9" in value;
    isInstance = isInstance && "level10" in value;
    isInstance = isInstance && "level11" in value;
    isInstance = isInstance && "level12" in value;
    isInstance = isInstance && "level13" in value;
    isInstance = isInstance && "level14" in value;
    isInstance = isInstance && "level15" in value;

    return isInstance;
}

export function LevelFromJSON(json: any): Level {
    return LevelFromJSONTyped(json, false);
}

export function LevelFromJSONTyped(json: any, ignoreDiscriminator: boolean): Level {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'level1': json['level1'],
        'level2': json['level2'],
        'level3': json['level3'],
        'level4': json['level4'],
        'level5': json['level5'],
        'level6': json['level6'],
        'level7': json['level7'],
        'level8': json['level8'],
        'level9': json['level9'],
        'level10': json['level10'],
        'level11': json['level11'],
        'level12': json['level12'],
        'level13': json['level13'],
        'level14': json['level14'],
        'level15': json['level15'],
    };
}

export function LevelToJSON(value?: Level | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'level1': value.level1,
        'level2': value.level2,
        'level3': value.level3,
        'level4': value.level4,
        'level5': value.level5,
        'level6': value.level6,
        'level7': value.level7,
        'level8': value.level8,
        'level9': value.level9,
        'level10': value.level10,
        'level11': value.level11,
        'level12': value.level12,
        'level13': value.level13,
        'level14': value.level14,
        'level15': value.level15,
    };
}

