/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WaterStructRequestRecord
 */
export interface WaterStructRequestRecord {
    /**
     * 
     * @type {number}
     * @memberof WaterStructRequestRecord
     */
    id?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructRequestRecord
     */
    docTypeCode?: number | null;
    /**
     * 
     * @type {string}
     * @memberof WaterStructRequestRecord
     */
    dateFrom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterStructRequestRecord
     */
    dateTo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterStructRequestRecord
     */
    note?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterStructRequestRecord
     */
    mainName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterStructRequestRecord
     */
    mainCity?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterStructRequestRecord
     */
    mainDescr?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterStructRequestRecord
     */
    relName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterStructRequestRecord
     */
    relCity?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterStructRequestRecord
     */
    relDescr?: string | null;
}

/**
 * Check if a given object implements the WaterStructRequestRecord interface.
 */
export function instanceOfWaterStructRequestRecord(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WaterStructRequestRecordFromJSON(json: any): WaterStructRequestRecord {
    return WaterStructRequestRecordFromJSONTyped(json, false);
}

export function WaterStructRequestRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): WaterStructRequestRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'docTypeCode': !exists(json, 'docTypeCode') ? undefined : json['docTypeCode'],
        'dateFrom': !exists(json, 'dateFrom') ? undefined : json['dateFrom'],
        'dateTo': !exists(json, 'dateTo') ? undefined : json['dateTo'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'mainName': !exists(json, 'mainName') ? undefined : json['mainName'],
        'mainCity': !exists(json, 'mainCity') ? undefined : json['mainCity'],
        'mainDescr': !exists(json, 'mainDescr') ? undefined : json['mainDescr'],
        'relName': !exists(json, 'relName') ? undefined : json['relName'],
        'relCity': !exists(json, 'relCity') ? undefined : json['relCity'],
        'relDescr': !exists(json, 'relDescr') ? undefined : json['relDescr'],
    };
}

export function WaterStructRequestRecordToJSON(value?: WaterStructRequestRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'docTypeCode': value.docTypeCode,
        'dateFrom': value.dateFrom,
        'dateTo': value.dateTo,
        'note': value.note,
        'mainName': value.mainName,
        'mainCity': value.mainCity,
        'mainDescr': value.mainDescr,
        'relName': value.relName,
        'relCity': value.relCity,
        'relDescr': value.relDescr,
    };
}

