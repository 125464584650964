/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface QualityTabRequestRecord
 */
export interface QualityTabRequestRecord {
    /**
     * 
     * @type {number}
     * @memberof QualityTabRequestRecord
     */
    id?: number | null;
    /**
     * 
     * @type {number}
     * @memberof QualityTabRequestRecord
     */
    determinantCode?: number | null;
    /**
     * 
     * @type {number}
     * @memberof QualityTabRequestRecord
     */
    avgConcentration?: number | null;
    /**
     * 
     * @type {number}
     * @memberof QualityTabRequestRecord
     */
    maxConcentration?: number | null;
    /**
     * 
     * @type {number}
     * @memberof QualityTabRequestRecord
     */
    balAmountDay?: number | null;
    /**
     * 
     * @type {number}
     * @memberof QualityTabRequestRecord
     */
    balAmountYear?: number | null;
}

/**
 * Check if a given object implements the QualityTabRequestRecord interface.
 */
export function instanceOfQualityTabRequestRecord(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function QualityTabRequestRecordFromJSON(json: any): QualityTabRequestRecord {
    return QualityTabRequestRecordFromJSONTyped(json, false);
}

export function QualityTabRequestRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): QualityTabRequestRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'determinantCode': !exists(json, 'determinantCode') ? undefined : json['determinantCode'],
        'avgConcentration': !exists(json, 'avgConcentration') ? undefined : json['avgConcentration'],
        'maxConcentration': !exists(json, 'maxConcentration') ? undefined : json['maxConcentration'],
        'balAmountDay': !exists(json, 'balAmountDay') ? undefined : json['balAmountDay'],
        'balAmountYear': !exists(json, 'balAmountYear') ? undefined : json['balAmountYear'],
    };
}

export function QualityTabRequestRecordToJSON(value?: QualityTabRequestRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'determinantCode': value.determinantCode,
        'avgConcentration': value.avgConcentration,
        'maxConcentration': value.maxConcentration,
        'balAmountDay': value.balAmountDay,
        'balAmountYear': value.balAmountYear,
    };
}

