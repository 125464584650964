/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SubmissionCatalogRecord
 */
export interface SubmissionCatalogRecord {
    /**
     * 
     * @type {number}
     * @memberof SubmissionCatalogRecord
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof SubmissionCatalogRecord
     */
    catalogItemId?: number;
    /**
     * 
     * @type {string}
     * @memberof SubmissionCatalogRecord
     */
    catalogItemName?: string;
    /**
     * 
     * @type {string}
     * @memberof SubmissionCatalogRecord
     */
    catalogType?: SubmissionCatalogRecordCatalogTypeEnum;
}


/**
 * @export
 */
export const SubmissionCatalogRecordCatalogTypeEnum = {
    Operator: 'OPERATOR',
    Installation: 'INSTALLATION',
    MonitoringPoint: 'MONITORING_POINT'
} as const;
export type SubmissionCatalogRecordCatalogTypeEnum = typeof SubmissionCatalogRecordCatalogTypeEnum[keyof typeof SubmissionCatalogRecordCatalogTypeEnum];


/**
 * Check if a given object implements the SubmissionCatalogRecord interface.
 */
export function instanceOfSubmissionCatalogRecord(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SubmissionCatalogRecordFromJSON(json: any): SubmissionCatalogRecord {
    return SubmissionCatalogRecordFromJSONTyped(json, false);
}

export function SubmissionCatalogRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubmissionCatalogRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'catalogItemId': !exists(json, 'catalogItemId') ? undefined : json['catalogItemId'],
        'catalogItemName': !exists(json, 'catalogItemName') ? undefined : json['catalogItemName'],
        'catalogType': !exists(json, 'catalogType') ? undefined : json['catalogType'],
    };
}

export function SubmissionCatalogRecordToJSON(value?: SubmissionCatalogRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'catalogItemId': value.catalogItemId,
        'catalogItemName': value.catalogItemName,
        'catalogType': value.catalogType,
    };
}

