/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CodeListItemBasicRecord } from './CodeListItemBasicRecord';
import {
    CodeListItemBasicRecordFromJSON,
    CodeListItemBasicRecordFromJSONTyped,
    CodeListItemBasicRecordToJSON,
} from './CodeListItemBasicRecord';
import type { NewOperatorResponseRecord } from './NewOperatorResponseRecord';
import {
    NewOperatorResponseRecordFromJSON,
    NewOperatorResponseRecordFromJSONTyped,
    NewOperatorResponseRecordToJSON,
} from './NewOperatorResponseRecord';
import type { OperatorRecord } from './OperatorRecord';
import {
    OperatorRecordFromJSON,
    OperatorRecordFromJSONTyped,
    OperatorRecordToJSON,
} from './OperatorRecord';
import type { WaterEvidenceOriginRecord } from './WaterEvidenceOriginRecord';
import {
    WaterEvidenceOriginRecordFromJSON,
    WaterEvidenceOriginRecordFromJSONTyped,
    WaterEvidenceOriginRecordToJSON,
} from './WaterEvidenceOriginRecord';

/**
 * 
 * @export
 * @interface WaterEvidenceDocumentResponseRecord
 */
export interface WaterEvidenceDocumentResponseRecord {
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceDocumentResponseRecord
     */
    id?: number | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceDocumentResponseRecord
     */
    number?: string | null;
    /**
     * 
     * @type {WaterEvidenceOriginRecord}
     * @memberof WaterEvidenceDocumentResponseRecord
     */
    origin?: WaterEvidenceOriginRecord | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof WaterEvidenceDocumentResponseRecord
     */
    sizp?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceDocumentResponseRecord
     */
    sizpName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceDocumentResponseRecord
     */
    note?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceDocumentResponseRecord
     */
    createdBy?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceDocumentResponseRecord
     */
    createdTimestamp?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceDocumentResponseRecord
     */
    updatedTimestamp?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceDocumentResponseRecord
     */
    updatedBy?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceDocumentResponseRecord
     */
    season?: string | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof WaterEvidenceDocumentResponseRecord
     */
    district?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof WaterEvidenceDocumentResponseRecord
     */
    region?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceDocumentResponseRecord
     */
    dateFrom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceDocumentResponseRecord
     */
    dateTo?: string | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof WaterEvidenceDocumentResponseRecord
     */
    documentType?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof WaterEvidenceDocumentResponseRecord
     */
    status?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {OperatorRecord}
     * @memberof WaterEvidenceDocumentResponseRecord
     */
    operator?: OperatorRecord | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceDocumentResponseRecord
     */
    operatorName?: string | null;
    /**
     * 
     * @type {NewOperatorResponseRecord}
     * @memberof WaterEvidenceDocumentResponseRecord
     */
    newOperator?: NewOperatorResponseRecord | null;
    /**
     * 
     * @type {boolean}
     * @memberof WaterEvidenceDocumentResponseRecord
     */
    isPlaceWaitingForPairing?: boolean | null;
}

/**
 * Check if a given object implements the WaterEvidenceDocumentResponseRecord interface.
 */
export function instanceOfWaterEvidenceDocumentResponseRecord(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WaterEvidenceDocumentResponseRecordFromJSON(json: any): WaterEvidenceDocumentResponseRecord {
    return WaterEvidenceDocumentResponseRecordFromJSONTyped(json, false);
}

export function WaterEvidenceDocumentResponseRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): WaterEvidenceDocumentResponseRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'number': !exists(json, 'number') ? undefined : json['number'],
        'origin': !exists(json, 'origin') ? undefined : WaterEvidenceOriginRecordFromJSON(json['origin']),
        'sizp': !exists(json, 'sizp') ? undefined : CodeListItemBasicRecordFromJSON(json['sizp']),
        'sizpName': !exists(json, 'sizpName') ? undefined : json['sizpName'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'createdTimestamp': !exists(json, 'createdTimestamp') ? undefined : json['createdTimestamp'],
        'updatedTimestamp': !exists(json, 'updatedTimestamp') ? undefined : json['updatedTimestamp'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
        'season': !exists(json, 'season') ? undefined : json['season'],
        'district': !exists(json, 'district') ? undefined : CodeListItemBasicRecordFromJSON(json['district']),
        'region': !exists(json, 'region') ? undefined : CodeListItemBasicRecordFromJSON(json['region']),
        'dateFrom': !exists(json, 'dateFrom') ? undefined : json['dateFrom'],
        'dateTo': !exists(json, 'dateTo') ? undefined : json['dateTo'],
        'documentType': !exists(json, 'documentType') ? undefined : CodeListItemBasicRecordFromJSON(json['documentType']),
        'status': !exists(json, 'status') ? undefined : CodeListItemBasicRecordFromJSON(json['status']),
        'operator': !exists(json, 'operator') ? undefined : OperatorRecordFromJSON(json['operator']),
        'operatorName': !exists(json, 'operatorName') ? undefined : json['operatorName'],
        'newOperator': !exists(json, 'newOperator') ? undefined : NewOperatorResponseRecordFromJSON(json['newOperator']),
        'isPlaceWaitingForPairing': !exists(json, 'isPlaceWaitingForPairing') ? undefined : json['isPlaceWaitingForPairing'],
    };
}

export function WaterEvidenceDocumentResponseRecordToJSON(value?: WaterEvidenceDocumentResponseRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'number': value.number,
        'origin': WaterEvidenceOriginRecordToJSON(value.origin),
        'sizp': CodeListItemBasicRecordToJSON(value.sizp),
        'sizpName': value.sizpName,
        'note': value.note,
        'createdBy': value.createdBy,
        'createdTimestamp': value.createdTimestamp,
        'updatedTimestamp': value.updatedTimestamp,
        'updatedBy': value.updatedBy,
        'season': value.season,
        'district': CodeListItemBasicRecordToJSON(value.district),
        'region': CodeListItemBasicRecordToJSON(value.region),
        'dateFrom': value.dateFrom,
        'dateTo': value.dateTo,
        'documentType': CodeListItemBasicRecordToJSON(value.documentType),
        'status': CodeListItemBasicRecordToJSON(value.status),
        'operator': OperatorRecordToJSON(value.operator),
        'operatorName': value.operatorName,
        'newOperator': NewOperatorResponseRecordToJSON(value.newOperator),
        'isPlaceWaitingForPairing': value.isPlaceWaitingForPairing,
    };
}

