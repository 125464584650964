/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CodeListItemBasicRecord } from './CodeListItemBasicRecord';
import {
    CodeListItemBasicRecordFromJSON,
    CodeListItemBasicRecordFromJSONTyped,
    CodeListItemBasicRecordToJSON,
} from './CodeListItemBasicRecord';

/**
 * 
 * @export
 * @interface WaterStructPowerPlantResponseRecord
 */
export interface WaterStructPowerPlantResponseRecord {
    /**
     * 
     * @type {number}
     * @memberof WaterStructPowerPlantResponseRecord
     */
    id?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructPowerPlantResponseRecord
     */
    maxFall?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructPowerPlantResponseRecord
     */
    minFall?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructPowerPlantResponseRecord
     */
    turbines?: number | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof WaterStructPowerPlantResponseRecord
     */
    turbineType?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructPowerPlantResponseRecord
     */
    desFlow?: number | null;
    /**
     * 
     * @type {string}
     * @memberof WaterStructPowerPlantResponseRecord
     */
    perform?: string | null;
}

/**
 * Check if a given object implements the WaterStructPowerPlantResponseRecord interface.
 */
export function instanceOfWaterStructPowerPlantResponseRecord(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WaterStructPowerPlantResponseRecordFromJSON(json: any): WaterStructPowerPlantResponseRecord {
    return WaterStructPowerPlantResponseRecordFromJSONTyped(json, false);
}

export function WaterStructPowerPlantResponseRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): WaterStructPowerPlantResponseRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'maxFall': !exists(json, 'maxFall') ? undefined : json['maxFall'],
        'minFall': !exists(json, 'minFall') ? undefined : json['minFall'],
        'turbines': !exists(json, 'turbines') ? undefined : json['turbines'],
        'turbineType': !exists(json, 'turbineType') ? undefined : CodeListItemBasicRecordFromJSON(json['turbineType']),
        'desFlow': !exists(json, 'desFlow') ? undefined : json['desFlow'],
        'perform': !exists(json, 'perform') ? undefined : json['perform'],
    };
}

export function WaterStructPowerPlantResponseRecordToJSON(value?: WaterStructPowerPlantResponseRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'maxFall': value.maxFall,
        'minFall': value.minFall,
        'turbines': value.turbines,
        'turbineType': CodeListItemBasicRecordToJSON(value.turbineType),
        'desFlow': value.desFlow,
        'perform': value.perform,
    };
}

