/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WaterStructDuctRequestRecord
 */
export interface WaterStructDuctRequestRecord {
    /**
     * 
     * @type {number}
     * @memberof WaterStructDuctRequestRecord
     */
    id?: number | null;
    /**
     * 
     * @type {string}
     * @memberof WaterStructDuctRequestRecord
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructDuctRequestRecord
     */
    ductCode?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructDuctRequestRecord
     */
    waterUseCode?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructDuctRequestRecord
     */
    supplies?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructDuctRequestRecord
     */
    typeCode?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructDuctRequestRecord
     */
    minSize?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructDuctRequestRecord
     */
    maxSize?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructDuctRequestRecord
     */
    length?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructDuctRequestRecord
     */
    objNum?: number | null;
}

/**
 * Check if a given object implements the WaterStructDuctRequestRecord interface.
 */
export function instanceOfWaterStructDuctRequestRecord(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WaterStructDuctRequestRecordFromJSON(json: any): WaterStructDuctRequestRecord {
    return WaterStructDuctRequestRecordFromJSONTyped(json, false);
}

export function WaterStructDuctRequestRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): WaterStructDuctRequestRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'ductCode': !exists(json, 'ductCode') ? undefined : json['ductCode'],
        'waterUseCode': !exists(json, 'waterUseCode') ? undefined : json['waterUseCode'],
        'supplies': !exists(json, 'supplies') ? undefined : json['supplies'],
        'typeCode': !exists(json, 'typeCode') ? undefined : json['typeCode'],
        'minSize': !exists(json, 'minSize') ? undefined : json['minSize'],
        'maxSize': !exists(json, 'maxSize') ? undefined : json['maxSize'],
        'length': !exists(json, 'length') ? undefined : json['length'],
        'objNum': !exists(json, 'objNum') ? undefined : json['objNum'],
    };
}

export function WaterStructDuctRequestRecordToJSON(value?: WaterStructDuctRequestRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'ductCode': value.ductCode,
        'waterUseCode': value.waterUseCode,
        'supplies': value.supplies,
        'typeCode': value.typeCode,
        'minSize': value.minSize,
        'maxSize': value.maxSize,
        'length': value.length,
        'objNum': value.objNum,
    };
}

