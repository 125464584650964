/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ValidationWeResult
 */
export interface ValidationWeResult {
    /**
     * 
     * @type {boolean}
     * @memberof ValidationWeResult
     */
    evidenceExist: boolean;
    /**
     * 
     * @type {string}
     * @memberof ValidationWeResult
     */
    error?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ValidationWeResult
     */
    placeDrId: string;
    /**
     * 
     * @type {string}
     * @memberof ValidationWeResult
     */
    installationDrId: string;
    /**
     * 
     * @type {string}
     * @memberof ValidationWeResult
     */
    operatorDrId: string;
}

/**
 * Check if a given object implements the ValidationWeResult interface.
 */
export function instanceOfValidationWeResult(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "evidenceExist" in value;
    isInstance = isInstance && "placeDrId" in value;
    isInstance = isInstance && "installationDrId" in value;
    isInstance = isInstance && "operatorDrId" in value;

    return isInstance;
}

export function ValidationWeResultFromJSON(json: any): ValidationWeResult {
    return ValidationWeResultFromJSONTyped(json, false);
}

export function ValidationWeResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValidationWeResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'evidenceExist': json['evidenceExist'],
        'error': !exists(json, 'error') ? undefined : json['error'],
        'placeDrId': json['placeDrId'],
        'installationDrId': json['installationDrId'],
        'operatorDrId': json['operatorDrId'],
    };
}

export function ValidationWeResultToJSON(value?: ValidationWeResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'evidenceExist': value.evidenceExist,
        'error': value.error,
        'placeDrId': value.placeDrId,
        'installationDrId': value.installationDrId,
        'operatorDrId': value.operatorDrId,
    };
}

