/* tslint:disable */
/* eslint-disable */
/**
 * Catalog API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.31.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CodelistItemInputRequired } from './CodelistItemInputRequired';
import {
    CodelistItemInputRequiredFromJSON,
    CodelistItemInputRequiredFromJSONTyped,
    CodelistItemInputRequiredToJSON,
} from './CodelistItemInputRequired';
import type { Level } from './Level';
import {
    LevelFromJSON,
    LevelFromJSONTyped,
    LevelToJSON,
} from './Level';

/**
 * 
 * @export
 * @interface EnvironmentInput
 */
export interface EnvironmentInput {
    /**
     * 
     * @type {string}
     * @memberof EnvironmentInput
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof EnvironmentInput
     */
    internationalCode: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnvironmentInput
     */
    effectiveFrom: string;
    /**
     * 
     * @type {string}
     * @memberof EnvironmentInput
     */
    effectiveTo: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnvironmentInput
     */
    internationalName: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnvironmentInput
     */
    nameForReport: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnvironmentInput
     */
    nameForGis: string | null;
    /**
     * 
     * @type {CodelistItemInputRequired}
     * @memberof EnvironmentInput
     */
    basinType: CodelistItemInputRequired;
    /**
     * 
     * @type {string}
     * @memberof EnvironmentInput
     */
    managerCode: string | null;
    /**
     * 
     * @type {number}
     * @memberof EnvironmentInput
     */
    hierarchicalLevel: number;
    /**
     * 
     * @type {CodelistItemInputRequired}
     * @memberof EnvironmentInput
     */
    positionType: CodelistItemInputRequired;
    /**
     * 
     * @type {string}
     * @memberof EnvironmentInput
     */
    mapNumber: string | null;
    /**
     * 
     * @type {number}
     * @memberof EnvironmentInput
     */
    length: number;
    /**
     * 
     * @type {string}
     * @memberof EnvironmentInput
     */
    hydrologicalNumber: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnvironmentInput
     */
    vhpid: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnvironmentInput
     */
    note: string | null;
    /**
     * 
     * @type {CodelistItemInputRequired}
     * @memberof EnvironmentInput
     */
    environmentType: CodelistItemInputRequired;
    /**
     * 
     * @type {CodelistItemInputRequired}
     * @memberof EnvironmentInput
     */
    environmentFormType: CodelistItemInputRequired;
    /**
     * 
     * @type {Level}
     * @memberof EnvironmentInput
     */
    level: Level | null;
    /**
     * 
     * @type {string}
     * @memberof EnvironmentInput
     */
    code: string | null;
}

/**
 * Check if a given object implements the EnvironmentInput interface.
 */
export function instanceOfEnvironmentInput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "internationalCode" in value;
    isInstance = isInstance && "effectiveFrom" in value;
    isInstance = isInstance && "effectiveTo" in value;
    isInstance = isInstance && "internationalName" in value;
    isInstance = isInstance && "nameForReport" in value;
    isInstance = isInstance && "nameForGis" in value;
    isInstance = isInstance && "basinType" in value;
    isInstance = isInstance && "managerCode" in value;
    isInstance = isInstance && "hierarchicalLevel" in value;
    isInstance = isInstance && "positionType" in value;
    isInstance = isInstance && "mapNumber" in value;
    isInstance = isInstance && "length" in value;
    isInstance = isInstance && "hydrologicalNumber" in value;
    isInstance = isInstance && "vhpid" in value;
    isInstance = isInstance && "note" in value;
    isInstance = isInstance && "environmentType" in value;
    isInstance = isInstance && "environmentFormType" in value;
    isInstance = isInstance && "level" in value;
    isInstance = isInstance && "code" in value;

    return isInstance;
}

export function EnvironmentInputFromJSON(json: any): EnvironmentInput {
    return EnvironmentInputFromJSONTyped(json, false);
}

export function EnvironmentInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): EnvironmentInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'internationalCode': json['internationalCode'],
        'effectiveFrom': json['effectiveFrom'],
        'effectiveTo': json['effectiveTo'],
        'internationalName': json['internationalName'],
        'nameForReport': json['nameForReport'],
        'nameForGis': json['nameForGis'],
        'basinType': CodelistItemInputRequiredFromJSON(json['basinType']),
        'managerCode': json['managerCode'],
        'hierarchicalLevel': json['hierarchicalLevel'],
        'positionType': CodelistItemInputRequiredFromJSON(json['positionType']),
        'mapNumber': json['mapNumber'],
        'length': json['length'],
        'hydrologicalNumber': json['hydrologicalNumber'],
        'vhpid': json['vhpid'],
        'note': json['note'],
        'environmentType': CodelistItemInputRequiredFromJSON(json['environmentType']),
        'environmentFormType': CodelistItemInputRequiredFromJSON(json['environmentFormType']),
        'level': LevelFromJSON(json['level']),
        'code': json['code'],
    };
}

export function EnvironmentInputToJSON(value?: EnvironmentInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'internationalCode': value.internationalCode,
        'effectiveFrom': value.effectiveFrom,
        'effectiveTo': value.effectiveTo,
        'internationalName': value.internationalName,
        'nameForReport': value.nameForReport,
        'nameForGis': value.nameForGis,
        'basinType': CodelistItemInputRequiredToJSON(value.basinType),
        'managerCode': value.managerCode,
        'hierarchicalLevel': value.hierarchicalLevel,
        'positionType': CodelistItemInputRequiredToJSON(value.positionType),
        'mapNumber': value.mapNumber,
        'length': value.length,
        'hydrologicalNumber': value.hydrologicalNumber,
        'vhpid': value.vhpid,
        'note': value.note,
        'environmentType': CodelistItemInputRequiredToJSON(value.environmentType),
        'environmentFormType': CodelistItemInputRequiredToJSON(value.environmentFormType),
        'level': LevelToJSON(value.level),
        'code': value.code,
    };
}

