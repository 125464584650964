/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CodeListItemBasicRecord } from './CodeListItemBasicRecord';
import {
    CodeListItemBasicRecordFromJSON,
    CodeListItemBasicRecordFromJSONTyped,
    CodeListItemBasicRecordToJSON,
} from './CodeListItemBasicRecord';
import type { WaterEvidenceOriginAppendixRecord } from './WaterEvidenceOriginAppendixRecord';
import {
    WaterEvidenceOriginAppendixRecordFromJSON,
    WaterEvidenceOriginAppendixRecordFromJSONTyped,
    WaterEvidenceOriginAppendixRecordToJSON,
} from './WaterEvidenceOriginAppendixRecord';
import type { WaterEvidenceOriginRecord } from './WaterEvidenceOriginRecord';
import {
    WaterEvidenceOriginRecordFromJSON,
    WaterEvidenceOriginRecordFromJSONTyped,
    WaterEvidenceOriginRecordToJSON,
} from './WaterEvidenceOriginRecord';

/**
 * 
 * @export
 * @interface WaterEvidenceOtherAppendixResponseRecord
 */
export interface WaterEvidenceOtherAppendixResponseRecord {
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceOtherAppendixResponseRecord
     */
    id?: number | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof WaterEvidenceOtherAppendixResponseRecord
     */
    docType?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {WaterEvidenceOriginRecord}
     * @memberof WaterEvidenceOtherAppendixResponseRecord
     */
    changedByDocument?: WaterEvidenceOriginRecord | null;
    /**
     * 
     * @type {WaterEvidenceOriginAppendixRecord}
     * @memberof WaterEvidenceOtherAppendixResponseRecord
     */
    originAppendix?: WaterEvidenceOriginAppendixRecord | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceOtherAppendixResponseRecord
     */
    dateFrom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceOtherAppendixResponseRecord
     */
    dateTo?: string | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof WaterEvidenceOtherAppendixResponseRecord
     */
    status?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceOtherAppendixResponseRecord
     */
    note?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceOtherAppendixResponseRecord
     */
    mainName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceOtherAppendixResponseRecord
     */
    mainCity?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceOtherAppendixResponseRecord
     */
    mainDescription?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceOtherAppendixResponseRecord
     */
    legislative?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceOtherAppendixResponseRecord
     */
    measureWording?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceOtherAppendixResponseRecord
     */
    implementConditions?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceOtherAppendixResponseRecord
     */
    otherConditions?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceOtherAppendixResponseRecord
     */
    delictWording?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceOtherAppendixResponseRecord
     */
    fineAmount?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceOtherAppendixResponseRecord
     */
    fineMaturity?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceOtherAppendixResponseRecord
     */
    finePayDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceOtherAppendixResponseRecord
     */
    facility?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceOtherAppendixResponseRecord
     */
    activity?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceOtherAppendixResponseRecord
     */
    affection?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceOtherAppendixResponseRecord
     */
    permDesc?: string | null;
}

/**
 * Check if a given object implements the WaterEvidenceOtherAppendixResponseRecord interface.
 */
export function instanceOfWaterEvidenceOtherAppendixResponseRecord(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WaterEvidenceOtherAppendixResponseRecordFromJSON(json: any): WaterEvidenceOtherAppendixResponseRecord {
    return WaterEvidenceOtherAppendixResponseRecordFromJSONTyped(json, false);
}

export function WaterEvidenceOtherAppendixResponseRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): WaterEvidenceOtherAppendixResponseRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'docType': !exists(json, 'docType') ? undefined : CodeListItemBasicRecordFromJSON(json['docType']),
        'changedByDocument': !exists(json, 'changedByDocument') ? undefined : WaterEvidenceOriginRecordFromJSON(json['changedByDocument']),
        'originAppendix': !exists(json, 'originAppendix') ? undefined : WaterEvidenceOriginAppendixRecordFromJSON(json['originAppendix']),
        'dateFrom': !exists(json, 'dateFrom') ? undefined : json['dateFrom'],
        'dateTo': !exists(json, 'dateTo') ? undefined : json['dateTo'],
        'status': !exists(json, 'status') ? undefined : CodeListItemBasicRecordFromJSON(json['status']),
        'note': !exists(json, 'note') ? undefined : json['note'],
        'mainName': !exists(json, 'mainName') ? undefined : json['mainName'],
        'mainCity': !exists(json, 'mainCity') ? undefined : json['mainCity'],
        'mainDescription': !exists(json, 'mainDescription') ? undefined : json['mainDescription'],
        'legislative': !exists(json, 'legislative') ? undefined : json['legislative'],
        'measureWording': !exists(json, 'measureWording') ? undefined : json['measureWording'],
        'implementConditions': !exists(json, 'implementConditions') ? undefined : json['implementConditions'],
        'otherConditions': !exists(json, 'otherConditions') ? undefined : json['otherConditions'],
        'delictWording': !exists(json, 'delictWording') ? undefined : json['delictWording'],
        'fineAmount': !exists(json, 'fineAmount') ? undefined : json['fineAmount'],
        'fineMaturity': !exists(json, 'fineMaturity') ? undefined : json['fineMaturity'],
        'finePayDate': !exists(json, 'finePayDate') ? undefined : json['finePayDate'],
        'facility': !exists(json, 'facility') ? undefined : json['facility'],
        'activity': !exists(json, 'activity') ? undefined : json['activity'],
        'affection': !exists(json, 'affection') ? undefined : json['affection'],
        'permDesc': !exists(json, 'permDesc') ? undefined : json['permDesc'],
    };
}

export function WaterEvidenceOtherAppendixResponseRecordToJSON(value?: WaterEvidenceOtherAppendixResponseRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'docType': CodeListItemBasicRecordToJSON(value.docType),
        'changedByDocument': WaterEvidenceOriginRecordToJSON(value.changedByDocument),
        'originAppendix': WaterEvidenceOriginAppendixRecordToJSON(value.originAppendix),
        'dateFrom': value.dateFrom,
        'dateTo': value.dateTo,
        'status': CodeListItemBasicRecordToJSON(value.status),
        'note': value.note,
        'mainName': value.mainName,
        'mainCity': value.mainCity,
        'mainDescription': value.mainDescription,
        'legislative': value.legislative,
        'measureWording': value.measureWording,
        'implementConditions': value.implementConditions,
        'otherConditions': value.otherConditions,
        'delictWording': value.delictWording,
        'fineAmount': value.fineAmount,
        'fineMaturity': value.fineMaturity,
        'finePayDate': value.finePayDate,
        'facility': value.facility,
        'activity': value.activity,
        'affection': value.affection,
        'permDesc': value.permDesc,
    };
}

