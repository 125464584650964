/* tslint:disable */
/* eslint-disable */
/**
 * Catalog API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.31.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ItemCriteria
 */
export interface ItemCriteria {
    /**
     * 
     * @type {string}
     * @memberof ItemCriteria
     */
    searchField?: string;
    /**
     * 
     * @type {number}
     * @memberof ItemCriteria
     */
    determinantType?: number;
    /**
     * 
     * @type {string}
     * @memberof ItemCriteria
     */
    detCodeInt?: string;
    /**
     * 
     * @type {number}
     * @memberof ItemCriteria
     */
    anMethodType?: number;
    /**
     * 
     * @type {number}
     * @memberof ItemCriteria
     */
    anMethodGroupType?: number;
    /**
     * 
     * @type {number}
     * @memberof ItemCriteria
     */
    institutionType?: number;
}

/**
 * Check if a given object implements the ItemCriteria interface.
 */
export function instanceOfItemCriteria(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ItemCriteriaFromJSON(json: any): ItemCriteria {
    return ItemCriteriaFromJSONTyped(json, false);
}

export function ItemCriteriaFromJSONTyped(json: any, ignoreDiscriminator: boolean): ItemCriteria {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'searchField': !exists(json, 'searchField') ? undefined : json['searchField'],
        'determinantType': !exists(json, 'determinantType') ? undefined : json['determinantType'],
        'detCodeInt': !exists(json, 'detCodeInt') ? undefined : json['detCodeInt'],
        'anMethodType': !exists(json, 'anMethodType') ? undefined : json['anMethodType'],
        'anMethodGroupType': !exists(json, 'anMethodGroupType') ? undefined : json['anMethodGroupType'],
        'institutionType': !exists(json, 'institutionType') ? undefined : json['institutionType'],
    };
}

export function ItemCriteriaToJSON(value?: ItemCriteria | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'searchField': value.searchField,
        'determinantType': value.determinantType,
        'detCodeInt': value.detCodeInt,
        'anMethodType': value.anMethodType,
        'anMethodGroupType': value.anMethodGroupType,
        'institutionType': value.institutionType,
    };
}

