/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { HydroAnalysisFileRequestRecord } from './HydroAnalysisFileRequestRecord';
import {
    HydroAnalysisFileRequestRecordFromJSON,
    HydroAnalysisFileRequestRecordFromJSONTyped,
    HydroAnalysisFileRequestRecordToJSON,
} from './HydroAnalysisFileRequestRecord';
import type { HydroAnalysisFloodPlainUseRequestRecord } from './HydroAnalysisFloodPlainUseRequestRecord';
import {
    HydroAnalysisFloodPlainUseRequestRecordFromJSON,
    HydroAnalysisFloodPlainUseRequestRecordFromJSONTyped,
    HydroAnalysisFloodPlainUseRequestRecordToJSON,
} from './HydroAnalysisFloodPlainUseRequestRecord';
import type { HydroAnalysisGeologyRequestRecord } from './HydroAnalysisGeologyRequestRecord';
import {
    HydroAnalysisGeologyRequestRecordFromJSON,
    HydroAnalysisGeologyRequestRecordFromJSONTyped,
    HydroAnalysisGeologyRequestRecordToJSON,
} from './HydroAnalysisGeologyRequestRecord';
import type { HydroAnalysisLadUseRequestRecord } from './HydroAnalysisLadUseRequestRecord';
import {
    HydroAnalysisLadUseRequestRecordFromJSON,
    HydroAnalysisLadUseRequestRecordFromJSONTyped,
    HydroAnalysisLadUseRequestRecordToJSON,
} from './HydroAnalysisLadUseRequestRecord';
import type { HydroAnalysisRiparianRequestRecord } from './HydroAnalysisRiparianRequestRecord';
import {
    HydroAnalysisRiparianRequestRecordFromJSON,
    HydroAnalysisRiparianRequestRecordFromJSONTyped,
    HydroAnalysisRiparianRequestRecordToJSON,
} from './HydroAnalysisRiparianRequestRecord';
import type { HydroAnalysisScoreRequestRecord } from './HydroAnalysisScoreRequestRecord';
import {
    HydroAnalysisScoreRequestRecordFromJSON,
    HydroAnalysisScoreRequestRecordFromJSONTyped,
    HydroAnalysisScoreRequestRecordToJSON,
} from './HydroAnalysisScoreRequestRecord';
import type { HydroAnalysisSoilTypeRequestRecord } from './HydroAnalysisSoilTypeRequestRecord';
import {
    HydroAnalysisSoilTypeRequestRecordFromJSON,
    HydroAnalysisSoilTypeRequestRecordFromJSONTyped,
    HydroAnalysisSoilTypeRequestRecordToJSON,
} from './HydroAnalysisSoilTypeRequestRecord';

/**
 * 
 * @export
 * @interface HydroMorphologyProtocolRequestRecord
 */
export interface HydroMorphologyProtocolRequestRecord {
    /**
     * 
     * @type {number}
     * @memberof HydroMorphologyProtocolRequestRecord
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof HydroMorphologyProtocolRequestRecord
     */
    analysisDate?: string;
    /**
     * 
     * @type {number}
     * @memberof HydroMorphologyProtocolRequestRecord
     */
    catchmentArea?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroMorphologyProtocolRequestRecord
     */
    distanceToSource?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroMorphologyProtocolRequestRecord
     */
    meanSlope?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroMorphologyProtocolRequestRecord
     */
    wettedWidth?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroMorphologyProtocolRequestRecord
     */
    bankfullWidth?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroMorphologyProtocolRequestRecord
     */
    maxDepthTo1meter?: number;
    /**
     * 
     * @type {boolean}
     * @memberof HydroMorphologyProtocolRequestRecord
     */
    maxDepthOver1meter?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof HydroMorphologyProtocolRequestRecord
     */
    dryRiverBed?: boolean;
    /**
     * 
     * @type {number}
     * @memberof HydroMorphologyProtocolRequestRecord
     */
    migrationBarriersU?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroMorphologyProtocolRequestRecord
     */
    migrationBarriersUHeight?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroMorphologyProtocolRequestRecord
     */
    migrationBarriersUDistance?: number;
    /**
     * 
     * @type {string}
     * @memberof HydroMorphologyProtocolRequestRecord
     */
    migrationBarriersUNote?: string;
    /**
     * 
     * @type {number}
     * @memberof HydroMorphologyProtocolRequestRecord
     */
    migrationBarriersD?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroMorphologyProtocolRequestRecord
     */
    migrationBarriersDHeight?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroMorphologyProtocolRequestRecord
     */
    migrationBarriersDDistance?: number;
    /**
     * 
     * @type {string}
     * @memberof HydroMorphologyProtocolRequestRecord
     */
    migrationBarriersDNote?: string;
    /**
     * 
     * @type {number}
     * @memberof HydroMorphologyProtocolRequestRecord
     */
    topographyMax?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroMorphologyProtocolRequestRecord
     */
    topographyMin?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroMorphologyProtocolRequestRecord
     */
    avgFlow?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroMorphologyProtocolRequestRecord
     */
    maxFlow?: number;
    /**
     * 
     * @type {string}
     * @memberof HydroMorphologyProtocolRequestRecord
     */
    maxFlowDate?: string;
    /**
     * 
     * @type {number}
     * @memberof HydroMorphologyProtocolRequestRecord
     */
    minFlow?: number;
    /**
     * 
     * @type {string}
     * @memberof HydroMorphologyProtocolRequestRecord
     */
    minFlowDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof HydroMorphologyProtocolRequestRecord
     */
    minFlowRep?: boolean;
    /**
     * 
     * @type {string}
     * @memberof HydroMorphologyProtocolRequestRecord
     */
    note?: string;
    /**
     * 
     * @type {string}
     * @memberof HydroMorphologyProtocolRequestRecord
     */
    monPointCode?: string;
    /**
     * 
     * @type {number}
     * @memberof HydroMorphologyProtocolRequestRecord
     */
    monPointId?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroMorphologyProtocolRequestRecord
     */
    researcherItemCode?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroMorphologyProtocolRequestRecord
     */
    depthVariationItemCode?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroMorphologyProtocolRequestRecord
     */
    vegetationItemCode?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroMorphologyProtocolRequestRecord
     */
    channelPlanTypeItemCode?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroMorphologyProtocolRequestRecord
     */
    channelPlanItemCode?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroMorphologyProtocolRequestRecord
     */
    valleyFormItemCode?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroMorphologyProtocolRequestRecord
     */
    migrationBarriersUTypesItemCode?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroMorphologyProtocolRequestRecord
     */
    migrationBarriersUStructureItemCode?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroMorphologyProtocolRequestRecord
     */
    migrationBarriersDTypesItemCode?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroMorphologyProtocolRequestRecord
     */
    migrationBarriersDStructureItemCode?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroMorphologyProtocolRequestRecord
     */
    migrationRegimeDiffGwItemCode?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroMorphologyProtocolRequestRecord
     */
    migrationRegimeDiffSwItemCode?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof HydroMorphologyProtocolRequestRecord
     */
    riverUseItemCodes?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof HydroMorphologyProtocolRequestRecord
     */
    crossSectionItemCodes?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof HydroMorphologyProtocolRequestRecord
     */
    bankStabilizationItemCodes?: Array<number>;
    /**
     * 
     * @type {Array<HydroAnalysisRiparianRequestRecord>}
     * @memberof HydroMorphologyProtocolRequestRecord
     */
    riparianZoneRequestItems?: Array<HydroAnalysisRiparianRequestRecord>;
    /**
     * 
     * @type {Array<HydroAnalysisFloodPlainUseRequestRecord>}
     * @memberof HydroMorphologyProtocolRequestRecord
     */
    floodplainZoneRequestItems?: Array<HydroAnalysisFloodPlainUseRequestRecord>;
    /**
     * 
     * @type {Array<HydroAnalysisGeologyRequestRecord>}
     * @memberof HydroMorphologyProtocolRequestRecord
     */
    geologyRequestItems?: Array<HydroAnalysisGeologyRequestRecord>;
    /**
     * 
     * @type {Array<HydroAnalysisSoilTypeRequestRecord>}
     * @memberof HydroMorphologyProtocolRequestRecord
     */
    soilTypeRequestItems?: Array<HydroAnalysisSoilTypeRequestRecord>;
    /**
     * 
     * @type {Array<HydroAnalysisLadUseRequestRecord>}
     * @memberof HydroMorphologyProtocolRequestRecord
     */
    landUseRequestItems?: Array<HydroAnalysisLadUseRequestRecord>;
    /**
     * 
     * @type {Array<HydroAnalysisFileRequestRecord>}
     * @memberof HydroMorphologyProtocolRequestRecord
     */
    hydroAnalysisFiles?: Array<HydroAnalysisFileRequestRecord>;
    /**
     * 
     * @type {HydroAnalysisScoreRequestRecord}
     * @memberof HydroMorphologyProtocolRequestRecord
     */
    hydroAnalysisScore?: HydroAnalysisScoreRequestRecord;
}

/**
 * Check if a given object implements the HydroMorphologyProtocolRequestRecord interface.
 */
export function instanceOfHydroMorphologyProtocolRequestRecord(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function HydroMorphologyProtocolRequestRecordFromJSON(json: any): HydroMorphologyProtocolRequestRecord {
    return HydroMorphologyProtocolRequestRecordFromJSONTyped(json, false);
}

export function HydroMorphologyProtocolRequestRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): HydroMorphologyProtocolRequestRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'analysisDate': !exists(json, 'analysisDate') ? undefined : json['analysisDate'],
        'catchmentArea': !exists(json, 'catchmentArea') ? undefined : json['catchmentArea'],
        'distanceToSource': !exists(json, 'distanceToSource') ? undefined : json['distanceToSource'],
        'meanSlope': !exists(json, 'meanSlope') ? undefined : json['meanSlope'],
        'wettedWidth': !exists(json, 'wettedWidth') ? undefined : json['wettedWidth'],
        'bankfullWidth': !exists(json, 'bankfullWidth') ? undefined : json['bankfullWidth'],
        'maxDepthTo1meter': !exists(json, 'maxDepthTo1meter') ? undefined : json['maxDepthTo1meter'],
        'maxDepthOver1meter': !exists(json, 'maxDepthOver1meter') ? undefined : json['maxDepthOver1meter'],
        'dryRiverBed': !exists(json, 'dryRiverBed') ? undefined : json['dryRiverBed'],
        'migrationBarriersU': !exists(json, 'migrationBarriers_u') ? undefined : json['migrationBarriers_u'],
        'migrationBarriersUHeight': !exists(json, 'migrationBarriers_u_height') ? undefined : json['migrationBarriers_u_height'],
        'migrationBarriersUDistance': !exists(json, 'migrationBarriers_u_distance') ? undefined : json['migrationBarriers_u_distance'],
        'migrationBarriersUNote': !exists(json, 'migrationBarriers_u_note') ? undefined : json['migrationBarriers_u_note'],
        'migrationBarriersD': !exists(json, 'migrationBarriers_d') ? undefined : json['migrationBarriers_d'],
        'migrationBarriersDHeight': !exists(json, 'migrationBarriers_d_height') ? undefined : json['migrationBarriers_d_height'],
        'migrationBarriersDDistance': !exists(json, 'migrationBarriers_d_distance') ? undefined : json['migrationBarriers_d_distance'],
        'migrationBarriersDNote': !exists(json, 'migrationBarriers_d_note') ? undefined : json['migrationBarriers_d_note'],
        'topographyMax': !exists(json, 'topographyMax') ? undefined : json['topographyMax'],
        'topographyMin': !exists(json, 'topographyMin') ? undefined : json['topographyMin'],
        'avgFlow': !exists(json, 'avgFlow') ? undefined : json['avgFlow'],
        'maxFlow': !exists(json, 'maxFlow') ? undefined : json['maxFlow'],
        'maxFlowDate': !exists(json, 'maxFlowDate') ? undefined : json['maxFlowDate'],
        'minFlow': !exists(json, 'minFlow') ? undefined : json['minFlow'],
        'minFlowDate': !exists(json, 'minFlowDate') ? undefined : json['minFlowDate'],
        'minFlowRep': !exists(json, 'minFlowRep') ? undefined : json['minFlowRep'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'monPointCode': !exists(json, 'monPointCode') ? undefined : json['monPointCode'],
        'monPointId': !exists(json, 'monPointId') ? undefined : json['monPointId'],
        'researcherItemCode': !exists(json, 'researcherItemCode') ? undefined : json['researcherItemCode'],
        'depthVariationItemCode': !exists(json, 'depthVariationItemCode') ? undefined : json['depthVariationItemCode'],
        'vegetationItemCode': !exists(json, 'vegetationItemCode') ? undefined : json['vegetationItemCode'],
        'channelPlanTypeItemCode': !exists(json, 'channelPlanTypeItemCode') ? undefined : json['channelPlanTypeItemCode'],
        'channelPlanItemCode': !exists(json, 'channelPlanItemCode') ? undefined : json['channelPlanItemCode'],
        'valleyFormItemCode': !exists(json, 'valleyFormItemCode') ? undefined : json['valleyFormItemCode'],
        'migrationBarriersUTypesItemCode': !exists(json, 'migrationBarriersUTypesItemCode') ? undefined : json['migrationBarriersUTypesItemCode'],
        'migrationBarriersUStructureItemCode': !exists(json, 'migrationBarriersUStructureItemCode') ? undefined : json['migrationBarriersUStructureItemCode'],
        'migrationBarriersDTypesItemCode': !exists(json, 'migrationBarriersDTypesItemCode') ? undefined : json['migrationBarriersDTypesItemCode'],
        'migrationBarriersDStructureItemCode': !exists(json, 'migrationBarriersDStructureItemCode') ? undefined : json['migrationBarriersDStructureItemCode'],
        'migrationRegimeDiffGwItemCode': !exists(json, 'migrationRegimeDiffGwItemCode') ? undefined : json['migrationRegimeDiffGwItemCode'],
        'migrationRegimeDiffSwItemCode': !exists(json, 'migrationRegimeDiffSwItemCode') ? undefined : json['migrationRegimeDiffSwItemCode'],
        'riverUseItemCodes': !exists(json, 'riverUseItemCodes') ? undefined : json['riverUseItemCodes'],
        'crossSectionItemCodes': !exists(json, 'crossSectionItemCodes') ? undefined : json['crossSectionItemCodes'],
        'bankStabilizationItemCodes': !exists(json, 'bankStabilizationItemCodes') ? undefined : json['bankStabilizationItemCodes'],
        'riparianZoneRequestItems': !exists(json, 'riparianZoneRequestItems') ? undefined : ((json['riparianZoneRequestItems'] as Array<any>).map(HydroAnalysisRiparianRequestRecordFromJSON)),
        'floodplainZoneRequestItems': !exists(json, 'floodplainZoneRequestItems') ? undefined : ((json['floodplainZoneRequestItems'] as Array<any>).map(HydroAnalysisFloodPlainUseRequestRecordFromJSON)),
        'geologyRequestItems': !exists(json, 'geologyRequestItems') ? undefined : ((json['geologyRequestItems'] as Array<any>).map(HydroAnalysisGeologyRequestRecordFromJSON)),
        'soilTypeRequestItems': !exists(json, 'soilTypeRequestItems') ? undefined : ((json['soilTypeRequestItems'] as Array<any>).map(HydroAnalysisSoilTypeRequestRecordFromJSON)),
        'landUseRequestItems': !exists(json, 'landUseRequestItems') ? undefined : ((json['landUseRequestItems'] as Array<any>).map(HydroAnalysisLadUseRequestRecordFromJSON)),
        'hydroAnalysisFiles': !exists(json, 'hydroAnalysisFiles') ? undefined : ((json['hydroAnalysisFiles'] as Array<any>).map(HydroAnalysisFileRequestRecordFromJSON)),
        'hydroAnalysisScore': !exists(json, 'hydroAnalysisScore') ? undefined : HydroAnalysisScoreRequestRecordFromJSON(json['hydroAnalysisScore']),
    };
}

export function HydroMorphologyProtocolRequestRecordToJSON(value?: HydroMorphologyProtocolRequestRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'analysisDate': value.analysisDate,
        'catchmentArea': value.catchmentArea,
        'distanceToSource': value.distanceToSource,
        'meanSlope': value.meanSlope,
        'wettedWidth': value.wettedWidth,
        'bankfullWidth': value.bankfullWidth,
        'maxDepthTo1meter': value.maxDepthTo1meter,
        'maxDepthOver1meter': value.maxDepthOver1meter,
        'dryRiverBed': value.dryRiverBed,
        'migrationBarriers_u': value.migrationBarriersU,
        'migrationBarriers_u_height': value.migrationBarriersUHeight,
        'migrationBarriers_u_distance': value.migrationBarriersUDistance,
        'migrationBarriers_u_note': value.migrationBarriersUNote,
        'migrationBarriers_d': value.migrationBarriersD,
        'migrationBarriers_d_height': value.migrationBarriersDHeight,
        'migrationBarriers_d_distance': value.migrationBarriersDDistance,
        'migrationBarriers_d_note': value.migrationBarriersDNote,
        'topographyMax': value.topographyMax,
        'topographyMin': value.topographyMin,
        'avgFlow': value.avgFlow,
        'maxFlow': value.maxFlow,
        'maxFlowDate': value.maxFlowDate,
        'minFlow': value.minFlow,
        'minFlowDate': value.minFlowDate,
        'minFlowRep': value.minFlowRep,
        'note': value.note,
        'monPointCode': value.monPointCode,
        'monPointId': value.monPointId,
        'researcherItemCode': value.researcherItemCode,
        'depthVariationItemCode': value.depthVariationItemCode,
        'vegetationItemCode': value.vegetationItemCode,
        'channelPlanTypeItemCode': value.channelPlanTypeItemCode,
        'channelPlanItemCode': value.channelPlanItemCode,
        'valleyFormItemCode': value.valleyFormItemCode,
        'migrationBarriersUTypesItemCode': value.migrationBarriersUTypesItemCode,
        'migrationBarriersUStructureItemCode': value.migrationBarriersUStructureItemCode,
        'migrationBarriersDTypesItemCode': value.migrationBarriersDTypesItemCode,
        'migrationBarriersDStructureItemCode': value.migrationBarriersDStructureItemCode,
        'migrationRegimeDiffGwItemCode': value.migrationRegimeDiffGwItemCode,
        'migrationRegimeDiffSwItemCode': value.migrationRegimeDiffSwItemCode,
        'riverUseItemCodes': value.riverUseItemCodes,
        'crossSectionItemCodes': value.crossSectionItemCodes,
        'bankStabilizationItemCodes': value.bankStabilizationItemCodes,
        'riparianZoneRequestItems': value.riparianZoneRequestItems === undefined ? undefined : ((value.riparianZoneRequestItems as Array<any>).map(HydroAnalysisRiparianRequestRecordToJSON)),
        'floodplainZoneRequestItems': value.floodplainZoneRequestItems === undefined ? undefined : ((value.floodplainZoneRequestItems as Array<any>).map(HydroAnalysisFloodPlainUseRequestRecordToJSON)),
        'geologyRequestItems': value.geologyRequestItems === undefined ? undefined : ((value.geologyRequestItems as Array<any>).map(HydroAnalysisGeologyRequestRecordToJSON)),
        'soilTypeRequestItems': value.soilTypeRequestItems === undefined ? undefined : ((value.soilTypeRequestItems as Array<any>).map(HydroAnalysisSoilTypeRequestRecordToJSON)),
        'landUseRequestItems': value.landUseRequestItems === undefined ? undefined : ((value.landUseRequestItems as Array<any>).map(HydroAnalysisLadUseRequestRecordToJSON)),
        'hydroAnalysisFiles': value.hydroAnalysisFiles === undefined ? undefined : ((value.hydroAnalysisFiles as Array<any>).map(HydroAnalysisFileRequestRecordToJSON)),
        'hydroAnalysisScore': HydroAnalysisScoreRequestRecordToJSON(value.hydroAnalysisScore),
    };
}

