import React, { Fragment, PropsWithChildren, lazy, useEffect } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Slide, ToastContainer } from 'react-toastify';
import { Navigate, Outlet, RouterProvider, createBrowserRouter, useParams } from 'react-router-dom';

import './App.scss';
import './yup-config';

import PreferencesProvider from './store/preferences-context';
import Base from './components/layout/Base';
import DashboardPage from './pages/DashboardPage';
import LoadingOverlay from './components/common/LoadingOverlay';
import UsersPageDetail from './pages/UsersPageDetail';
import { useAuth } from 'react-oidc-context';
import useRole, { ROLE_APPLICATION, ROLE_DOMAIN, ROLE_OPERATION, ROLES } from './hooks/use-role';
import { allMonPointRoles, editingMonPointRoles } from './components/monitoringPoint/MonPointsDefinitions';
import useMonPointTypeRole from './hooks/use-mon-point-type-role';
import { isBlank } from './utils/stringUtils';
import { environmentSchema } from './environmentSchema';

const CodelistsPage = lazy(() => import('./pages/codelist/CodelistsPage'));
const CodelistPage = lazy(() => import('./pages/codelist/CodelistPage'));
const CodeItemsPage = lazy(() => import('./pages/codelist/CodeItemsPage'));
const CodeItemPage = lazy(() => import('./pages/codelist/CodeItemPage'));

const HydroMorphProtocolsPage = lazy(() => import('./pages/hydroMorphProtocols/HydroMorphProtocolsPage'));
const HydroMorphProtocolPage = lazy(() => import('./pages/hydroMorphProtocols/HydroMorphProtocolPage'));
const HydroMorphProtocolEditPage = lazy(() => import('./pages/hydroMorphProtocols/HydroMorphProtocolEditPage'));
const HydroMorphProtocolCreatePage = lazy(() => import('./pages/hydroMorphProtocols/HydroMorphProtocolCreatePage'));
const AssessmentsPage = lazy(() => import('./pages/assessment/AssessmentsPage'));
const AssessmentCreatePage = lazy(() => import('./pages/assessment/AssessmentCreatePage'));
const AssessmentEditPage = lazy(() => import('./pages/assessment/AssessmentEditPage'));
const AssessmentDetailPage = lazy(() => import('./pages/assessment/AssessmentDetailPage'));
const MandatoryReportsPage = lazy(() => import('./pages/mandatoryReport/MandatoryReportsPage'));
const MandatoryReportPairingPage = lazy(() => import('./pages/mandatoryReport/MandatoryReportPairingPage'));
const MandatoryReportNotificationCreatePage = lazy(() => import('./pages/mandatoryReport/MandatoryReportNotificationCreatePage'));
const WaterLawRecordsPage = lazy(() => import('./pages/waterLawRecords/WaterLawRecordsPage'));
const WaterLawRecordCreatePage = lazy(() => import('./pages/waterLawRecords/WaterLawRecordCreatePage'));
const WaterLawRecordDetailPage = lazy(() => import('./pages/waterLawRecords/WaterLawRecordDetailPage'));
const WaterLawRecordEditPage = lazy(() => import('./pages/waterLawRecords/WaterLawRecordEditPage'));
const WaterLawRecordChangePage = lazy(() => import('./pages/waterLawRecords/WaterLawRecordChangePage'));
const WaterHandlingAttachmentCreatePage = lazy(() => import('./pages/waterLawRecords/WaterHandlingAttachmentCreatePage'));
const WaterHandlingAttachmentDetailPage = lazy(() => import('./pages/waterLawRecords/WaterHandlingAttachmentDetailPage'));
const WaterHandlingAttachmentEditPage = lazy(() => import('./pages/waterLawRecords/WaterHandlingAttachmentEditPage'));
const WaterStructureAttachmentCreatePage = lazy(() => import('./pages/waterLawRecords/WaterStructureAttachmentCreatePage'));
const WaterStructureAttachmentDetailPage = lazy(() => import('./pages/waterLawRecords/WaterStructureAttachmentDetailPage'));
const WaterStructureAttachmentEditPage = lazy(() => import('./pages/waterLawRecords/WaterStructureAttachmentEditPage'));
const OtherAttachmentCreatePage = lazy(() => import('./pages/waterLawRecords/OtherAttachmentCreatePage'));
const OtherAttachmentDetailPage = lazy(() => import('./pages/waterLawRecords/OtherAttachmentDetailPage'));
const OtherAttachmentEditPage = lazy(() => import('./pages/waterLawRecords/OtherAttachmentEditPage'));
const WaterStructureSubAttachmentCreatePage = lazy(() => import('./pages/waterLawRecords/WaterStructureSubAttachmentCreatePage'));
const WaterStructureSubAttachmentDetailPage = lazy(() => import('./pages/waterLawRecords/WaterStructureSubAttachmentDetailPage'));
const WaterStructureSubAttachmentEditPage = lazy(() => import('./pages/waterLawRecords/WaterStructureSubAttachmentEditPage'));
const CodelistImportPage = lazy(() => import('./pages/import/CodelistImportPage'));
const DataImportPage = lazy(() => import('./pages/import/DataImportPage'));
const CodelistImportsPage = lazy(() => import('./pages/import/CodelistImportsPage'));
const DataImportsPage = lazy(() => import('./pages/import/DataImportsPage'));
const DataReportPage = lazy(() => import('./pages/import/DataReportPage'));
const DataSummaryPage = lazy(() => import('./pages/import/DataSummaryPage'));
const CodelistEditorPage = lazy(() => import('./pages/import/CodelistEditorPage'));
const DataEditorPage = lazy(() => import('./pages/import/DataEditorPage'));
const MonPointsPage = lazy(() => import('./pages/catalog/MonPointsPage'));
const MonPointPage = lazy(() => import('./pages/catalog/MonPointPage'));
const EnvironmentsPage = lazy(() => import('./pages/catalog/EnvironmentsPage'));
const EnvironmentPage = lazy(() => import('./pages/catalog/EnvironmentPage'));
const WaterShapesPage = lazy(() => import('./pages/catalog/WaterShapesPage'));
const WaterShapePage = lazy(() => import('./pages/catalog/WaterShapePage'));
const OperatorsPage = lazy(() => import('./pages/catalog/OperatorsPage'));
const OperatorPage = lazy(() => import('./pages/catalog/OperatorPage'));
const InstallationsPage = lazy(() => import('./pages/catalog/InstallationsPage'));
const InstallationPage = lazy(() => import('./pages/catalog/InstallationPage'));
const EvidencePage = lazy(() => import('./pages/catalog/EvidencePage'));
const InstallationMonPointsPage = lazy(() => import('./pages/catalog/InstallationMonPointsPage'));
const SettingsPage = lazy(() => import('./pages/SettingsPage'));
const UsersPage = lazy(() => import('./pages/UsersPage'));
const OverviewFiltersPage = lazy(() => import('./pages/overview/OverviewFiltersPage'));
const OverviewFilterPage = lazy(() => import('./pages/overview/OverviewFilterPage'));
const OverviewEditorPage = lazy(() => import('./pages/overview/OverviewEditorPage'));
const OverviewReportPage = lazy(() => import('./pages/overview/OverviewReportPage'));
const MetadataPage = lazy(() => import('./pages/overview/MetadataPage'));
const OverviewInProcess = lazy(() => import('./pages/overview/OverviewInProcess'));
const NotificationsPage = lazy(() => import('./pages/NotificationsPage'));
const CatalogUserGroupsPage = lazy(() => import('./pages/catalog/UserGroupsPage'));
const CatalogUserGroupPage = lazy(() => import('./pages/catalog/UserGroupPage'));
const CodelistUserGroupsPage = lazy(() => import('./pages/codelist/UserGroupsPage'));
const CodelistUserGroupPage = lazy(() => import('./pages/codelist/UserGroupPage'));
const FileDefinitionsPage = lazy(() => import('./pages/import/metadata/FileDefinitionsPage'));
const FileDefinitionPage = lazy(() => import('./pages/import/metadata/FileDefinitionPage'));
const FilePartDefinitionPage = lazy(() => import('./pages/import/metadata/FilePartDefinitionPage'));
const RowTypeDefinitionPage = lazy(() => import('./pages/import/metadata/RowTypeDefinitionPage'));
const ColumnDefinitionPage = lazy(() => import('./pages/import/metadata/ColumnDefinitionPage'));
const RowAttributeDefinitionPage = lazy(() => import('./pages/import/metadata/RowAttributeDefinitionPage'));
const RowAttributeTransformationDefinitionPage = lazy(() => import('./pages/import/metadata/RowAttributeTransformationDefinitionPage'));
const DataRegistryProductDefinitionPage = lazy(() => import('./pages/import/metadata/DataRegistryProductDefinitionPage'));
const ThresholdsPage = lazy(() => import('./pages/catalog/ThresholdsPage'));
const ThresholdPage = lazy(() => import('./pages/catalog/ThresholdPage'));
const MethodicsPage = lazy(() => import('./pages/catalog/MethodicsPage'));
const MethodicPage = lazy(() => import('./pages/catalog/MethodicPage'));
const MonPointImportPage = lazy(() => import('./pages/import/MonPointImportPage'));
const MonPointImportsPage = lazy(() => import('./pages/import/MonPointImportsPage'));
const MonPointEditorPage = lazy(() => import('./pages/import/MonPointEditorPage'));
const MethodicImportPage = lazy(() => import('./pages/import/MethodicImportPage'));
const MethodicImportsPage = lazy(() => import('./pages/import/MethodicImportsPage'));
const MethodicEditorPage = lazy(() => import('./pages/import/MethodicEditorPage'));

// Validácia správnosti premenných prostredia
environmentSchema.validateSync(ENV);

window.getAuthTokenForEform = function () {
    const getSession = sessionStorage.getItem(
        'oidc.user:' + ENV.KEYCLOAK_URL + '/realms/' + ENV.KEYCLOAK_REALM + ':' + ENV.KEYCLOAK_CLIENT
    );
    const sessionJson = JSON.parse(getSession!);
    return sessionJson.access_token;
};

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: Infinity
        }
    }
});

type ProtectProps = {
    roles?: ROLES[];
    forbidden?: ROLES[];
    appRole?: ROLE_APPLICATION;
    domainRoles?: {
        domain: ROLE_DOMAIN[];
        application: ROLE_APPLICATION;
        operation?: ROLE_OPERATION;
    };
};

const Protect: React.FC<PropsWithChildren<ProtectProps>> = ({ children, roles, forbidden, appRole, domainRoles }) => {
    const { hasSome, hasSomeDomainRole, hasSomeAppRole } = useRole();

    const hasRole = roles ? hasSome(roles) : false;
    const hasAppRole = appRole ? hasSomeAppRole(appRole) : false;
    const hasDomainRole = domainRoles ? hasSomeDomainRole(domainRoles.domain, domainRoles.application, domainRoles.operation) : false;
    const hasForbiddenRole = forbidden ? hasSome(forbidden) : false;

    if ((!hasRole && !hasAppRole && !hasDomainRole) || hasForbiddenRole) {
        return (
            <Navigate
                to='/dashboard'
                replace
            />
        );
    }

    return <>{children}</>;
};

const ProtectDomain: React.FC<PropsWithChildren<ProtectProps & { application: ROLE_APPLICATION; domainParamKey?: string }>> = ({
    children,
    application,
    domainParamKey = 'domain',
    roles,
    appRole,
    domainRoles
}) => {
    const { hasSome, hasSomeDomainRole } = useRole();
    const params = useParams();
    const domain = params[domainParamKey];

    if (isBlank(domain) || (!hasSome(ROLES.ADMINISTRATOR) && !hasSomeDomainRole(domain as ROLE_DOMAIN, application))) {
        return (
            <Navigate
                to='/dashboard'
                replace
            />
        );
    }

    return (
        <Protect
            roles={roles}
            appRole={appRole}
            domainRoles={domainRoles}
        >
            {children}
        </Protect>
    );
};

const ProtectMonPoint: React.FC<PropsWithChildren<{ writeRoleRequired?: boolean }>> = ({ children, writeRoleRequired = false }) => {
    const { monPointType } = useParams();
    const { hasEditorRole, hasReadOrEditorRole } = useMonPointTypeRole();
    const showMonPoint = writeRoleRequired ? hasEditorRole(monPointType) : hasReadOrEditorRole(monPointType);
    if (showMonPoint) {
        return <>{children}</>;
    } else {
        return (
            <Navigate
                to='/dashboard'
                replace
            />
        );
    }
};

/**
 * Slúži na prerendrovanie komponenty ak sa zmeni nejaky parameter v URL definovany s keyName
 *
 * @param keyName: názov parametra v url ktorý sa má použiť ako key pre komponenty, môže byť jeden alebo viacero ako pole
 * @returns komponent zadaný ako children
 */
const ComponentWithKey: React.FC<PropsWithChildren<{ keyName: string | string[] }>> = ({ children, keyName }) => {
    const params = useParams();
    const keys = Array.isArray(keyName) ? keyName : [keyName];
    const fragmentKey = JSON.stringify(
        keys.reduce<Record<string, string>>((prev, curr) => {
            prev[curr] = params[curr] ?? 'undefined';
            return prev;
        }, {})
    );

    return <Fragment key={fragmentKey}>{children}</Fragment>;
};

const router = createBrowserRouter([
    {
        path: '/',
        element: <Base />,
        children: [
            {
                index: true,
                element: <DashboardPage />
            },
            {
                path: 'dashboard',
                element: <DashboardPage />
            },
            {
                path: 'codelist',
                element: (
                    <Protect
                        roles={[ROLES.ADMINISTRATOR, ROLES.CODELIST_READ, ROLES.CODELIST_WRITE]}
                        forbidden={[ROLES.WATER_LAW_RECORDS_EXTERNAL_WRITE]}
                    >
                        <Outlet />
                    </Protect>
                ),
                children: [
                    {
                        index: true,
                        element: <CodelistsPage />
                    },
                    {
                        path: ':tabName/new',
                        element: (
                            <Protect roles={[ROLES.ADMINISTRATOR, ROLES.CODELIST_WRITE]}>
                                <CodelistPage />
                            </Protect>
                        )
                    },
                    {
                        path: ':tabName/:codelistCode',
                        element: <CodelistPage />
                    },
                    {
                        path: ':codelistCode/codeItem',
                        element: <CodeItemsPage />
                    },
                    {
                        path: ':codelistCode/codeItem/:itemtabName/new',
                        element: (
                            <Protect roles={[ROLES.ADMINISTRATOR, ROLES.CODELIST_WRITE]}>
                                <CodeItemPage />
                            </Protect>
                        )
                    },
                    {
                        path: ':codelistCode/codeItem/:itemtabName/:itemCode/:itemDate?',
                        element: <CodeItemPage />
                    }
                ]
            },
            {
                path: 'hydroMorphProtocols',
                element: (
                    <Protect
                        roles={[ROLES.ADMINISTRATOR, ROLES.HYDRO_MORPHOLOGY_READ, ROLES.HYDRO_MORPHOLOGY_WRITE]}
                        forbidden={[ROLES.WATER_LAW_RECORDS_EXTERNAL_WRITE]}
                    >
                        <Outlet />
                    </Protect>
                ),
                children: [
                    {
                        index: true,
                        element: <HydroMorphProtocolsPage />
                    },
                    {
                        path: 'new/:tabName?',
                        element: (
                            <Protect roles={[ROLES.ADMINISTRATOR, ROLES.HYDRO_MORPHOLOGY_WRITE]}>
                                <HydroMorphProtocolCreatePage />
                            </Protect>
                        )
                    },
                    {
                        path: 'edit/:protocolCode/:tabName?',
                        element: (
                            <Protect roles={[ROLES.ADMINISTRATOR, ROLES.HYDRO_MORPHOLOGY_WRITE]}>
                                <HydroMorphProtocolEditPage />
                            </Protect>
                        )
                    },
                    {
                        path: ':protocolCode/:tabName?',
                        element: <HydroMorphProtocolPage />
                    }
                ]
            },
            {
                path: 'assessments',
                element: (
                    <Protect
                        roles={[ROLES.ADMINISTRATOR, ROLES.ASSESSMENTS_READ, ROLES.ASSESSMENTS_WRITE]}
                        forbidden={[ROLES.WATER_LAW_RECORDS_EXTERNAL_WRITE]}
                    >
                        <Outlet />
                    </Protect>
                ),
                children: [
                    {
                        index: true,
                        element: <AssessmentsPage />
                    },
                    {
                        path: 'new/:tabName?',
                        element: (
                            <Protect roles={[ROLES.ADMINISTRATOR, ROLES.ASSESSMENTS_WRITE]}>
                                <AssessmentCreatePage />
                            </Protect>
                        )
                    },
                    {
                        path: ':assessmentId/detail/:tabName?',
                        element: <AssessmentDetailPage />
                    },
                    {
                        path: ':assessmentId/edit/:tabName?',
                        element: (
                            <Protect roles={[ROLES.ADMINISTRATOR, ROLES.ASSESSMENTS_WRITE]}>
                                <AssessmentEditPage />
                            </Protect>
                        )
                    }
                ]
            },
            {
                path: 'mandatoryReports',
                element: (
                    <Protect
                        roles={[
                            ROLES.ADMINISTRATOR,
                            ROLES.MANDATORY_REPORTS_PDZV_READ,
                            ROLES.MANDATORY_REPORTS_PDZV_WRITE,
                            ROLES.MANDATORY_REPORTS_PV_READ,
                            ROLES.MANDATORY_REPORTS_PV_WRITE
                        ]}
                        forbidden={[ROLES.WATER_LAW_RECORDS_EXTERNAL_WRITE]}
                    >
                        <Outlet />
                    </Protect>
                ),
                children: [
                    {
                        index: true,
                        element: <MandatoryReportsPage />
                    },
                    {
                        path: ':mandatoryReportId/pairing/:tabName?',
                        element: (
                            <Protect roles={[ROLES.ADMINISTRATOR, ROLES.MANDATORY_REPORTS_PDZV_WRITE, ROLES.MANDATORY_REPORTS_PV_WRITE]}>
                                <MandatoryReportPairingPage />
                            </Protect>
                        )
                    },
                    {
                        path: 'notifications/new',
                        element: (
                            <Protect roles={[ROLES.ADMINISTRATOR, ROLES.MANDATORY_REPORTS_PDZV_WRITE, ROLES.MANDATORY_REPORTS_PV_WRITE]}>
                                <MandatoryReportNotificationCreatePage />
                            </Protect>
                        )
                    }
                ]
            },
            {
                path: 'waterLawRecords',
                element: (
                    <Protect
                        roles={[
                            ROLES.ADMINISTRATOR,
                            ROLES.WATER_LAW_RECORDS_READ,
                            ROLES.WATER_LAW_RECORDS_WRITE,
                            ROLES.WATER_LAW_RECORDS_EXTERNAL_WRITE
                        ]}
                    >
                        <Outlet />
                    </Protect>
                ),
                children: [
                    {
                        index: true,
                        element: <WaterLawRecordsPage />
                    },
                    {
                        path: 'new',
                        element: (
                            <Protect roles={[ROLES.ADMINISTRATOR, ROLES.WATER_LAW_RECORDS_WRITE, ROLES.WATER_LAW_RECORDS_EXTERNAL_WRITE]}>
                                <WaterLawRecordCreatePage />
                            </Protect>
                        )
                    },
                    {
                        path: ':waterLawRecordId/detail/:tabName?',
                        element: <WaterLawRecordDetailPage />
                    },
                    {
                        path: ':waterLawRecordId/edit',
                        element: (
                            <Protect roles={[ROLES.ADMINISTRATOR, ROLES.WATER_LAW_RECORDS_WRITE, ROLES.WATER_LAW_RECORDS_EXTERNAL_WRITE]}>
                                <WaterLawRecordEditPage />
                            </Protect>
                        )
                    },
                    {
                        path: ':waterLawRecordId/change/:tabName?',
                        element: (
                            <Protect roles={[ROLES.ADMINISTRATOR, ROLES.WATER_LAW_RECORDS_WRITE, ROLES.WATER_LAW_RECORDS_EXTERNAL_WRITE]}>
                                <WaterLawRecordChangePage />
                            </Protect>
                        )
                    },
                    {
                        path: ':waterLawRecordId/attachments/waterHandling/:attachmentTypeId/new/:tabName?',
                        element: (
                            <Protect roles={[ROLES.ADMINISTRATOR, ROLES.WATER_LAW_RECORDS_WRITE, ROLES.WATER_LAW_RECORDS_EXTERNAL_WRITE]}>
                                <WaterHandlingAttachmentCreatePage />
                            </Protect>
                        )
                    },
                    {
                        path: ':waterLawRecordId/attachments/waterHandling/:attachmentId/detail/:tabName?',
                        element: <WaterHandlingAttachmentDetailPage />
                    },
                    {
                        path: ':waterLawRecordId/attachments/waterHandling/:attachmentId/edit/:tabName?',
                        element: (
                            <Protect roles={[ROLES.ADMINISTRATOR, ROLES.WATER_LAW_RECORDS_WRITE, ROLES.WATER_LAW_RECORDS_EXTERNAL_WRITE]}>
                                <WaterHandlingAttachmentEditPage />
                            </Protect>
                        )
                    },
                    {
                        path: ':waterLawRecordId/attachments/waterStructure/:attachmentTypeId/new/:tabName?',
                        element: (
                            <Protect roles={[ROLES.ADMINISTRATOR, ROLES.WATER_LAW_RECORDS_WRITE, ROLES.WATER_LAW_RECORDS_EXTERNAL_WRITE]}>
                                <WaterStructureAttachmentCreatePage />
                            </Protect>
                        )
                    },
                    {
                        path: ':waterLawRecordId/attachments/waterStructure/:attachmentId/detail/:tabName?',
                        element: <WaterStructureAttachmentDetailPage />
                    },
                    {
                        path: ':waterLawRecordId/attachments/waterStructure/:attachmentId/edit/:tabName?',
                        element: (
                            <Protect roles={[ROLES.ADMINISTRATOR, ROLES.WATER_LAW_RECORDS_WRITE, ROLES.WATER_LAW_RECORDS_EXTERNAL_WRITE]}>
                                <WaterStructureAttachmentEditPage />
                            </Protect>
                        )
                    },
                    {
                        path: ':waterLawRecordId/attachments/other/:attachmentTypeId/new/:tabName?',
                        element: (
                            <Protect roles={[ROLES.ADMINISTRATOR, ROLES.WATER_LAW_RECORDS_WRITE, ROLES.WATER_LAW_RECORDS_EXTERNAL_WRITE]}>
                                <OtherAttachmentCreatePage />
                            </Protect>
                        )
                    },
                    {
                        path: ':waterLawRecordId/attachments/other/:attachmentId/detail/:tabName?',
                        element: <OtherAttachmentDetailPage />
                    },
                    {
                        path: ':waterLawRecordId/attachments/other/:attachmentId/edit/:tabName?',
                        element: (
                            <Protect roles={[ROLES.ADMINISTRATOR, ROLES.WATER_LAW_RECORDS_WRITE, ROLES.WATER_LAW_RECORDS_EXTERNAL_WRITE]}>
                                <OtherAttachmentEditPage />
                            </Protect>
                        )
                    },
                    {
                        path: ':waterLawRecordId/attachments/waterStructure/:attachmentId/attachments/waterStructure/:subAttachmentTypeId/:waterStructureCode/new/:tabName?',
                        element: (
                            <Protect roles={[ROLES.ADMINISTRATOR, ROLES.WATER_LAW_RECORDS_WRITE, ROLES.WATER_LAW_RECORDS_EXTERNAL_WRITE]}>
                                <WaterStructureSubAttachmentCreatePage />
                            </Protect>
                        )
                    },
                    {
                        path: ':waterLawRecordId/attachments/waterStructure/:attachmentId/attachments/waterStructure/:subAttachmentId/detail/:tabName?',
                        element: <WaterStructureSubAttachmentDetailPage />
                    },
                    {
                        path: ':waterLawRecordId/attachments/waterStructure/:attachmentId/attachments/waterStructure/:subAttachmentId/edit/:tabName?',
                        element: (
                            <Protect roles={[ROLES.ADMINISTRATOR, ROLES.WATER_LAW_RECORDS_WRITE, ROLES.WATER_LAW_RECORDS_EXTERNAL_WRITE]}>
                                <WaterStructureSubAttachmentEditPage />
                            </Protect>
                        )
                    }
                ]
            },
            {
                path: 'overview/data',
                element: (
                    <Protect
                        roles={[ROLES.ADMINISTRATOR]}
                        appRole={ROLE_APPLICATION.OVERVIEW}
                        forbidden={[ROLES.WATER_LAW_RECORDS_EXTERNAL_WRITE]}
                    >
                        <Outlet />
                    </Protect>
                ),
                children: [
                    {
                        index: true,
                        element: <OverviewInProcess />
                    },
                    {
                        path: ':previewReference/:filterId',
                        element: (
                            <ComponentWithKey keyName={['previewReference', 'filterId']}>
                                <OverviewEditorPage />
                            </ComponentWithKey>
                        )
                    },
                    {
                        path: ':previewReference/:filterId/report',
                        element: (
                            <ComponentWithKey keyName={['previewReference', 'filterId']}>
                                <OverviewReportPage />
                            </ComponentWithKey>
                        )
                    },
                    {
                        path: ':previewReference/:filterId/:subpreviewReference/:subfilterId',
                        element: (
                            <ComponentWithKey keyName={['previewReference', 'filterId', 'subpreviewReference', 'subfilterId']}>
                                <OverviewEditorPage />
                            </ComponentWithKey>
                        )
                    },
                    {
                        path: ':previewReference/:filterId/:subpreviewReference/:subfilterId/report',
                        element: (
                            <ComponentWithKey keyName={['previewReference', 'filterId', 'subpreviewReference', 'subfilterId']}>
                                <OverviewReportPage />
                            </ComponentWithKey>
                        )
                    }
                ]
            },
            {
                path: 'overview/metadata',
                element: (
                    <Protect
                        roles={[ROLES.ADMINISTRATOR]}
                        forbidden={[ROLES.WATER_LAW_RECORDS_EXTERNAL_WRITE]}
                    >
                        <MetadataPage />
                    </Protect>
                )
            },
            {
                path: 'overview/:domain',
                element: (
                    <ProtectDomain
                        roles={[ROLES.ADMINISTRATOR]}
                        appRole={ROLE_APPLICATION.OVERVIEW}
                        application={ROLE_APPLICATION.OVERVIEW}
                        forbidden={[ROLES.WATER_LAW_RECORDS_EXTERNAL_WRITE]}
                    >
                        <Outlet />
                    </ProtectDomain>
                ),
                children: [
                    {
                        index: true,
                        element: (
                            <ComponentWithKey keyName='domain'>
                                <OverviewFiltersPage />
                            </ComponentWithKey>
                        )
                    },
                    {
                        path: ':previewReference/:filterId',
                        element: <OverviewFilterPage />
                    }
                ]
            },
            {
                path: 'import',
                element: (
                    <Protect
                        roles={[ROLES.ADMINISTRATOR, ROLES.CODELIST_WRITE, ...editingMonPointRoles]}
                        forbidden={[ROLES.WATER_LAW_RECORDS_EXTERNAL_WRITE]}
                        appRole={ROLE_APPLICATION.IMPORT}
                    >
                        <Outlet />
                    </Protect>
                ),
                children: [
                    {
                        path: 'codelist',
                        element: (
                            <Protect roles={[ROLES.ADMINISTRATOR, ROLES.CODELIST_WRITE]}>
                                <Outlet />
                            </Protect>
                        ),
                        children: [
                            {
                                index: true,
                                element: <CodelistImportPage />
                            },
                            {
                                path: 'documents',
                                element: <CodelistImportsPage />
                            },
                            {
                                path: 'documents/:documentId',
                                element: <CodelistEditorPage />
                            }
                        ]
                    },
                    {
                        path: 'monpoint',
                        element: (
                            <Protect roles={[...editingMonPointRoles]}>
                                <Outlet />
                            </Protect>
                        ),
                        children: [
                            {
                                index: true,
                                element: <MonPointImportPage />
                            },
                            {
                                path: 'documents',
                                element: <MonPointImportsPage />
                            },
                            {
                                path: 'documents/:documentId/:tabName',
                                element: <MonPointEditorPage />
                            }
                        ]
                    },
                    {
                        path: 'methodic',
                        element: (
                            <Protect roles={[ROLES.ADMINISTRATOR, ROLES.CODELIST_WRITE]}>
                                <Outlet />
                            </Protect>
                        ),
                        children: [
                            {
                                index: true,
                                element: <MethodicImportPage />
                            },
                            {
                                path: 'documents',
                                element: <MethodicImportsPage />
                            },
                            {
                                path: 'documents/:documentId',
                                element: <MethodicEditorPage />
                            }
                        ]
                    },
                    {
                        path: 'data',
                        element: (
                            <Protect
                                roles={[ROLES.ADMINISTRATOR]}
                                appRole={ROLE_APPLICATION.IMPORT}
                            >
                                <Outlet />
                            </Protect>
                        ),
                        children: [
                            {
                                path: ':domain/:importFileDefinitionId?',
                                element: (
                                    <ProtectDomain
                                        roles={[ROLES.ADMINISTRATOR]}
                                        appRole={ROLE_APPLICATION.IMPORT}
                                        application={ROLE_APPLICATION.IMPORT}
                                    >
                                        <DataImportPage />
                                    </ProtectDomain>
                                )
                            },
                            {
                                path: 'documents',
                                element: <DataImportsPage />
                            },
                            {
                                path: 'documents/:documentId',
                                element: <DataEditorPage />
                            },
                            {
                                path: 'documents/:documentId/report/:tabName',
                                element: <DataReportPage />
                            },
                            {
                                path: 'documents/:documentId/summary',
                                element: <DataSummaryPage />
                            }
                        ]
                    },
                    {
                        path: 'metadata',
                        element: (
                            <Protect roles={[ROLES.ADMINISTRATOR]}>
                                <Outlet />
                            </Protect>
                        ),
                        children: [
                            {
                                index: true,
                                element: <FileDefinitionsPage />
                            },
                            {
                                path: ':fileDefinitionId/:tabName',
                                element: <Outlet />,
                                children: [
                                    { index: true, element: <FileDefinitionPage /> },
                                    {
                                        path: ':filePartDefinitionId/:partTabName',
                                        element: <Outlet />,
                                        children: [
                                            { index: true, element: <FilePartDefinitionPage /> },
                                            {
                                                path: ':rowTypeDefinitionId',
                                                element: <Outlet />,
                                                children: [
                                                    { index: true, element: <RowTypeDefinitionPage /> },
                                                    {
                                                        path: 'columnDef/:columnDefinitionId',
                                                        element: <Outlet />,
                                                        children: [
                                                            {
                                                                index: true,
                                                                element: <ColumnDefinitionPage />
                                                            },
                                                            {
                                                                path: 'rowAttribute/:rowAttributeDefinitionId',
                                                                element: <Outlet />,
                                                                children: [
                                                                    {
                                                                        index: true,
                                                                        element: <RowAttributeDefinitionPage />
                                                                    },
                                                                    {
                                                                        path: 'rowAttTransformation/:rowAttributeTransformationDefinitionId',
                                                                        element: <RowAttributeTransformationDefinitionPage />
                                                                    }
                                                                ]
                                                            }
                                                        ]
                                                    }
                                                ]
                                            }
                                        ]
                                    },
                                    { path: ':dataRegistryProductDefinitionId', element: <DataRegistryProductDefinitionPage /> }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                path: 'monPoint/:mpTabName',
                element: (
                    <Protect
                        roles={allMonPointRoles}
                        forbidden={[ROLES.WATER_LAW_RECORDS_EXTERNAL_WRITE]}
                    >
                        <Outlet />
                    </Protect>
                ),
                children: [
                    {
                        index: true,
                        element: <MonPointsPage />
                    },
                    {
                        path: ':monPointType/:tabName',
                        element: (
                            <ProtectMonPoint>
                                <Outlet />
                            </ProtectMonPoint>
                        ),
                        children: [
                            {
                                path: 'new',
                                element: (
                                    <ProtectMonPoint writeRoleRequired={true}>
                                        <MonPointPage />
                                    </ProtectMonPoint>
                                )
                            },
                            {
                                path: 'new/source/:sourceId',
                                element: (
                                    <ProtectMonPoint writeRoleRequired={true}>
                                        <MonPointPage />
                                    </ProtectMonPoint>
                                )
                            },
                            {
                                path: ':monPointId',
                                element: <MonPointPage />
                            }
                        ]
                    }
                ]
            },
            {
                path: 'environment/:envTabName',
                element: (
                    <Protect
                        roles={[ROLES.ADMINISTRATOR, ROLES.ENVIRONMENT_READ, ROLES.ENVIRONMENT_WRITE]}
                        forbidden={[ROLES.WATER_LAW_RECORDS_EXTERNAL_WRITE]}
                    >
                        <Outlet />
                    </Protect>
                ),
                children: [
                    {
                        index: true,
                        element: <EnvironmentsPage />
                    },
                    {
                        path: 'new',
                        element: (
                            <Protect roles={[ROLES.ADMINISTRATOR, ROLES.ENVIRONMENT_WRITE]}>
                                <EnvironmentPage />
                            </Protect>
                        )
                    },
                    {
                        path: ':environmentId',
                        element: <EnvironmentPage />
                    }
                ]
            },
            {
                path: 'waterShape',
                element: (
                    <Protect
                        roles={[ROLES.ADMINISTRATOR, ROLES.WATER_SHAPE_READ, ROLES.WATER_SHAPE_WRITE]}
                        forbidden={[ROLES.WATER_LAW_RECORDS_EXTERNAL_WRITE]}
                    >
                        <Outlet />
                    </Protect>
                ),
                children: [
                    {
                        index: true,
                        element: <WaterShapesPage />
                    },
                    {
                        path: 'new',
                        element: (
                            <Protect roles={[ROLES.ADMINISTRATOR, ROLES.WATER_SHAPE_WRITE]}>
                                <WaterShapePage />
                            </Protect>
                        )
                    },
                    {
                        path: ':waterShapeId',
                        element: <WaterShapePage />
                    }
                ]
            },
            {
                path: 'operator',
                element: (
                    <Protect roles={[ROLES.ADMINISTRATOR, ROLES.OPERATOR_AND_INSTALLATION_READ, ROLES.OPERATOR_AND_INSTALLATION_WRITE]}>
                        <Outlet />
                    </Protect>
                ),
                children: [
                    {
                        index: true,
                        element: <OperatorsPage />
                    },
                    {
                        path: ':tabName/new',
                        element: (
                            <Protect roles={[ROLES.ADMINISTRATOR, ROLES.OPERATOR_AND_INSTALLATION_WRITE]}>
                                <OperatorPage direction='operator' />
                            </Protect>
                        )
                    },
                    {
                        path: ':tabName/:operatorId',
                        element: <OperatorPage direction='operator' />
                    },
                    {
                        path: ':operatorId/installation',
                        element: <InstallationsPage direction='operator' />
                    },
                    {
                        path: ':operatorId/installation/:tabName/new',
                        element: (
                            <Protect roles={[ROLES.ADMINISTRATOR, ROLES.OPERATOR_AND_INSTALLATION_WRITE]}>
                                <InstallationPage direction='operator' />
                            </Protect>
                        )
                    },
                    {
                        path: ':operatorId/installation/:tabName/:installationId',
                        element: <InstallationPage direction='operator' />
                    },
                    {
                        path: ':operatorId/evidence',
                        element: <EvidencePage conditionKey='oper_id' />
                    },
                    {
                        path: ':operatorId/installation/:installationId/evidence',
                        element: <EvidencePage conditionKey='install_id' />
                    },
                    {
                        path: ':operatorId/installation/:installationId/monPoints',
                        element: <InstallationMonPointsPage />
                    },
                    {
                        path: ':operatorId/installation/:installationId/monPoints/:monPointId/evidence',
                        element: <EvidencePage conditionKey='mp_id' />
                    }
                ]
            },
            {
                path: 'installation',
                element: (
                    <Protect roles={[ROLES.ADMINISTRATOR, ROLES.OPERATOR_AND_INSTALLATION_READ, ROLES.OPERATOR_AND_INSTALLATION_WRITE]}>
                        <Outlet />
                    </Protect>
                ),
                children: [
                    {
                        index: true,
                        element: <InstallationsPage direction='installation' />
                    },
                    {
                        path: ':tabName/:installationId',
                        element: <InstallationPage direction='installation' />
                    },
                    {
                        path: ':installationId/operator/:tabName/:operatorId',
                        element: <OperatorPage direction='installation' />
                    },
                    {
                        path: ':installationId/evidence',
                        element: <EvidencePage conditionKey='install_id' />
                    },
                    {
                        path: ':installationId/monPoints',
                        element: <InstallationMonPointsPage />
                    },
                    {
                        path: ':installationId/monPoints/:monPointId/evidence',
                        element: <EvidencePage conditionKey='mp_id' />
                    }
                ]
            },
            {
                path: 'threshold',
                element: (
                    <Protect
                        roles={[ROLES.ADMINISTRATOR, ROLES.WATER_SHAPE_READ, ROLES.WATER_SHAPE_WRITE]}
                        forbidden={[ROLES.WATER_LAW_RECORDS_EXTERNAL_WRITE]}
                    >
                        <Outlet />
                    </Protect>
                ),
                children: [
                    {
                        index: true,
                        element: <ThresholdsPage />
                    },
                    {
                        path: 'new',
                        element: (
                            <Protect roles={[ROLES.ADMINISTRATOR, ROLES.WATER_SHAPE_WRITE]}>
                                <ThresholdPage />
                            </Protect>
                        )
                    },
                    {
                        path: ':thresholdId',
                        element: <ThresholdPage />
                    }
                ]
            },
            {
                path: 'methodic',
                element: (
                    <Protect
                        roles={[ROLES.ADMINISTRATOR, ROLES.CODELIST_READ, ROLES.CODELIST_WRITE]}
                        forbidden={[ROLES.WATER_LAW_RECORDS_EXTERNAL_WRITE]}
                    >
                        <Outlet />
                    </Protect>
                ),
                children: [
                    {
                        index: true,
                        element: <MethodicsPage />
                    },
                    {
                        path: 'new',
                        element: (
                            <Protect roles={[ROLES.ADMINISTRATOR, ROLES.CODELIST_WRITE]}>
                                <MethodicPage />
                            </Protect>
                        )
                    },
                    {
                        path: ':methodicId',
                        element: <MethodicPage />
                    }
                ]
            },
            {
                path: '/userGroup',
                element: <Outlet />,
                children: [
                    {
                        path: 'codelist',
                        element: (
                            <Protect
                                roles={[ROLES.ADMINISTRATOR, ROLES.CODELIST_READ, ROLES.CODELIST_WRITE]}
                                forbidden={[ROLES.WATER_LAW_RECORDS_EXTERNAL_WRITE]}
                            >
                                <Outlet />
                            </Protect>
                        ),
                        children: [
                            {
                                index: true,
                                element: <CodelistUserGroupsPage />
                            },
                            {
                                path: 'new',
                                element: <CodelistUserGroupPage />
                            },
                            {
                                path: ':userGroupId',
                                element: <CodelistUserGroupPage />
                            }
                        ]
                    },
                    {
                        path: 'catalog',
                        element: (
                            <Protect
                                roles={allMonPointRoles}
                                forbidden={[ROLES.WATER_LAW_RECORDS_EXTERNAL_WRITE]}
                            >
                                <Outlet />
                            </Protect>
                        ),
                        children: [
                            {
                                index: true,
                                element: <CatalogUserGroupsPage />
                            },
                            {
                                path: 'new',
                                element: <CatalogUserGroupPage />
                            },
                            {
                                path: ':userGroupId',
                                element: <CatalogUserGroupPage />
                            }
                        ]
                    }
                ]
            },
            {
                path: 'settings/:tabName',
                element: <SettingsPage />
            },
            {
                path: 'notifications',
                element: <NotificationsPage />
            },
            {
                path: 'users',
                element: (
                    <Protect forbidden={[ROLES.WATER_LAW_RECORDS_EXTERNAL_WRITE]}>
                        <Outlet />
                    </Protect>
                ),
                children: [
                    {
                        index: true,
                        element: <UsersPage />
                    },
                    {
                        path: 'detail/:id',
                        element: <UsersPageDetail />
                    }
                ]
            },
            {
                // Fallback
                path: '*',
                element: (
                    <Navigate
                        replace
                        to='/dashboard'
                    />
                )
            }
        ]
    }
]);

function App() {
    const { isLoading, activeNavigator, isAuthenticated, signinRedirect } = useAuth();

    useEffect(() => {
        if (!isLoading && !isAuthenticated && !activeNavigator) {
            signinRedirect();
        }
    }, [isLoading, isAuthenticated, activeNavigator, signinRedirect]);

    return (
        <>
            {isLoading && (
                <LoadingOverlay
                    loading={true}
                    fullscreen={true}
                />
            )}
            {isAuthenticated && (
                <QueryClientProvider client={queryClient}>
                    <PreferencesProvider>
                        <RouterProvider router={router} />
                        <ToastContainer
                            position='bottom-left'
                            hideProgressBar
                            newestOnTop
                            draggable={false}
                            theme='colored'
                            transition={Slide}
                        />
                    </PreferencesProvider>
                    <ReactQueryDevtools
                        initialIsOpen={false}
                        buttonPosition='bottom-left'
                    />
                </QueryClientProvider>
            )}
        </>
    );
}

export default App;
