/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WaterEvidenceOtherAppendixRequestRecord
 */
export interface WaterEvidenceOtherAppendixRequestRecord {
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceOtherAppendixRequestRecord
     */
    id?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceOtherAppendixRequestRecord
     */
    docTypeCode?: number | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceOtherAppendixRequestRecord
     */
    dateFrom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceOtherAppendixRequestRecord
     */
    dateTo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceOtherAppendixRequestRecord
     */
    note?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceOtherAppendixRequestRecord
     */
    mainName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceOtherAppendixRequestRecord
     */
    mainCity?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceOtherAppendixRequestRecord
     */
    mainDescription?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceOtherAppendixRequestRecord
     */
    legislative?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceOtherAppendixRequestRecord
     */
    measureWording?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceOtherAppendixRequestRecord
     */
    implementConditions?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceOtherAppendixRequestRecord
     */
    otherConditions?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceOtherAppendixRequestRecord
     */
    delictWording?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceOtherAppendixRequestRecord
     */
    fineAmount?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceOtherAppendixRequestRecord
     */
    fineMaturity?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceOtherAppendixRequestRecord
     */
    finePayDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceOtherAppendixRequestRecord
     */
    facility?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceOtherAppendixRequestRecord
     */
    activity?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceOtherAppendixRequestRecord
     */
    affection?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceOtherAppendixRequestRecord
     */
    permDesc?: string | null;
}

/**
 * Check if a given object implements the WaterEvidenceOtherAppendixRequestRecord interface.
 */
export function instanceOfWaterEvidenceOtherAppendixRequestRecord(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WaterEvidenceOtherAppendixRequestRecordFromJSON(json: any): WaterEvidenceOtherAppendixRequestRecord {
    return WaterEvidenceOtherAppendixRequestRecordFromJSONTyped(json, false);
}

export function WaterEvidenceOtherAppendixRequestRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): WaterEvidenceOtherAppendixRequestRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'docTypeCode': !exists(json, 'docTypeCode') ? undefined : json['docTypeCode'],
        'dateFrom': !exists(json, 'dateFrom') ? undefined : json['dateFrom'],
        'dateTo': !exists(json, 'dateTo') ? undefined : json['dateTo'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'mainName': !exists(json, 'mainName') ? undefined : json['mainName'],
        'mainCity': !exists(json, 'mainCity') ? undefined : json['mainCity'],
        'mainDescription': !exists(json, 'mainDescription') ? undefined : json['mainDescription'],
        'legislative': !exists(json, 'legislative') ? undefined : json['legislative'],
        'measureWording': !exists(json, 'measureWording') ? undefined : json['measureWording'],
        'implementConditions': !exists(json, 'implementConditions') ? undefined : json['implementConditions'],
        'otherConditions': !exists(json, 'otherConditions') ? undefined : json['otherConditions'],
        'delictWording': !exists(json, 'delictWording') ? undefined : json['delictWording'],
        'fineAmount': !exists(json, 'fineAmount') ? undefined : json['fineAmount'],
        'fineMaturity': !exists(json, 'fineMaturity') ? undefined : json['fineMaturity'],
        'finePayDate': !exists(json, 'finePayDate') ? undefined : json['finePayDate'],
        'facility': !exists(json, 'facility') ? undefined : json['facility'],
        'activity': !exists(json, 'activity') ? undefined : json['activity'],
        'affection': !exists(json, 'affection') ? undefined : json['affection'],
        'permDesc': !exists(json, 'permDesc') ? undefined : json['permDesc'],
    };
}

export function WaterEvidenceOtherAppendixRequestRecordToJSON(value?: WaterEvidenceOtherAppendixRequestRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'docTypeCode': value.docTypeCode,
        'dateFrom': value.dateFrom,
        'dateTo': value.dateTo,
        'note': value.note,
        'mainName': value.mainName,
        'mainCity': value.mainCity,
        'mainDescription': value.mainDescription,
        'legislative': value.legislative,
        'measureWording': value.measureWording,
        'implementConditions': value.implementConditions,
        'otherConditions': value.otherConditions,
        'delictWording': value.delictWording,
        'fineAmount': value.fineAmount,
        'fineMaturity': value.fineMaturity,
        'finePayDate': value.finePayDate,
        'facility': value.facility,
        'activity': value.activity,
        'affection': value.affection,
        'permDesc': value.permDesc,
    };
}

