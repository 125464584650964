/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AttachmentTypeResponse
 */
export interface AttachmentTypeResponse {
    /**
     * 
     * @type {string}
     * @memberof AttachmentTypeResponse
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof AttachmentTypeResponse
     */
    template?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof AttachmentTypeResponse
     */
    titles?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof AttachmentTypeResponse
     */
    fields?: { [key: string]: any; };
}

/**
 * Check if a given object implements the AttachmentTypeResponse interface.
 */
export function instanceOfAttachmentTypeResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AttachmentTypeResponseFromJSON(json: any): AttachmentTypeResponse {
    return AttachmentTypeResponseFromJSONTyped(json, false);
}

export function AttachmentTypeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AttachmentTypeResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'template': !exists(json, 'template') ? undefined : json['template'],
        'titles': !exists(json, 'titles') ? undefined : json['titles'],
        'fields': !exists(json, 'fields') ? undefined : json['fields'],
    };
}

export function AttachmentTypeResponseToJSON(value?: AttachmentTypeResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'template': value.template,
        'titles': value.titles,
        'fields': value.fields,
    };
}

