/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AddressRecord } from './AddressRecord';
import {
    AddressRecordFromJSON,
    AddressRecordFromJSONTyped,
    AddressRecordToJSON,
} from './AddressRecord';
import type { ContactPersonRecord } from './ContactPersonRecord';
import {
    ContactPersonRecordFromJSON,
    ContactPersonRecordFromJSONTyped,
    ContactPersonRecordToJSON,
} from './ContactPersonRecord';
import type { GeneralTypeRecord } from './GeneralTypeRecord';
import {
    GeneralTypeRecordFromJSON,
    GeneralTypeRecordFromJSONTyped,
    GeneralTypeRecordToJSON,
} from './GeneralTypeRecord';
import type { TechnicalAttributeRecord } from './TechnicalAttributeRecord';
import {
    TechnicalAttributeRecordFromJSON,
    TechnicalAttributeRecordFromJSONTyped,
    TechnicalAttributeRecordToJSON,
} from './TechnicalAttributeRecord';

/**
 * 
 * @export
 * @interface OperatorRecord
 */
export interface OperatorRecord {
    /**
     * 
     * @type {string}
     * @memberof OperatorRecord
     */
    technicalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof OperatorRecord
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof OperatorRecord
     */
    operatorType?: string;
    /**
     * 
     * @type {number}
     * @memberof OperatorRecord
     */
    ippc?: number;
    /**
     * 
     * @type {string}
     * @memberof OperatorRecord
     */
    ico?: string;
    /**
     * 
     * @type {string}
     * @memberof OperatorRecord
     */
    dic?: string;
    /**
     * 
     * @type {GeneralTypeRecord}
     * @memberof OperatorRecord
     */
    naceType?: GeneralTypeRecord;
    /**
     * 
     * @type {GeneralTypeRecord}
     * @memberof OperatorRecord
     */
    nace2Type?: GeneralTypeRecord;
    /**
     * 
     * @type {AddressRecord}
     * @memberof OperatorRecord
     */
    address?: AddressRecord;
    /**
     * 
     * @type {any}
     * @memberof OperatorRecord
     */
    notificationDuty?: any;
    /**
     * 
     * @type {any}
     * @memberof OperatorRecord
     */
    evaluated?: any;
    /**
     * 
     * @type {string}
     * @memberof OperatorRecord
     */
    note?: string;
    /**
     * 
     * @type {string}
     * @memberof OperatorRecord
     */
    effectiveFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof OperatorRecord
     */
    effectiveTo?: string;
    /**
     * 
     * @type {number}
     * @memberof OperatorRecord
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof OperatorRecord
     */
    code?: string;
    /**
     * 
     * @type {Array<ContactPersonRecord>}
     * @memberof OperatorRecord
     */
    contactPerson?: Array<ContactPersonRecord>;
    /**
     * 
     * @type {TechnicalAttributeRecord}
     * @memberof OperatorRecord
     */
    technicalAttribute?: TechnicalAttributeRecord;
    /**
     * 
     * @type {string}
     * @memberof OperatorRecord
     */
    status?: string;
    /**
     * 
     * @type {number}
     * @memberof OperatorRecord
     */
    installationCount?: number;
    /**
     * 
     * @type {string}
     * @memberof OperatorRecord
     */
    createdTimestamp?: string;
    /**
     * 
     * @type {string}
     * @memberof OperatorRecord
     */
    updatedTimestamp?: string;
    /**
     * 
     * @type {number}
     * @memberof OperatorRecord
     */
    shmuId?: number;
}

/**
 * Check if a given object implements the OperatorRecord interface.
 */
export function instanceOfOperatorRecord(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function OperatorRecordFromJSON(json: any): OperatorRecord {
    return OperatorRecordFromJSONTyped(json, false);
}

export function OperatorRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): OperatorRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'technicalCode': !exists(json, 'technicalCode') ? undefined : json['technicalCode'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'operatorType': !exists(json, 'operatorType') ? undefined : json['operatorType'],
        'ippc': !exists(json, 'ippc') ? undefined : json['ippc'],
        'ico': !exists(json, 'ico') ? undefined : json['ico'],
        'dic': !exists(json, 'dic') ? undefined : json['dic'],
        'naceType': !exists(json, 'naceType') ? undefined : GeneralTypeRecordFromJSON(json['naceType']),
        'nace2Type': !exists(json, 'nace2Type') ? undefined : GeneralTypeRecordFromJSON(json['nace2Type']),
        'address': !exists(json, 'address') ? undefined : AddressRecordFromJSON(json['address']),
        'notificationDuty': !exists(json, 'notificationDuty') ? undefined : json['notificationDuty'],
        'evaluated': !exists(json, 'evaluated') ? undefined : json['evaluated'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'effectiveFrom': !exists(json, 'effectiveFrom') ? undefined : json['effectiveFrom'],
        'effectiveTo': !exists(json, 'effectiveTo') ? undefined : json['effectiveTo'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'contactPerson': !exists(json, 'contactPerson') ? undefined : ((json['contactPerson'] as Array<any>).map(ContactPersonRecordFromJSON)),
        'technicalAttribute': !exists(json, 'technicalAttribute') ? undefined : TechnicalAttributeRecordFromJSON(json['technicalAttribute']),
        'status': !exists(json, 'status') ? undefined : json['status'],
        'installationCount': !exists(json, 'installationCount') ? undefined : json['installationCount'],
        'createdTimestamp': !exists(json, 'createdTimestamp') ? undefined : json['createdTimestamp'],
        'updatedTimestamp': !exists(json, 'updatedTimestamp') ? undefined : json['updatedTimestamp'],
        'shmuId': !exists(json, 'shmuId') ? undefined : json['shmuId'],
    };
}

export function OperatorRecordToJSON(value?: OperatorRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'technicalCode': value.technicalCode,
        'name': value.name,
        'operatorType': value.operatorType,
        'ippc': value.ippc,
        'ico': value.ico,
        'dic': value.dic,
        'naceType': GeneralTypeRecordToJSON(value.naceType),
        'nace2Type': GeneralTypeRecordToJSON(value.nace2Type),
        'address': AddressRecordToJSON(value.address),
        'notificationDuty': value.notificationDuty,
        'evaluated': value.evaluated,
        'note': value.note,
        'effectiveFrom': value.effectiveFrom,
        'effectiveTo': value.effectiveTo,
        'id': value.id,
        'code': value.code,
        'contactPerson': value.contactPerson === undefined ? undefined : ((value.contactPerson as Array<any>).map(ContactPersonRecordToJSON)),
        'technicalAttribute': TechnicalAttributeRecordToJSON(value.technicalAttribute),
        'status': value.status,
        'installationCount': value.installationCount,
        'createdTimestamp': value.createdTimestamp,
        'updatedTimestamp': value.updatedTimestamp,
        'shmuId': value.shmuId,
    };
}

