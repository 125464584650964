/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GeneralTypeRecord } from './GeneralTypeRecord';
import {
    GeneralTypeRecordFromJSON,
    GeneralTypeRecordFromJSONTyped,
    GeneralTypeRecordToJSON,
} from './GeneralTypeRecord';
import type { TechnicalAttributeRecord } from './TechnicalAttributeRecord';
import {
    TechnicalAttributeRecordFromJSON,
    TechnicalAttributeRecordFromJSONTyped,
    TechnicalAttributeRecordToJSON,
} from './TechnicalAttributeRecord';

/**
 * 
 * @export
 * @interface WaterShapeRecord
 */
export interface WaterShapeRecord {
    /**
     * 
     * @type {string}
     * @memberof WaterShapeRecord
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof WaterShapeRecord
     */
    riverKmClosest?: number;
    /**
     * 
     * @type {number}
     * @memberof WaterShapeRecord
     */
    riverKmFurthest?: number;
    /**
     * 
     * @type {GeneralTypeRecord}
     * @memberof WaterShapeRecord
     */
    basinType?: GeneralTypeRecord;
    /**
     * 
     * @type {GeneralTypeRecord}
     * @memberof WaterShapeRecord
     */
    wbCharacterType?: GeneralTypeRecord;
    /**
     * 
     * @type {GeneralTypeRecord}
     * @memberof WaterShapeRecord
     */
    wbGroupType?: GeneralTypeRecord;
    /**
     * 
     * @type {GeneralTypeRecord}
     * @memberof WaterShapeRecord
     */
    wbType?: GeneralTypeRecord;
    /**
     * 
     * @type {GeneralTypeRecord}
     * @memberof WaterShapeRecord
     */
    cycleType?: GeneralTypeRecord;
    /**
     * 
     * @type {number}
     * @memberof WaterShapeRecord
     */
    jtskXStart?: number;
    /**
     * 
     * @type {number}
     * @memberof WaterShapeRecord
     */
    jtskXEnd?: number;
    /**
     * 
     * @type {number}
     * @memberof WaterShapeRecord
     */
    jtskYStart?: number;
    /**
     * 
     * @type {number}
     * @memberof WaterShapeRecord
     */
    jtskYEnd?: number;
    /**
     * 
     * @type {string}
     * @memberof WaterShapeRecord
     */
    effectiveFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof WaterShapeRecord
     */
    effectiveTo?: string;
    /**
     * 
     * @type {number}
     * @memberof WaterShapeRecord
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof WaterShapeRecord
     */
    code?: string;
    /**
     * 
     * @type {boolean}
     * @memberof WaterShapeRecord
     */
    deleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof WaterShapeRecord
     */
    version?: string;
    /**
     * 
     * @type {number}
     * @memberof WaterShapeRecord
     */
    flowLength?: number;
    /**
     * 
     * @type {number}
     * @memberof WaterShapeRecord
     */
    statusType?: number;
    /**
     * 
     * @type {boolean}
     * @memberof WaterShapeRecord
     */
    historical?: boolean;
    /**
     * 
     * @type {TechnicalAttributeRecord}
     * @memberof WaterShapeRecord
     */
    technicalAttribute?: TechnicalAttributeRecord;
    /**
     * 
     * @type {string}
     * @memberof WaterShapeRecord
     */
    createdTimestamp?: string;
    /**
     * 
     * @type {string}
     * @memberof WaterShapeRecord
     */
    updatedTimestamp?: string;
}

/**
 * Check if a given object implements the WaterShapeRecord interface.
 */
export function instanceOfWaterShapeRecord(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WaterShapeRecordFromJSON(json: any): WaterShapeRecord {
    return WaterShapeRecordFromJSONTyped(json, false);
}

export function WaterShapeRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): WaterShapeRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'riverKmClosest': !exists(json, 'riverKmClosest') ? undefined : json['riverKmClosest'],
        'riverKmFurthest': !exists(json, 'riverKmFurthest') ? undefined : json['riverKmFurthest'],
        'basinType': !exists(json, 'basinType') ? undefined : GeneralTypeRecordFromJSON(json['basinType']),
        'wbCharacterType': !exists(json, 'wbCharacterType') ? undefined : GeneralTypeRecordFromJSON(json['wbCharacterType']),
        'wbGroupType': !exists(json, 'wbGroupType') ? undefined : GeneralTypeRecordFromJSON(json['wbGroupType']),
        'wbType': !exists(json, 'wbType') ? undefined : GeneralTypeRecordFromJSON(json['wbType']),
        'cycleType': !exists(json, 'cycleType') ? undefined : GeneralTypeRecordFromJSON(json['cycleType']),
        'jtskXStart': !exists(json, 'jtskXStart') ? undefined : json['jtskXStart'],
        'jtskXEnd': !exists(json, 'jtskXEnd') ? undefined : json['jtskXEnd'],
        'jtskYStart': !exists(json, 'jtskYStart') ? undefined : json['jtskYStart'],
        'jtskYEnd': !exists(json, 'jtskYEnd') ? undefined : json['jtskYEnd'],
        'effectiveFrom': !exists(json, 'effectiveFrom') ? undefined : json['effectiveFrom'],
        'effectiveTo': !exists(json, 'effectiveTo') ? undefined : json['effectiveTo'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'deleted': !exists(json, 'deleted') ? undefined : json['deleted'],
        'version': !exists(json, 'version') ? undefined : json['version'],
        'flowLength': !exists(json, 'flowLength') ? undefined : json['flowLength'],
        'statusType': !exists(json, 'statusType') ? undefined : json['statusType'],
        'historical': !exists(json, 'historical') ? undefined : json['historical'],
        'technicalAttribute': !exists(json, 'technicalAttribute') ? undefined : TechnicalAttributeRecordFromJSON(json['technicalAttribute']),
        'createdTimestamp': !exists(json, 'createdTimestamp') ? undefined : json['createdTimestamp'],
        'updatedTimestamp': !exists(json, 'updatedTimestamp') ? undefined : json['updatedTimestamp'],
    };
}

export function WaterShapeRecordToJSON(value?: WaterShapeRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'riverKmClosest': value.riverKmClosest,
        'riverKmFurthest': value.riverKmFurthest,
        'basinType': GeneralTypeRecordToJSON(value.basinType),
        'wbCharacterType': GeneralTypeRecordToJSON(value.wbCharacterType),
        'wbGroupType': GeneralTypeRecordToJSON(value.wbGroupType),
        'wbType': GeneralTypeRecordToJSON(value.wbType),
        'cycleType': GeneralTypeRecordToJSON(value.cycleType),
        'jtskXStart': value.jtskXStart,
        'jtskXEnd': value.jtskXEnd,
        'jtskYStart': value.jtskYStart,
        'jtskYEnd': value.jtskYEnd,
        'effectiveFrom': value.effectiveFrom,
        'effectiveTo': value.effectiveTo,
        'id': value.id,
        'code': value.code,
        'deleted': value.deleted,
        'version': value.version,
        'flowLength': value.flowLength,
        'statusType': value.statusType,
        'historical': value.historical,
        'technicalAttribute': TechnicalAttributeRecordToJSON(value.technicalAttribute),
        'createdTimestamp': value.createdTimestamp,
        'updatedTimestamp': value.updatedTimestamp,
    };
}

