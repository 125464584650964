/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GeneralTypeRecord } from './GeneralTypeRecord';
import {
    GeneralTypeRecordFromJSON,
    GeneralTypeRecordFromJSONTyped,
    GeneralTypeRecordToJSON,
} from './GeneralTypeRecord';
import type { LevelRecord } from './LevelRecord';
import {
    LevelRecordFromJSON,
    LevelRecordFromJSONTyped,
    LevelRecordToJSON,
} from './LevelRecord';
import type { TechnicalAttributeRecord } from './TechnicalAttributeRecord';
import {
    TechnicalAttributeRecordFromJSON,
    TechnicalAttributeRecordFromJSONTyped,
    TechnicalAttributeRecordToJSON,
} from './TechnicalAttributeRecord';

/**
 * 
 * @export
 * @interface EnvironmentRecord
 */
export interface EnvironmentRecord {
    /**
     * 
     * @type {string}
     * @memberof EnvironmentRecord
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof EnvironmentRecord
     */
    internationalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof EnvironmentRecord
     */
    effectiveFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof EnvironmentRecord
     */
    effectiveTo?: string;
    /**
     * 
     * @type {string}
     * @memberof EnvironmentRecord
     */
    internationalName?: string;
    /**
     * 
     * @type {string}
     * @memberof EnvironmentRecord
     */
    nameForReport?: string;
    /**
     * 
     * @type {string}
     * @memberof EnvironmentRecord
     */
    nameForGis?: string;
    /**
     * 
     * @type {GeneralTypeRecord}
     * @memberof EnvironmentRecord
     */
    basinType?: GeneralTypeRecord;
    /**
     * 
     * @type {string}
     * @memberof EnvironmentRecord
     */
    managerCode?: string;
    /**
     * 
     * @type {number}
     * @memberof EnvironmentRecord
     */
    hierarchicalLevel?: number;
    /**
     * 
     * @type {GeneralTypeRecord}
     * @memberof EnvironmentRecord
     */
    positionType?: GeneralTypeRecord;
    /**
     * 
     * @type {string}
     * @memberof EnvironmentRecord
     */
    mapNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof EnvironmentRecord
     */
    length?: number;
    /**
     * 
     * @type {string}
     * @memberof EnvironmentRecord
     */
    hydrologicalNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof EnvironmentRecord
     */
    vhpid?: string;
    /**
     * 
     * @type {string}
     * @memberof EnvironmentRecord
     */
    note?: string;
    /**
     * 
     * @type {GeneralTypeRecord}
     * @memberof EnvironmentRecord
     */
    environmentType?: GeneralTypeRecord;
    /**
     * 
     * @type {GeneralTypeRecord}
     * @memberof EnvironmentRecord
     */
    environmentFormType?: GeneralTypeRecord;
    /**
     * 
     * @type {LevelRecord}
     * @memberof EnvironmentRecord
     */
    level?: LevelRecord;
    /**
     * 
     * @type {number}
     * @memberof EnvironmentRecord
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof EnvironmentRecord
     */
    code?: string;
    /**
     * 
     * @type {TechnicalAttributeRecord}
     * @memberof EnvironmentRecord
     */
    technicalAttribute?: TechnicalAttributeRecord;
    /**
     * 
     * @type {boolean}
     * @memberof EnvironmentRecord
     */
    deleted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EnvironmentRecord
     */
    active?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EnvironmentRecord
     */
    historical?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EnvironmentRecord
     */
    version?: string;
    /**
     * 
     * @type {string}
     * @memberof EnvironmentRecord
     */
    createdTimestamp?: string;
    /**
     * 
     * @type {string}
     * @memberof EnvironmentRecord
     */
    updatedTimestamp?: string;
}

/**
 * Check if a given object implements the EnvironmentRecord interface.
 */
export function instanceOfEnvironmentRecord(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EnvironmentRecordFromJSON(json: any): EnvironmentRecord {
    return EnvironmentRecordFromJSONTyped(json, false);
}

export function EnvironmentRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): EnvironmentRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'internationalCode': !exists(json, 'internationalCode') ? undefined : json['internationalCode'],
        'effectiveFrom': !exists(json, 'effectiveFrom') ? undefined : json['effectiveFrom'],
        'effectiveTo': !exists(json, 'effectiveTo') ? undefined : json['effectiveTo'],
        'internationalName': !exists(json, 'internationalName') ? undefined : json['internationalName'],
        'nameForReport': !exists(json, 'nameForReport') ? undefined : json['nameForReport'],
        'nameForGis': !exists(json, 'nameForGis') ? undefined : json['nameForGis'],
        'basinType': !exists(json, 'basinType') ? undefined : GeneralTypeRecordFromJSON(json['basinType']),
        'managerCode': !exists(json, 'managerCode') ? undefined : json['managerCode'],
        'hierarchicalLevel': !exists(json, 'hierarchicalLevel') ? undefined : json['hierarchicalLevel'],
        'positionType': !exists(json, 'positionType') ? undefined : GeneralTypeRecordFromJSON(json['positionType']),
        'mapNumber': !exists(json, 'mapNumber') ? undefined : json['mapNumber'],
        'length': !exists(json, 'length') ? undefined : json['length'],
        'hydrologicalNumber': !exists(json, 'hydrologicalNumber') ? undefined : json['hydrologicalNumber'],
        'vhpid': !exists(json, 'vhpid') ? undefined : json['vhpid'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'environmentType': !exists(json, 'environmentType') ? undefined : GeneralTypeRecordFromJSON(json['environmentType']),
        'environmentFormType': !exists(json, 'environmentFormType') ? undefined : GeneralTypeRecordFromJSON(json['environmentFormType']),
        'level': !exists(json, 'level') ? undefined : LevelRecordFromJSON(json['level']),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'technicalAttribute': !exists(json, 'technicalAttribute') ? undefined : TechnicalAttributeRecordFromJSON(json['technicalAttribute']),
        'deleted': !exists(json, 'deleted') ? undefined : json['deleted'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'historical': !exists(json, 'historical') ? undefined : json['historical'],
        'version': !exists(json, 'version') ? undefined : json['version'],
        'createdTimestamp': !exists(json, 'createdTimestamp') ? undefined : json['createdTimestamp'],
        'updatedTimestamp': !exists(json, 'updatedTimestamp') ? undefined : json['updatedTimestamp'],
    };
}

export function EnvironmentRecordToJSON(value?: EnvironmentRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'internationalCode': value.internationalCode,
        'effectiveFrom': value.effectiveFrom,
        'effectiveTo': value.effectiveTo,
        'internationalName': value.internationalName,
        'nameForReport': value.nameForReport,
        'nameForGis': value.nameForGis,
        'basinType': GeneralTypeRecordToJSON(value.basinType),
        'managerCode': value.managerCode,
        'hierarchicalLevel': value.hierarchicalLevel,
        'positionType': GeneralTypeRecordToJSON(value.positionType),
        'mapNumber': value.mapNumber,
        'length': value.length,
        'hydrologicalNumber': value.hydrologicalNumber,
        'vhpid': value.vhpid,
        'note': value.note,
        'environmentType': GeneralTypeRecordToJSON(value.environmentType),
        'environmentFormType': GeneralTypeRecordToJSON(value.environmentFormType),
        'level': LevelRecordToJSON(value.level),
        'id': value.id,
        'code': value.code,
        'technicalAttribute': TechnicalAttributeRecordToJSON(value.technicalAttribute),
        'deleted': value.deleted,
        'active': value.active,
        'historical': value.historical,
        'version': value.version,
        'createdTimestamp': value.createdTimestamp,
        'updatedTimestamp': value.updatedTimestamp,
    };
}

