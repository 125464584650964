/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CodeListItemBasicRecord } from './CodeListItemBasicRecord';
import {
    CodeListItemBasicRecordFromJSON,
    CodeListItemBasicRecordFromJSONTyped,
    CodeListItemBasicRecordToJSON,
} from './CodeListItemBasicRecord';

/**
 * 
 * @export
 * @interface WaterStructEmbankResponseRecord
 */
export interface WaterStructEmbankResponseRecord {
    /**
     * 
     * @type {number}
     * @memberof WaterStructEmbankResponseRecord
     */
    id?: number | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof WaterStructEmbankResponseRecord
     */
    type?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof WaterStructEmbankResponseRecord
     */
    kind?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof WaterStructEmbankResponseRecord
     */
    loc?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructEmbankResponseRecord
     */
    altit?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructEmbankResponseRecord
     */
    height?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructEmbankResponseRecord
     */
    length?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructEmbankResponseRecord
     */
    cap?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructEmbankResponseRecord
     */
    capoth?: number | null;
}

/**
 * Check if a given object implements the WaterStructEmbankResponseRecord interface.
 */
export function instanceOfWaterStructEmbankResponseRecord(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WaterStructEmbankResponseRecordFromJSON(json: any): WaterStructEmbankResponseRecord {
    return WaterStructEmbankResponseRecordFromJSONTyped(json, false);
}

export function WaterStructEmbankResponseRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): WaterStructEmbankResponseRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'type': !exists(json, 'type') ? undefined : CodeListItemBasicRecordFromJSON(json['type']),
        'kind': !exists(json, 'kind') ? undefined : CodeListItemBasicRecordFromJSON(json['kind']),
        'loc': !exists(json, 'loc') ? undefined : CodeListItemBasicRecordFromJSON(json['loc']),
        'altit': !exists(json, 'altit') ? undefined : json['altit'],
        'height': !exists(json, 'height') ? undefined : json['height'],
        'length': !exists(json, 'length') ? undefined : json['length'],
        'cap': !exists(json, 'cap') ? undefined : json['cap'],
        'capoth': !exists(json, 'capoth') ? undefined : json['capoth'],
    };
}

export function WaterStructEmbankResponseRecordToJSON(value?: WaterStructEmbankResponseRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': CodeListItemBasicRecordToJSON(value.type),
        'kind': CodeListItemBasicRecordToJSON(value.kind),
        'loc': CodeListItemBasicRecordToJSON(value.loc),
        'altit': value.altit,
        'height': value.height,
        'length': value.length,
        'cap': value.cap,
        'capoth': value.capoth,
    };
}

