/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LandRegistryType
 */
export interface LandRegistryType {
    /**
     * 
     * @type {number}
     * @memberof LandRegistryType
     */
    code?: number;
    /**
     * 
     * @type {string}
     * @memberof LandRegistryType
     */
    acronym?: string;
    /**
     * 
     * @type {string}
     * @memberof LandRegistryType
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof LandRegistryType
     */
    effectiveFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof LandRegistryType
     */
    effectiveTo?: string;
}

/**
 * Check if a given object implements the LandRegistryType interface.
 */
export function instanceOfLandRegistryType(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function LandRegistryTypeFromJSON(json: any): LandRegistryType {
    return LandRegistryTypeFromJSONTyped(json, false);
}

export function LandRegistryTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): LandRegistryType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': !exists(json, 'code') ? undefined : json['code'],
        'acronym': !exists(json, 'acronym') ? undefined : json['acronym'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'effectiveFrom': !exists(json, 'effectiveFrom') ? undefined : json['effectiveFrom'],
        'effectiveTo': !exists(json, 'effectiveTo') ? undefined : json['effectiveTo'],
    };
}

export function LandRegistryTypeToJSON(value?: LandRegistryType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': value.code,
        'acronym': value.acronym,
        'name': value.name,
        'effectiveFrom': value.effectiveFrom,
        'effectiveTo': value.effectiveTo,
    };
}

