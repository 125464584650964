/* tslint:disable */
/* eslint-disable */
/**
 * Data import metadata
 * Administration of metadata for import
 *
 * The version of the OpenAPI document: 1.0
 * Contact: schvarcz@bb.microcomp.sk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ControlDefinitionItemRequest
 */
export interface ControlDefinitionItemRequest {
    /**
     * 
     * @type {string}
     * @memberof ControlDefinitionItemRequest
     */
    controlReferenceAttributeType?: ControlDefinitionItemRequestControlReferenceAttributeTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ControlDefinitionItemRequest
     */
    item?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlDefinitionItemRequest
     */
    attributeType?: ControlDefinitionItemRequestAttributeTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ControlDefinitionItemRequest
     */
    originalValueFlag?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ControlDefinitionItemRequest
     */
    dataset?: number;
}


/**
 * @export
 */
export const ControlDefinitionItemRequestControlReferenceAttributeTypeEnum = {
    Year: 'YEAR',
    Date: 'DATE',
    Source: 'SOURCE',
    ReferenceCode: 'REFERENCE_CODE',
    ReferenceValue: 'REFERENCE_VALUE',
    Time: 'TIME',
    Stage: 'STAGE',
    Temp: 'TEMP',
    Yield: 'YIELD',
    MonPointId: 'MON_POINT_ID',
    FrequencyCode: 'FREQUENCY_CODE',
    AttributeCode: 'ATTRIBUTE_CODE',
    ReferenceAttribute: 'REFERENCE_ATTRIBUTE',
    WaterTypeCode: 'WATER_TYPE_CODE',
    SampleTypeCode: 'SAMPLE_TYPE_CODE',
    DeterminantCode: 'DETERMINANT_CODE',
    PollutionKind: 'POLLUTION_KIND',
    ExtremeStage: 'EXTREME_STAGE',
    ExtremeTemp: 'EXTREME_TEMP',
    ExtremeMin: 'EXTREME_MIN',
    ExtremeMax: 'EXTREME_MAX',
    ExtremeYield: 'EXTREME_YIELD',
    Discharge: 'DISCHARGE',
    ExtremeDischarge: 'EXTREME_DISCHARGE',
    ValueNum: 'VALUE_NUM',
    ValueStr: 'VALUE_STR',
    DetLimit: 'DET_LIMIT',
    ValueUnit: 'VALUE_UNIT',
    DeterminantUnit: 'DETERMINANT_UNIT',
    MeasuringInterval: 'MEASURING_INTERVAL',
    UndergroundWaterDepth: 'UNDERGROUND_WATER_DEPTH',
    UndergroundWaterHeight: 'UNDERGROUND_WATER_HEIGHT',
    ValidFrom: 'VALID_FROM',
    ValidTo: 'VALID_TO',
    SeasonFrom: 'SEASON_FROM',
    SeasonTo: 'SEASON_TO'
} as const;
export type ControlDefinitionItemRequestControlReferenceAttributeTypeEnum = typeof ControlDefinitionItemRequestControlReferenceAttributeTypeEnum[keyof typeof ControlDefinitionItemRequestControlReferenceAttributeTypeEnum];

/**
 * @export
 */
export const ControlDefinitionItemRequestAttributeTypeEnum = {
    RowAttributeDefinitionCode: 'ROW_ATTRIBUTE_DEFINITION_CODE',
    Value: 'VALUE'
} as const;
export type ControlDefinitionItemRequestAttributeTypeEnum = typeof ControlDefinitionItemRequestAttributeTypeEnum[keyof typeof ControlDefinitionItemRequestAttributeTypeEnum];


/**
 * Check if a given object implements the ControlDefinitionItemRequest interface.
 */
export function instanceOfControlDefinitionItemRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ControlDefinitionItemRequestFromJSON(json: any): ControlDefinitionItemRequest {
    return ControlDefinitionItemRequestFromJSONTyped(json, false);
}

export function ControlDefinitionItemRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlDefinitionItemRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'controlReferenceAttributeType': !exists(json, 'controlReferenceAttributeType') ? undefined : json['controlReferenceAttributeType'],
        'item': !exists(json, 'item') ? undefined : json['item'],
        'attributeType': !exists(json, 'attributeType') ? undefined : json['attributeType'],
        'originalValueFlag': !exists(json, 'originalValueFlag') ? undefined : json['originalValueFlag'],
        'dataset': !exists(json, 'dataset') ? undefined : json['dataset'],
    };
}

export function ControlDefinitionItemRequestToJSON(value?: ControlDefinitionItemRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'controlReferenceAttributeType': value.controlReferenceAttributeType,
        'item': value.item,
        'attributeType': value.attributeType,
        'originalValueFlag': value.originalValueFlag,
        'dataset': value.dataset,
    };
}

