/* tslint:disable */
/* eslint-disable */
/**
 * Catalog API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.31.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CodelistItemInput } from './CodelistItemInput';
import {
    CodelistItemInputFromJSON,
    CodelistItemInputFromJSONTyped,
    CodelistItemInputToJSON,
} from './CodelistItemInput';

/**
 * 
 * @export
 * @interface AddressCodelistItemInput
 */
export interface AddressCodelistItemInput {
    /**
     * 
     * @type {string}
     * @memberof AddressCodelistItemInput
     */
    street: string | null;
    /**
     * 
     * @type {CodelistItemInput}
     * @memberof AddressCodelistItemInput
     */
    cityType: CodelistItemInput | null;
    /**
     * 
     * @type {string}
     * @memberof AddressCodelistItemInput
     */
    zip: string | null;
    /**
     * 
     * @type {CodelistItemInput}
     * @memberof AddressCodelistItemInput
     */
    countryType: CodelistItemInput | null;
    /**
     * 
     * @type {CodelistItemInput}
     * @memberof AddressCodelistItemInput
     */
    districtType: CodelistItemInput | null;
    /**
     * 
     * @type {CodelistItemInput}
     * @memberof AddressCodelistItemInput
     */
    landRegistryType: CodelistItemInput | null;
}

/**
 * Check if a given object implements the AddressCodelistItemInput interface.
 */
export function instanceOfAddressCodelistItemInput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "street" in value;
    isInstance = isInstance && "cityType" in value;
    isInstance = isInstance && "zip" in value;
    isInstance = isInstance && "countryType" in value;
    isInstance = isInstance && "districtType" in value;
    isInstance = isInstance && "landRegistryType" in value;

    return isInstance;
}

export function AddressCodelistItemInputFromJSON(json: any): AddressCodelistItemInput {
    return AddressCodelistItemInputFromJSONTyped(json, false);
}

export function AddressCodelistItemInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddressCodelistItemInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'street': json['street'],
        'cityType': CodelistItemInputFromJSON(json['cityType']),
        'zip': json['zip'],
        'countryType': CodelistItemInputFromJSON(json['countryType']),
        'districtType': CodelistItemInputFromJSON(json['districtType']),
        'landRegistryType': CodelistItemInputFromJSON(json['landRegistryType']),
    };
}

export function AddressCodelistItemInputToJSON(value?: AddressCodelistItemInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'street': value.street,
        'cityType': CodelistItemInputToJSON(value.cityType),
        'zip': value.zip,
        'countryType': CodelistItemInputToJSON(value.countryType),
        'districtType': CodelistItemInputToJSON(value.districtType),
        'landRegistryType': CodelistItemInputToJSON(value.landRegistryType),
    };
}

