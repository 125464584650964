/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { NewOperatorRequestRecord } from './NewOperatorRequestRecord';
import {
    NewOperatorRequestRecordFromJSON,
    NewOperatorRequestRecordFromJSONTyped,
    NewOperatorRequestRecordToJSON,
} from './NewOperatorRequestRecord';

/**
 * 
 * @export
 * @interface WaterEvidenceDocumentRequestRecord
 */
export interface WaterEvidenceDocumentRequestRecord {
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceDocumentRequestRecord
     */
    id?: number | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceDocumentRequestRecord
     */
    number?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceDocumentRequestRecord
     */
    sizp?: number | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceDocumentRequestRecord
     */
    note?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceDocumentRequestRecord
     */
    season?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceDocumentRequestRecord
     */
    districtCode?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceDocumentRequestRecord
     */
    regionCode?: number | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceDocumentRequestRecord
     */
    dateFrom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceDocumentRequestRecord
     */
    dateTo?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceDocumentRequestRecord
     */
    documentTypeCode?: number | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceDocumentRequestRecord
     */
    operatorCode?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceDocumentRequestRecord
     */
    operatorId?: number | null;
    /**
     * 
     * @type {NewOperatorRequestRecord}
     * @memberof WaterEvidenceDocumentRequestRecord
     */
    newOperator?: NewOperatorRequestRecord | null;
}

/**
 * Check if a given object implements the WaterEvidenceDocumentRequestRecord interface.
 */
export function instanceOfWaterEvidenceDocumentRequestRecord(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WaterEvidenceDocumentRequestRecordFromJSON(json: any): WaterEvidenceDocumentRequestRecord {
    return WaterEvidenceDocumentRequestRecordFromJSONTyped(json, false);
}

export function WaterEvidenceDocumentRequestRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): WaterEvidenceDocumentRequestRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'number': !exists(json, 'number') ? undefined : json['number'],
        'sizp': !exists(json, 'sizp') ? undefined : json['sizp'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'season': !exists(json, 'season') ? undefined : json['season'],
        'districtCode': !exists(json, 'districtCode') ? undefined : json['districtCode'],
        'regionCode': !exists(json, 'regionCode') ? undefined : json['regionCode'],
        'dateFrom': !exists(json, 'dateFrom') ? undefined : json['dateFrom'],
        'dateTo': !exists(json, 'dateTo') ? undefined : json['dateTo'],
        'documentTypeCode': !exists(json, 'documentTypeCode') ? undefined : json['documentTypeCode'],
        'operatorCode': !exists(json, 'operatorCode') ? undefined : json['operatorCode'],
        'operatorId': !exists(json, 'operatorId') ? undefined : json['operatorId'],
        'newOperator': !exists(json, 'newOperator') ? undefined : NewOperatorRequestRecordFromJSON(json['newOperator']),
    };
}

export function WaterEvidenceDocumentRequestRecordToJSON(value?: WaterEvidenceDocumentRequestRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'number': value.number,
        'sizp': value.sizp,
        'note': value.note,
        'season': value.season,
        'districtCode': value.districtCode,
        'regionCode': value.regionCode,
        'dateFrom': value.dateFrom,
        'dateTo': value.dateTo,
        'documentTypeCode': value.documentTypeCode,
        'operatorCode': value.operatorCode,
        'operatorId': value.operatorId,
        'newOperator': NewOperatorRequestRecordToJSON(value.newOperator),
    };
}

