import React, { useContext, useEffect, useMemo, useState } from 'react';
import Header from '../components/layout/Header';
import PageTitle from '../components/layout/PageTitle';
import { Button, FieldCol, FieldGrid, FieldGroup, Input as McInput, Modal } from '@mc-ui/react-components';
import { useParams } from 'react-router-dom';
import Badge from '../components/common/Badge';
import { ColumnDef } from '@tanstack/react-table';
import { PreferencesContext, TableDensity } from '../store/preferences-context';
import TableView from '../components/table/TableView';
import LoadingOverlay from '../components/common/LoadingOverlay';
import { uniqueId } from 'lodash';
import { useFetch } from '../hooks/use-fetch';
import useMemoryTable from '../hooks/use-memory-table';

export enum UserState {
    'approved' = 'approved',
    'denied' = 'denied',
    'waiting' = 'waiting'
}

export interface ShmuUser {
    id: string;
    username: string;
    password: string;
    type: string;
    firstName: string;
    lastName: string;
    email: string;
    attributes: Record<string, string>;
}

export enum UserType {
    'WATER_EVIDENCE' = 'water-evidence',
    'REPORTING_OBLIGATION' = 'reporting-obligation',
    'SHMU_USER' = 'shmu-user'
}

export interface OperatorType {
    id: number;
    type: string;
    name: string;
    ico: string;
    dic: string;
    country: string;
    district: string;
    city: string;
    address: string;
}

const OperatorTypeMapping = {
    P: 'Právnická osoba',
    F: 'Fyzická osoba',
    Z: 'Zahraničná firma'
};

const UsersPageDetail: React.FC = () => {
    const { id } = useParams();
    const [denyModal, setDenyModal] = useState<boolean>(false);
    const [denyReason, setDenyReason] = useState<string>('');
    const [update, setUpdate] = useState<string>(uniqueId('user-detail-'));
    const [user, setUser] = useState<ShmuUser>();
    const [operators, setOperators] = useState<OperatorType[]>([]);
    const { defaultPageSize, defaultDensity } = useContext(PreferencesContext);
    const [density] = useState<TableDensity>(defaultDensity);
    const fetchInstance = useFetch(ENV.KEYCLOAK_URL ?? '/');
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    function getDomain() {
        let port = window.location.port;
        if (port && port !== '80') port = ':' + port;
        return `${window.location.protocol}//${window.location.hostname}${port}`;
    }

    function approve() {
        setSubmitting(true);
        fetchInstance.current
            ?.postJson('/realms/shmu/shmu-provider/approve', {
                id: id,
                url: getDomain(),
                reason: ''
            })
            .then((r) => {
                setSubmitting(false);
                setUpdate(uniqueId('user-detail-'));
            })
            .catch((e) => {
                setSubmitting(false);
                setUpdate(uniqueId('user-detail-'));
            });
    }

    function deny() {
        setSubmitting(true);
        fetchInstance.current
            ?.postJson('/realms/shmu/shmu-provider/deny', {
                id: id,
                url: getDomain(),
                reason: denyReason
            })
            .then((r) => {
                setSubmitting(false);
                setUpdate(uniqueId('user-detail-'));
            })
            .catch((e) => {
                setSubmitting(false);
                setUpdate(uniqueId('user-detail-'));
            });
    }

    useEffect(() => {
        setLoading(true);
        fetchInstance.current
            ?.getJson(`/realms/shmu/shmu-provider/user?id=${id}`)
            .then((response) => {
                let newUser = response.data;
                let newOperators: OperatorType[] = [];
                for (let index = 0; index < 1000; index++) {
                    if (`operator_${index}_id` in newUser.attributes) {
                        newOperators.push({
                            id: newUser.attributes[`operator_${index}_id`],
                            type: OperatorTypeMapping[newUser.attributes[`operator_${index}_type`] as keyof typeof OperatorTypeMapping],
                            name: newUser.attributes[`operator_${index}_name`],
                            ico: newUser.attributes[`operator_${index}_ico`],
                            dic: newUser.attributes[`operator_${index}_dic`],
                            country: newUser.attributes[`operator_${index}_country_name`],
                            district: newUser.attributes[`operator_${index}_district_name`],
                            city: newUser.attributes[`operator_${index}_city_name`],
                            address: newUser.attributes[`operator_${index}_address`]
                        });
                    } else {
                        break;
                    }
                }
                setUser(response.data);
                setOperators(newOperators);
                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);
            });
    }, [update, fetchInstance, id]);

    const columns = useMemo<ColumnDef<OperatorType>[]>(
        () => [
            {
                accessorKey: 'id',
                cell: (props) => {
                    return !props.getValue() ? 'Nový prevádzkovateľ' : 'Existujúci prevádzkovateľ';
                },
                header: 'Stav',
                enableSorting: false
            },
            {
                accessorKey: 'type',
                header: 'Typ',
                enableSorting: false
            },
            {
                accessorKey: 'name',
                header: 'Meno / Názov',
                enableSorting: false
            },
            {
                accessorKey: 'ico',
                header: 'IČO',
                enableSorting: false
            },
            {
                accessorKey: 'dic',
                header: 'DIČ',
                enableSorting: false
            },
            {
                accessorKey: 'country',
                header: 'Krajina',
                enableSorting: false
            },
            {
                accessorKey: 'district',
                header: 'Okres',
                enableSorting: false
            },
            {
                accessorKey: 'city',
                header: 'Mesto',
                enableSorting: false
            },
            {
                accessorKey: 'address',
                header: 'Adresa',
                enableSorting: false
            }
        ],
        []
    );

    const { table } = useMemoryTable({
        density,
        columns,
        data: operators,
        initialState: {
            pagination: {
                pageIndex: 0,
                pageSize: defaultPageSize
            },
            columnVisibility: {}
        }
    });

    const approved = user?.attributes.status;

    return (
        <LoadingOverlay loading={loading || submitting}>
            <Header
                breadcrumbs={[
                    {
                        name: 'Používatelia',
                        to: '/users'
                    },
                    {
                        name: 'Detail',
                        to: '/user'
                    }
                ]}
            />
            <PageTitle title={`${user?.firstName} ${user?.lastName} `}>
                <div className='hidden flex-row gap-3 lg:flex'>
                    <Modal
                        isOpen={denyModal}
                        size='auto'
                        body={
                            <>
                                <textarea
                                    style={{ width: 250, height: 150 }}
                                    onChange={(event: React.FormEvent<HTMLTextAreaElement>) => setDenyReason(event.currentTarget.value)}
                                    value={denyReason}
                                    name='denyReason'
                                ></textarea>
                            </>
                        }
                        type='negative'
                        title='Dôvod zamietnutia'
                        confirmLabel='Zamietnuť'
                        onClose={(confirm: boolean) => {
                            if (confirm) {
                                deny();
                            }
                            setDenyModal(false);
                        }}
                    />
                    <Badge
                        color={UserState.approved === approved ? 'success' : UserState.waiting === approved ? 'warning' : 'failure'}
                        variant='rounded'
                    >
                        {UserState.approved === approved
                            ? 'Schválené'
                            : UserState.waiting === approved
                              ? 'Čaká na schválenie'
                              : 'Zamietnuté'}
                    </Badge>
                    <Button
                        onClick={() => {
                            approve();
                        }}
                        disabled={UserState.approved === approved}
                        color='primary'
                        variant='rounded'
                        size='large'
                    >
                        Schváliť
                    </Button>
                    <Button
                        onClick={() => {
                            setDenyModal(true);
                        }}
                        disabled={UserState.denied === approved}
                        color='secondary'
                        variant='rounded'
                        size='large'
                    >
                        Zamietnuť
                    </Button>
                </div>
            </PageTitle>
            <div>
                <FieldGroup
                    color='dark'
                    title='Používateľ'
                >
                    <FieldGrid>
                        <FieldCol>
                            <McInput
                                label='Meno'
                                readOnly
                                defaultValue={user?.firstName}
                            />
                        </FieldCol>
                        <FieldCol>
                            <McInput
                                label='Priezvisko'
                                readOnly
                                defaultValue={user?.lastName}
                            />
                        </FieldCol>
                    </FieldGrid>
                    <FieldGrid>
                        <FieldCol>
                            <McInput
                                label='Email'
                                readOnly
                                defaultValue={user?.email}
                            />
                        </FieldCol>
                        {UserType.WATER_EVIDENCE === user?.type ? (
                            <FieldCol>
                                <McInput
                                    label='Okres'
                                    readOnly
                                    defaultValue={user?.attributes['district_name']}
                                />
                            </FieldCol>
                        ) : null}
                    </FieldGrid>
                </FieldGroup>
                {UserType.REPORTING_OBLIGATION === user?.type ? (
                    <FieldGroup
                        color='light'
                        title='Prevádzkovatelia'
                    >
                        <TableView table={table} />
                    </FieldGroup>
                ) : null}
            </div>
        </LoadingOverlay>
    );
};

export default UsersPageDetail;
