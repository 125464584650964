import * as yup from 'yup';

// Definicia typu premenných prostredia
export const environmentSchema = yup.object({
    ATTACHMENT_MAX_SIZE: yup.number().defined().required(),
    CODELIST_ITEM_BASIN_LEVEL_BASIN: yup.number().defined().required(),
    CODELIST_ITEM_BASIN_LEVEL_PARTIAL_BASIN: yup.number().defined().required(),
    CODELIST_ITEM_BASIN_LEVEL_SUB_BASIN: yup.number().defined().required(),
    CODELIST_ITEM_COUNTRY_SLOVAKIA: yup.number().defined().required(),
    CODELIST_ITEM_DET_SELECTION_WATER_LAW_RECORDS: yup.number().defined().required(),
    CODELIST_ITEM_DOC_STATUS_CANCELLED: yup.number().defined().required(),
    CODELIST_ITEM_DOC_STATUS_CHANGED: yup.number().defined().required(),
    CODELIST_ITEM_DOC_STATUS_DELETED: yup.number().defined().required(),
    CODELIST_ITEM_DOC_STATUS_REPLACED: yup.number().defined().required(),
    CODELIST_ITEM_DOC_STATUS_VALID: yup.number().defined().required(),
    CODELIST_ITEM_DOC_TYPE_DECISION: yup.number().defined().required(),
    CODELIST_ITEM_DOC_TYPE_DECISION_ABOUT_CANCELLATION: yup.number().defined().required(),
    CODELIST_ITEM_DOC_TYPE_DECISION_ABOUT_CHANGE: yup.number().defined().required(),
    CODELIST_ITEM_DOC_TYPE_IPKZ_DECISION: yup.number().defined().required(),
    CODELIST_ITEM_DOC_TYPE_IPKZ_DECISION_ABOUT_CANCELLATION: yup.number().defined().required(),
    CODELIST_ITEM_DOC_TYPE_IPKZ_DECISION_ABOUT_CHANGE: yup.number().defined().required(),
    CODELIST_ITEM_ENV_TYPE_SURFACE_WATER: yup.number().defined().required(),
    CODELIST_ITEM_INSTITUTION_SHMU: yup.number().defined().required(),
    CODELIST_ITEM_INSTITUTION_SHMU_MAIN: yup.number().defined().required(),
    CODELIST_ITEM_INSTITUTION_SIZP: yup.number().defined().required(),
    CODELIST_ITEM_INSTITUTION_TYPE_SLKV: yup.number().defined().required(),
    CODELIST_ITEM_VALUE_TYPE_COLLECTIONS: yup.number().defined().required(),
    CODELIST_ITEM_VALUE_TYPE_RECORDS: yup.number().defined().required(),
    CODELIST_ITEM_WB_CYCLE_PC1: yup.number().defined().required(),
    CODELIST_AFFECTED: yup.number().defined().required(),
    CODELIST_AN_METHOD: yup.number().defined().required(),
    CODELIST_AN_METHOD_GROUP: yup.number().defined().required(),
    CODELIST_AN_METHODIC: yup.number().defined().required(),
    CODELIST_AREA: yup.number().defined().required(),
    CODELIST_ASSESMENT_BASIN: yup.number().defined().required(),
    CODELIST_ASSESMENT_COLOR: yup.number().defined().required(),
    CODELIST_ASSESMENT_DETERMINANT: yup.number().defined().required(),
    CODELIST_ASSESMENT_SMELL: yup.number().defined().required(),
    CODELIST_ASSESMENT_SOLVER: yup.number().defined().required(),
    CODELIST_ASSESMENT_TURBIDITY: yup.number().defined().required(),
    CODELIST_ASSESMENT_TYPE: yup.number().defined().required(),
    CODELIST_ASSESMENT_WATER_FLOW: yup.number().defined().required(),
    CODELIST_BASIN: yup.number().defined().required(),
    CODELIST_BUILDIN_FORM: yup.number().defined().required(),
    CODELIST_CHVO: yup.number().defined().required(),
    CODELIST_COORD_ACQ: yup.number().defined().required(),
    CODELIST_COUNTRY: yup.number().defined().required(),
    CODELIST_DETERMINANT: yup.number().defined().required(),
    CODELIST_DET_SELECTION2: yup.number().defined().required(),
    CODELIST_DIKE_MATERIAL: yup.number().defined().required(),
    CODELIST_DIKE_PLACE: yup.number().defined().required(),
    CODELIST_DIKE: yup.number().defined().required(),
    CODELIST_DISCHARGE_TYPE: yup.number().defined().required(),
    CODELIST_DISTRICT: yup.number().defined().required(),
    CODELIST_DOC_STATUS: yup.number().defined().required(),
    CODELIST_DOC_TYPE: yup.number().defined().required(),
    CODELIST_DRAINAGE_TYPE: yup.number().defined().required(),
    CODELIST_ENV_FORM: yup.number().defined().required(),
    CODELIST_ENV_TYPE: yup.number().defined().required(),
    CODELIST_GW_BODY: yup.number().defined().required(),
    CODELIST_HYDROLOGICAL_CODE: yup.number().defined().required(),
    CODELIST_INST_BRANCH: yup.number().defined().required(),
    CODELIST_INSTITUTION: yup.number().defined().required(),
    CODELIST_IRRIGATION_TYPE: yup.number().defined().required(),
    CODELIST_LAND_CULTURE: yup.number().defined().required(),
    CODELIST_LAND_REGISTER: yup.number().defined().required(),
    CODELIST_MAIN_HGR: yup.number().defined().required(),
    CODELIST_MEASURE_DEVICE: yup.number().defined().required(),
    CODELIST_MEASURED_PARAM: yup.number().defined().required(),
    CODELIST_MEASURING_TYPE: yup.number().defined().required(),
    CODELIST_MOD_CATEGORY: yup.number().defined().required(),
    CODELIST_MON_POINT_TYPE: yup.number().defined().required(),
    CODELIST_MONITORING_PURPOSE: yup.number().defined().required(),
    CODELIST_MONITORING_TYPE: yup.number().defined().required(),
    CODELIST_MUNICIPALITY_FOREIGN: yup.number().defined().required(),
    CODELIST_MUNICIPALITY: yup.number().defined().required(),
    CODELIST_NACE: yup.number().defined().required(),
    CODELIST_NACE2: yup.number().defined().required(),
    CODELIST_OBJ_MANAGER: yup.number().defined().required(),
    CODELIST_PARTIAL_HGR: yup.number().defined().required(),
    CODELIST_PERM_OBJECT: yup.number().defined().required(),
    CODELIST_POSITION: yup.number().defined().required(),
    CODELIST_PURIF_TYPE: yup.number().defined().required(),
    CODELIST_REGION: yup.number().defined().required(),
    CODELIST_RESEARCHER: yup.number().defined().required(),
    CODELIST_RESERVOIR_TYPE: yup.number().defined().required(),
    CODELIST_SAMPLE_AREA: yup.number().defined().required(),
    CODELIST_SAMPLE_TYPE: yup.number().defined().required(),
    CODELIST_SEASON: yup.number().defined().required(),
    CODELIST_SED_SLUDGE_BED: yup.number().defined().required(),
    CODELIST_SEWAGE_CHAR: yup.number().defined().required(),
    CODELIST_SEWAGE_PURPOSE: yup.number().defined().required(),
    CODELIST_SEWAGE_SYSTEM: yup.number().defined().required(),
    CODELIST_SHIPPING_DEVICE: yup.number().defined().required(),
    CODELIST_SLUDGE_BED_FLOW: yup.number().defined().required(),
    CODELIST_SLUDGE_DISPOSAL: yup.number().defined().required(),
    CODELIST_SLUDGE_STABILIZATION: yup.number().defined().required(),
    CODELIST_SPECIFIC_PURPOSE: yup.number().defined().required(),
    CODELIST_STATUS: yup.number().defined().required(),
    CODELIST_TANK: yup.number().defined().required(),
    CODELIST_TBD_CATEGORY: yup.number().defined().required(),
    CODELIST_UNIT: yup.number().defined().required(),
    CODELIST_USAGE_INTENT: yup.number().defined().required(),
    CODELIST_WATER_BLEEDING: yup.number().defined().required(),
    CODELIST_WATER_ESTUARY: yup.number().defined().required(),
    CODELIST_WATER_PURIFICATION: yup.number().defined().required(),
    CODELIST_WATER_STATION: yup.number().defined().required(),
    CODELIST_WATER_SYSTEM: yup.number().defined().required(),
    CODELIST_WATER_TREATMENT: yup.number().defined().required(),
    CODELIST_WATER_TYPE: yup.number().defined().required(),
    CODELIST_WATERCON_USAGE: yup.number().defined().required(),
    CODELIST_WATERPIPE_REF: yup.number().defined().required(),
    CODELIST_WATERWAY: yup.number().defined().required(),
    CODELIST_WB_CATEGORIE: yup.number().defined().required(),
    CODELIST_WB_CHARACTER: yup.number().defined().required(),
    CODELIST_WB_CYCLE: yup.number().defined().required(),
    CODELIST_WB_GROUP: yup.number().defined().required(),
    CODELIST_WB_TYPE: yup.number().defined().required(),
    CODELIST_WEIR_CONSTR: yup.number().defined().required(),
    CODELIST_WEIR_LEVEL_CAP: yup.number().defined().required(),
    CODELIST_WEIR_SHAPE: yup.number().defined().required(),
    CODELIST_WEIR: yup.number().defined().required(),
    CODELIST_WELL_TYPE: yup.number().defined().required(),
    CODELIST_WP_TURBINE: yup.number().defined().required(),
    CODELIST_WRB_GROUNDWATER_SUMMARIES: yup.number().defined().required(),
    HERKI_URL: yup.string().defined().required(),
    API_CATALOG_URL: yup.string().defined().required(),
    API_CATALOG_IMPORT_URL: yup.string().defined().required(),
    API_CODELIST_URL: yup.string().defined().required(),
    API_CODELIST_IMPORT_URL: yup.string().defined().required(),
    API_DATA_IMPORT_URL: yup.string().defined().required(),
    API_DATA_IMPORT_METADATA_URL: yup.string().defined().required(),
    API_HISTORY_URL: yup.string().defined().required(),
    API_METHODIC_IMPORT_URL: yup.string().defined().required(),
    API_NOTIFICATOR_URL: yup.string().defined().required(),
    API_OVERVIEW_URL: yup.string().defined().required(),
    API_USER_PROFILE_URL: yup.string().defined().required(),
    API_DATA_REGISTRY_URL: yup.string().defined().required(),
    KEYCLOAK_REALM: yup.string().defined().required(),
    KEYCLOAK_CLIENT: yup.string().defined().required(),
    KEYCLOAK_URL: yup.string().defined().required(),
    SUPERSET_URL: yup.string().defined().nullable(),
    MAP_ENVIRONMENT_SCALE_10_LAYER: yup.number().defined().required(),
    MAP_ENVIRONMENT_SCALE_10_SUBLAYER: yup.number().defined().required(),
    MAP_ENVIRONMENT_SCALE_10_SUBLAYER_ATTR_GID: yup.string().defined().required(),
    MAP_ENVIRONMENT_SCALE_10_SUBLAYER_ATTR_ID_TOKU: yup.string().defined().required(),
    MAP_ENVIRONMENT_SCALE_10_MON_POINT_LAYER: yup.number().defined().required(),
    MAP_ENVIRONMENT_SCALE_10_SUBLAYER_ATTR_KOD_VU: yup.string().defined().required(),
    MAP_MON_POINT_SUBLAYER: yup.number().defined().required(),
    MAP_MON_POINT_SUBLAYER_ATTR: yup.string().defined().required(),
    MAP_ADM_SEGM_LAYER: yup.number().defined().required(),
    MAP_ADM_SEGM_LAND_REGISTER_SUBLAYER: yup.number().defined().required(),
    MAP_ADM_SEGM_LAND_REGISTER_SUBLAYER_ATTR: yup.string().defined().required(),
    MAP_MAP_SHEET_LAYER: yup.number().defined().required(),
    MAP_MAP_SHEET_NUMBER_SUBLAYER: yup.number().defined().required(),
    MAP_MAP_SHEET_NUMBER_SUBLAYER_ATTR: yup.string().defined().required(),
    MAP_BASIN_LAYER: yup.number().defined().required(),
    MAP_BASIN_SUBLAYER: yup.number().defined().required(),
    MAP_BASIN_SUBLAYER_ATTR: yup.string().defined().required(),
    MAP_PARTIAL_BASIN_LAYER: yup.number().defined().required(),
    MAP_PARTIAL_BASIN_SUBLAYER: yup.number().defined().required(),
    MAP_PARTIAL_BASIN_SUBLAYER_ATTR: yup.string().defined().required(),
    MAP_SUB_BASIN_LAYER: yup.number().defined().required(),
    MAP_SUB_BASIN_SUBLAYER: yup.number().defined().required(),
    MAP_SUB_BASIN_SUBLAYER_ATTR: yup.string().defined().required(),
    MAP_DETAILED_BASIN_LAYER: yup.number().defined().required(),
    MAP_DETAILED_BASIN_SUBLAYER: yup.number().defined().required(),
    MAP_DETAILED_BASIN_SUBLAYER_ATTR: yup.string().defined().required(),
    MAP_ASSESMENT_BASIN_LAYER: yup.number().defined().required(),
    MAP_ASSESMENT_BASIN_SUBLAYER: yup.number().defined().required(),
    MAP_ASSESMENT_BASIN_SUBLAYER_ATTR: yup.string().defined().required(),
    MAP_CHVO_LAYER: yup.number().defined().required(),
    MAP_CHVO_SUBLAYER: yup.number().defined().required(),
    MAP_CHVO_SUBLAYER_ATTR: yup.string().defined().required(),
    MAP_UNDERGROUND_WATER_1_WATER_PLAN_LAYER: yup.number().defined().required(),
    MAP_UNDERGROUND_WATER_2_WATER_PLAN_LAYER: yup.number().defined().required(),
    MAP_UNDERGROUND_WATER_3_WATER_PLAN_LAYER: yup.number().defined().required(),
    MAP_UNDERGROUND_WATER_QUARTER_SUBLAYER: yup.number().defined().required(),
    MAP_UNDERGROUND_WATER_FOREQUARTER_SUBLAYER: yup.number().defined().required(),
    MAP_UNDERGROUND_WATER_SUBLAYER_ATTR: yup.string().defined().required(),
    MAP_HYDROGEOLOGICAL_REGION_LAYER: yup.number().defined().required(),
    MAP_HYDROGEOLOGICAL_REGION_PZV_SUBLAYER: yup.number().defined().required(),
    MAP_HYDROGEOLOGICAL_REGION_PZV_SUBLAYER_ATTR: yup.string().defined().required(),
    MAP_HYDROGEOLOGICAL_REGION_VHB_SUBLAYER: yup.number().defined().required(),
    MAP_HYDROGEOLOGICAL_REGION_VHB_SUBLAYER_ATTR: yup.string().defined().required(),
    ARCGIS_URL: yup.string().defined().required(),
    ZBGISWS_SKGEODESY_BASE_URL: yup.string().defined().required(),
    MPT_SVP_SK_FULL_URL: yup.string().defined().required(),
    APP_NAME: yup.string().defined().nullable(),
    APP_VERSION: yup.string().defined().nullable(),
    TEST_SERVER: yup.number().defined().nullable()
});
