/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CodeListItemBasicRecord } from './CodeListItemBasicRecord';
import {
    CodeListItemBasicRecordFromJSON,
    CodeListItemBasicRecordFromJSONTyped,
    CodeListItemBasicRecordToJSON,
} from './CodeListItemBasicRecord';

/**
 * 
 * @export
 * @interface WaterStructReservoirResponseRecord
 */
export interface WaterStructReservoirResponseRecord {
    /**
     * 
     * @type {number}
     * @memberof WaterStructReservoirResponseRecord
     */
    id?: number | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof WaterStructReservoirResponseRecord
     */
    type?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructReservoirResponseRecord
     */
    volume?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructReservoirResponseRecord
     */
    volRet?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructReservoirResponseRecord
     */
    volSt?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructReservoirResponseRecord
     */
    volFix?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructReservoirResponseRecord
     */
    altit?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructReservoirResponseRecord
     */
    altitSt?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructReservoirResponseRecord
     */
    altitFix?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructReservoirResponseRecord
     */
    altitBed?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructReservoirResponseRecord
     */
    area?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructReservoirResponseRecord
     */
    len?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof WaterStructReservoirResponseRecord
     */
    test?: boolean | null;
}

/**
 * Check if a given object implements the WaterStructReservoirResponseRecord interface.
 */
export function instanceOfWaterStructReservoirResponseRecord(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WaterStructReservoirResponseRecordFromJSON(json: any): WaterStructReservoirResponseRecord {
    return WaterStructReservoirResponseRecordFromJSONTyped(json, false);
}

export function WaterStructReservoirResponseRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): WaterStructReservoirResponseRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'type': !exists(json, 'type') ? undefined : CodeListItemBasicRecordFromJSON(json['type']),
        'volume': !exists(json, 'volume') ? undefined : json['volume'],
        'volRet': !exists(json, 'volRet') ? undefined : json['volRet'],
        'volSt': !exists(json, 'volSt') ? undefined : json['volSt'],
        'volFix': !exists(json, 'volFix') ? undefined : json['volFix'],
        'altit': !exists(json, 'altit') ? undefined : json['altit'],
        'altitSt': !exists(json, 'altitSt') ? undefined : json['altitSt'],
        'altitFix': !exists(json, 'altitFix') ? undefined : json['altitFix'],
        'altitBed': !exists(json, 'altitBed') ? undefined : json['altitBed'],
        'area': !exists(json, 'area') ? undefined : json['area'],
        'len': !exists(json, 'len') ? undefined : json['len'],
        'test': !exists(json, 'test') ? undefined : json['test'],
    };
}

export function WaterStructReservoirResponseRecordToJSON(value?: WaterStructReservoirResponseRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': CodeListItemBasicRecordToJSON(value.type),
        'volume': value.volume,
        'volRet': value.volRet,
        'volSt': value.volSt,
        'volFix': value.volFix,
        'altit': value.altit,
        'altitSt': value.altitSt,
        'altitFix': value.altitFix,
        'altitBed': value.altitBed,
        'area': value.area,
        'len': value.len,
        'test': value.test,
    };
}

