/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CodeListItemBasicRecord } from './CodeListItemBasicRecord';
import {
    CodeListItemBasicRecordFromJSON,
    CodeListItemBasicRecordFromJSONTyped,
    CodeListItemBasicRecordToJSON,
} from './CodeListItemBasicRecord';

/**
 * 
 * @export
 * @interface HydroAnalysisGeologyResponseRecord
 */
export interface HydroAnalysisGeologyResponseRecord {
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisGeologyResponseRecord
     */
    id?: number;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof HydroAnalysisGeologyResponseRecord
     */
    geologyItem?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {boolean}
     * @memberof HydroAnalysisGeologyResponseRecord
     */
    prevailing?: boolean;
}

/**
 * Check if a given object implements the HydroAnalysisGeologyResponseRecord interface.
 */
export function instanceOfHydroAnalysisGeologyResponseRecord(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function HydroAnalysisGeologyResponseRecordFromJSON(json: any): HydroAnalysisGeologyResponseRecord {
    return HydroAnalysisGeologyResponseRecordFromJSONTyped(json, false);
}

export function HydroAnalysisGeologyResponseRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): HydroAnalysisGeologyResponseRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'geologyItem': !exists(json, 'geologyItem') ? undefined : CodeListItemBasicRecordFromJSON(json['geologyItem']),
        'prevailing': !exists(json, 'prevailing') ? undefined : json['prevailing'],
    };
}

export function HydroAnalysisGeologyResponseRecordToJSON(value?: HydroAnalysisGeologyResponseRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'geologyItem': CodeListItemBasicRecordToJSON(value.geologyItem),
        'prevailing': value.prevailing,
    };
}

