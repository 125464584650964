/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CodeListItemBasicRecord } from './CodeListItemBasicRecord';
import {
    CodeListItemBasicRecordFromJSON,
    CodeListItemBasicRecordFromJSONTyped,
    CodeListItemBasicRecordToJSON,
} from './CodeListItemBasicRecord';
import type { WaterEvidenceOriginAppendixRecord } from './WaterEvidenceOriginAppendixRecord';
import {
    WaterEvidenceOriginAppendixRecordFromJSON,
    WaterEvidenceOriginAppendixRecordFromJSONTyped,
    WaterEvidenceOriginAppendixRecordToJSON,
} from './WaterEvidenceOriginAppendixRecord';
import type { WaterEvidenceOriginRecord } from './WaterEvidenceOriginRecord';
import {
    WaterEvidenceOriginRecordFromJSON,
    WaterEvidenceOriginRecordFromJSONTyped,
    WaterEvidenceOriginRecordToJSON,
} from './WaterEvidenceOriginRecord';

/**
 * 
 * @export
 * @interface WaterEvidenceAppendixBasic
 */
export interface WaterEvidenceAppendixBasic {
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceAppendixBasic
     */
    type?: WaterEvidenceAppendixBasicTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof WaterEvidenceAppendixBasic
     */
    id?: number;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof WaterEvidenceAppendixBasic
     */
    status?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof WaterEvidenceAppendixBasic
     */
    documentType?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceAppendixBasic
     */
    dateFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof WaterEvidenceAppendixBasic
     */
    dateTo?: string;
    /**
     * 
     * @type {WaterEvidenceOriginRecord}
     * @memberof WaterEvidenceAppendixBasic
     */
    changedByDocument?: WaterEvidenceOriginRecord | null;
    /**
     * 
     * @type {WaterEvidenceOriginAppendixRecord}
     * @memberof WaterEvidenceAppendixBasic
     */
    originAppendix?: WaterEvidenceOriginAppendixRecord | null;
    /**
     * 
     * @type {boolean}
     * @memberof WaterEvidenceAppendixBasic
     */
    isPlaceWaitingForPairing?: boolean;
}


/**
 * @export
 */
export const WaterEvidenceAppendixBasicTypeEnum = {
    WaterEvidence: 'WATER_EVIDENCE',
    WaterStruct: 'WATER_STRUCT',
    Other: 'OTHER',
    WaterStructAppendix: 'WATER_STRUCT_APPENDIX'
} as const;
export type WaterEvidenceAppendixBasicTypeEnum = typeof WaterEvidenceAppendixBasicTypeEnum[keyof typeof WaterEvidenceAppendixBasicTypeEnum];


/**
 * Check if a given object implements the WaterEvidenceAppendixBasic interface.
 */
export function instanceOfWaterEvidenceAppendixBasic(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WaterEvidenceAppendixBasicFromJSON(json: any): WaterEvidenceAppendixBasic {
    return WaterEvidenceAppendixBasicFromJSONTyped(json, false);
}

export function WaterEvidenceAppendixBasicFromJSONTyped(json: any, ignoreDiscriminator: boolean): WaterEvidenceAppendixBasic {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': !exists(json, 'type') ? undefined : json['type'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'status': !exists(json, 'status') ? undefined : CodeListItemBasicRecordFromJSON(json['status']),
        'documentType': !exists(json, 'documentType') ? undefined : CodeListItemBasicRecordFromJSON(json['documentType']),
        'dateFrom': !exists(json, 'dateFrom') ? undefined : json['dateFrom'],
        'dateTo': !exists(json, 'dateTo') ? undefined : json['dateTo'],
        'changedByDocument': !exists(json, 'changedByDocument') ? undefined : WaterEvidenceOriginRecordFromJSON(json['changedByDocument']),
        'originAppendix': !exists(json, 'originAppendix') ? undefined : WaterEvidenceOriginAppendixRecordFromJSON(json['originAppendix']),
        'isPlaceWaitingForPairing': !exists(json, 'isPlaceWaitingForPairing') ? undefined : json['isPlaceWaitingForPairing'],
    };
}

export function WaterEvidenceAppendixBasicToJSON(value?: WaterEvidenceAppendixBasic | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'id': value.id,
        'status': CodeListItemBasicRecordToJSON(value.status),
        'documentType': CodeListItemBasicRecordToJSON(value.documentType),
        'dateFrom': value.dateFrom,
        'dateTo': value.dateTo,
        'changedByDocument': WaterEvidenceOriginRecordToJSON(value.changedByDocument),
        'originAppendix': WaterEvidenceOriginAppendixRecordToJSON(value.originAppendix),
        'isPlaceWaitingForPairing': value.isPlaceWaitingForPairing,
    };
}

