/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WaterStructTreatmentRequestRecord
 */
export interface WaterStructTreatmentRequestRecord {
    /**
     * 
     * @type {number}
     * @memberof WaterStructTreatmentRequestRecord
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof WaterStructTreatmentRequestRecord
     */
    avCap?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructTreatmentRequestRecord
     */
    maxCap?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructTreatmentRequestRecord
     */
    waterOriginCode?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructTreatmentRequestRecord
     */
    waterCatCode?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructTreatmentRequestRecord
     */
    waterModifCode?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructTreatmentRequestRecord
     */
    sludgeHandCode?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructTreatmentRequestRecord
     */
    sludgeStabCode?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructTreatmentRequestRecord
     */
    sludgeDrainCode?: number | null;
}

/**
 * Check if a given object implements the WaterStructTreatmentRequestRecord interface.
 */
export function instanceOfWaterStructTreatmentRequestRecord(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WaterStructTreatmentRequestRecordFromJSON(json: any): WaterStructTreatmentRequestRecord {
    return WaterStructTreatmentRequestRecordFromJSONTyped(json, false);
}

export function WaterStructTreatmentRequestRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): WaterStructTreatmentRequestRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'avCap': !exists(json, 'avCap') ? undefined : json['avCap'],
        'maxCap': !exists(json, 'maxCap') ? undefined : json['maxCap'],
        'waterOriginCode': !exists(json, 'waterOriginCode') ? undefined : json['waterOriginCode'],
        'waterCatCode': !exists(json, 'waterCatCode') ? undefined : json['waterCatCode'],
        'waterModifCode': !exists(json, 'waterModifCode') ? undefined : json['waterModifCode'],
        'sludgeHandCode': !exists(json, 'sludgeHandCode') ? undefined : json['sludgeHandCode'],
        'sludgeStabCode': !exists(json, 'sludgeStabCode') ? undefined : json['sludgeStabCode'],
        'sludgeDrainCode': !exists(json, 'sludgeDrainCode') ? undefined : json['sludgeDrainCode'],
    };
}

export function WaterStructTreatmentRequestRecordToJSON(value?: WaterStructTreatmentRequestRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'avCap': value.avCap,
        'maxCap': value.maxCap,
        'waterOriginCode': value.waterOriginCode,
        'waterCatCode': value.waterCatCode,
        'waterModifCode': value.waterModifCode,
        'sludgeHandCode': value.sludgeHandCode,
        'sludgeStabCode': value.sludgeStabCode,
        'sludgeDrainCode': value.sludgeDrainCode,
    };
}

