/* tslint:disable */
/* eslint-disable */
/**
 * Data import
 * Modul for importing data to Data Registry.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: schvarcz@bb.microcomp.sk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ImportSummaryRowResponse,
} from '../models/index';
import {
    ImportSummaryRowResponseFromJSON,
    ImportSummaryRowResponseToJSON,
} from '../models/index';

export interface SummaryApiCreateSummaryRequest {
    documentId: string;
}

export interface SummaryApiGetSummaryRequest {
    documentId: string;
}

/**
 * 
 */
export class SummaryApi extends runtime.BaseAPI {

    /**
     * Test service for create summary
     */
    async createSummaryRaw(requestParameters: SummaryApiCreateSummaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling createSummary.');
        }

        const queryParameters: any = {};

        if (requestParameters.documentId !== undefined) {
            queryParameters['documentId'] = requestParameters.documentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/summary/create-summary`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Test service for create summary
     */
    async createSummary(documentId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createSummaryRaw({ documentId: documentId }, initOverrides);
    }

    /**
     * Get validation and transformation error report for document
     */
    async getSummaryRaw(requestParameters: SummaryApiGetSummaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ImportSummaryRowResponse>>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling getSummary.');
        }

        const queryParameters: any = {};

        if (requestParameters.documentId !== undefined) {
            queryParameters['documentId'] = requestParameters.documentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/summary/summary`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ImportSummaryRowResponseFromJSON));
    }

    /**
     * Get validation and transformation error report for document
     */
    async getSummary(documentId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ImportSummaryRowResponse>> {
        const response = await this.getSummaryRaw({ documentId: documentId }, initOverrides);
        return await response.value();
    }

}
