/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CodeListItemBasicRecord
 */
export interface CodeListItemBasicRecord {
    /**
     * 
     * @type {number}
     * @memberof CodeListItemBasicRecord
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof CodeListItemBasicRecord
     */
    codelistCode?: number;
    /**
     * 
     * @type {number}
     * @memberof CodeListItemBasicRecord
     */
    code?: number;
    /**
     * 
     * @type {string}
     * @memberof CodeListItemBasicRecord
     */
    name?: string;
}

/**
 * Check if a given object implements the CodeListItemBasicRecord interface.
 */
export function instanceOfCodeListItemBasicRecord(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CodeListItemBasicRecordFromJSON(json: any): CodeListItemBasicRecord {
    return CodeListItemBasicRecordFromJSONTyped(json, false);
}

export function CodeListItemBasicRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): CodeListItemBasicRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'codelistCode': !exists(json, 'codelistCode') ? undefined : json['codelistCode'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'name': !exists(json, 'name') ? undefined : json['name'],
    };
}

export function CodeListItemBasicRecordToJSON(value?: CodeListItemBasicRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'codelistCode': value.codelistCode,
        'code': value.code,
        'name': value.name,
    };
}

