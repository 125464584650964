/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NewPlaceRequestRecord
 */
export interface NewPlaceRequestRecord {
    /**
     * 
     * @type {number}
     * @memberof NewPlaceRequestRecord
     */
    id?: number | null;
    /**
     * 
     * @type {string}
     * @memberof NewPlaceRequestRecord
     */
    code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NewPlaceRequestRecord
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NewPlaceRequestRecord
     */
    basinCode?: number | null;
    /**
     * 
     * @type {number}
     * @memberof NewPlaceRequestRecord
     */
    riverKm?: number | null;
    /**
     * 
     * @type {number}
     * @memberof NewPlaceRequestRecord
     */
    positionCode?: number | null;
    /**
     * 
     * @type {string}
     * @memberof NewPlaceRequestRecord
     */
    note?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NewPlaceRequestRecord
     */
    monPointTypeCode?: number | null;
}

/**
 * Check if a given object implements the NewPlaceRequestRecord interface.
 */
export function instanceOfNewPlaceRequestRecord(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function NewPlaceRequestRecordFromJSON(json: any): NewPlaceRequestRecord {
    return NewPlaceRequestRecordFromJSONTyped(json, false);
}

export function NewPlaceRequestRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewPlaceRequestRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'basinCode': !exists(json, 'basinCode') ? undefined : json['basinCode'],
        'riverKm': !exists(json, 'riverKm') ? undefined : json['riverKm'],
        'positionCode': !exists(json, 'positionCode') ? undefined : json['positionCode'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'monPointTypeCode': !exists(json, 'monPointTypeCode') ? undefined : json['monPointTypeCode'],
    };
}

export function NewPlaceRequestRecordToJSON(value?: NewPlaceRequestRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'code': value.code,
        'name': value.name,
        'basinCode': value.basinCode,
        'riverKm': value.riverKm,
        'positionCode': value.positionCode,
        'note': value.note,
        'monPointTypeCode': value.monPointTypeCode,
    };
}

