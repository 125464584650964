/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface HydroAnalysisLadUseRequestRecord
 */
export interface HydroAnalysisLadUseRequestRecord {
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisLadUseRequestRecord
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisLadUseRequestRecord
     */
    landUseItemCode?: number;
    /**
     * 
     * @type {boolean}
     * @memberof HydroAnalysisLadUseRequestRecord
     */
    prevailing?: boolean;
}

/**
 * Check if a given object implements the HydroAnalysisLadUseRequestRecord interface.
 */
export function instanceOfHydroAnalysisLadUseRequestRecord(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function HydroAnalysisLadUseRequestRecordFromJSON(json: any): HydroAnalysisLadUseRequestRecord {
    return HydroAnalysisLadUseRequestRecordFromJSONTyped(json, false);
}

export function HydroAnalysisLadUseRequestRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): HydroAnalysisLadUseRequestRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'landUseItemCode': !exists(json, 'landUseItemCode') ? undefined : json['landUseItemCode'],
        'prevailing': !exists(json, 'prevailing') ? undefined : json['prevailing'],
    };
}

export function HydroAnalysisLadUseRequestRecordToJSON(value?: HydroAnalysisLadUseRequestRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'landUseItemCode': value.landUseItemCode,
        'prevailing': value.prevailing,
    };
}

