/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiExceptionRecord,
  FileRecord,
  HydroAnalysisPrefillRequest,
  HydroAnalysisScoreCalculationRequest,
  HydroAnalysisScoreCalculationResult,
  HydroAnalysisScoreResponseRecord,
  HydroMorphologyProtocolRequestRecord,
  HydroMorphologyProtocolResponseRecord,
  PageHydroMorphologyProtocolResponseRecord,
} from '../models/index';
import {
    ApiExceptionRecordFromJSON,
    ApiExceptionRecordToJSON,
    FileRecordFromJSON,
    FileRecordToJSON,
    HydroAnalysisPrefillRequestFromJSON,
    HydroAnalysisPrefillRequestToJSON,
    HydroAnalysisScoreCalculationRequestFromJSON,
    HydroAnalysisScoreCalculationRequestToJSON,
    HydroAnalysisScoreCalculationResultFromJSON,
    HydroAnalysisScoreCalculationResultToJSON,
    HydroAnalysisScoreResponseRecordFromJSON,
    HydroAnalysisScoreResponseRecordToJSON,
    HydroMorphologyProtocolRequestRecordFromJSON,
    HydroMorphologyProtocolRequestRecordToJSON,
    HydroMorphologyProtocolResponseRecordFromJSON,
    HydroMorphologyProtocolResponseRecordToJSON,
    PageHydroMorphologyProtocolResponseRecordFromJSON,
    PageHydroMorphologyProtocolResponseRecordToJSON,
} from '../models/index';

export interface HydroMorphologyAPIApiCalculatedProtocolScoreRequest {
    hydroAnalysisScoreCalculationRequest: HydroAnalysisScoreCalculationRequest;
}

export interface HydroMorphologyAPIApiCancelProtocolRequest {
    protocolId: number;
}

export interface HydroMorphologyAPIApiCreateProtocolRequest {
    hydroMorphologyProtocolRequestRecord: HydroMorphologyProtocolRequestRecord;
}

export interface HydroMorphologyAPIApiDeleteProtocolRequest {
    protocolId: number;
}

export interface HydroMorphologyAPIApiDeleteProtocolFileAttachmentRequest {
    protocolId: number;
    hydroAnalysisFileId: number;
    fileId: number;
}

export interface HydroMorphologyAPIApiDeleteProtocolPhotoAttachmentRequest {
    protocolId: number;
    photoId: number;
}

export interface HydroMorphologyAPIApiExistsByMonPointCodeRequest {
    monPointCode: string;
}

export interface HydroMorphologyAPIApiExportProtocolRequest {
    protocolId: number;
}

export interface HydroMorphologyAPIApiExportProtocolScoreRequest {
    protocolId: number;
}

export interface HydroMorphologyAPIApiGenerateExcelFile2Request {
    requestBody: { [key: string]: string; };
    page?: number;
    size?: number;
    sort?: string;
}

export interface HydroMorphologyAPIApiGetPhotoAttachmentRequest {
    protocolId: number;
    photoId: number;
}

export interface HydroMorphologyAPIApiGetProtocolRequest {
    protocolId: number;
}

export interface HydroMorphologyAPIApiGetProtocolFileAttachmentRequest {
    protocolId: number;
    hydroAnalysisFileId: number;
    fileId: number;
}

export interface HydroMorphologyAPIApiGetProtocolPhotosRequest {
    protocolId: number;
}

export interface HydroMorphologyAPIApiGetScoreByProtocolIdRequest {
    protocolId: number;
}

export interface HydroMorphologyAPIApiPrefillProtocolRequest {
    hydroAnalysisPrefillRequest: HydroAnalysisPrefillRequest;
}

export interface HydroMorphologyAPIApiSearchProtocolsRequest {
    requestBody: { [key: string]: string; };
    page?: number;
    size?: number;
    sort?: string;
}

export interface HydroMorphologyAPIApiUpdateProtocolRequest {
    protocolId: number;
    hydroMorphologyProtocolRequestRecord: HydroMorphologyProtocolRequestRecord;
}

export interface HydroMorphologyAPIApiUploadProtocolFileAttachmentRequest {
    protocolId: number;
    hydroAnalysisFileId: number;
    file: Blob;
    newFileName?: string;
}

export interface HydroMorphologyAPIApiUploadProtocolPhotoAttachmentRequest {
    protocolId: number;
    file: Blob;
    newFileName?: string;
}

/**
 * 
 */
export class HydroMorphologyAPIApi extends runtime.BaseAPI {

    /**
     * Returns calculated parameters for protocol score
     * Calculate parameters for protocol score
     */
    async calculatedProtocolScoreRaw(requestParameters: HydroMorphologyAPIApiCalculatedProtocolScoreRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HydroAnalysisScoreCalculationResult>> {
        if (requestParameters.hydroAnalysisScoreCalculationRequest === null || requestParameters.hydroAnalysisScoreCalculationRequest === undefined) {
            throw new runtime.RequiredError('hydroAnalysisScoreCalculationRequest','Required parameter requestParameters.hydroAnalysisScoreCalculationRequest was null or undefined when calling calculatedProtocolScore.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/hydromorphology/v1/score/calculate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HydroAnalysisScoreCalculationRequestToJSON(requestParameters.hydroAnalysisScoreCalculationRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HydroAnalysisScoreCalculationResultFromJSON(jsonValue));
    }

    /**
     * Returns calculated parameters for protocol score
     * Calculate parameters for protocol score
     */
    async calculatedProtocolScore(hydroAnalysisScoreCalculationRequest: HydroAnalysisScoreCalculationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HydroAnalysisScoreCalculationResult> {
        const response = await this.calculatedProtocolScoreRaw({ hydroAnalysisScoreCalculationRequest: hydroAnalysisScoreCalculationRequest }, initOverrides);
        return await response.value();
    }

    /**
     * Returns HTTPS status code 200 if the protocol was canceled successfully.
     * cancel hydro morphology protocol
     */
    async cancelProtocolRaw(requestParameters: HydroMorphologyAPIApiCancelProtocolRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HydroMorphologyProtocolResponseRecord>> {
        if (requestParameters.protocolId === null || requestParameters.protocolId === undefined) {
            throw new runtime.RequiredError('protocolId','Required parameter requestParameters.protocolId was null or undefined when calling cancelProtocol.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/hydromorphology/v1/protocol/{protocolId}/cancel`.replace(`{${"protocolId"}}`, encodeURIComponent(String(requestParameters.protocolId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HydroMorphologyProtocolResponseRecordFromJSON(jsonValue));
    }

    /**
     * Returns HTTPS status code 200 if the protocol was canceled successfully.
     * cancel hydro morphology protocol
     */
    async cancelProtocol(protocolId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HydroMorphologyProtocolResponseRecord> {
        const response = await this.cancelProtocolRaw({ protocolId: protocolId }, initOverrides);
        return await response.value();
    }

    /**
     * Returns HTTPS status code 200 if the protocol was created successfully.
     * create hydro morphology protocol
     */
    async createProtocolRaw(requestParameters: HydroMorphologyAPIApiCreateProtocolRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HydroMorphologyProtocolResponseRecord>> {
        if (requestParameters.hydroMorphologyProtocolRequestRecord === null || requestParameters.hydroMorphologyProtocolRequestRecord === undefined) {
            throw new runtime.RequiredError('hydroMorphologyProtocolRequestRecord','Required parameter requestParameters.hydroMorphologyProtocolRequestRecord was null or undefined when calling createProtocol.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/hydromorphology/v1/protocol/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HydroMorphologyProtocolRequestRecordToJSON(requestParameters.hydroMorphologyProtocolRequestRecord),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HydroMorphologyProtocolResponseRecordFromJSON(jsonValue));
    }

    /**
     * Returns HTTPS status code 200 if the protocol was created successfully.
     * create hydro morphology protocol
     */
    async createProtocol(hydroMorphologyProtocolRequestRecord: HydroMorphologyProtocolRequestRecord, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HydroMorphologyProtocolResponseRecord> {
        const response = await this.createProtocolRaw({ hydroMorphologyProtocolRequestRecord: hydroMorphologyProtocolRequestRecord }, initOverrides);
        return await response.value();
    }

    /**
     * Delete the protocol by provided ID.
     * Delete protocol
     */
    async deleteProtocolRaw(requestParameters: HydroMorphologyAPIApiDeleteProtocolRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.protocolId === null || requestParameters.protocolId === undefined) {
            throw new runtime.RequiredError('protocolId','Required parameter requestParameters.protocolId was null or undefined when calling deleteProtocol.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/hydromorphology/v1/protocol/{protocolId}`.replace(`{${"protocolId"}}`, encodeURIComponent(String(requestParameters.protocolId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Delete the protocol by provided ID.
     * Delete protocol
     */
    async deleteProtocol(protocolId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.deleteProtocolRaw({ protocolId: protocolId }, initOverrides);
        return await response.value();
    }

    /**
     * Returns HTTP OK if the protocol photo attachment was deleted successfully.
     * Delete protocol photo attachment
     */
    async deleteProtocolFileAttachmentRaw(requestParameters: HydroMorphologyAPIApiDeleteProtocolFileAttachmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.protocolId === null || requestParameters.protocolId === undefined) {
            throw new runtime.RequiredError('protocolId','Required parameter requestParameters.protocolId was null or undefined when calling deleteProtocolFileAttachment.');
        }

        if (requestParameters.hydroAnalysisFileId === null || requestParameters.hydroAnalysisFileId === undefined) {
            throw new runtime.RequiredError('hydroAnalysisFileId','Required parameter requestParameters.hydroAnalysisFileId was null or undefined when calling deleteProtocolFileAttachment.');
        }

        if (requestParameters.fileId === null || requestParameters.fileId === undefined) {
            throw new runtime.RequiredError('fileId','Required parameter requestParameters.fileId was null or undefined when calling deleteProtocolFileAttachment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/hydromorphology/v1/protocol/{protocolId}/hydro-analysis-file/{hydroAnalysisFileId}/file/{fileId}`.replace(`{${"protocolId"}}`, encodeURIComponent(String(requestParameters.protocolId))).replace(`{${"hydroAnalysisFileId"}}`, encodeURIComponent(String(requestParameters.hydroAnalysisFileId))).replace(`{${"fileId"}}`, encodeURIComponent(String(requestParameters.fileId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Returns HTTP OK if the protocol photo attachment was deleted successfully.
     * Delete protocol photo attachment
     */
    async deleteProtocolFileAttachment(protocolId: number, hydroAnalysisFileId: number, fileId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.deleteProtocolFileAttachmentRaw({ protocolId: protocolId, hydroAnalysisFileId: hydroAnalysisFileId, fileId: fileId }, initOverrides);
        return await response.value();
    }

    /**
     * Returns HTTP OK if the protocol photo attachment was deleted successfully.
     * Delete protocol photo attachment
     */
    async deleteProtocolPhotoAttachmentRaw(requestParameters: HydroMorphologyAPIApiDeleteProtocolPhotoAttachmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.protocolId === null || requestParameters.protocolId === undefined) {
            throw new runtime.RequiredError('protocolId','Required parameter requestParameters.protocolId was null or undefined when calling deleteProtocolPhotoAttachment.');
        }

        if (requestParameters.photoId === null || requestParameters.photoId === undefined) {
            throw new runtime.RequiredError('photoId','Required parameter requestParameters.photoId was null or undefined when calling deleteProtocolPhotoAttachment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/hydromorphology/v1/protocol/{protocolId}/photo/{photoId}`.replace(`{${"protocolId"}}`, encodeURIComponent(String(requestParameters.protocolId))).replace(`{${"photoId"}}`, encodeURIComponent(String(requestParameters.photoId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Returns HTTP OK if the protocol photo attachment was deleted successfully.
     * Delete protocol photo attachment
     */
    async deleteProtocolPhotoAttachment(protocolId: number, photoId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.deleteProtocolPhotoAttachmentRaw({ protocolId: protocolId, photoId: photoId }, initOverrides);
        return await response.value();
    }

    /**
     * Returns true if protocol exists by mon point code.
     * Check if protocol exists by mon point code
     */
    async existsByMonPointCodeRaw(requestParameters: HydroMorphologyAPIApiExistsByMonPointCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.monPointCode === null || requestParameters.monPointCode === undefined) {
            throw new runtime.RequiredError('monPointCode','Required parameter requestParameters.monPointCode was null or undefined when calling existsByMonPointCode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/hydromorphology/v1/protocol/exists-by-mon-point-code/{monPointCode}`.replace(`{${"monPointCode"}}`, encodeURIComponent(String(requestParameters.monPointCode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Returns true if protocol exists by mon point code.
     * Check if protocol exists by mon point code
     */
    async existsByMonPointCode(monPointCode: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.existsByMonPointCodeRaw({ monPointCode: monPointCode }, initOverrides);
        return await response.value();
    }

    /**
     */
    async exportProtocolRaw(requestParameters: HydroMorphologyAPIApiExportProtocolRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.protocolId === null || requestParameters.protocolId === undefined) {
            throw new runtime.RequiredError('protocolId','Required parameter requestParameters.protocolId was null or undefined when calling exportProtocol.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/hydromorphology/v1/protocol/{protocolId}/export`.replace(`{${"protocolId"}}`, encodeURIComponent(String(requestParameters.protocolId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async exportProtocol(protocolId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.exportProtocolRaw({ protocolId: protocolId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async exportProtocolScoreRaw(requestParameters: HydroMorphologyAPIApiExportProtocolScoreRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.protocolId === null || requestParameters.protocolId === undefined) {
            throw new runtime.RequiredError('protocolId','Required parameter requestParameters.protocolId was null or undefined when calling exportProtocolScore.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/hydromorphology/v1/protocol/{protocolId}/score/export`.replace(`{${"protocolId"}}`, encodeURIComponent(String(requestParameters.protocolId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async exportProtocolScore(protocolId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.exportProtocolScoreRaw({ protocolId: protocolId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async generateExcelFile2Raw(requestParameters: HydroMorphologyAPIApiGenerateExcelFile2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling generateExcelFile2.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/hydromorphology/v1/protocol/export`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async generateExcelFile2(requestBody: { [key: string]: string; }, page?: number, size?: number, sort?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.generateExcelFile2Raw({ requestBody: requestBody, page: page, size: size, sort: sort }, initOverrides);
        return await response.value();
    }

    /**
     * Returns the photo attachment by provided IDs.
     * Get photo attachment by id
     */
    async getPhotoAttachmentRaw(requestParameters: HydroMorphologyAPIApiGetPhotoAttachmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FileRecord>> {
        if (requestParameters.protocolId === null || requestParameters.protocolId === undefined) {
            throw new runtime.RequiredError('protocolId','Required parameter requestParameters.protocolId was null or undefined when calling getPhotoAttachment.');
        }

        if (requestParameters.photoId === null || requestParameters.photoId === undefined) {
            throw new runtime.RequiredError('photoId','Required parameter requestParameters.photoId was null or undefined when calling getPhotoAttachment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/hydromorphology/v1/protocol/{protocolId}/photo/{photoId}`.replace(`{${"protocolId"}}`, encodeURIComponent(String(requestParameters.protocolId))).replace(`{${"photoId"}}`, encodeURIComponent(String(requestParameters.photoId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FileRecordFromJSON(jsonValue));
    }

    /**
     * Returns the photo attachment by provided IDs.
     * Get photo attachment by id
     */
    async getPhotoAttachment(protocolId: number, photoId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FileRecord> {
        const response = await this.getPhotoAttachmentRaw({ protocolId: protocolId, photoId: photoId }, initOverrides);
        return await response.value();
    }

    /**
     * Returns the protocol by provided ID.
     * Get protocol
     */
    async getProtocolRaw(requestParameters: HydroMorphologyAPIApiGetProtocolRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HydroMorphologyProtocolResponseRecord>> {
        if (requestParameters.protocolId === null || requestParameters.protocolId === undefined) {
            throw new runtime.RequiredError('protocolId','Required parameter requestParameters.protocolId was null or undefined when calling getProtocol.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/hydromorphology/v1/protocol/{protocolId}`.replace(`{${"protocolId"}}`, encodeURIComponent(String(requestParameters.protocolId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HydroMorphologyProtocolResponseRecordFromJSON(jsonValue));
    }

    /**
     * Returns the protocol by provided ID.
     * Get protocol
     */
    async getProtocol(protocolId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HydroMorphologyProtocolResponseRecord> {
        const response = await this.getProtocolRaw({ protocolId: protocolId }, initOverrides);
        return await response.value();
    }

    /**
     * Returns the protocol photo attachment by provided IDs.
     * Get protocol photo attachment
     */
    async getProtocolFileAttachmentRaw(requestParameters: HydroMorphologyAPIApiGetProtocolFileAttachmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FileRecord>> {
        if (requestParameters.protocolId === null || requestParameters.protocolId === undefined) {
            throw new runtime.RequiredError('protocolId','Required parameter requestParameters.protocolId was null or undefined when calling getProtocolFileAttachment.');
        }

        if (requestParameters.hydroAnalysisFileId === null || requestParameters.hydroAnalysisFileId === undefined) {
            throw new runtime.RequiredError('hydroAnalysisFileId','Required parameter requestParameters.hydroAnalysisFileId was null or undefined when calling getProtocolFileAttachment.');
        }

        if (requestParameters.fileId === null || requestParameters.fileId === undefined) {
            throw new runtime.RequiredError('fileId','Required parameter requestParameters.fileId was null or undefined when calling getProtocolFileAttachment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/hydromorphology/v1/protocol/{protocolId}/hydro-analysis-file/{hydroAnalysisFileId}/file/{fileId}`.replace(`{${"protocolId"}}`, encodeURIComponent(String(requestParameters.protocolId))).replace(`{${"hydroAnalysisFileId"}}`, encodeURIComponent(String(requestParameters.hydroAnalysisFileId))).replace(`{${"fileId"}}`, encodeURIComponent(String(requestParameters.fileId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FileRecordFromJSON(jsonValue));
    }

    /**
     * Returns the protocol photo attachment by provided IDs.
     * Get protocol photo attachment
     */
    async getProtocolFileAttachment(protocolId: number, hydroAnalysisFileId: number, fileId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FileRecord> {
        const response = await this.getProtocolFileAttachmentRaw({ protocolId: protocolId, hydroAnalysisFileId: hydroAnalysisFileId, fileId: fileId }, initOverrides);
        return await response.value();
    }

    /**
     * Returns all photos for protocol with provided ID.
     * Get all photos related to protocol
     */
    async getProtocolPhotosRaw(requestParameters: HydroMorphologyAPIApiGetProtocolPhotosRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FileRecord>>> {
        if (requestParameters.protocolId === null || requestParameters.protocolId === undefined) {
            throw new runtime.RequiredError('protocolId','Required parameter requestParameters.protocolId was null or undefined when calling getProtocolPhotos.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/hydromorphology/v1/protocol/{protocolId}/photos`.replace(`{${"protocolId"}}`, encodeURIComponent(String(requestParameters.protocolId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FileRecordFromJSON));
    }

    /**
     * Returns all photos for protocol with provided ID.
     * Get all photos related to protocol
     */
    async getProtocolPhotos(protocolId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FileRecord>> {
        const response = await this.getProtocolPhotosRaw({ protocolId: protocolId }, initOverrides);
        return await response.value();
    }

    /**
     * Returns the score protocol by provided protocol ID.
     * Get protocol score
     */
    async getScoreByProtocolIdRaw(requestParameters: HydroMorphologyAPIApiGetScoreByProtocolIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HydroAnalysisScoreResponseRecord>> {
        if (requestParameters.protocolId === null || requestParameters.protocolId === undefined) {
            throw new runtime.RequiredError('protocolId','Required parameter requestParameters.protocolId was null or undefined when calling getScoreByProtocolId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/hydromorphology/v1/protocol/{protocolId}/score`.replace(`{${"protocolId"}}`, encodeURIComponent(String(requestParameters.protocolId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HydroAnalysisScoreResponseRecordFromJSON(jsonValue));
    }

    /**
     * Returns the score protocol by provided protocol ID.
     * Get protocol score
     */
    async getScoreByProtocolId(protocolId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HydroAnalysisScoreResponseRecord> {
        const response = await this.getScoreByProtocolIdRaw({ protocolId: protocolId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async initHMFromDRRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/hydromorphology/v1/protocol/init`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async initHMFromDR(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.initHMFromDRRaw(initOverrides);
    }

    /**
     * Returns prefill protocol.
     * Prefill protocol
     */
    async prefillProtocolRaw(requestParameters: HydroMorphologyAPIApiPrefillProtocolRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HydroMorphologyProtocolResponseRecord>> {
        if (requestParameters.hydroAnalysisPrefillRequest === null || requestParameters.hydroAnalysisPrefillRequest === undefined) {
            throw new runtime.RequiredError('hydroAnalysisPrefillRequest','Required parameter requestParameters.hydroAnalysisPrefillRequest was null or undefined when calling prefillProtocol.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/hydromorphology/v1/protocol/prefill`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HydroAnalysisPrefillRequestToJSON(requestParameters.hydroAnalysisPrefillRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HydroMorphologyProtocolResponseRecordFromJSON(jsonValue));
    }

    /**
     * Returns prefill protocol.
     * Prefill protocol
     */
    async prefillProtocol(hydroAnalysisPrefillRequest: HydroAnalysisPrefillRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HydroMorphologyProtocolResponseRecord> {
        const response = await this.prefillProtocolRaw({ hydroAnalysisPrefillRequest: hydroAnalysisPrefillRequest }, initOverrides);
        return await response.value();
    }

    /**
     * Returns a page of protocols matching the search criteria.
     * Search protocols
     */
    async searchProtocolsRaw(requestParameters: HydroMorphologyAPIApiSearchProtocolsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageHydroMorphologyProtocolResponseRecord>> {
        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling searchProtocols.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/hydromorphology/v1/protocol/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageHydroMorphologyProtocolResponseRecordFromJSON(jsonValue));
    }

    /**
     * Returns a page of protocols matching the search criteria.
     * Search protocols
     */
    async searchProtocols(requestBody: { [key: string]: string; }, page?: number, size?: number, sort?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageHydroMorphologyProtocolResponseRecord> {
        const response = await this.searchProtocolsRaw({ requestBody: requestBody, page: page, size: size, sort: sort }, initOverrides);
        return await response.value();
    }

    /**
     * Returns HTTPS status code 200 and updated protocol if protocol was updated successfully.
     * update hydro morphology protocol
     */
    async updateProtocolRaw(requestParameters: HydroMorphologyAPIApiUpdateProtocolRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HydroMorphologyProtocolResponseRecord>> {
        if (requestParameters.protocolId === null || requestParameters.protocolId === undefined) {
            throw new runtime.RequiredError('protocolId','Required parameter requestParameters.protocolId was null or undefined when calling updateProtocol.');
        }

        if (requestParameters.hydroMorphologyProtocolRequestRecord === null || requestParameters.hydroMorphologyProtocolRequestRecord === undefined) {
            throw new runtime.RequiredError('hydroMorphologyProtocolRequestRecord','Required parameter requestParameters.hydroMorphologyProtocolRequestRecord was null or undefined when calling updateProtocol.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/hydromorphology/v1/protocol/{protocolId}/update`.replace(`{${"protocolId"}}`, encodeURIComponent(String(requestParameters.protocolId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HydroMorphologyProtocolRequestRecordToJSON(requestParameters.hydroMorphologyProtocolRequestRecord),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HydroMorphologyProtocolResponseRecordFromJSON(jsonValue));
    }

    /**
     * Returns HTTPS status code 200 and updated protocol if protocol was updated successfully.
     * update hydro morphology protocol
     */
    async updateProtocol(protocolId: number, hydroMorphologyProtocolRequestRecord: HydroMorphologyProtocolRequestRecord, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HydroMorphologyProtocolResponseRecord> {
        const response = await this.updateProtocolRaw({ protocolId: protocolId, hydroMorphologyProtocolRequestRecord: hydroMorphologyProtocolRequestRecord }, initOverrides);
        return await response.value();
    }

    /**
     * Returns the uploaded protocol photo attachment ID
     * Upload protocol photo attachment
     */
    async uploadProtocolFileAttachmentRaw(requestParameters: HydroMorphologyAPIApiUploadProtocolFileAttachmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.protocolId === null || requestParameters.protocolId === undefined) {
            throw new runtime.RequiredError('protocolId','Required parameter requestParameters.protocolId was null or undefined when calling uploadProtocolFileAttachment.');
        }

        if (requestParameters.hydroAnalysisFileId === null || requestParameters.hydroAnalysisFileId === undefined) {
            throw new runtime.RequiredError('hydroAnalysisFileId','Required parameter requestParameters.hydroAnalysisFileId was null or undefined when calling uploadProtocolFileAttachment.');
        }

        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling uploadProtocolFileAttachment.');
        }

        const queryParameters: any = {};

        if (requestParameters.newFileName !== undefined) {
            queryParameters['newFileName'] = requestParameters.newFileName;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/api/integration/hydromorphology/v1/protocol/{protocolId}/hydro-analysis-file/{hydroAnalysisFileId}/file`.replace(`{${"protocolId"}}`, encodeURIComponent(String(requestParameters.protocolId))).replace(`{${"hydroAnalysisFileId"}}`, encodeURIComponent(String(requestParameters.hydroAnalysisFileId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Returns the uploaded protocol photo attachment ID
     * Upload protocol photo attachment
     */
    async uploadProtocolFileAttachment(protocolId: number, hydroAnalysisFileId: number, file: Blob, newFileName?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.uploadProtocolFileAttachmentRaw({ protocolId: protocolId, hydroAnalysisFileId: hydroAnalysisFileId, file: file, newFileName: newFileName }, initOverrides);
        return await response.value();
    }

    /**
     * Returns the uploaded protocol photo attachment ID
     * Upload protocol photo attachment
     */
    async uploadProtocolPhotoAttachmentRaw(requestParameters: HydroMorphologyAPIApiUploadProtocolPhotoAttachmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.protocolId === null || requestParameters.protocolId === undefined) {
            throw new runtime.RequiredError('protocolId','Required parameter requestParameters.protocolId was null or undefined when calling uploadProtocolPhotoAttachment.');
        }

        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling uploadProtocolPhotoAttachment.');
        }

        const queryParameters: any = {};

        if (requestParameters.newFileName !== undefined) {
            queryParameters['newFileName'] = requestParameters.newFileName;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/api/integration/hydromorphology/v1/protocol/{protocolId}/photo`.replace(`{${"protocolId"}}`, encodeURIComponent(String(requestParameters.protocolId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Returns the uploaded protocol photo attachment ID
     * Upload protocol photo attachment
     */
    async uploadProtocolPhotoAttachment(protocolId: number, file: Blob, newFileName?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.uploadProtocolPhotoAttachmentRaw({ protocolId: protocolId, file: file, newFileName: newFileName }, initOverrides);
        return await response.value();
    }

}
