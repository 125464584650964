/* tslint:disable */
/* eslint-disable */
/**
 * Catalog API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.31.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ImportTemplateResponse
 */
export interface ImportTemplateResponse {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ImportTemplateResponse
     */
    importTemplateEntries: { [key: string]: string; };
}

/**
 * Check if a given object implements the ImportTemplateResponse interface.
 */
export function instanceOfImportTemplateResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "importTemplateEntries" in value;

    return isInstance;
}

export function ImportTemplateResponseFromJSON(json: any): ImportTemplateResponse {
    return ImportTemplateResponseFromJSONTyped(json, false);
}

export function ImportTemplateResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImportTemplateResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'importTemplateEntries': json['importTemplateEntries'],
    };
}

export function ImportTemplateResponseToJSON(value?: ImportTemplateResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'importTemplateEntries': value.importTemplateEntries,
    };
}

