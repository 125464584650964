/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CodeListItemBasicRecord } from './CodeListItemBasicRecord';
import {
    CodeListItemBasicRecordFromJSON,
    CodeListItemBasicRecordFromJSONTyped,
    CodeListItemBasicRecordToJSON,
} from './CodeListItemBasicRecord';

/**
 * 
 * @export
 * @interface NewPlaceResponseRecord
 */
export interface NewPlaceResponseRecord {
    /**
     * 
     * @type {number}
     * @memberof NewPlaceResponseRecord
     */
    id?: number | null;
    /**
     * 
     * @type {string}
     * @memberof NewPlaceResponseRecord
     */
    code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NewPlaceResponseRecord
     */
    name?: string | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof NewPlaceResponseRecord
     */
    basin?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {number}
     * @memberof NewPlaceResponseRecord
     */
    riverKm?: number | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof NewPlaceResponseRecord
     */
    position?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {string}
     * @memberof NewPlaceResponseRecord
     */
    note?: string | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof NewPlaceResponseRecord
     */
    monPointType?: CodeListItemBasicRecord | null;
}

/**
 * Check if a given object implements the NewPlaceResponseRecord interface.
 */
export function instanceOfNewPlaceResponseRecord(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function NewPlaceResponseRecordFromJSON(json: any): NewPlaceResponseRecord {
    return NewPlaceResponseRecordFromJSONTyped(json, false);
}

export function NewPlaceResponseRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewPlaceResponseRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'basin': !exists(json, 'basin') ? undefined : CodeListItemBasicRecordFromJSON(json['basin']),
        'riverKm': !exists(json, 'riverKm') ? undefined : json['riverKm'],
        'position': !exists(json, 'position') ? undefined : CodeListItemBasicRecordFromJSON(json['position']),
        'note': !exists(json, 'note') ? undefined : json['note'],
        'monPointType': !exists(json, 'monPointType') ? undefined : CodeListItemBasicRecordFromJSON(json['monPointType']),
    };
}

export function NewPlaceResponseRecordToJSON(value?: NewPlaceResponseRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'code': value.code,
        'name': value.name,
        'basin': CodeListItemBasicRecordToJSON(value.basin),
        'riverKm': value.riverKm,
        'position': CodeListItemBasicRecordToJSON(value.position),
        'note': value.note,
        'monPointType': CodeListItemBasicRecordToJSON(value.monPointType),
    };
}

