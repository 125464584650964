/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface HydroAnalysisScoreCalculationResult
 */
export interface HydroAnalysisScoreCalculationResult {
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisScoreCalculationResult
     */
    channelScore?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisScoreCalculationResult
     */
    bedElementScore?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisScoreCalculationResult
     */
    substrateScore?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisScoreCalculationResult
     */
    varInWidthScore?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisScoreCalculationResult
     */
    flowTypeScore?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisScoreCalculationResult
     */
    largeWoodyDebrisScore?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisScoreCalculationResult
     */
    artificialBedFeaturesScore?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisScoreCalculationResult
     */
    instreamScore?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisScoreCalculationResult
     */
    riparianVegetationScore?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisScoreCalculationResult
     */
    bankStabilizationScore?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisScoreCalculationResult
     */
    bankProfileScore?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisScoreCalculationResult
     */
    bankRiparianScore?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisScoreCalculationResult
     */
    floodedAreaScore?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisScoreCalculationResult
     */
    naturalVegetationScore?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisScoreCalculationResult
     */
    floodplainScore?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisScoreCalculationResult
     */
    qualityScore?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisScoreCalculationResult
     */
    classOfQuality?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisScoreCalculationResult
     */
    ecologicalClass?: number;
    /**
     * 
     * @type {number}
     * @memberof HydroAnalysisScoreCalculationResult
     */
    threeParameterScore?: number;
}

/**
 * Check if a given object implements the HydroAnalysisScoreCalculationResult interface.
 */
export function instanceOfHydroAnalysisScoreCalculationResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function HydroAnalysisScoreCalculationResultFromJSON(json: any): HydroAnalysisScoreCalculationResult {
    return HydroAnalysisScoreCalculationResultFromJSONTyped(json, false);
}

export function HydroAnalysisScoreCalculationResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): HydroAnalysisScoreCalculationResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'channelScore': !exists(json, 'channelScore') ? undefined : json['channelScore'],
        'bedElementScore': !exists(json, 'bedElementScore') ? undefined : json['bedElementScore'],
        'substrateScore': !exists(json, 'substrateScore') ? undefined : json['substrateScore'],
        'varInWidthScore': !exists(json, 'varInWidthScore') ? undefined : json['varInWidthScore'],
        'flowTypeScore': !exists(json, 'flowTypeScore') ? undefined : json['flowTypeScore'],
        'largeWoodyDebrisScore': !exists(json, 'largeWoodyDebrisScore') ? undefined : json['largeWoodyDebrisScore'],
        'artificialBedFeaturesScore': !exists(json, 'artificialBedFeaturesScore') ? undefined : json['artificialBedFeaturesScore'],
        'instreamScore': !exists(json, 'instreamScore') ? undefined : json['instreamScore'],
        'riparianVegetationScore': !exists(json, 'riparianVegetationScore') ? undefined : json['riparianVegetationScore'],
        'bankStabilizationScore': !exists(json, 'bankStabilizationScore') ? undefined : json['bankStabilizationScore'],
        'bankProfileScore': !exists(json, 'bankProfileScore') ? undefined : json['bankProfileScore'],
        'bankRiparianScore': !exists(json, 'bankRiparianScore') ? undefined : json['bankRiparianScore'],
        'floodedAreaScore': !exists(json, 'floodedAreaScore') ? undefined : json['floodedAreaScore'],
        'naturalVegetationScore': !exists(json, 'naturalVegetationScore') ? undefined : json['naturalVegetationScore'],
        'floodplainScore': !exists(json, 'floodplainScore') ? undefined : json['floodplainScore'],
        'qualityScore': !exists(json, 'qualityScore') ? undefined : json['qualityScore'],
        'classOfQuality': !exists(json, 'classOfQuality') ? undefined : json['classOfQuality'],
        'ecologicalClass': !exists(json, 'ecologicalClass') ? undefined : json['ecologicalClass'],
        'threeParameterScore': !exists(json, 'threeParameterScore') ? undefined : json['threeParameterScore'],
    };
}

export function HydroAnalysisScoreCalculationResultToJSON(value?: HydroAnalysisScoreCalculationResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'channelScore': value.channelScore,
        'bedElementScore': value.bedElementScore,
        'substrateScore': value.substrateScore,
        'varInWidthScore': value.varInWidthScore,
        'flowTypeScore': value.flowTypeScore,
        'largeWoodyDebrisScore': value.largeWoodyDebrisScore,
        'artificialBedFeaturesScore': value.artificialBedFeaturesScore,
        'instreamScore': value.instreamScore,
        'riparianVegetationScore': value.riparianVegetationScore,
        'bankStabilizationScore': value.bankStabilizationScore,
        'bankProfileScore': value.bankProfileScore,
        'bankRiparianScore': value.bankRiparianScore,
        'floodedAreaScore': value.floodedAreaScore,
        'naturalVegetationScore': value.naturalVegetationScore,
        'floodplainScore': value.floodplainScore,
        'qualityScore': value.qualityScore,
        'classOfQuality': value.classOfQuality,
        'ecologicalClass': value.ecologicalClass,
        'threeParameterScore': value.threeParameterScore,
    };
}

