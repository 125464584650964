/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Environment } from './Environment';
import {
    EnvironmentFromJSON,
    EnvironmentFromJSONTyped,
    EnvironmentToJSON,
} from './Environment';
import type { GeneralTypeRecord } from './GeneralTypeRecord';
import {
    GeneralTypeRecordFromJSON,
    GeneralTypeRecordFromJSONTyped,
    GeneralTypeRecordToJSON,
} from './GeneralTypeRecord';
import type { WaterShapeRecord } from './WaterShapeRecord';
import {
    WaterShapeRecordFromJSON,
    WaterShapeRecordFromJSONTyped,
    WaterShapeRecordToJSON,
} from './WaterShapeRecord';

/**
 * 
 * @export
 * @interface WaterCourseRecord
 */
export interface WaterCourseRecord {
    /**
     * 
     * @type {GeneralTypeRecord}
     * @memberof WaterCourseRecord
     */
    assesmentWaterFlowType?: GeneralTypeRecord;
    /**
     * 
     * @type {Environment}
     * @memberof WaterCourseRecord
     */
    environment?: Environment;
    /**
     * 
     * @type {number}
     * @memberof WaterCourseRecord
     */
    gisKm?: number;
    /**
     * 
     * @type {string}
     * @memberof WaterCourseRecord
     */
    hydrographicNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof WaterCourseRecord
     */
    vhmKm?: number;
    /**
     * 
     * @type {WaterShapeRecord}
     * @memberof WaterCourseRecord
     */
    waterShape?: WaterShapeRecord;
}

/**
 * Check if a given object implements the WaterCourseRecord interface.
 */
export function instanceOfWaterCourseRecord(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WaterCourseRecordFromJSON(json: any): WaterCourseRecord {
    return WaterCourseRecordFromJSONTyped(json, false);
}

export function WaterCourseRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): WaterCourseRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'assesmentWaterFlowType': !exists(json, 'assesmentWaterFlowType') ? undefined : GeneralTypeRecordFromJSON(json['assesmentWaterFlowType']),
        'environment': !exists(json, 'environment') ? undefined : EnvironmentFromJSON(json['environment']),
        'gisKm': !exists(json, 'gisKm') ? undefined : json['gisKm'],
        'hydrographicNumber': !exists(json, 'hydrographicNumber') ? undefined : json['hydrographicNumber'],
        'vhmKm': !exists(json, 'vhmKm') ? undefined : json['vhmKm'],
        'waterShape': !exists(json, 'waterShape') ? undefined : WaterShapeRecordFromJSON(json['waterShape']),
    };
}

export function WaterCourseRecordToJSON(value?: WaterCourseRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'assesmentWaterFlowType': GeneralTypeRecordToJSON(value.assesmentWaterFlowType),
        'environment': EnvironmentToJSON(value.environment),
        'gisKm': value.gisKm,
        'hydrographicNumber': value.hydrographicNumber,
        'vhmKm': value.vhmKm,
        'waterShape': WaterShapeRecordToJSON(value.waterShape),
    };
}

