/* tslint:disable */
/* eslint-disable */
/**
 * Data import
 * Modul for importing data to Data Registry.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: schvarcz@bb.microcomp.sk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PreviewMetadataResponse
 */
export interface PreviewMetadataResponse {
    /**
     * 
     * @type {number}
     * @memberof PreviewMetadataResponse
     */
    columnNum?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof PreviewMetadataResponse
     */
    columnHeader?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PreviewMetadataResponse
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof PreviewMetadataResponse
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PreviewMetadataResponse
     */
    required?: boolean;
}

/**
 * Check if a given object implements the PreviewMetadataResponse interface.
 */
export function instanceOfPreviewMetadataResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PreviewMetadataResponseFromJSON(json: any): PreviewMetadataResponse {
    return PreviewMetadataResponseFromJSONTyped(json, false);
}

export function PreviewMetadataResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PreviewMetadataResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'columnNum': !exists(json, 'columnNum') ? undefined : json['columnNum'],
        'columnHeader': !exists(json, 'columnHeader') ? undefined : json['columnHeader'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'required': !exists(json, 'required') ? undefined : json['required'],
    };
}

export function PreviewMetadataResponseToJSON(value?: PreviewMetadataResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'columnNum': value.columnNum,
        'columnHeader': value.columnHeader,
        'code': value.code,
        'name': value.name,
        'required': value.required,
    };
}

