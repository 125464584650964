/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiExceptionRecord,
  AttachmentTypeResponse,
  FileRecord,
  PageWaterEvidenceDocumentResponseRecord,
  ValidationWeResult,
  WaterEvidenceAppendixBasic,
  WaterEvidenceAppendixRequestRecord,
  WaterEvidenceAppendixResponseRecord,
  WaterEvidenceDocumentChangeRequestRecord,
  WaterEvidenceDocumentRequestRecord,
  WaterEvidenceDocumentResponseRecord,
  WaterEvidenceOtherAppendixRequestRecord,
  WaterEvidenceOtherAppendixResponseRecord,
  WaterEvidenceWaterStructAppendixBasic,
  WaterStructAppendixRequestRecord,
  WaterStructAppendixResponseRecord,
  WaterStructRequestRecord,
  WaterStructResponseRecord,
} from '../models/index';
import {
    ApiExceptionRecordFromJSON,
    ApiExceptionRecordToJSON,
    AttachmentTypeResponseFromJSON,
    AttachmentTypeResponseToJSON,
    FileRecordFromJSON,
    FileRecordToJSON,
    PageWaterEvidenceDocumentResponseRecordFromJSON,
    PageWaterEvidenceDocumentResponseRecordToJSON,
    ValidationWeResultFromJSON,
    ValidationWeResultToJSON,
    WaterEvidenceAppendixBasicFromJSON,
    WaterEvidenceAppendixBasicToJSON,
    WaterEvidenceAppendixRequestRecordFromJSON,
    WaterEvidenceAppendixRequestRecordToJSON,
    WaterEvidenceAppendixResponseRecordFromJSON,
    WaterEvidenceAppendixResponseRecordToJSON,
    WaterEvidenceDocumentChangeRequestRecordFromJSON,
    WaterEvidenceDocumentChangeRequestRecordToJSON,
    WaterEvidenceDocumentRequestRecordFromJSON,
    WaterEvidenceDocumentRequestRecordToJSON,
    WaterEvidenceDocumentResponseRecordFromJSON,
    WaterEvidenceDocumentResponseRecordToJSON,
    WaterEvidenceOtherAppendixRequestRecordFromJSON,
    WaterEvidenceOtherAppendixRequestRecordToJSON,
    WaterEvidenceOtherAppendixResponseRecordFromJSON,
    WaterEvidenceOtherAppendixResponseRecordToJSON,
    WaterEvidenceWaterStructAppendixBasicFromJSON,
    WaterEvidenceWaterStructAppendixBasicToJSON,
    WaterStructAppendixRequestRecordFromJSON,
    WaterStructAppendixRequestRecordToJSON,
    WaterStructAppendixResponseRecordFromJSON,
    WaterStructAppendixResponseRecordToJSON,
    WaterStructRequestRecordFromJSON,
    WaterStructRequestRecordToJSON,
    WaterStructResponseRecordFromJSON,
    WaterStructResponseRecordToJSON,
} from '../models/index';

export interface WaterEvidenceAPIApiChangeDocumentRequest {
    documentId: number;
    waterEvidenceDocumentChangeRequestRecord: WaterEvidenceDocumentChangeRequestRecord;
}

export interface WaterEvidenceAPIApiCheckGenerateExcelRequest {
    waterEvidenceExportFilesId: number;
}

export interface WaterEvidenceAPIApiCreateAppendixRequest {
    documentId: number;
    waterEvidenceAppendixRequestRecord: WaterEvidenceAppendixRequestRecord;
}

export interface WaterEvidenceAPIApiCreateDocumentRequest {
    waterEvidenceDocumentRequestRecord: WaterEvidenceDocumentRequestRecord;
}

export interface WaterEvidenceAPIApiCreateOtherAppendixRequest {
    documentId: number;
    waterEvidenceOtherAppendixRequestRecord: WaterEvidenceOtherAppendixRequestRecord;
}

export interface WaterEvidenceAPIApiCreateWaterStructRequest {
    documentId: number;
    waterStructRequestRecord: WaterStructRequestRecord;
}

export interface WaterEvidenceAPIApiCreateWaterStructAppendixRequest {
    documentId: number;
    waterStructId: number;
    waterStructAppendixRequestRecord: WaterStructAppendixRequestRecord;
}

export interface WaterEvidenceAPIApiDeleteAppendixRequest {
    documentId: number;
    appendixId: number;
}

export interface WaterEvidenceAPIApiDeleteDocumentRequest {
    documentId: number;
}

export interface WaterEvidenceAPIApiDeleteDocumentFileRequest {
    documentId: number;
    fileId: number;
}

export interface WaterEvidenceAPIApiDeleteOtherAppendixRequest {
    documentId: number;
    appendixId: number;
}

export interface WaterEvidenceAPIApiDeleteWaterStructRequest {
    documentId: number;
    waterStructId: number;
}

export interface WaterEvidenceAPIApiDeleteWaterStructAppendixRequest {
    documentId: number;
    waterStructId: number;
    appendixId: number;
}

export interface WaterEvidenceAPIApiGenerateExcelFileRequest {
    waterEvidenceExportFilesId: number;
}

export interface WaterEvidenceAPIApiGenerateExcelFileBasicRequest {
    requestBody: { [key: string]: any; };
    page?: number;
    size?: number;
    sort?: string;
}

export interface WaterEvidenceAPIApiGenerateExcelFileReport6Request {
    requestBody: { [key: string]: any; };
}

export interface WaterEvidenceAPIApiGenerateExcelFileWithAppendixRequest {
    requestBody: { [key: string]: any; };
    page?: number;
    size?: number;
    sort?: string;
}

export interface WaterEvidenceAPIApiGenerateExcelFileWithDeterminantRequest {
    requestBody: { [key: string]: any; };
    page?: number;
    size?: number;
    sort?: string;
}

export interface WaterEvidenceAPIApiGetAppendixRequest {
    documentId: number;
    appendixId: number;
}

export interface WaterEvidenceAPIApiGetAppendixesRequest {
    documentId: number;
}

export interface WaterEvidenceAPIApiGetDocumentRequest {
    documentId: number;
}

export interface WaterEvidenceAPIApiGetDocumentAttachmentRequest {
    documentId: number;
    fileId: number;
}

export interface WaterEvidenceAPIApiGetDocumentAttachmentsRequest {
    documentId: number;
}

export interface WaterEvidenceAPIApiGetOtherAppendixRequest {
    documentId: number;
    appendixId: number;
}

export interface WaterEvidenceAPIApiGetOtherDataRequest {
    documentTypeCode: number;
}

export interface WaterEvidenceAPIApiGetValidationDrWriteRequest {
    appendixId: number;
}

export interface WaterEvidenceAPIApiGetWaterHandlingDataRequest {
    documentTypeCode: number;
}

export interface WaterEvidenceAPIApiGetWaterStructRequest {
    documentId: number;
    waterStructId: number;
}

export interface WaterEvidenceAPIApiGetWaterStructAppendixRequest {
    documentId: number;
    waterStructId: number;
    appendixId: number;
}

export interface WaterEvidenceAPIApiGetWaterStructAppendixesRequest {
    documentId: number;
    waterStructId: number;
}

export interface WaterEvidenceAPIApiGetWaterStructureDataRequest {
    documentTypeCode: number;
    waterStructCode: number;
}

export interface WaterEvidenceAPIApiSearchDocumentsRequest {
    requestBody: { [key: string]: any; };
    page?: number;
    size?: number;
    sort?: string;
}

export interface WaterEvidenceAPIApiStartGenerateExcelRequest {
    requestBody: { [key: string]: any; };
    page?: number;
    size?: number;
    sort?: string;
}

export interface WaterEvidenceAPIApiUpdateAppendixRequest {
    documentId: number;
    appendixId: number;
    waterEvidenceAppendixRequestRecord: WaterEvidenceAppendixRequestRecord;
}

export interface WaterEvidenceAPIApiUpdateDocumentRequest {
    documentId: number;
    waterEvidenceDocumentRequestRecord: WaterEvidenceDocumentRequestRecord;
}

export interface WaterEvidenceAPIApiUpdateOtherAppendixRequest {
    documentId: number;
    appendixId: number;
    waterEvidenceOtherAppendixRequestRecord: WaterEvidenceOtherAppendixRequestRecord;
}

export interface WaterEvidenceAPIApiUpdateWaterStructRequest {
    documentId: number;
    waterStructId: number;
    waterStructRequestRecord: WaterStructRequestRecord;
}

export interface WaterEvidenceAPIApiUpdateWaterStructAppendixRequest {
    documentId: number;
    waterStructId: number;
    appendixId: number;
    waterStructAppendixRequestRecord: WaterStructAppendixRequestRecord;
}

export interface WaterEvidenceAPIApiUploadDocumentFileRequest {
    documentId: number;
    file: Blob;
}

export interface WaterEvidenceAPIApiWriteAppendixToDrRequest {
    appendixId: number;
    validationWeResult: ValidationWeResult;
}

/**
 * 
 */
export class WaterEvidenceAPIApi extends runtime.BaseAPI {

    /**
     * Returns HTTPS status code 200 and new changed document with required appendixes
     * change water evidence document
     */
    async changeDocumentRaw(requestParameters: WaterEvidenceAPIApiChangeDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WaterEvidenceDocumentResponseRecord>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling changeDocument.');
        }

        if (requestParameters.waterEvidenceDocumentChangeRequestRecord === null || requestParameters.waterEvidenceDocumentChangeRequestRecord === undefined) {
            throw new runtime.RequiredError('waterEvidenceDocumentChangeRequestRecord','Required parameter requestParameters.waterEvidenceDocumentChangeRequestRecord was null or undefined when calling changeDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/water-evidence/v1/document/{documentId}/change`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WaterEvidenceDocumentChangeRequestRecordToJSON(requestParameters.waterEvidenceDocumentChangeRequestRecord),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WaterEvidenceDocumentResponseRecordFromJSON(jsonValue));
    }

    /**
     * Returns HTTPS status code 200 and new changed document with required appendixes
     * change water evidence document
     */
    async changeDocument(documentId: number, waterEvidenceDocumentChangeRequestRecord: WaterEvidenceDocumentChangeRequestRecord, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WaterEvidenceDocumentResponseRecord> {
        const response = await this.changeDocumentRaw({ documentId: documentId, waterEvidenceDocumentChangeRequestRecord: waterEvidenceDocumentChangeRequestRecord }, initOverrides);
        return await response.value();
    }

    /**
     */
    async checkGenerateExcelRaw(requestParameters: WaterEvidenceAPIApiCheckGenerateExcelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.waterEvidenceExportFilesId === null || requestParameters.waterEvidenceExportFilesId === undefined) {
            throw new runtime.RequiredError('waterEvidenceExportFilesId','Required parameter requestParameters.waterEvidenceExportFilesId was null or undefined when calling checkGenerateExcel.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/water-evidence/v1/{waterEvidenceExportFilesId}/check-export`.replace(`{${"waterEvidenceExportFilesId"}}`, encodeURIComponent(String(requestParameters.waterEvidenceExportFilesId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async checkGenerateExcel(waterEvidenceExportFilesId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.checkGenerateExcelRaw({ waterEvidenceExportFilesId: waterEvidenceExportFilesId }, initOverrides);
        return await response.value();
    }

    /**
     * Returns created object if the appendix was created successfully.
     * create water evidence appendix
     */
    async createAppendixRaw(requestParameters: WaterEvidenceAPIApiCreateAppendixRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WaterEvidenceAppendixResponseRecord>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling createAppendix.');
        }

        if (requestParameters.waterEvidenceAppendixRequestRecord === null || requestParameters.waterEvidenceAppendixRequestRecord === undefined) {
            throw new runtime.RequiredError('waterEvidenceAppendixRequestRecord','Required parameter requestParameters.waterEvidenceAppendixRequestRecord was null or undefined when calling createAppendix.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/water-evidence/v1/document/{documentId}/appendix/create`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WaterEvidenceAppendixRequestRecordToJSON(requestParameters.waterEvidenceAppendixRequestRecord),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WaterEvidenceAppendixResponseRecordFromJSON(jsonValue));
    }

    /**
     * Returns created object if the appendix was created successfully.
     * create water evidence appendix
     */
    async createAppendix(documentId: number, waterEvidenceAppendixRequestRecord: WaterEvidenceAppendixRequestRecord, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WaterEvidenceAppendixResponseRecord> {
        const response = await this.createAppendixRaw({ documentId: documentId, waterEvidenceAppendixRequestRecord: waterEvidenceAppendixRequestRecord }, initOverrides);
        return await response.value();
    }

    /**
     * Returns HTTPS status code 200 if the document was created successfully.
     * create water evidence document
     */
    async createDocumentRaw(requestParameters: WaterEvidenceAPIApiCreateDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WaterEvidenceDocumentResponseRecord>> {
        if (requestParameters.waterEvidenceDocumentRequestRecord === null || requestParameters.waterEvidenceDocumentRequestRecord === undefined) {
            throw new runtime.RequiredError('waterEvidenceDocumentRequestRecord','Required parameter requestParameters.waterEvidenceDocumentRequestRecord was null or undefined when calling createDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/water-evidence/v1/document/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WaterEvidenceDocumentRequestRecordToJSON(requestParameters.waterEvidenceDocumentRequestRecord),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WaterEvidenceDocumentResponseRecordFromJSON(jsonValue));
    }

    /**
     * Returns HTTPS status code 200 if the document was created successfully.
     * create water evidence document
     */
    async createDocument(waterEvidenceDocumentRequestRecord: WaterEvidenceDocumentRequestRecord, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WaterEvidenceDocumentResponseRecord> {
        const response = await this.createDocumentRaw({ waterEvidenceDocumentRequestRecord: waterEvidenceDocumentRequestRecord }, initOverrides);
        return await response.value();
    }

    /**
     * Returns created object if the other appendix was created successfully.
     * create water evidence other appendix
     */
    async createOtherAppendixRaw(requestParameters: WaterEvidenceAPIApiCreateOtherAppendixRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WaterEvidenceOtherAppendixResponseRecord>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling createOtherAppendix.');
        }

        if (requestParameters.waterEvidenceOtherAppendixRequestRecord === null || requestParameters.waterEvidenceOtherAppendixRequestRecord === undefined) {
            throw new runtime.RequiredError('waterEvidenceOtherAppendixRequestRecord','Required parameter requestParameters.waterEvidenceOtherAppendixRequestRecord was null or undefined when calling createOtherAppendix.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/water-evidence/v1/document/{documentId}/other-appendix/create`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WaterEvidenceOtherAppendixRequestRecordToJSON(requestParameters.waterEvidenceOtherAppendixRequestRecord),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WaterEvidenceOtherAppendixResponseRecordFromJSON(jsonValue));
    }

    /**
     * Returns created object if the other appendix was created successfully.
     * create water evidence other appendix
     */
    async createOtherAppendix(documentId: number, waterEvidenceOtherAppendixRequestRecord: WaterEvidenceOtherAppendixRequestRecord, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WaterEvidenceOtherAppendixResponseRecord> {
        const response = await this.createOtherAppendixRaw({ documentId: documentId, waterEvidenceOtherAppendixRequestRecord: waterEvidenceOtherAppendixRequestRecord }, initOverrides);
        return await response.value();
    }

    /**
     * Returns created object if the water struct was created successfully.
     * create water evidence water struct
     */
    async createWaterStructRaw(requestParameters: WaterEvidenceAPIApiCreateWaterStructRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WaterStructResponseRecord>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling createWaterStruct.');
        }

        if (requestParameters.waterStructRequestRecord === null || requestParameters.waterStructRequestRecord === undefined) {
            throw new runtime.RequiredError('waterStructRequestRecord','Required parameter requestParameters.waterStructRequestRecord was null or undefined when calling createWaterStruct.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/water-evidence/v1/document/{documentId}/water-struct/create`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WaterStructRequestRecordToJSON(requestParameters.waterStructRequestRecord),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WaterStructResponseRecordFromJSON(jsonValue));
    }

    /**
     * Returns created object if the water struct was created successfully.
     * create water evidence water struct
     */
    async createWaterStruct(documentId: number, waterStructRequestRecord: WaterStructRequestRecord, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WaterStructResponseRecord> {
        const response = await this.createWaterStructRaw({ documentId: documentId, waterStructRequestRecord: waterStructRequestRecord }, initOverrides);
        return await response.value();
    }

    /**
     * Returns created object if the water struct appendix was created successfully.
     * create water evidence water struct appendix
     */
    async createWaterStructAppendixRaw(requestParameters: WaterEvidenceAPIApiCreateWaterStructAppendixRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WaterStructAppendixResponseRecord>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling createWaterStructAppendix.');
        }

        if (requestParameters.waterStructId === null || requestParameters.waterStructId === undefined) {
            throw new runtime.RequiredError('waterStructId','Required parameter requestParameters.waterStructId was null or undefined when calling createWaterStructAppendix.');
        }

        if (requestParameters.waterStructAppendixRequestRecord === null || requestParameters.waterStructAppendixRequestRecord === undefined) {
            throw new runtime.RequiredError('waterStructAppendixRequestRecord','Required parameter requestParameters.waterStructAppendixRequestRecord was null or undefined when calling createWaterStructAppendix.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/water-evidence/v1/document/{documentId}/water-struct/{waterStructId}/appendix/create`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))).replace(`{${"waterStructId"}}`, encodeURIComponent(String(requestParameters.waterStructId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WaterStructAppendixRequestRecordToJSON(requestParameters.waterStructAppendixRequestRecord),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WaterStructAppendixResponseRecordFromJSON(jsonValue));
    }

    /**
     * Returns created object if the water struct appendix was created successfully.
     * create water evidence water struct appendix
     */
    async createWaterStructAppendix(documentId: number, waterStructId: number, waterStructAppendixRequestRecord: WaterStructAppendixRequestRecord, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WaterStructAppendixResponseRecord> {
        const response = await this.createWaterStructAppendixRaw({ documentId: documentId, waterStructId: waterStructId, waterStructAppendixRequestRecord: waterStructAppendixRequestRecord }, initOverrides);
        return await response.value();
    }

    /**
     * Returns HTTP OK if the appendix was deleted successfully.
     * delete water evidence appendix
     */
    async deleteAppendixRaw(requestParameters: WaterEvidenceAPIApiDeleteAppendixRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling deleteAppendix.');
        }

        if (requestParameters.appendixId === null || requestParameters.appendixId === undefined) {
            throw new runtime.RequiredError('appendixId','Required parameter requestParameters.appendixId was null or undefined when calling deleteAppendix.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/water-evidence/v1/document/{documentId}/appendix/{appendixId}`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))).replace(`{${"appendixId"}}`, encodeURIComponent(String(requestParameters.appendixId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Returns HTTP OK if the appendix was deleted successfully.
     * delete water evidence appendix
     */
    async deleteAppendix(documentId: number, appendixId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.deleteAppendixRaw({ documentId: documentId, appendixId: appendixId }, initOverrides);
        return await response.value();
    }

    /**
     * Returns HTTPS status code 200 if the document was deleted successfully.
     * delete water evidence document
     */
    async deleteDocumentRaw(requestParameters: WaterEvidenceAPIApiDeleteDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling deleteDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/water-evidence/v1/document/{documentId}/delete`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Returns HTTPS status code 200 if the document was deleted successfully.
     * delete water evidence document
     */
    async deleteDocument(documentId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.deleteDocumentRaw({ documentId: documentId }, initOverrides);
        return await response.value();
    }

    /**
     * Returns HTTP OK if the document file was deleted successfully.
     * Delete document file
     */
    async deleteDocumentFileRaw(requestParameters: WaterEvidenceAPIApiDeleteDocumentFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling deleteDocumentFile.');
        }

        if (requestParameters.fileId === null || requestParameters.fileId === undefined) {
            throw new runtime.RequiredError('fileId','Required parameter requestParameters.fileId was null or undefined when calling deleteDocumentFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/water-evidence/v1/document/{documentId}/file/{fileId}`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))).replace(`{${"fileId"}}`, encodeURIComponent(String(requestParameters.fileId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Returns HTTP OK if the document file was deleted successfully.
     * Delete document file
     */
    async deleteDocumentFile(documentId: number, fileId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.deleteDocumentFileRaw({ documentId: documentId, fileId: fileId }, initOverrides);
        return await response.value();
    }

    /**
     * Returns HTTP OK if the other appendix was deleted successfully.
     * delete other appendix
     */
    async deleteOtherAppendixRaw(requestParameters: WaterEvidenceAPIApiDeleteOtherAppendixRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling deleteOtherAppendix.');
        }

        if (requestParameters.appendixId === null || requestParameters.appendixId === undefined) {
            throw new runtime.RequiredError('appendixId','Required parameter requestParameters.appendixId was null or undefined when calling deleteOtherAppendix.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/water-evidence/v1/document/{documentId}/other-appendix/{appendixId}`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))).replace(`{${"appendixId"}}`, encodeURIComponent(String(requestParameters.appendixId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Returns HTTP OK if the other appendix was deleted successfully.
     * delete other appendix
     */
    async deleteOtherAppendix(documentId: number, appendixId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.deleteOtherAppendixRaw({ documentId: documentId, appendixId: appendixId }, initOverrides);
        return await response.value();
    }

    /**
     * Returns HTTP OK if the water struct was deleted successfully.
     * delete water evidence water struct
     */
    async deleteWaterStructRaw(requestParameters: WaterEvidenceAPIApiDeleteWaterStructRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling deleteWaterStruct.');
        }

        if (requestParameters.waterStructId === null || requestParameters.waterStructId === undefined) {
            throw new runtime.RequiredError('waterStructId','Required parameter requestParameters.waterStructId was null or undefined when calling deleteWaterStruct.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/water-evidence/v1/document/{documentId}/water-struct/{waterStructId}`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))).replace(`{${"waterStructId"}}`, encodeURIComponent(String(requestParameters.waterStructId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Returns HTTP OK if the water struct was deleted successfully.
     * delete water evidence water struct
     */
    async deleteWaterStruct(documentId: number, waterStructId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.deleteWaterStructRaw({ documentId: documentId, waterStructId: waterStructId }, initOverrides);
        return await response.value();
    }

    /**
     * Returns HTTP OK if the water struct appendix was deleted successfully.
     * delete water evidence water struct appendix
     */
    async deleteWaterStructAppendixRaw(requestParameters: WaterEvidenceAPIApiDeleteWaterStructAppendixRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling deleteWaterStructAppendix.');
        }

        if (requestParameters.waterStructId === null || requestParameters.waterStructId === undefined) {
            throw new runtime.RequiredError('waterStructId','Required parameter requestParameters.waterStructId was null or undefined when calling deleteWaterStructAppendix.');
        }

        if (requestParameters.appendixId === null || requestParameters.appendixId === undefined) {
            throw new runtime.RequiredError('appendixId','Required parameter requestParameters.appendixId was null or undefined when calling deleteWaterStructAppendix.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/water-evidence/v1/document/{documentId}/water-struct/{waterStructId}/appendix/{appendixId}`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))).replace(`{${"waterStructId"}}`, encodeURIComponent(String(requestParameters.waterStructId))).replace(`{${"appendixId"}}`, encodeURIComponent(String(requestParameters.appendixId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Returns HTTP OK if the water struct appendix was deleted successfully.
     * delete water evidence water struct appendix
     */
    async deleteWaterStructAppendix(documentId: number, waterStructId: number, appendixId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.deleteWaterStructAppendixRaw({ documentId: documentId, waterStructId: waterStructId, appendixId: appendixId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async generateExcelFileRaw(requestParameters: WaterEvidenceAPIApiGenerateExcelFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.waterEvidenceExportFilesId === null || requestParameters.waterEvidenceExportFilesId === undefined) {
            throw new runtime.RequiredError('waterEvidenceExportFilesId','Required parameter requestParameters.waterEvidenceExportFilesId was null or undefined when calling generateExcelFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/water-evidence/v1/{waterEvidenceExportFilesId}/export`.replace(`{${"waterEvidenceExportFilesId"}}`, encodeURIComponent(String(requestParameters.waterEvidenceExportFilesId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async generateExcelFile(waterEvidenceExportFilesId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.generateExcelFileRaw({ waterEvidenceExportFilesId: waterEvidenceExportFilesId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async generateExcelFileBasicRaw(requestParameters: WaterEvidenceAPIApiGenerateExcelFileBasicRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling generateExcelFileBasic.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/water-evidence/v1/export/detail/basic`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async generateExcelFileBasic(requestBody: { [key: string]: any; }, page?: number, size?: number, sort?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.generateExcelFileBasicRaw({ requestBody: requestBody, page: page, size: size, sort: sort }, initOverrides);
        return await response.value();
    }

    /**
     */
    async generateExcelFileReport6Raw(requestParameters: WaterEvidenceAPIApiGenerateExcelFileReport6Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling generateExcelFileReport6.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/water-evidence/v1/export/report6`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async generateExcelFileReport6(requestBody: { [key: string]: any; }, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.generateExcelFileReport6Raw({ requestBody: requestBody }, initOverrides);
        return await response.value();
    }

    /**
     */
    async generateExcelFileWithAppendixRaw(requestParameters: WaterEvidenceAPIApiGenerateExcelFileWithAppendixRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling generateExcelFileWithAppendix.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/water-evidence/v1/export/detail/with-appendix`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async generateExcelFileWithAppendix(requestBody: { [key: string]: any; }, page?: number, size?: number, sort?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.generateExcelFileWithAppendixRaw({ requestBody: requestBody, page: page, size: size, sort: sort }, initOverrides);
        return await response.value();
    }

    /**
     */
    async generateExcelFileWithDeterminantRaw(requestParameters: WaterEvidenceAPIApiGenerateExcelFileWithDeterminantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling generateExcelFileWithDeterminant.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/water-evidence/v1/export/detail/with-determinant`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async generateExcelFileWithDeterminant(requestBody: { [key: string]: any; }, page?: number, size?: number, sort?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.generateExcelFileWithDeterminantRaw({ requestBody: requestBody, page: page, size: size, sort: sort }, initOverrides);
        return await response.value();
    }

    /**
     * Returns appendix by id.
     * Get appendix by id
     */
    async getAppendixRaw(requestParameters: WaterEvidenceAPIApiGetAppendixRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WaterEvidenceAppendixResponseRecord>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling getAppendix.');
        }

        if (requestParameters.appendixId === null || requestParameters.appendixId === undefined) {
            throw new runtime.RequiredError('appendixId','Required parameter requestParameters.appendixId was null or undefined when calling getAppendix.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/water-evidence/v1/document/{documentId}/appendix/{appendixId}`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))).replace(`{${"appendixId"}}`, encodeURIComponent(String(requestParameters.appendixId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WaterEvidenceAppendixResponseRecordFromJSON(jsonValue));
    }

    /**
     * Returns appendix by id.
     * Get appendix by id
     */
    async getAppendix(documentId: number, appendixId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WaterEvidenceAppendixResponseRecord> {
        const response = await this.getAppendixRaw({ documentId: documentId, appendixId: appendixId }, initOverrides);
        return await response.value();
    }

    /**
     * Returns all top level appendixes for document id.
     * Get all top level appendixes for document id
     */
    async getAppendixesRaw(requestParameters: WaterEvidenceAPIApiGetAppendixesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<WaterEvidenceAppendixBasic>>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling getAppendixes.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/water-evidence/v1/document/{documentId}/appendix/list`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(WaterEvidenceAppendixBasicFromJSON));
    }

    /**
     * Returns all top level appendixes for document id.
     * Get all top level appendixes for document id
     */
    async getAppendixes(documentId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<WaterEvidenceAppendixBasic>> {
        const response = await this.getAppendixesRaw({ documentId: documentId }, initOverrides);
        return await response.value();
    }

    /**
     * Returns the document by provided ID.
     * Get document
     */
    async getDocumentRaw(requestParameters: WaterEvidenceAPIApiGetDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WaterEvidenceDocumentResponseRecord>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling getDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/water-evidence/v1/document/{documentId}`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WaterEvidenceDocumentResponseRecordFromJSON(jsonValue));
    }

    /**
     * Returns the document by provided ID.
     * Get document
     */
    async getDocument(documentId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WaterEvidenceDocumentResponseRecord> {
        const response = await this.getDocumentRaw({ documentId: documentId }, initOverrides);
        return await response.value();
    }

    /**
     * Returns the document attachment by provided IDs.
     * Get document attachment by id
     */
    async getDocumentAttachmentRaw(requestParameters: WaterEvidenceAPIApiGetDocumentAttachmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FileRecord>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling getDocumentAttachment.');
        }

        if (requestParameters.fileId === null || requestParameters.fileId === undefined) {
            throw new runtime.RequiredError('fileId','Required parameter requestParameters.fileId was null or undefined when calling getDocumentAttachment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/water-evidence/v1/document/{documentId}/file/{fileId}`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))).replace(`{${"fileId"}}`, encodeURIComponent(String(requestParameters.fileId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FileRecordFromJSON(jsonValue));
    }

    /**
     * Returns the document attachment by provided IDs.
     * Get document attachment by id
     */
    async getDocumentAttachment(documentId: number, fileId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FileRecord> {
        const response = await this.getDocumentAttachmentRaw({ documentId: documentId, fileId: fileId }, initOverrides);
        return await response.value();
    }

    /**
     * Returns all files for document with provided ID.
     * Get all files related to document
     */
    async getDocumentAttachmentsRaw(requestParameters: WaterEvidenceAPIApiGetDocumentAttachmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FileRecord>>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling getDocumentAttachments.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/water-evidence/v1/document/{documentId}/files`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FileRecordFromJSON));
    }

    /**
     * Returns all files for document with provided ID.
     * Get all files related to document
     */
    async getDocumentAttachments(documentId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FileRecord>> {
        const response = await this.getDocumentAttachmentsRaw({ documentId: documentId }, initOverrides);
        return await response.value();
    }

    /**
     * Returns other appendix by id.
     * Get other appendix by id
     */
    async getOtherAppendixRaw(requestParameters: WaterEvidenceAPIApiGetOtherAppendixRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WaterEvidenceOtherAppendixResponseRecord>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling getOtherAppendix.');
        }

        if (requestParameters.appendixId === null || requestParameters.appendixId === undefined) {
            throw new runtime.RequiredError('appendixId','Required parameter requestParameters.appendixId was null or undefined when calling getOtherAppendix.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/water-evidence/v1/document/{documentId}/other-appendix/{appendixId}`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))).replace(`{${"appendixId"}}`, encodeURIComponent(String(requestParameters.appendixId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WaterEvidenceOtherAppendixResponseRecordFromJSON(jsonValue));
    }

    /**
     * Returns other appendix by id.
     * Get other appendix by id
     */
    async getOtherAppendix(documentId: number, appendixId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WaterEvidenceOtherAppendixResponseRecord> {
        const response = await this.getOtherAppendixRaw({ documentId: documentId, appendixId: appendixId }, initOverrides);
        return await response.value();
    }

    /**
     * get json for other attachments in water evidence 
     */
    async getOtherDataRaw(requestParameters: WaterEvidenceAPIApiGetOtherDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AttachmentTypeResponse>> {
        if (requestParameters.documentTypeCode === null || requestParameters.documentTypeCode === undefined) {
            throw new runtime.RequiredError('documentTypeCode','Required parameter requestParameters.documentTypeCode was null or undefined when calling getOtherData.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/water-evidence/v1/other/{documentTypeCode}`.replace(`{${"documentTypeCode"}}`, encodeURIComponent(String(requestParameters.documentTypeCode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AttachmentTypeResponseFromJSON(jsonValue));
    }

    /**
     * get json for other attachments in water evidence 
     */
    async getOtherData(documentTypeCode: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AttachmentTypeResponse> {
        const response = await this.getOtherDataRaw({ documentTypeCode: documentTypeCode }, initOverrides);
        return await response.value();
    }

    /**
     * Get validation for possibility to write to DR
     */
    async getValidationDrWriteRaw(requestParameters: WaterEvidenceAPIApiGetValidationDrWriteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ValidationWeResult>> {
        if (requestParameters.appendixId === null || requestParameters.appendixId === undefined) {
            throw new runtime.RequiredError('appendixId','Required parameter requestParameters.appendixId was null or undefined when calling getValidationDrWrite.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/water-evidence/v1/document/appendix/{appendixId}/validate`.replace(`{${"appendixId"}}`, encodeURIComponent(String(requestParameters.appendixId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidationWeResultFromJSON(jsonValue));
    }

    /**
     * Get validation for possibility to write to DR
     */
    async getValidationDrWrite(appendixId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ValidationWeResult> {
        const response = await this.getValidationDrWriteRaw({ appendixId: appendixId }, initOverrides);
        return await response.value();
    }

    /**
     * get json for water handling attachments
     */
    async getWaterHandlingDataRaw(requestParameters: WaterEvidenceAPIApiGetWaterHandlingDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AttachmentTypeResponse>> {
        if (requestParameters.documentTypeCode === null || requestParameters.documentTypeCode === undefined) {
            throw new runtime.RequiredError('documentTypeCode','Required parameter requestParameters.documentTypeCode was null or undefined when calling getWaterHandlingData.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/water-evidence/v1/water-handling/{documentTypeCode}`.replace(`{${"documentTypeCode"}}`, encodeURIComponent(String(requestParameters.documentTypeCode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AttachmentTypeResponseFromJSON(jsonValue));
    }

    /**
     * get json for water handling attachments
     */
    async getWaterHandlingData(documentTypeCode: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AttachmentTypeResponse> {
        const response = await this.getWaterHandlingDataRaw({ documentTypeCode: documentTypeCode }, initOverrides);
        return await response.value();
    }

    /**
     * Returns water struct by id.
     * Get water struct by id
     */
    async getWaterStructRaw(requestParameters: WaterEvidenceAPIApiGetWaterStructRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WaterStructResponseRecord>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling getWaterStruct.');
        }

        if (requestParameters.waterStructId === null || requestParameters.waterStructId === undefined) {
            throw new runtime.RequiredError('waterStructId','Required parameter requestParameters.waterStructId was null or undefined when calling getWaterStruct.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/water-evidence/v1/document/{documentId}/water-struct/{waterStructId}`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))).replace(`{${"waterStructId"}}`, encodeURIComponent(String(requestParameters.waterStructId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WaterStructResponseRecordFromJSON(jsonValue));
    }

    /**
     * Returns water struct by id.
     * Get water struct by id
     */
    async getWaterStruct(documentId: number, waterStructId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WaterStructResponseRecord> {
        const response = await this.getWaterStructRaw({ documentId: documentId, waterStructId: waterStructId }, initOverrides);
        return await response.value();
    }

    /**
     * Returns water struct appendix by id.
     * Get water struct appendix by id
     */
    async getWaterStructAppendixRaw(requestParameters: WaterEvidenceAPIApiGetWaterStructAppendixRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WaterStructAppendixResponseRecord>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling getWaterStructAppendix.');
        }

        if (requestParameters.waterStructId === null || requestParameters.waterStructId === undefined) {
            throw new runtime.RequiredError('waterStructId','Required parameter requestParameters.waterStructId was null or undefined when calling getWaterStructAppendix.');
        }

        if (requestParameters.appendixId === null || requestParameters.appendixId === undefined) {
            throw new runtime.RequiredError('appendixId','Required parameter requestParameters.appendixId was null or undefined when calling getWaterStructAppendix.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/water-evidence/v1/document/{documentId}/water-struct/{waterStructId}/appendix/{appendixId}`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))).replace(`{${"waterStructId"}}`, encodeURIComponent(String(requestParameters.waterStructId))).replace(`{${"appendixId"}}`, encodeURIComponent(String(requestParameters.appendixId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WaterStructAppendixResponseRecordFromJSON(jsonValue));
    }

    /**
     * Returns water struct appendix by id.
     * Get water struct appendix by id
     */
    async getWaterStructAppendix(documentId: number, waterStructId: number, appendixId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WaterStructAppendixResponseRecord> {
        const response = await this.getWaterStructAppendixRaw({ documentId: documentId, waterStructId: waterStructId, appendixId: appendixId }, initOverrides);
        return await response.value();
    }

    /**
     * Returns all top level appendixes for water struct id.
     * Get all top level appendixes for water struct id
     */
    async getWaterStructAppendixesRaw(requestParameters: WaterEvidenceAPIApiGetWaterStructAppendixesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<WaterEvidenceWaterStructAppendixBasic>>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling getWaterStructAppendixes.');
        }

        if (requestParameters.waterStructId === null || requestParameters.waterStructId === undefined) {
            throw new runtime.RequiredError('waterStructId','Required parameter requestParameters.waterStructId was null or undefined when calling getWaterStructAppendixes.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/water-evidence/v1/document/{documentId}/water-struct/{waterStructId}/appendix/list`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))).replace(`{${"waterStructId"}}`, encodeURIComponent(String(requestParameters.waterStructId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(WaterEvidenceWaterStructAppendixBasicFromJSON));
    }

    /**
     * Returns all top level appendixes for water struct id.
     * Get all top level appendixes for water struct id
     */
    async getWaterStructAppendixes(documentId: number, waterStructId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<WaterEvidenceWaterStructAppendixBasic>> {
        const response = await this.getWaterStructAppendixesRaw({ documentId: documentId, waterStructId: waterStructId }, initOverrides);
        return await response.value();
    }

    /**
     * get json for water structures attachments
     */
    async getWaterStructureDataRaw(requestParameters: WaterEvidenceAPIApiGetWaterStructureDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AttachmentTypeResponse>> {
        if (requestParameters.documentTypeCode === null || requestParameters.documentTypeCode === undefined) {
            throw new runtime.RequiredError('documentTypeCode','Required parameter requestParameters.documentTypeCode was null or undefined when calling getWaterStructureData.');
        }

        if (requestParameters.waterStructCode === null || requestParameters.waterStructCode === undefined) {
            throw new runtime.RequiredError('waterStructCode','Required parameter requestParameters.waterStructCode was null or undefined when calling getWaterStructureData.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/water-evidence/v1/water-structure/{documentTypeCode}/{waterStructCode}`.replace(`{${"documentTypeCode"}}`, encodeURIComponent(String(requestParameters.documentTypeCode))).replace(`{${"waterStructCode"}}`, encodeURIComponent(String(requestParameters.waterStructCode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AttachmentTypeResponseFromJSON(jsonValue));
    }

    /**
     * get json for water structures attachments
     */
    async getWaterStructureData(documentTypeCode: number, waterStructCode: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AttachmentTypeResponse> {
        const response = await this.getWaterStructureDataRaw({ documentTypeCode: documentTypeCode, waterStructCode: waterStructCode }, initOverrides);
        return await response.value();
    }

    /**
     * Returns a page of documents matching the search criteria.
     * Search documents
     */
    async searchDocumentsRaw(requestParameters: WaterEvidenceAPIApiSearchDocumentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageWaterEvidenceDocumentResponseRecord>> {
        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling searchDocuments.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/water-evidence/v1/document/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageWaterEvidenceDocumentResponseRecordFromJSON(jsonValue));
    }

    /**
     * Returns a page of documents matching the search criteria.
     * Search documents
     */
    async searchDocuments(requestBody: { [key: string]: any; }, page?: number, size?: number, sort?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageWaterEvidenceDocumentResponseRecord> {
        const response = await this.searchDocumentsRaw({ requestBody: requestBody, page: page, size: size, sort: sort }, initOverrides);
        return await response.value();
    }

    /**
     */
    async startGenerateExcelRaw(requestParameters: WaterEvidenceAPIApiStartGenerateExcelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling startGenerateExcel.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/water-evidence/v1/init-export`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async startGenerateExcel(requestBody: { [key: string]: any; }, page?: number, size?: number, sort?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.startGenerateExcelRaw({ requestBody: requestBody, page: page, size: size, sort: sort }, initOverrides);
        return await response.value();
    }

    /**
     * Returns updated object if the appendix was created successfully.
     * update water evidence appendix
     */
    async updateAppendixRaw(requestParameters: WaterEvidenceAPIApiUpdateAppendixRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WaterEvidenceAppendixResponseRecord>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling updateAppendix.');
        }

        if (requestParameters.appendixId === null || requestParameters.appendixId === undefined) {
            throw new runtime.RequiredError('appendixId','Required parameter requestParameters.appendixId was null or undefined when calling updateAppendix.');
        }

        if (requestParameters.waterEvidenceAppendixRequestRecord === null || requestParameters.waterEvidenceAppendixRequestRecord === undefined) {
            throw new runtime.RequiredError('waterEvidenceAppendixRequestRecord','Required parameter requestParameters.waterEvidenceAppendixRequestRecord was null or undefined when calling updateAppendix.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/water-evidence/v1/document/{documentId}/appendix/{appendixId}/update`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))).replace(`{${"appendixId"}}`, encodeURIComponent(String(requestParameters.appendixId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WaterEvidenceAppendixRequestRecordToJSON(requestParameters.waterEvidenceAppendixRequestRecord),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WaterEvidenceAppendixResponseRecordFromJSON(jsonValue));
    }

    /**
     * Returns updated object if the appendix was created successfully.
     * update water evidence appendix
     */
    async updateAppendix(documentId: number, appendixId: number, waterEvidenceAppendixRequestRecord: WaterEvidenceAppendixRequestRecord, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WaterEvidenceAppendixResponseRecord> {
        const response = await this.updateAppendixRaw({ documentId: documentId, appendixId: appendixId, waterEvidenceAppendixRequestRecord: waterEvidenceAppendixRequestRecord }, initOverrides);
        return await response.value();
    }

    /**
     * Returns HTTPS status code 200 and updated document if document was updated successfully.
     * update water evidence document
     */
    async updateDocumentRaw(requestParameters: WaterEvidenceAPIApiUpdateDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WaterEvidenceDocumentResponseRecord>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling updateDocument.');
        }

        if (requestParameters.waterEvidenceDocumentRequestRecord === null || requestParameters.waterEvidenceDocumentRequestRecord === undefined) {
            throw new runtime.RequiredError('waterEvidenceDocumentRequestRecord','Required parameter requestParameters.waterEvidenceDocumentRequestRecord was null or undefined when calling updateDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/water-evidence/v1/document/{documentId}/update`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WaterEvidenceDocumentRequestRecordToJSON(requestParameters.waterEvidenceDocumentRequestRecord),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WaterEvidenceDocumentResponseRecordFromJSON(jsonValue));
    }

    /**
     * Returns HTTPS status code 200 and updated document if document was updated successfully.
     * update water evidence document
     */
    async updateDocument(documentId: number, waterEvidenceDocumentRequestRecord: WaterEvidenceDocumentRequestRecord, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WaterEvidenceDocumentResponseRecord> {
        const response = await this.updateDocumentRaw({ documentId: documentId, waterEvidenceDocumentRequestRecord: waterEvidenceDocumentRequestRecord }, initOverrides);
        return await response.value();
    }

    /**
     * Returns updated object if the other appendix was created successfully.
     * update other appendix
     */
    async updateOtherAppendixRaw(requestParameters: WaterEvidenceAPIApiUpdateOtherAppendixRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WaterEvidenceOtherAppendixResponseRecord>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling updateOtherAppendix.');
        }

        if (requestParameters.appendixId === null || requestParameters.appendixId === undefined) {
            throw new runtime.RequiredError('appendixId','Required parameter requestParameters.appendixId was null or undefined when calling updateOtherAppendix.');
        }

        if (requestParameters.waterEvidenceOtherAppendixRequestRecord === null || requestParameters.waterEvidenceOtherAppendixRequestRecord === undefined) {
            throw new runtime.RequiredError('waterEvidenceOtherAppendixRequestRecord','Required parameter requestParameters.waterEvidenceOtherAppendixRequestRecord was null or undefined when calling updateOtherAppendix.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/water-evidence/v1/document/{documentId}/other-appendix/{appendixId}/update`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))).replace(`{${"appendixId"}}`, encodeURIComponent(String(requestParameters.appendixId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WaterEvidenceOtherAppendixRequestRecordToJSON(requestParameters.waterEvidenceOtherAppendixRequestRecord),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WaterEvidenceOtherAppendixResponseRecordFromJSON(jsonValue));
    }

    /**
     * Returns updated object if the other appendix was created successfully.
     * update other appendix
     */
    async updateOtherAppendix(documentId: number, appendixId: number, waterEvidenceOtherAppendixRequestRecord: WaterEvidenceOtherAppendixRequestRecord, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WaterEvidenceOtherAppendixResponseRecord> {
        const response = await this.updateOtherAppendixRaw({ documentId: documentId, appendixId: appendixId, waterEvidenceOtherAppendixRequestRecord: waterEvidenceOtherAppendixRequestRecord }, initOverrides);
        return await response.value();
    }

    /**
     * Returns updated object if the water struct was created successfully.
     * update water evidence water struct
     */
    async updateWaterStructRaw(requestParameters: WaterEvidenceAPIApiUpdateWaterStructRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WaterStructResponseRecord>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling updateWaterStruct.');
        }

        if (requestParameters.waterStructId === null || requestParameters.waterStructId === undefined) {
            throw new runtime.RequiredError('waterStructId','Required parameter requestParameters.waterStructId was null or undefined when calling updateWaterStruct.');
        }

        if (requestParameters.waterStructRequestRecord === null || requestParameters.waterStructRequestRecord === undefined) {
            throw new runtime.RequiredError('waterStructRequestRecord','Required parameter requestParameters.waterStructRequestRecord was null or undefined when calling updateWaterStruct.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/water-evidence/v1/document/{documentId}/water-struct/{waterStructId}/update`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))).replace(`{${"waterStructId"}}`, encodeURIComponent(String(requestParameters.waterStructId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WaterStructRequestRecordToJSON(requestParameters.waterStructRequestRecord),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WaterStructResponseRecordFromJSON(jsonValue));
    }

    /**
     * Returns updated object if the water struct was created successfully.
     * update water evidence water struct
     */
    async updateWaterStruct(documentId: number, waterStructId: number, waterStructRequestRecord: WaterStructRequestRecord, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WaterStructResponseRecord> {
        const response = await this.updateWaterStructRaw({ documentId: documentId, waterStructId: waterStructId, waterStructRequestRecord: waterStructRequestRecord }, initOverrides);
        return await response.value();
    }

    /**
     * Returns updated object if the water struct appendix was created successfully.
     * update water evidence water struct appendix
     */
    async updateWaterStructAppendixRaw(requestParameters: WaterEvidenceAPIApiUpdateWaterStructAppendixRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WaterStructAppendixResponseRecord>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling updateWaterStructAppendix.');
        }

        if (requestParameters.waterStructId === null || requestParameters.waterStructId === undefined) {
            throw new runtime.RequiredError('waterStructId','Required parameter requestParameters.waterStructId was null or undefined when calling updateWaterStructAppendix.');
        }

        if (requestParameters.appendixId === null || requestParameters.appendixId === undefined) {
            throw new runtime.RequiredError('appendixId','Required parameter requestParameters.appendixId was null or undefined when calling updateWaterStructAppendix.');
        }

        if (requestParameters.waterStructAppendixRequestRecord === null || requestParameters.waterStructAppendixRequestRecord === undefined) {
            throw new runtime.RequiredError('waterStructAppendixRequestRecord','Required parameter requestParameters.waterStructAppendixRequestRecord was null or undefined when calling updateWaterStructAppendix.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/water-evidence/v1/document/{documentId}/water-struct/{waterStructId}/appendix/{appendixId}/update`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))).replace(`{${"waterStructId"}}`, encodeURIComponent(String(requestParameters.waterStructId))).replace(`{${"appendixId"}}`, encodeURIComponent(String(requestParameters.appendixId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WaterStructAppendixRequestRecordToJSON(requestParameters.waterStructAppendixRequestRecord),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WaterStructAppendixResponseRecordFromJSON(jsonValue));
    }

    /**
     * Returns updated object if the water struct appendix was created successfully.
     * update water evidence water struct appendix
     */
    async updateWaterStructAppendix(documentId: number, waterStructId: number, appendixId: number, waterStructAppendixRequestRecord: WaterStructAppendixRequestRecord, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WaterStructAppendixResponseRecord> {
        const response = await this.updateWaterStructAppendixRaw({ documentId: documentId, waterStructId: waterStructId, appendixId: appendixId, waterStructAppendixRequestRecord: waterStructAppendixRequestRecord }, initOverrides);
        return await response.value();
    }

    /**
     * Returns uploaded document ID
     * Upload document file
     */
    async uploadDocumentFileRaw(requestParameters: WaterEvidenceAPIApiUploadDocumentFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling uploadDocumentFile.');
        }

        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling uploadDocumentFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/api/integration/water-evidence/v1/document/{documentId}/file`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Returns uploaded document ID
     * Upload document file
     */
    async uploadDocumentFile(documentId: number, file: Blob, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.uploadDocumentFileRaw({ documentId: documentId, file: file }, initOverrides);
        return await response.value();
    }

    /**
     * Write appendix to DR
     */
    async writeAppendixToDrRaw(requestParameters: WaterEvidenceAPIApiWriteAppendixToDrRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.appendixId === null || requestParameters.appendixId === undefined) {
            throw new runtime.RequiredError('appendixId','Required parameter requestParameters.appendixId was null or undefined when calling writeAppendixToDr.');
        }

        if (requestParameters.validationWeResult === null || requestParameters.validationWeResult === undefined) {
            throw new runtime.RequiredError('validationWeResult','Required parameter requestParameters.validationWeResult was null or undefined when calling writeAppendixToDr.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/integration/water-evidence/v1/document/appendix/{appendixId}/write`.replace(`{${"appendixId"}}`, encodeURIComponent(String(requestParameters.appendixId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ValidationWeResultToJSON(requestParameters.validationWeResult),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Write appendix to DR
     */
    async writeAppendixToDr(appendixId: number, validationWeResult: ValidationWeResult, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.writeAppendixToDrRaw({ appendixId: appendixId, validationWeResult: validationWeResult }, initOverrides);
        return await response.value();
    }

}
