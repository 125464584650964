/* tslint:disable */
/* eslint-disable */
/**
 * Catalog API
 * Nástroj pre správu metadát a katalógov - koncové služby
 *
 * The version of the OpenAPI document: 0.31.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CodelistItemInput } from './CodelistItemInput';
import {
    CodelistItemInputFromJSON,
    CodelistItemInputFromJSONTyped,
    CodelistItemInputToJSON,
} from './CodelistItemInput';
import type { GPSCodelistItemInput } from './GPSCodelistItemInput';
import {
    GPSCodelistItemInputFromJSON,
    GPSCodelistItemInputFromJSONTyped,
    GPSCodelistItemInputToJSON,
} from './GPSCodelistItemInput';
import type { MonitoringCodelistItemInputRequired } from './MonitoringCodelistItemInputRequired';
import {
    MonitoringCodelistItemInputRequiredFromJSON,
    MonitoringCodelistItemInputRequiredFromJSONTyped,
    MonitoringCodelistItemInputRequiredToJSON,
} from './MonitoringCodelistItemInputRequired';
import type { OtherCodelistItemInput } from './OtherCodelistItemInput';
import {
    OtherCodelistItemInputFromJSON,
    OtherCodelistItemInputFromJSONTyped,
    OtherCodelistItemInputToJSON,
} from './OtherCodelistItemInput';
import type { Place } from './Place';
import {
    PlaceFromJSON,
    PlaceFromJSONTyped,
    PlaceToJSON,
} from './Place';
import type { PointCategoryCodelistItemInputRequired } from './PointCategoryCodelistItemInputRequired';
import {
    PointCategoryCodelistItemInputRequiredFromJSON,
    PointCategoryCodelistItemInputRequiredFromJSONTyped,
    PointCategoryCodelistItemInputRequiredToJSON,
} from './PointCategoryCodelistItemInputRequired';
import type { UndergroundWaterCodelistItemInputGwBodyItemInput } from './UndergroundWaterCodelistItemInputGwBodyItemInput';
import {
    UndergroundWaterCodelistItemInputGwBodyItemInputFromJSON,
    UndergroundWaterCodelistItemInputGwBodyItemInputFromJSONTyped,
    UndergroundWaterCodelistItemInputGwBodyItemInputToJSON,
} from './UndergroundWaterCodelistItemInputGwBodyItemInput';
import type { WaterCourseInputForMonitoringPointCodelistItemInput } from './WaterCourseInputForMonitoringPointCodelistItemInput';
import {
    WaterCourseInputForMonitoringPointCodelistItemInputFromJSON,
    WaterCourseInputForMonitoringPointCodelistItemInputFromJSONTyped,
    WaterCourseInputForMonitoringPointCodelistItemInputToJSON,
} from './WaterCourseInputForMonitoringPointCodelistItemInput';
import type { WaterWithdrawalInputCodelistItemInput } from './WaterWithdrawalInputCodelistItemInput';
import {
    WaterWithdrawalInputCodelistItemInputFromJSON,
    WaterWithdrawalInputCodelistItemInputFromJSONTyped,
    WaterWithdrawalInputCodelistItemInputToJSON,
} from './WaterWithdrawalInputCodelistItemInput';

/**
 * 
 * @export
 * @interface MonitoringPointInput
 */
export interface MonitoringPointInput {
    /**
     * 
     * @type {CodelistItemInput}
     * @memberof MonitoringPointInput
     */
    basinType: CodelistItemInput | null;
    /**
     * 
     * @type {CodelistItemInput}
     * @memberof MonitoringPointInput
     */
    assesmentBasinType: CodelistItemInput | null;
    /**
     * 
     * @type {CodelistItemInput}
     * @memberof MonitoringPointInput
     */
    partialBasinType: CodelistItemInput | null;
    /**
     * 
     * @type {CodelistItemInput}
     * @memberof MonitoringPointInput
     */
    subBasinType: CodelistItemInput | null;
    /**
     * 
     * @type {string}
     * @memberof MonitoringPointInput
     */
    groupId?: string;
    /**
     * 
     * @type {CodelistItemInput}
     * @memberof MonitoringPointInput
     */
    hydrologicalCodeType: CodelistItemInput | null;
    /**
     * 
     * @type {CodelistItemInput}
     * @memberof MonitoringPointInput
     */
    instBranchType: CodelistItemInput | null;
    /**
     * 
     * @type {string}
     * @memberof MonitoringPointInput
     */
    locationDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitoringPointInput
     */
    mapSheetNumber?: string;
    /**
     * 
     * @type {CodelistItemInput}
     * @memberof MonitoringPointInput
     */
    montPointType: CodelistItemInput | null;
    /**
     * 
     * @type {string}
     * @memberof MonitoringPointInput
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitoringPointInput
     */
    placeName?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitoringPointInput
     */
    note?: string;
    /**
     * 
     * @type {CodelistItemInput}
     * @memberof MonitoringPointInput
     */
    positionType: CodelistItemInput | null;
    /**
     * 
     * @type {CodelistItemInput}
     * @memberof MonitoringPointInput
     */
    statusType: CodelistItemInput | null;
    /**
     * 
     * @type {CodelistItemInput}
     * @memberof MonitoringPointInput
     */
    countryType: CodelistItemInput | null;
    /**
     * 
     * @type {CodelistItemInput}
     * @memberof MonitoringPointInput
     */
    areaType: CodelistItemInput | null;
    /**
     * 
     * @type {CodelistItemInput}
     * @memberof MonitoringPointInput
     */
    regionType: CodelistItemInput | null;
    /**
     * 
     * @type {CodelistItemInput}
     * @memberof MonitoringPointInput
     */
    districtType: CodelistItemInput | null;
    /**
     * 
     * @type {CodelistItemInput}
     * @memberof MonitoringPointInput
     */
    municipalityType: CodelistItemInput | null;
    /**
     * 
     * @type {CodelistItemInput}
     * @memberof MonitoringPointInput
     */
    municipalityForeignType: CodelistItemInput | null;
    /**
     * 
     * @type {CodelistItemInput}
     * @memberof MonitoringPointInput
     */
    landRegisterType: CodelistItemInput | null;
    /**
     * 
     * @type {string}
     * @memberof MonitoringPointInput
     */
    effectiveTo?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitoringPointInput
     */
    effectiveFrom?: string;
    /**
     * 
     * @type {UndergroundWaterCodelistItemInputGwBodyItemInput}
     * @memberof MonitoringPointInput
     */
    undergroundWater?: UndergroundWaterCodelistItemInputGwBodyItemInput;
    /**
     * 
     * @type {GPSCodelistItemInput}
     * @memberof MonitoringPointInput
     */
    gps?: GPSCodelistItemInput;
    /**
     * 
     * @type {OtherCodelistItemInput}
     * @memberof MonitoringPointInput
     */
    other?: OtherCodelistItemInput;
    /**
     * 
     * @type {string}
     * @memberof MonitoringPointInput
     */
    code?: string;
    /**
     * 
     * @type {WaterCourseInputForMonitoringPointCodelistItemInput}
     * @memberof MonitoringPointInput
     */
    waterCourse?: WaterCourseInputForMonitoringPointCodelistItemInput;
    /**
     * 
     * @type {WaterWithdrawalInputCodelistItemInput}
     * @memberof MonitoringPointInput
     */
    waterWithdrawal?: WaterWithdrawalInputCodelistItemInput;
    /**
     * 
     * @type {Array<MonitoringCodelistItemInputRequired>}
     * @memberof MonitoringPointInput
     */
    monitorings?: Array<MonitoringCodelistItemInputRequired>;
    /**
     * 
     * @type {Array<PointCategoryCodelistItemInputRequired>}
     * @memberof MonitoringPointInput
     */
    pointCategories?: Array<PointCategoryCodelistItemInputRequired>;
    /**
     * 
     * @type {Array<Place>}
     * @memberof MonitoringPointInput
     */
    places?: Array<Place>;
}

/**
 * Check if a given object implements the MonitoringPointInput interface.
 */
export function instanceOfMonitoringPointInput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "basinType" in value;
    isInstance = isInstance && "assesmentBasinType" in value;
    isInstance = isInstance && "partialBasinType" in value;
    isInstance = isInstance && "subBasinType" in value;
    isInstance = isInstance && "hydrologicalCodeType" in value;
    isInstance = isInstance && "instBranchType" in value;
    isInstance = isInstance && "montPointType" in value;
    isInstance = isInstance && "positionType" in value;
    isInstance = isInstance && "statusType" in value;
    isInstance = isInstance && "countryType" in value;
    isInstance = isInstance && "areaType" in value;
    isInstance = isInstance && "regionType" in value;
    isInstance = isInstance && "districtType" in value;
    isInstance = isInstance && "municipalityType" in value;
    isInstance = isInstance && "municipalityForeignType" in value;
    isInstance = isInstance && "landRegisterType" in value;

    return isInstance;
}

export function MonitoringPointInputFromJSON(json: any): MonitoringPointInput {
    return MonitoringPointInputFromJSONTyped(json, false);
}

export function MonitoringPointInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitoringPointInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'basinType': CodelistItemInputFromJSON(json['basinType']),
        'assesmentBasinType': CodelistItemInputFromJSON(json['assesmentBasinType']),
        'partialBasinType': CodelistItemInputFromJSON(json['partialBasinType']),
        'subBasinType': CodelistItemInputFromJSON(json['subBasinType']),
        'groupId': !exists(json, 'groupId') ? undefined : json['groupId'],
        'hydrologicalCodeType': CodelistItemInputFromJSON(json['hydrologicalCodeType']),
        'instBranchType': CodelistItemInputFromJSON(json['instBranchType']),
        'locationDescription': !exists(json, 'locationDescription') ? undefined : json['locationDescription'],
        'mapSheetNumber': !exists(json, 'mapSheetNumber') ? undefined : json['mapSheetNumber'],
        'montPointType': CodelistItemInputFromJSON(json['montPointType']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'placeName': !exists(json, 'placeName') ? undefined : json['placeName'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'positionType': CodelistItemInputFromJSON(json['positionType']),
        'statusType': CodelistItemInputFromJSON(json['statusType']),
        'countryType': CodelistItemInputFromJSON(json['countryType']),
        'areaType': CodelistItemInputFromJSON(json['areaType']),
        'regionType': CodelistItemInputFromJSON(json['regionType']),
        'districtType': CodelistItemInputFromJSON(json['districtType']),
        'municipalityType': CodelistItemInputFromJSON(json['municipalityType']),
        'municipalityForeignType': CodelistItemInputFromJSON(json['municipalityForeignType']),
        'landRegisterType': CodelistItemInputFromJSON(json['landRegisterType']),
        'effectiveTo': !exists(json, 'effectiveTo') ? undefined : json['effectiveTo'],
        'effectiveFrom': !exists(json, 'effectiveFrom') ? undefined : json['effectiveFrom'],
        'undergroundWater': !exists(json, 'undergroundWater') ? undefined : UndergroundWaterCodelistItemInputGwBodyItemInputFromJSON(json['undergroundWater']),
        'gps': !exists(json, 'gps') ? undefined : GPSCodelistItemInputFromJSON(json['gps']),
        'other': !exists(json, 'other') ? undefined : OtherCodelistItemInputFromJSON(json['other']),
        'code': !exists(json, 'code') ? undefined : json['code'],
        'waterCourse': !exists(json, 'waterCourse') ? undefined : WaterCourseInputForMonitoringPointCodelistItemInputFromJSON(json['waterCourse']),
        'waterWithdrawal': !exists(json, 'waterWithdrawal') ? undefined : WaterWithdrawalInputCodelistItemInputFromJSON(json['waterWithdrawal']),
        'monitorings': !exists(json, 'monitorings') ? undefined : ((json['monitorings'] as Array<any>).map(MonitoringCodelistItemInputRequiredFromJSON)),
        'pointCategories': !exists(json, 'pointCategories') ? undefined : ((json['pointCategories'] as Array<any>).map(PointCategoryCodelistItemInputRequiredFromJSON)),
        'places': !exists(json, 'places') ? undefined : ((json['places'] as Array<any>).map(PlaceFromJSON)),
    };
}

export function MonitoringPointInputToJSON(value?: MonitoringPointInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'basinType': CodelistItemInputToJSON(value.basinType),
        'assesmentBasinType': CodelistItemInputToJSON(value.assesmentBasinType),
        'partialBasinType': CodelistItemInputToJSON(value.partialBasinType),
        'subBasinType': CodelistItemInputToJSON(value.subBasinType),
        'groupId': value.groupId,
        'hydrologicalCodeType': CodelistItemInputToJSON(value.hydrologicalCodeType),
        'instBranchType': CodelistItemInputToJSON(value.instBranchType),
        'locationDescription': value.locationDescription,
        'mapSheetNumber': value.mapSheetNumber,
        'montPointType': CodelistItemInputToJSON(value.montPointType),
        'name': value.name,
        'placeName': value.placeName,
        'note': value.note,
        'positionType': CodelistItemInputToJSON(value.positionType),
        'statusType': CodelistItemInputToJSON(value.statusType),
        'countryType': CodelistItemInputToJSON(value.countryType),
        'areaType': CodelistItemInputToJSON(value.areaType),
        'regionType': CodelistItemInputToJSON(value.regionType),
        'districtType': CodelistItemInputToJSON(value.districtType),
        'municipalityType': CodelistItemInputToJSON(value.municipalityType),
        'municipalityForeignType': CodelistItemInputToJSON(value.municipalityForeignType),
        'landRegisterType': CodelistItemInputToJSON(value.landRegisterType),
        'effectiveTo': value.effectiveTo,
        'effectiveFrom': value.effectiveFrom,
        'undergroundWater': UndergroundWaterCodelistItemInputGwBodyItemInputToJSON(value.undergroundWater),
        'gps': GPSCodelistItemInputToJSON(value.gps),
        'other': OtherCodelistItemInputToJSON(value.other),
        'code': value.code,
        'waterCourse': WaterCourseInputForMonitoringPointCodelistItemInputToJSON(value.waterCourse),
        'waterWithdrawal': WaterWithdrawalInputCodelistItemInputToJSON(value.waterWithdrawal),
        'monitorings': value.monitorings === undefined ? undefined : ((value.monitorings as Array<any>).map(MonitoringCodelistItemInputRequiredToJSON)),
        'pointCategories': value.pointCategories === undefined ? undefined : ((value.pointCategories as Array<any>).map(PointCategoryCodelistItemInputRequiredToJSON)),
        'places': value.places === undefined ? undefined : ((value.places as Array<any>).map(PlaceToJSON)),
    };
}

