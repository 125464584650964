/* tslint:disable */
/* eslint-disable */
/**
 * Supporting registers API
 * SHMU API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CodeListItemBasicRecord } from './CodeListItemBasicRecord';
import {
    CodeListItemBasicRecordFromJSON,
    CodeListItemBasicRecordFromJSONTyped,
    CodeListItemBasicRecordToJSON,
} from './CodeListItemBasicRecord';

/**
 * 
 * @export
 * @interface WaterStructConduitResponseRecord
 */
export interface WaterStructConduitResponseRecord {
    /**
     * 
     * @type {number}
     * @memberof WaterStructConduitResponseRecord
     */
    id?: number | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof WaterStructConduitResponseRecord
     */
    type?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructConduitResponseRecord
     */
    length?: number | null;
    /**
     * 
     * @type {CodeListItemBasicRecord}
     * @memberof WaterStructConduitResponseRecord
     */
    typeSew?: CodeListItemBasicRecord | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructConduitResponseRecord
     */
    lengthSew?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructConduitResponseRecord
     */
    levelDiff?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WaterStructConduitResponseRecord
     */
    desFlow?: number | null;
}

/**
 * Check if a given object implements the WaterStructConduitResponseRecord interface.
 */
export function instanceOfWaterStructConduitResponseRecord(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WaterStructConduitResponseRecordFromJSON(json: any): WaterStructConduitResponseRecord {
    return WaterStructConduitResponseRecordFromJSONTyped(json, false);
}

export function WaterStructConduitResponseRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): WaterStructConduitResponseRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'type': !exists(json, 'type') ? undefined : CodeListItemBasicRecordFromJSON(json['type']),
        'length': !exists(json, 'length') ? undefined : json['length'],
        'typeSew': !exists(json, 'typeSew') ? undefined : CodeListItemBasicRecordFromJSON(json['typeSew']),
        'lengthSew': !exists(json, 'lengthSew') ? undefined : json['lengthSew'],
        'levelDiff': !exists(json, 'levelDiff') ? undefined : json['levelDiff'],
        'desFlow': !exists(json, 'desFlow') ? undefined : json['desFlow'],
    };
}

export function WaterStructConduitResponseRecordToJSON(value?: WaterStructConduitResponseRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': CodeListItemBasicRecordToJSON(value.type),
        'length': value.length,
        'typeSew': CodeListItemBasicRecordToJSON(value.typeSew),
        'lengthSew': value.lengthSew,
        'levelDiff': value.levelDiff,
        'desFlow': value.desFlow,
    };
}

